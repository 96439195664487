import SearchIcon from '@mui/icons-material/Search';
import { InputBase, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../Buttons/RoundedButton';
import { WorkspaceDivider, WorkspaceRaisedContainer } from '../components';
import { useMembers } from '../mocks';
import MembersListRow from './MembersListRow';
const useStyles = makeStyles((theme) => ({
    membersActionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 24,
    },
    searchInputContainer: {
        alignItems: 'center',
        border: `1px solid ${theme.palette.ava.blue2}`,
        borderRadius: '100px',
        display: 'flex',
        padding: '8px 16px',
    },
    searchIcon: {
        fill: theme.palette.ava.blue2,
    },
    searchInput: {
        color: theme.palette.ava.blue2,
        fontSize: 18,
        lineHeight: 24,
        marginLeft: 16,
        opacity: 1,
    },
    inviteBtn: {
        padding: '8px 24px',
    },
    inviteBtnText: {
        fontSize: '18px',
        lineHeight: '24px',
    },
    listContainer: {
        display: 'flex',
    },
    rowContainer: {
        display: 'flex',
        width: '100%',
        margin: '8px 0',
        justifyContent: 'space-between',
    },
    column: {
        flex: 1,
    },
}));
const MembersList = () => {
    const members = useMembers();
    const classes = useStyles();
    const { t } = useTranslation();
    const columnHeadingKeys = [
        'workspace.members.member',
        'workspace.members.role',
        'workspace.members.email',
        'workspace.members.license',
        'workspace.members.captionQuality',
        'workspace.members.scribeLimit',
    ];
    return (React.createElement(WorkspaceRaisedContainer, null,
        React.createElement(Typography, { variant: "h6" }, t('workspace.members.members')),
        React.createElement(WorkspaceDivider, null),
        React.createElement("div", { className: classes.membersActionsContainer },
            React.createElement("div", { className: classes.searchInputContainer },
                React.createElement(SearchIcon, { className: classes.searchIcon }),
                React.createElement(InputBase, { className: classes.searchInput, placeholder: t('workspace.members.search') })),
            React.createElement(RoundedButton, { className: classes.inviteBtn, color: "blue" },
                React.createElement(Typography, { className: classes.inviteBtnText }, '+ ' + t('workspace.members.inviteNewMembers')))),
        React.createElement("div", null,
            React.createElement("div", { className: classes.rowContainer }, columnHeadingKeys.map((key) => (React.createElement(Typography, { className: classes.column, key: key, variant: "h6" }, t(key))))),
            members.map((member) => (React.createElement("div", { className: classes.rowContainer },
                React.createElement(MembersListRow, Object.assign({}, member, { columnClass: classes.column, key: member.avaName, onRoleChange: (newRole) => console.log(newRole) }))))))));
};
export default MembersList;
