import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../utils';
import MobileDownloadButton from './MobileDownloadButton';
const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    heading: {
        marginBottom: 20,
    },
    button: {
        width: '100%',
        minWidth: 210,
        backgroundColor: theme.palette.ava.blue,
        color: theme.palette.ava.white,
    },
    buttonContained: {
        '&:hover': {
            backgroundColor: theme.palette.ava.blue,
        },
    },
    buttonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
    buttonMainText: {
        fontSize: 20,
        fontWeight: 600,
    },
    buttonSubText: {
        marginTop: -10,
        fontSize: 16,
        opacity: 0.8,
    },
}));
const RatingAverage = ({ userExists, hostName, onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement("div", { className: classes.content }, !userExists && (React.createElement(React.Fragment, null,
        React.createElement(Typography, { className: classes.heading, variant: "h5" },
            t('conversation.over.planOnSpeakWith'),
            " ",
            hostName,
            " ",
            t('conversation.over.again?')),
        isMobile() ? (React.createElement(MobileDownloadButton, null)) : (React.createElement(Button, { key: "download_convo_btn", classes: {
                root: classes.button,
                contained: classes.buttonContained,
                label: classes.buttonLabel,
            }, variant: "contained", onClick: onClick },
            React.createElement("span", { className: classes.buttonMainText }, t('conversation.over.downloadAvaApp')),
            React.createElement("span", { className: classes.buttonSubText }, t('conversation.over.forAMagicalExperience'))))))));
};
export default RatingAverage;
