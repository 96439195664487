import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { call, put } from 'redux-saga/effects';
import { unvalidateLogin } from '../../actions/account/session';
import { dashboardReady, fetchUser, fetchUserInfos, newOrganization, updateUser } from '../../actions/account/user';
import firebase from '../../firebase';
import * as avaApi from '../../services/api/ava';
import { users } from '../../services/api/saas';
export function* login({ payload }) {
    yield put(fetchUser.request(payload.user));
}
export function* unvalidateLoginRequest() {
    yield firebase.auth().signOut();
}
export function* fetchUserRequest({ payload }) {
    try {
        const orgType = window.localStorage.getItem('account_orgType');
        const res = yield call(users.getOrCreateUser, {
            name: payload ? payload.name : '',
            type: orgType,
        });
        if (res.data.user && res.data.user.parse) {
            yield put(fetchUser.success(res.data));
            yield put(fetchUserInfos.request());
            //window.browserHistory.push('/account/dashboard');
            window.navigate('/account/dashboard');
        }
        else {
            yield put(unvalidateLogin());
            if (orgType === 'individual') {
                // window.browserHistory.push('/account/login/individual');
                window.navigate('/account/login/individual');
            }
            else if (!orgType) {
                // window.browserHistory.push('/account/start');
                window.navigate('/account/start');
            }
        }
    }
    catch (err) {
        yield put(fetchUser.failure(err.response));
    }
}
export function* updateUserRequest({ payload }) {
    try {
        const { role } = payload;
        const { data } = yield call(users.getOrCreateUser);
        if (data.user && data.user.parse) {
            const { user } = data;
            yield call(avaApi.users.updateRole, {
                avaId: user.parse.avaId,
                firebaseAuthUID: user.parse.firebaseAuthUID,
                role,
            });
            yield put(updateUser.success(data));
        }
        if (payload.reset) {
            yield window.open('/', '_self');
        }
    }
    catch (err) {
        Sentry.captureException(err);
        yield put(updateUser.failure(err.response));
    }
}
export function* fetchUserInfoRequest() {
    try {
        const { language } = i18n;
        const res = yield call(users.getUser, language);
        yield put(fetchUserInfos.success(res.data));
    }
    catch (err) {
        const { data } = err.response;
        if (data.ok === false &&
            data.error &&
            data.error.error &&
            data.error.error.content.missing &&
            data.error.error.content.missing.organization) {
            const isIndividual = window.localStorage.getItem('account_orgType') === 'individual';
            if (isIndividual) {
                const organization = {
                    name: '',
                    type: 'individual',
                };
                yield put(newOrganization.request(organization));
            }
            else {
                //window.browserHistory.push('/account/organization/create');
                window.navigate('/account/organization/create');
            }
        }
        else if (err && err.response && err.response.data.ok === false) {
            yield put(unvalidateLogin());
            //window.browserHistory.push('/account/login');
            window.navigate('/account/login');
        }
        yield put(fetchUserInfos.failure(err.response));
    }
}
export function* handleFetchInfoUser({ payload }) {
    if (!payload.selfLicence.licence && !payload.organization) {
        //window.browserHistory.push('/account/organization/create');
        window.navigate('/account/organization/create');
    }
    yield put(dashboardReady());
}
export function* logout() {
    yield firebase.auth().signOut();
}
export function* updateLang() {
    var _a, _b;
    if ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.pathname) === null || _b === void 0 ? void 0 : _b.includes('/buy')) {
        yield put(fetchUserInfos.request());
    }
}
