import { makeStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
const useStyles = makeStyles({
    dot: {
        animation: `1s $blink infinite`,
        '&:nth-child(1)': {
            animationDelay: '0ms',
        },
        '&:nth-child(2)': {
            animationDelay: '250ms',
        },
        '&:nth-child(3)': {
            animationDelay: '500ms',
        },
    },
    '@keyframes blink': {
        '50%': {
            color: 'transparent',
        },
    },
});
const AsrQueueingMessageLoader = ({ value }) => {
    const classes = useStyles();
    return (React.createElement("span", null, value && (React.createElement("span", null,
        React.createElement("span", { className: classes.dot }, "."),
        React.createElement("span", { className: classes.dot }, "."),
        React.createElement("span", { className: classes.dot }, ".")))));
};
const mapStateToProps = ({ scribeConversation: { transcripts } }, { transcriptId }) => {
    var _a, _b;
    return ({
        value: ((_b = (_a = transcripts[transcriptId]) === null || _a === void 0 ? void 0 : _a.mutationsQueuedByHumanMutation) === null || _b === void 0 ? void 0 : _b.length) > 0,
    });
};
export default connect(mapStateToProps, {})(AsrQueueingMessageLoader);
