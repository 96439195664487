var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { selectAudioParamsSent } from '../../selectors/audioRecorder';
import { selectSncfConsentGiven } from '../../selectors/avaTranslate';
import { getOS } from '../../utils';
import { sendMbackendMessage } from '../../utils/ws-v1';
import { setSncfConsentDialogShown } from './avaTranslate';
import { updateIsInConversation } from './conversation';
const initializeRecordingContext = () => {
    return {
        audioProcessor: null,
        audioContext: null,
        sessionId: '',
        sources: [],
        streamNames: [],
        streams: [],
        volumeInterval: null,
    };
};
const getInitialState = () => {
    let needInternalAudioAccess = false;
    let microphoneAccess = 'granted';
    if (window.isElectron) {
        // We ask electron to check the mac internal audio config
        const audioSetup = window.electronIPC.sendSyncCheckAudioSetup_UNSAFE();
        if (audioSetup) {
            needInternalAudioAccess = !audioSetup.internalAudio;
            microphoneAccess = audioSetup.micAccess;
        }
    }
    return {
        hasMicrophoneRestarted: false,
        isRestartingMicrophone: false,
        recordingContext: initializeRecordingContext(),
        allMicsSelected: [],
        recording: false,
        availableMics: [],
        onlyInternalAudio: false,
        audioParamsSent: false,
        needInternalAudioAccess,
        microphoneAccess,
        micIdSelected: undefined,
    };
};
export const audioRecorderSlice = createSlice({
    name: 'audioRecorder',
    initialState: getInitialState,
    reducers: {
        resetAudioRecorderState(state) {
            const oldMicsSelected = state.allMicsSelected;
            const oldRecording = state.recording;
            const oldAvailableMics = state.availableMics;
            const oldMicrophoneAccess = state.microphoneAccess;
            Object.assign(state, getInitialState());
            // TODO: Audio recording currently expects those two value to remain
            // unchanged through state reset. Please fix.
            state.allMicsSelected = oldMicsSelected;
            state.recording = oldRecording;
            state.availableMics = oldAvailableMics;
            state.microphoneAccess = oldMicrophoneAccess;
        },
        resetRecordingContext(state) {
            state.recordingContext = initializeRecordingContext();
        },
        setHasMicrophoneRestarted(state, { payload }) {
            state.hasMicrophoneRestarted = payload;
        },
        setIsRestartingMicrophone(state, { payload }) {
            state.isRestartingMicrophone = payload;
        },
        setRecordingContext(state, { payload }) {
            state.recordingContext = payload;
        },
        setAvailableMics(state, { payload }) {
            state.availableMics = payload;
        },
        setOnlyInternalAudio(state, { payload }) {
            state.onlyInternalAudio = payload;
        },
        setRecordingState(state, { payload }) {
            state.recording = payload;
        },
        setAudioParamsSent(state, { payload }) {
            state.audioParamsSent = payload;
        },
        setupMacAudioComplete(state) {
            state.microphoneAccess = 'granted';
            state.needInternalAudioAccess = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updateIsInConversation.pending, (state, { meta: { arg } }) => {
            if (arg === false) {
                state.allMicsSelected = [];
                if (state.micIdSelected) {
                    const mic = state.availableMics && state.availableMics.find((m) => m.value === state.micIdSelected);
                    if (mic) {
                        state.allMicsSelected.push(mic.label);
                    }
                }
            }
        });
        builder.addCase(setMicId.fulfilled, (state, { payload: { micId, updatedState } }) => {
            state.micIdSelected = micId;
            if (updatedState === null || updatedState === void 0 ? void 0 : updatedState.allMicsSelected) {
                state.allMicsSelected = updatedState.allMicsSelected;
            }
        });
    },
});
export const audioRecorderReducer = audioRecorderSlice.reducer;
export const { resetAudioRecorderState, resetRecordingContext, setHasMicrophoneRestarted, setIsRestartingMicrophone, setRecordingContext, setAvailableMics, setOnlyInternalAudio, setAudioParamsSent, setupMacAudioComplete, } = audioRecorderSlice.actions;
const { setRecordingState } = audioRecorderSlice.actions;
export const setRecording = createAsyncThunk('audioRecorder/setRecording', (recording, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const socket = state.v1Session.v1Socket;
    if (recording && !selectSncfConsentGiven(state)) {
        // Can't begin recording if SNCF consent was not given
        dispatch(setSncfConsentDialogShown(true));
        return;
    }
    dispatch(setRecordingState(recording));
    if (!recording && selectAudioParamsSent(state) && socket) {
        setTimeout(() => {
            // The 'mute' message is sent to the backend with a delay, because
            // if any audio packet is sent after 'mute', then the backend
            // will try to reset audio recording with 'reset-recorder' message
            // (see the function 'startConversationReadClient' below).
            // However, resetting audio recorder means it will start recording again.
            // So, it can happen that 'stopRecord()' sometimes doesn't really stop
            // recording.
            //
            // The delay here gives audio recorder time  to gracefully
            // shutdown all the streams.
            sendMbackendMessage(socket, {
                type: 'mute',
            });
        }, 500);
    }
}));
export const setMicId = createAsyncThunk('audioRecorder/setMicId', ({ micId, oldMic }, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const audioState = state.audioRecorder;
    const micSelected = audioState.availableMics && audioState.availableMics.find((m) => m.value === micId);
    if (window.isElectron && getOS() === 'Mac') {
        const { participants } = state.scribeConversation.status;
        const oldMicInternal = oldMic && oldMic.label.startsWith('Ava Computer Audio');
        const newMicInternal = micSelected && micSelected.label.startsWith('Ava Computer Audio');
        const shouldActiveMacInternalAudio = !oldMicInternal && newMicInternal;
        const conversationParticipants = participants || [];
        const shouldDeactivateMacInternalAudio = (oldMicInternal && !newMicInternal) || conversationParticipants.length > 1;
        if (shouldActiveMacInternalAudio) {
            window.electronIPC.sendActivateMacInternalAudio();
            // notify being true means we send a notification
            return { micId, notify: true };
        }
        else if (shouldDeactivateMacInternalAudio) {
            window.electronIPC.sendDeactivateMacInternalAudio();
            return { micId, notify: false };
        }
    }
    if (micSelected && audioState.micIdSelected !== micId) {
        // we use immer here since allMicsSelected becomes readOnly and only way to prevent an error
        // inside the thunk is to use produce
        const updatedState = produce(audioState, (draftState) => {
            const set = new Set(draftState.allMicsSelected);
            if (!set.has(micSelected.label))
                draftState.allMicsSelected.push(micSelected.label);
        });
        return { micId, notify: false, updatedState };
    }
    return { micId, notify: false, updatedState: null };
}));
