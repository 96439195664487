/* eslint-disable no-shadow */
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { Tooltip, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import { withStyles, withTheme } from '@mui/styles';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import i18next from 'i18next';
import { withSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import makeComponentTrashable from 'trashable-react';
import AvaLogoImg from '../../../assets/images/logo.png';
import { clearScribeConversationState } from '../../actions';
import ConnectToMeetingsContainer from '../../modules/AvaConnect/ConnectToMeetingsContainer';
import ConnectToMeetingsMobileContainer from '../../modules/AvaConnect/ConnectToMeetingsMobileContainer';
import AddParticipant from '../../modules/scribe/AddParticipant';
import AudioChunkBuffer from '../../modules/scribe/AudioChunkBuffer';
import { BackendBadge, getBadge } from '../../modules/scribe/BackendBadge';
import CallListContainer from '../../modules/scribe/CallListContainer';
import FullScreenHamburger from '../../modules/scribe/FullScreenHamburger';
import ScribeSpeakerAudioSwitchContainer from '../../modules/scribe/ScribeSpeakerAudioSwitchContainer';
import SelectAccuracyButton from '../../modules/scribe/SelectAccuracyButton';
import UpdateConvoMeta from '../../modules/scribe/UpdateConvoMeta';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectQuickSetupShown } from '../../selectors/combined';
import { selectInTwilioCalls, selectIsInConversation } from '../../selectors/conversation';
import { selectIsRecallAIHost } from '../../selectors/recallAI';
import { selectIsScribing } from '../../selectors/scribe';
import { selectDisplayShortcuts, selectElectronCaptionMode, selectFullScreen, selectIsConnectToMeetingOpen, selectIsSideBarOpen, } from '../../selectors/ui';
import { selectAvaId } from '../../selectors/userProfile';
import { selectBackends, selectDisplayDev } from '../../selectors/v1Session';
import { setFullScreen } from '../../store/slices/uiState';
import { toggleSideBar } from '../../store/slices/uiState';
import { useAppDispatch } from '../../store/store';
import { useAppSelector } from '../../store/store';
import { SNCF } from '../../utils';
import { getIsMac } from '../../utils/electron';
import { useShouldShowNewSidebarAndHeader } from '../../utils/layout';
import { isHost } from '../../utils/status';
import { isDefaultRoomId } from '../../utils/ws-v1';
import DebugWindow from '../Debug/DebugWindow';
import JoinConversationButton from '../JoinConversation/Button';
import Offline from '../Network/Offline';
import ParticipantList from '../ParticipantList';
import HomeView from './HomeView';
import { SNCFConsentButton } from './SNCFConsentButton';
const getConversationModeColor = (conversationMode) => {
    switch (conversationMode) {
        case 'public-muted':
            return 'darkgreen';
        case 'public':
            return 'darkhaki';
        case 'private':
            return 'tomato';
        case 'group':
            return 'steelblue';
        default:
            return 'red';
    }
};
const hideHeaderButtonsText = (width, participantCount, isConnectToMeetingsOpen) => {
    // width of logo + hamburger
    const logoWidth = 300;
    // aprox width of buttons based on language
    const buttonsWidth = i18next.language === 'fr' ? 500 : 430;
    // width of all avatars
    const avatarWidth = participantCount * 52;
    // recallAI adds more density:
    const recallWidth = isConnectToMeetingsOpen ? 220 : 150;
    return logoWidth + buttonsWidth + avatarWidth + recallWidth > width;
};
const styles = (theme) => {
    return {
        appBar: {
            boxShadow: 'none',
            borderBottom: `1px solid ${theme.palette.border}`,
        },
        signIn: {
            '&:hover': {
                color: theme.palette.text.primary,
            },
            color: theme.palette.text.primary,
            fontWeight: 700,
            marginRight: 4,
        },
        windowsTitlebarButton: {
            '&:focus': {
                outline: 'none',
            },
            background: 'transparent',
            border: 'none',
            cursor: 'default',
            fontSize: 12,
            height: '100%',
            padding: 0,
            WebkitAppearance: 'none',
            width: 46,
        },
        windowsTitlebarButtonLight: {
            '&:hover': {
                background: 'rgba(23,23,23,0.1)',
            },
            '&:active': {
                background: 'rgba(23,23,23,0.2)',
            },
            '&>svg>g': {
                fill: '#171717',
            },
        },
        windowsTitlebarButtonDark: {
            '&:hover': {
                background: 'rgba(255,255,255,0.1)',
            },
            '&:active': {
                background: 'rgba(255,255,255,0.2)',
            },
            color: '#FFFFFF',
        },
        windowsTitlebarCloseButton: {
            '&:hover': {
                background: '#E81123',
                '&>svg>g': {
                    fill: '#FFFFFF',
                },
            },
            '&:active': {
                background: '#F1707A',
                '&>svg>g': {
                    fill: '#171717',
                },
            },
        },
        scribeLogo: {
            margin: '0px',
            marginBottom: '5px',
            marginLeft: '10px',
            '--webkit-app-region': 'drag',
        },
        logo: {
            width: '100px',
        },
        joinConversation: {
            display: 'flex',
            alignItems: 'center',
        },
        existingRoom: {
            color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
            fontSize: 24,
            lineHeight: '31.2px',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        arrowRight: {
            marginRight: 11,
            color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
            fontSize: 24,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    };
};
function ScribeHeader({ classes, features, speakers, status, theme, className, }) {
    let ref;
    const [hideText, setHideText] = useState(false);
    const displayDev = useAppSelector(selectDisplayDev);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const avaId = useAppSelector(selectAvaId);
    const fullScreen = useAppSelector(selectFullScreen);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const displayShortcuts = useAppSelector(selectDisplayShortcuts);
    const isSidebarOpen = useAppSelector(selectIsSideBarOpen);
    const backends = useAppSelector(selectBackends);
    const scribing = useAppSelector(selectIsScribing);
    const isInConversation = useAppSelector(selectIsInConversation);
    const inTwilioCalls = useAppSelector(selectInTwilioCalls);
    const dispatch = useAppDispatch();
    const tabletOrMobile = useMediaQuery(theme.breakpoints.down('md')) && !window.isElectron;
    const isRecallAIHost = useAppSelector(selectIsRecallAIHost);
    const isConnectToMeetingsOpen = useAppSelector(selectIsConnectToMeetingOpen);
    const quickSetupShown = useAppSelector(selectQuickSetupShown);
    const location = useLocation();
    const { t } = useTranslation();
    const handleUpdateFullScreen = (e) => {
        e.preventDefault();
        dispatch(setFullScreen(!fullScreen));
        dispatch(toggleSideBar());
    };
    useEffect(() => {
        if (ref && ref.off) {
            ref.off();
        }
    }, []);
    useEffect(() => {
        if (firebaseUser) {
            Sentry.setUser({ email: firebaseUser.email, id: avaId });
        }
    }, [avaId, firebaseUser]);
    const participants = (status || {}).participants || [];
    const transcriptOwnersId = (status || {}).transcriptOwners || [];
    const backgroundColor = theme.palette.secondary[theme.palette.mode];
    const backend = getBadge(backends);
    useEffect(() => {
        const handleResize = () => {
            setHideText(hideHeaderButtonsText(window.innerWidth, participants.length + transcriptOwnersId.length, isConnectToMeetingsOpen));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [participants, transcriptOwnersId]);
    const isMac = getIsMac();
    let headerTop = 0;
    if (window.isElectron) {
        headerTop = isMac ? 28 : 32;
    }
    const showNewSidebarAndHeader = useShouldShowNewSidebarAndHeader({
        electronCaptionMode,
        firebaseUser,
        isInConversation,
    });
    if (quickSetupShown)
        return null;
    if (showNewSidebarAndHeader) {
        return (React.createElement(HomeView, { className: className, style: {
                top: headerTop,
                width: isSidebarOpen ? 'calc(100% - 272px)' : 'calc(100% - 90px)',
            } }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(AppBar, { className: classnames(classes.appBar, className), style: {
                zIndex: 1300,
                WebkitAppRegion: 'no-drag',
                top: headerTop,
                display: fullScreen ? 'none' : 'flex',
            } },
            React.createElement(Offline, null),
            React.createElement(Toolbar, { style: {
                    background: backgroundColor,
                    height: 67,
                    justifyContent: 'center',
                    display: fullScreen ? 'none' : 'flex',
                } },
                firebaseUser && !location.pathname.includes('/login') && (
                // eslint-disable-next-line
                React.createElement("div", { 
                    // {...getTogglerProps()}
                    role: "button", onClick: () => dispatch(toggleSideBar()), style: {
                        outline: 'none',
                        display: 'flex',
                    } },
                    React.createElement(MenuOutlinedIcon, { style: {
                            fontSize: 30,
                            marginRight: 20,
                            cursor: 'pointer',
                            color: theme.palette.text.primary,
                        } }))),
                React.createElement(Hidden, { smDown: true }, isInConversation ? (React.createElement("img", { className: classes.logo, src: AvaLogoImg, alt: "ava logo", "data-qa": "avaLogo" })) : (React.createElement("a", { href: "/", "data-qa": "avaLogo" },
                    React.createElement("img", { className: classes.logo, src: AvaLogoImg, alt: "ava logo" })))),
                scribing && React.createElement(ScribeSpeakerAudioSwitchContainer, null),
                React.createElement(CallListContainer, null),
                scribing && React.createElement(AudioChunkBuffer, null),
                status && status.id && !isDefaultRoomId(status.id) && isInConversation && (React.createElement(ParticipantList, { displayShortcuts: displayShortcuts, participants: participants, roomSpeakers: status.speakers, scribe: scribing, share: true, speakers: speakers, status: status, theme: theme, transcriptOwnersId: transcriptOwnersId })),
                React.createElement("div", { style: { flexGrow: 1 } }),
                displayDev && (React.createElement(Hidden, { smDown: true },
                    React.createElement(BackendBadge, { style: {
                            backgroundColor: backend.backgroundColor,
                        } },
                        backend.name,
                        features.ok))),
                scribing && status && status.conversationMode && (React.createElement(BackendBadge, { style: {
                        backgroundColor: getConversationModeColor(status.conversationMode),
                    } }, status.conversationMode)),
                scribing && status && status.title !== undefined && (React.createElement(UpdateConvoMeta, { title: status.title, roLink: status.links ? status.links.ro : '' })),
                React.createElement(Tooltip, { disableInteractive: true, title: !isRecallAIHost
                        ? t('connectToOnlineMeetings.onlyHost')
                        : t('connectToOnlineMeetings.title'), "aria-label": "share-link", enterDelay: 300, leaveDelay: 200 },
                    React.createElement("div", { style: { order: `${tabletOrMobile ? 1 : 0}` } }, isInConversation && features['recall-ai'] && tabletOrMobile ? (React.createElement("div", null,
                        React.createElement(ConnectToMeetingsMobileContainer, null))) : isInConversation && features['recall-ai'] && !tabletOrMobile ? (React.createElement(ConnectToMeetingsContainer, { hideText: hideText })) : (React.createElement("div", { style: { display: 'none' } })))),
                React.createElement(Hidden, { smDown: true },
                    React.createElement("div", { style: { flexGrow: 1 } }),
                    React.createElement("div", { style: { flexGrow: 1 } })),
                React.createElement("div", { style: { flexGrow: 1 } }),
                React.createElement(DebugWindow, null),
                status && status.id && !isDefaultRoomId(status.id) && isHost(status, avaId) && isInConversation && (React.createElement(SelectAccuracyButton, { status: status, hideText: hideText })),
                status &&
                    status.id &&
                    !status.past &&
                    transcriptOwnersId &&
                    transcriptOwnersId.length > 0 &&
                    !isDefaultRoomId(status.id) &&
                    isInConversation && React.createElement(React.Fragment, null, !inTwilioCalls && React.createElement(AddParticipant, { scribe: scribing, hideText: hideText })),
                !window.isElectron && (!avaId || (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous)) && location.pathname === '/web/login' && (React.createElement("div", { className: classes.joinConversation },
                    React.createElement(JoinConversationButton, { header: true }))),
                SNCF && isInConversation && React.createElement(SNCFConsentButton, null))),
        !electronCaptionMode && (React.createElement(FullScreenHamburger, { updateFullScreen: (e) => handleUpdateFullScreen(e), fullScreen: fullScreen }))));
}
const mapStateToProps = ({ scribeConversation: { speakers, status }, userProfile: { features } }) => ({
    features,
    speakers,
    status,
});
export default withSnackbar(connect(mapStateToProps, {
    clearScribeConversationState,
})(withTheme(withStyles(styles)(makeComponentTrashable(withTranslation()(ScribeHeader))))));
