import MoreIcon from '@mui/icons-material/MoreHoriz';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../Buttons/RoundedButton';
import { CircularIconWrapper, WorkspaceDivider } from '../components';
import { useMembers } from '../mocks';
import MemberWithAssignedLicense from './MemberWithAssignedLicense';
const useStyles = makeStyles((theme) => ({
    topContainer: {
        margin: '24px 0',
    },
    countContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    count: {
        color: theme.palette.ava.blue1,
    },
    availableAssignContainer: {
        alignItems: 'center',
        backgroundColor: '#e9fbff',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '8px 16px',
    },
    noLicensesContainer: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.ava.blue2,
    },
}));
const AllocatedLicenses = ({ handleGoToAssign }) => {
    const membersWithAssignedLicenses = useMembers();
    const classes = useStyles();
    const { t } = useTranslation();
    const availableLicenseCount = 9;
    const proLicensesCount = 12;
    return (React.createElement("div", null,
        React.createElement("div", { className: classes.countContainer },
            React.createElement(Typography, { className: classes.count, variant: "h4" }, proLicensesCount),
            React.createElement(CircularIconWrapper, null,
                React.createElement(MoreIcon, null))),
        React.createElement("div", { className: classes.availableAssignContainer },
            React.createElement(Typography, null, `${availableLicenseCount} ${t('workspace.members.available')}`),
            React.createElement(RoundedButton, { color: "blue", onClick: handleGoToAssign }, t('workspace.members.assign'))),
        React.createElement(WorkspaceDivider, null),
        !membersWithAssignedLicenses.length && (React.createElement("div", { className: classes.noLicensesContainer },
            React.createElement(Typography, { variant: "h6" }, t('workspace.members.noLicensesAssigned')))),
        membersWithAssignedLicenses.length &&
            membersWithAssignedLicenses.map((member) => (React.createElement(MemberWithAssignedLicense, { avaName: member.avaName, avatar: member === null || member === void 0 ? void 0 : member.avatar, email: member.email, license: member.license, name: member.name })))));
};
export default AllocatedLicenses;
