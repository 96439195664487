import Box from '@mui/material/Box';
import React from 'react';
import AvaLogoSphere from '../../../assets/images/ava_logo_sphere.png';
import { ApplessParticipantListBadge } from './ApplessParticipantListBadge';
import { ApplessQRButton } from './ApplessQRButton';
export function ApplessHeader() {
    return (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            flexShrink: 0,
            height: 80,
            paddingX: 3,
            backgroundColor: 'white',
            borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        } },
        React.createElement(Box, { sx: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            } },
            React.createElement("img", { src: AvaLogoSphere, style: { height: 40 }, alt: "Ava logo" })),
        React.createElement(Box, { sx: { flexGrow: 1 } }),
        React.createElement(ApplessParticipantListBadge, null),
        React.createElement(ApplessQRButton, null)));
}
