import { Fab, Hidden, Tooltip } from '@mui/material';
import { withTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import AccuracyIcon from '../../../assets/icons/accuracy.svg';
import { SNCF } from '../../utils';
import * as segment from '../../utils/segment';
import SelectAccuracyDialog from './SelectAccuracyDialog';
const useStyles = makeStyles({
    fab: {
        backgroundColor: 'white',
        textTransform: 'none',
        fontSize: 18,
        color: '#102867',
        fontWeight: 600,
        height: 44,
        flexShrink: 0,
    },
    buttonText: {
        marginLeft: 10,
    },
});
function SelectAccuracyButton(props) {
    const { t, theme, status, hideText } = props;
    const classes = useStyles(theme);
    const [dialogOpen, setDialogOpen] = useState(false);
    if (SNCF)
        return null;
    return (React.createElement(React.Fragment, null,
        dialogOpen && React.createElement(SelectAccuracyDialog, { open: dialogOpen, setDialogOpen: setDialogOpen, status: status }),
        React.createElement(Tooltip, { title: t('accuracy.improveAccuracy'), "aria-label": "change-accuracy", enterDelay: 300, leaveDelay: 200, disableInteractive: true },
            React.createElement(Fab, { variant: "extended", className: classes.fab, "data-qa": "improveAccuracyButton", onClick: () => {
                    segment.track('Accuracy Settings Clicked');
                    setDialogOpen(true);
                } },
                React.createElement("img", { src: AccuracyIcon, alt: "accuracy" }),
                !hideText && (React.createElement(Hidden, { only: ['xs', 'sm'] },
                    React.createElement("span", { className: classes.buttonText }, t('accuracy.improveAccuracy'))))))));
}
export default withTheme(withTranslation()(SelectAccuracyButton));
