// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/AdelleSans-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/fonts/AdelleSans-Semibold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/fonts/AdelleSans-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../assets/fonts/AdelleSans-Light.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody {\n  overflow-x: hidden;\n}\nbody {\n  position: relative;\n}\n@font-face {\n  font-family: AdelleSans;\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\n}\n\n@font-face {\n  font-family: AdelleSans;\n  font-weight: 500;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\n}\n\n@font-face {\n  font-family: AdelleSans;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('opentype');\n}\n\n@font-face {\n  font-family: AdelleSans;\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('opentype');\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;EAEE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,+DAAkE;AACpE;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,+DAAsE;AACxE;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,+DAAqE;AACvE;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,+DAAmE;AACrE","sourcesContent":["html,\nbody {\n  overflow-x: hidden;\n}\nbody {\n  position: relative;\n}\n@font-face {\n  font-family: AdelleSans;\n  font-weight: 700;\n  src: url('../assets/fonts/AdelleSans-Bold.otf') format('opentype');\n}\n\n@font-face {\n  font-family: AdelleSans;\n  font-weight: 500;\n  src: url('../assets/fonts/AdelleSans-Semibold.otf') format('opentype');\n}\n\n@font-face {\n  font-family: AdelleSans;\n  font-weight: 400;\n  src: url('../assets/fonts/AdelleSans-Regular.otf') format('opentype');\n}\n\n@font-face {\n  font-family: AdelleSans;\n  font-weight: 300;\n  src: url('../assets/fonts/AdelleSans-Light.otf') format('opentype');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
