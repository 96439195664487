import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useAutoScroll } from '../../hooks/useAutoScroll';
import { selectAllTranscripts } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
import { ApplessTranscriptItem } from './ApplessTranscriptItem';
export function ApplessTranscriptList() {
    const scrollableContainer = useRef(null);
    const allTranscripts = useAppSelector(selectAllTranscripts);
    const theme = useTheme();
    const { update, follow, showMoreButton } = useAutoScroll(scrollableContainer);
    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        update();
    }, [allTranscripts]);
    useEffect(() => {
        setTimeout(() => {
            setAnimate(true);
        }, 1000);
    });
    return (React.createElement(Box, { ref: scrollableContainer, sx: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            width: '100%',
            overflow: 'auto',
            backgroundColor: theme.palette.grey[100],
            flexGrow: 1,
            '::-webkit-scrollbar': {
                '-webkit-appearance': 'none',
            },
        } }, allTranscripts.map((transcript) => (React.createElement(React.Fragment, null,
        React.createElement(ApplessTranscriptItem, { transcript: transcript, animate: animate }))))));
}
