import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ArrowRight from '../../../assets/images/arrow-right.png';
import { setLangSelected } from '../../actions';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectIsRecording } from '../../selectors/audioRecorder';
import { selectStatus } from '../../selectors/legacy-conversation';
import { selectScribeTrainingFileUrl, selectScribeTrainingLang } from '../../selectors/scribe-dashboard';
import { updateSpeakerListened } from '../../store/slices/scribe';
import { scribeDashboardStreamReady } from '../../store/slices/scribeDashboard';
import { useAppDispatch } from '../../store/store';
const TrainingButton = () => {
    const scribeTrainingFileURL = useSelector(selectScribeTrainingFileUrl);
    const scribeTrainingLang = useSelector(selectScribeTrainingLang);
    const isRecording = useSelector(selectIsRecording);
    const status = useSelector(selectStatus);
    const audioRef = useRef(null);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const [socket] = useV1Socket();
    const [clicked, setClicked] = useState(false);
    useEffect(() => {
        const ac = new AudioContext();
        const sourceNode = ac.createMediaElementSource(audioRef.current);
        dispatch(scribeDashboardStreamReady({ sourceNode, ac }));
    }, [scribeTrainingFileURL, audioRef]);
    const onClick = () => {
        var _a;
        if (!status || !((_a = status.audioStreams) === null || _a === void 0 ? void 0 : _a.length))
            return;
        dispatch(updateSpeakerListened(status.audioStreams[0]));
        dispatch(setLangSelected(scribeTrainingLang));
        audioRef.current.play();
        setClicked(true);
    };
    return (React.createElement("div", { style: { display: clicked ? 'none' : 'inherit' } },
        React.createElement("audio", { crossOrigin: "anonymous", controls: true, ref: audioRef, preload: "true", style: { display: 'none' } },
            React.createElement("source", { src: scribeTrainingFileURL, type: "audio/webm" })),
        React.createElement(Fab, { color: "secondary", style: { backgroundColor: theme.palette.ava.green }, onClick: onClick, disabled: !isRecording },
            React.createElement("img", { src: ArrowRight, alt: "begin scribe training", height: "20" }))));
};
export default TrainingButton;
