import React from 'react';
export const styles = (theme) => ({
    drawerPaper: {
        [theme.breakpoints.down('sm')]: {
            width: 'calc(var(--vw) * 100)',
        },
        [theme.breakpoints.up('sm')]: {
            width: 270,
        },
        marginTop: 67,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            width: '0 !important',
        },
        backgroundColor: theme.palette.secondary[theme.palette.mode],
    },
    listItemText: {
        paddingRight: 0,
    },
    expandIcon: {
        minWidth: 0,
        '& i': {
            marginLeft: 0,
        },
    },
});
export const EmptyIcon = () => React.createElement("div", { style: { width: 24, marginRight: 16 } });
