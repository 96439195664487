import ClosedCaptionOutlinedIcon from '@mui/icons-material/ClosedCaptionOutlined';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Toggle from 'react-toggled';
import { ZoomBroadcastCaptionsPopup } from './ZoomBroadcastCaptionsPopup';
function ZoomBroadcastCaptionsMenuItem(props) {
    return (React.createElement(Toggle, null, ({ on, setOn, setOff }) => (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { onClick: setOn, style: {
                paddingLeft: 32,
            }, role: "button", button: true },
            React.createElement(ListItemIcon, null,
                React.createElement(ClosedCaptionOutlinedIcon, null)),
            React.createElement(ListItemText, null, props.t('conversation.zoomExport.broadcastToZoom'))),
        React.createElement(ZoomBroadcastCaptionsPopup, { on: on, setOff: setOff })))));
}
export default withTranslation()(ZoomBroadcastCaptionsMenuItem);
