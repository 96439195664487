var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CardActions, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { withStyles, withTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createNewConversation } from '../../actions';
import Footer from '../../components/Footer';
import Loading from '../../components/Loading';
import { LoginApple } from '../../components/LoginApple';
import { LoginEmail } from '../../components/LoginEmail';
import { LoginFacebook } from '../../components/LoginFacebook';
import { LoginGoogle } from '../../components/LoginGoogle';
import { LoginPhone } from '../../components/LoginPhone';
import { AVA_URL } from '../../constants';
import { appleProvider, authInstance, facebookProvider, googleProvider } from '../../firebase';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectMicrophoneAccess, selectNeedInternalAudioAccess } from '../../selectors/audioRecorder';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectLoggedIn, selectLoggedOut } from '../../selectors/combined';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectShowQuickSetup } from '../../selectors/quickSetup';
import { selectElectronAppLoaded, selectLoading } from '../../selectors/ui';
import { selectFeatures, selectIsProfileComplete } from '../../selectors/userProfile';
import { selectV1Token } from '../../selectors/v1Session';
import { initiateFirebaseLoginWithProvider } from '../../store/slices/auth';
import { setCreateConversationWhenReady } from '../../store/slices/conversation';
import { setElectronAppLoaded } from '../../store/slices/uiState';
import { useAppSelector } from '../../store/store';
import { getUrlParams } from '../../utils';
import AccountProviders from '../../utils/classes/AccountProviders';
import { getLanguageCode } from '../../utils/i18n';
import Explanation from '../account/components/Signup/Explanation';
import Testimonial from '../account/components/Signup/Testimonial';
import Login from '../login/Login';
import ScribeLoginEmail from './ScribeLoginEmail';
import ScribeLoginPhone from './ScribeLoginPhone';
import ScribeLoginSSO from './ScribeLoginSSO';
const styles = (theme) => ({
    message: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 44,
        '& > :not(:last-child)': {
            marginRight: 4,
        },
        [theme.breakpoints.down('md')]: {
            marginBottom: 150,
        },
    },
    link: {
        color: theme.palette.ava.blue,
        '&:link, &:visited, &:hover, &:active': {
            textDecoration: 'none',
        },
        '&:hover': {
            color: theme.palette.ava.blue1,
        },
    },
    middleContainer: {
        paddingBottom: 48,
        paddingTop: 96,
        maxWidth: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '85%',
    },
    title: {
        fontWeight: 700,
        color: theme.palette.ava.selfColorBlue,
    },
});
const ScribeWelcome = (props) => {
    var _a;
    const { classes, product, t } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [ws, wsStatus] = useV1Socket();
    const [accountExistsEmail, setAccountExistsEmail] = useState('');
    const [accountExistsProviders, setAccountExistsProviders] = useState(new AccountProviders().getProviders());
    const dispatch = useDispatch();
    const avaUser = useAppSelector((state) => state.userProfile.avaUser);
    const electronAppLoaded = useAppSelector(selectElectronAppLoaded);
    const features = useAppSelector(selectFeatures);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const microphoneAccess = useAppSelector(selectMicrophoneAccess);
    const needInternalAudioAccess = useAppSelector(selectNeedInternalAudioAccess);
    const showQuickSetup = useAppSelector(selectShowQuickSetup);
    const v1Token = useAppSelector(selectV1Token);
    const loggedIn = useAppSelector(selectLoggedIn);
    const loggedOut = useAppSelector(selectLoggedOut);
    const isProfileComplete = useAppSelector(selectIsProfileComplete);
    const isInConversation = useAppSelector(selectIsInConversation);
    const loading = useAppSelector(selectLoading);
    const urlForProfileSetup = isProfileComplete
        ? undefined
        : `/web/login/profile/user-name${((_a = avaUser === null || avaUser === void 0 ? void 0 : avaUser.parse) === null || _a === void 0 ? void 0 : _a.organizationId) ? '-subscribed' : ''}`;
    const branchId = localStorage.getItem('ava_branch_id') || '';
    const login = (provider) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield initiateFirebaseLoginWithProvider(dispatch, provider);
        }
        catch (error) {
            if (error.code === 'auth/account-exists-with-different-credential') {
                // TODO: This does not work as of 05/02
                const providers = yield authInstance.fetchSignInMethodsForEmail(error.email);
                const accountProviders = new AccountProviders();
                accountProviders.updateProviders(providers);
                setAccountExistsProviders(accountProviders.getProviders());
                setAccountExistsEmail(error.email);
                if (location.pathname.split('/')[1] === 'web') {
                    navigate('/web/login/account-exists');
                }
            }
        }
    });
    const checkProvidersForPasswordReset = (email) => __awaiter(void 0, void 0, void 0, function* () {
        const providers = yield authInstance.fetchSignInMethodsForEmail(email);
        if (providers.length === 0 || providers.includes('password')) {
            return false;
        }
        const accountProviders = new AccountProviders();
        accountProviders.updateProviders(providers);
        setAccountExistsProviders(accountProviders.getProviders());
        setAccountExistsEmail(email);
        if (location.pathname.split('/')[1] === 'web') {
            navigate('/web/login/account-exists');
        }
        return true;
    });
    const shouldStartConvoOnLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        // We need to figure out whether to start a conversation instantly or not.
        if (location.pathname.includes('/account/')) {
            return false;
        }
        let result = false;
        if (firebaseUser && !needInternalAudioAccess) {
            result = true;
        }
        const branchId = localStorage.getItem('ava_branch_id') || '';
        if (!branchId && window.isElectron && electronAppLoaded) {
            // Auto-start conversation on desktop app start
            // (except for one-click links -> for them the conversation will
            // auto start via another event)
            // Do not autostart on macOS if app was launched on login
            const launchedOnLogin = yield window.electronIPC.invokeCheckLaunchedOnLogin();
            result = !launchedOnLogin;
            dispatch(setElectronAppLoaded(false));
        }
        if (!window.isElectron && features.scribe) {
            // Never auto-start conversation for 'scribe' users on web
            result = false;
        }
        return result && !v1Token && !needInternalAudioAccess && microphoneAccess === 'granted' && !showQuickSetup;
    });
    useEffect(() => {
        if (!firebaseUser || !avaUser || product === 'account' || firebaseUser.isAnonymous)
            return;
        const inner = () => __awaiter(void 0, void 0, void 0, function* () {
            const request = localStorage.getItem('ava_request');
            const { search } = location;
            // If the user has never set up their hearing profile, we want to redirect them
            // to the profile creation page.
            if (!isProfileComplete && urlForProfileSetup) {
                navigate(urlForProfileSetup);
                return;
            }
            // If the user was signing in during the electron sign-in flow, we want to
            // redirect them.
            const queryParams = getUrlParams();
            if ((queryParams === null || queryParams === void 0 ? void 0 : queryParams.auth) === 'desktop') {
                // User has clicked 'Sign in with browser' in the desktop app. In this
                // case we have to redirect them back to a page where they can click a
                // button to redirect back to the desktop app.
                navigate(`/web/login/desktop${(queryParams === null || queryParams === void 0 ? void 0 : queryParams.tauri) ? '?tauri=true' : ''}`);
                return;
            }
            if (features.scribe && !window.isElectron && !v1Token && !branchId && !request) {
                navigate('/web/scribe-dashboard');
            }
            else {
                if (yield shouldStartConvoOnLogin()) {
                    if (wsStatus === 'online') {
                        createNewConversation()(dispatch, window.store.getState);
                    }
                    else {
                        dispatch(setCreateConversationWhenReady(true));
                    }
                }
                navigate(`/web/transcript${search}`);
            }
        });
        inner();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avaUser && avaUser.uid]);
    useEffect(() => {
        if (isInConversation && wsStatus === 'online') {
            navigate('/web/transcript');
        }
    }, [isInConversation]);
    const getAvaLanguageUrl = () => {
        const languageCode = getLanguageCode();
        if (languageCode !== 'en') {
            return `${AVA_URL}/${languageCode}`;
        }
        return AVA_URL;
    };
    const productPath = location.pathname.split('/')[1];
    const MiddleContainer = () => (React.createElement("div", { className: classes.middleContainer },
        React.createElement("div", { style: { flexGrow: 1 } }),
        React.createElement(Outlet, null)));
    const FooterContainer = () => (React.createElement(React.Fragment, null,
        React.createElement(Outlet, null),
        React.createElement(Footer, null)));
    // If the user is logged in - they will be redirected soon, so we show the
    // loading banner. This is also true if we are loading or already in conversation.
    if (loggedIn || loading || isInConversation) {
        return React.createElement(Loading, null);
    }
    // If the user is not logged in and not logged out - we show
    // the loading banner as long as they are not logged in anonymously.
    if (!loggedOut && !(firebaseUser && firebaseUser.isAnonymous)) {
        return React.createElement(Loading, null);
    }
    const LoginRoute = product === 'account' ? (React.createElement(Route, { index: true, element: 
        // account login
        React.createElement(Grid, { container: true },
            React.createElement(Grid, { item: true, xs: 12, md: 4 },
                React.createElement(Testimonial, null)),
            React.createElement(Grid, { item: true, xs: 12, md: 8, style: {
                    minHeight: 'calc(100vh - 114px)',
                } },
                React.createElement(Explanation, { loading: loading, handleLoginFacebook: () => login(facebookProvider), handleLoginApple: () => login(appleProvider), handleLoginGoogle: () => login(googleProvider), loginPhoneLink: "/account/login/phone", loginEmailLink: "/account/login/email" }))) })) : (React.createElement(Route, { index: true, element: React.createElement("div", null,
            React.createElement(Login, { loginWithApple: () => login(appleProvider), loginWithGoogle: () => login(googleProvider), loginWithFacebook: () => login(facebookProvider), productPath: productPath }),
            React.createElement("div", { className: classes.message },
                React.createElement("span", null, t('landing.agreement.use')),
                React.createElement("a", { className: classes.link, target: "_blank", href: `${getAvaLanguageUrl()}/terms` }, t('landing.agreement.terms')),
                React.createElement("span", null, t('landing.agreement.and')),
                React.createElement("a", { className: classes.link, target: "_blank", href: `${`${getAvaLanguageUrl()}/privacy`}` }, t('landing.agreement.privacy')))) }));
    return (React.createElement(Routes, null,
        React.createElement(Route, { path: "*", element: React.createElement(FooterContainer, null) },
            LoginRoute,
            React.createElement(Route, { path: "email", element: React.createElement(ScribeLoginEmail, { checkProvidersForPasswordReset: checkProvidersForPasswordReset }) }),
            React.createElement(Route, { path: "*", element: React.createElement(MiddleContainer, null) },
                React.createElement(Route, { path: "phone", element: React.createElement(ScribeLoginPhone, null) }),
                React.createElement(Route, { path: "sso", element: React.createElement(ScribeLoginSSO, null) }),
                React.createElement(Route, { path: "account-exists", element: React.createElement(Card, { style: {
                            boxShadow: 'none',
                            marginBottom: '20px',
                        } },
                        React.createElement(CardActions, null,
                            React.createElement("div", { style: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '0 auto 12px',
                                    maxWidth: 380,
                                } },
                                React.createElement(Typography, { variant: "h4", className: classes.title, align: "center", gutterBottom: true }, t('signin-messages.accountExistsHead')),
                                React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t('signin-messages.accountExistsBody', { email: accountExistsEmail })),
                                accountExistsProviders.facebook && React.createElement(LoginFacebook, { login: () => login(facebookProvider) }),
                                accountExistsProviders.google && React.createElement(LoginGoogle, { login: () => login(googleProvider) }),
                                accountExistsProviders.apple && React.createElement(LoginApple, { login: () => login(appleProvider) }),
                                accountExistsProviders.email && React.createElement(LoginEmail, { to: `/login/email` }),
                                accountExistsProviders.phone && React.createElement(LoginPhone, { to: `/login/phone` })))) }),
                React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: '/' + productPath }) })))));
};
export default withTranslation()(withTheme(withStyles(styles)(ScribeWelcome)));
