var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { v4 as uuid } from 'uuid';
import Phone from '../../../assets/icons/phone.png';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectAvaId } from '../../selectors/userProfile';
import { calls } from '../../services/api/ava';
import { useAppSelector } from '../../store/store';
import { getSnack } from '../../utils/snackbar';
const intercomLinks = {
    en: 'https://intercom.help/ava/en/articles/3837320-how-to-caption-a-conference-call-with-ava',
    fr: 'https://intercom.help/ava/fr/articles/3837320-comment-sous-titrer-ma-conference-telephonique-avec-ava',
};
const useStyles = makeStyles({
    dialInButton: {
        color: 'white',
        display: 'flex',
        flexGrow: 1,
        margin: 20,
        marginBottom: 10,
        marginTop: 0,
        textTransform: '',
        fontWeight: 600,
    },
    dialog: { width: 600 },
    dialogContent: { paddingBottom: 0 },
    invitation: { display: 'flex' },
    howToLink: {
        textAlign: 'center',
        textDecoration: 'underline',
        color: '#337ab7',
        marginBottom: 10,
    },
    title: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '2rem',
    },
    phoneCodeContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 8,
    },
    phone: { marginRight: 10, width: '100%' },
    code: { width: 160, flexShrink: 0 },
    password: {
        marginTop: 10,
        width: '100%',
    },
    phoneIconContainer: {
        display: 'flex',
        flexGrow: 1,
    },
    phoneIconContainerClose: {
        padding: 25,
        borderRadius: 75,
        margin: 'auto',
        marginBottom: 10,
    },
    phoneIcon: {
        width: 80,
    },
    progress: {
        position: 'absolute',
        width: 10,
        height: 10,
    },
});
export default function ConferenceCallPopup(props) {
    const classes = useStyles();
    const [phone, setPhone] = React.useState('');
    const [invitation, setInvitation] = React.useState('');
    const [code, setCode] = React.useState('');
    const [zoomPassword, setZoomPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [conferenceType, setConferenceType] = React.useState(undefined);
    const avaId = useAppSelector(selectAvaId);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const handlePhoneChange = (p) => {
        setPhone(p.replace(/[^0-9+]/g, ''));
    };
    const handleCodeChange = (c) => {
        setCode(c.replace(/[^0-9#*]/g, ''));
    };
    const handleZoomPasswordChange = (p) => {
        setZoomPassword(p.replace(/[^0-9#*]/g, ''));
    };
    const handleInvitationChange = (event) => {
        const inv = event.target.value;
        setInvitation(inv);
        const invitationType = [
            {
                reg: /to join by phone, dial ([+][ 0-9]*) and enter this PIN: ([ 0-9]*[#])/,
                name: 'google-meet',
                conference: true,
            },
            {
                reg: /https[:][/][/]([a-zA-Z0-9]*[.?])?zoom.us[/]j[/]([0-9]*)/,
                conference: true,
                name: 'zoom',
            },
            { reg: /([+][ 0-9]*),,([ 0-9]*[#]) /, name: 'one-tap', conference: true },
        ];
        invitationType.forEach(({ reg, name, conference }, i) => {
            const res = inv.match(reg);
            if (res && res.length) {
                if (i === 1) {
                    handlePhoneChange('+14086380968');
                    handleCodeChange(`${res[2]}#`);
                }
                else {
                    handlePhoneChange(res[1]);
                    handleCodeChange(res[2]);
                }
                if (conference) {
                    setConferenceType(name);
                }
            }
        });
    };
    const call = (setOff) => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        const { speechLang } = props;
        const roomId = `${avaId}_${uuid()}`;
        const sendDigits = [
            {
                digits: code,
                wait: 3,
            },
        ];
        if (conferenceType === 'zoom') {
            let password = zoomPassword;
            if (password[password.length - 1] !== '#') {
                password += '#';
            }
            sendDigits.push({
                digits: '#',
                wait: 0,
            }, {
                digits: password,
                wait: 2,
            });
        }
        try {
            yield calls.createTwilioCall({
                avaId: avaId,
                roomId,
                calleeInternationalNumber: phone,
                sendDigits,
                speechLang,
                conferenceType,
                firebaseAuthUID: firebaseUser.uid,
            });
            props.createNewConversation({
                inConferenceCall: true,
                roomId,
            });
        }
        catch (err) {
            const { response } = err;
            if (response && response.status === 400) {
                const { data } = response;
                getSnack(props)(props.t(`conversation.call.code.${data.msg}`, {
                    supportedPhoneCode: (data.supported || []).map((c) => `+${c}`).join(', '),
                }), {
                    variant: 'error',
                });
            }
            else {
                getSnack(props)(props.t('conversation.call.unexpectedError'), { variant: 'error' });
            }
        }
        finally {
            setLoading(false);
            setOff();
        }
    });
    const inputDisabled = loading;
    const sendDisabled = inputDisabled || phone.length < 10 || (invitation.search('pwd=') !== -1 && !zoomPassword);
    const { on, setOff, theme, i18n, t } = props;
    return (React.createElement(Dialog, { open: on, keepMounted: true, onClose: setOff, "aria-labelledby": "form-dialog-title", classes: { paper: classes.dialog } },
        React.createElement(DialogContent, { classes: { root: classes.dialogContent } },
            React.createElement("div", { className: classes.phoneIconContainer },
                React.createElement("div", { className: classes.phoneIconContainerClose, style: { backgroundColor: theme.palette.ava.green } },
                    React.createElement("img", { src: Phone, alt: "phone", className: classes.phoneIcon }))),
            React.createElement(Typography, { className: classes.title }, t('conversation.call.join')),
            React.createElement(Typography, { className: classes.howToLink, variant: "h6" },
                React.createElement("a", { href: i18n.language === 'fr' ? intercomLinks.fr : intercomLinks.en, rel: "noopener noreferrer", target: "_blank" }, t('conversation.call.howTo'))),
            React.createElement(TextField
            // label="Invitation"
            , { 
                // label="Invitation"
                multiline: true, autoFocus: true, minRows: "3", value: invitation, placeholder: t('conversation.call.invitationPlaceholder'), disabled: inputDisabled, onChange: handleInvitationChange, className: classes.invitation, variant: "outlined" }),
            React.createElement("div", { className: classes.phoneCodeContainer },
                React.createElement(TextField, { label: t('conversation.call.usOrFrenchPhoneNumber'), value: phone, disabled: inputDisabled, onChange: (e) => handlePhoneChange(e.target.value), className: classes.phone, variant: "outlined" }),
                React.createElement("div", { style: { flexGrow: 1 } }),
                React.createElement(TextField, { label: t('conversation.call.sendDigits'), value: code, disabled: inputDisabled, onChange: (e) => handleCodeChange(e.target.value), className: classes.code, variant: "outlined" })),
            React.createElement(TextField, { label: t('conversation.call.password'), required: invitation.search('pwd=') !== -1, value: zoomPassword, disabled: inputDisabled, onChange: (e) => handleZoomPasswordChange(e.target.value), className: classes.password, variant: "outlined", fullWidth: true })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: () => call(setOff), disabled: sendDisabled, className: classes.dialInButton, style: { backgroundColor: theme.palette.ava.green } },
                ' ',
                t('conversation.call.dialIn'),
                ' ',
                loading && React.createElement(CircularProgress, { size: 20, className: classes.progress })))));
}
