import { Chip, InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
    container: {
        border: `1px solid ${theme.palette.ava.grey2}`,
        borderRadius: 8,
        minHeight: 124,
        padding: '10px 16px',
        width: '100%',
    },
    containerFocused: {
        borderColor: theme.palette.ava.blue2,
    },
    chip: {
        backgroundColor: `${theme.palette.ava.blue5} !important`,
        marginBottom: 8,
        marginRight: 16,
    },
    textInput: {
        width: '60%',
    },
}));
const ChipTextInput = ({ handleChange }) => {
    const [inputValue, setInputValue] = useState('');
    const [chips, setChips] = useState([]);
    const [focused, setFocused] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    useEffect(() => {
        if (handleChange) {
            handleChange(chips);
        }
    }, [chips]);
    const handleFocus = () => setFocused(true);
    const handleBlur = () => setFocused(false);
    const handleTextChange = (e) => {
        const { value } = e.target;
        if (value === ',') {
            return;
        }
        setInputValue(value);
    };
    const handleEnterPress = (e) => {
        const keys = ['Enter', ',', ' '];
        if (!keys.includes(e.key)) {
            return;
        }
        setChips((prevChips) => [...prevChips, inputValue]);
        setInputValue('');
    };
    const handleDeleteChip = (value) => () => {
        setChips((prevChips) => prevChips.filter((v) => v !== value));
    };
    return (React.createElement("div", { className: classNames(classes.container, {
            [classes.containerFocused]: focused,
        }) },
        chips.map((value) => (React.createElement(Chip, { className: classes.chip, label: value, onDelete: handleDeleteChip(value) }))),
        React.createElement(InputBase, { className: classes.textInput, onBlur: handleBlur, onChange: handleTextChange, onFocus: handleFocus, onKeyDown: handleEnterPress, placeholder: t('workspace.members.nameOrEmailPlaceholder'), value: inputValue })));
};
export default ChipTextInput;
