const shortcutList = [
  {
    title: 'Transcripts',
    content: [
      {
        title: 'Assign speaker #[digit]',
        shortcut: {
          mac: ['⌘', 'digit (1, 2…)'],
          windows: ['ctrl', 'digit (1, 2…)'],
        },
      },
      {
        title: ' Assign speaker #[9 + digit]',
        shortcut: {
          mac: ['⌘', 'option', 'digit (1, 2…)'],
          windows: ['ctrl', 'alt', 'digit (1, 2…)'],
        },
      },
      {
        title: 'Go to next word',
        shortcut: {
          mac: ['tab ⇥'],
          windows: ['tab ⇥'],
        },
      },
      {
        title: 'Go to previous word',
        shortcut: {
          mac: ['shift ⇧', 'tab ⇥'],
          windows: ['shift ⇧ ', 'tab ⇥'],
        },
      },
      {
        title: 'Go to start',
        shortcut: {
          mac: ['⌥', '←'],
          windows: ['ctrl', '←'],
        },
      },
      {
        title: 'Got to end',
        shortcut: {
          mac: ['⌥', '→'],
          windows: ['ctrl', '→'],
        },
      },
      {
        title: 'Select first word on next bloc',
        shortcut: {
          mac: ['alt ⌥', '↓'],
          windows: ['alt', '↓'],
        },
      },
      {
        title: 'Select first word of previous bloc',
        shortcut: {
          mac: ['alt ⌥', '↑'],
          windows: ['alt', '↑'],
        },
      },
      {
        title: 'Select first word of current bloc',
        shortcut: {
          mac: ['⌘', '←'],
          windows: ['ctrl', 'alt', '←'],
        },
      },
      {
        title: 'Select last word of current bloc',
        shortcut: {
          mac: ['⌘', '→'],
          windows: ['ctrl', 'alt', '→'],
        },
      },
      {
        title: 'Go to the next highlighted blod',
        shortcut: {
          mac: ['⌘', '↓'],
          windows: ['ctrl', 'alt', '↓'],
        },
      },
      {
        title: 'Go to the previous highlighted blod',
        shortcut: {
          mac: ['⌘', '↑'],
          windows: ['ctrl', 'alt', '↑'],
        },
      },
      {
        title: 'Merge with previous word',
        shortcut: {
          mac: ['delete ⌫'],
          windows: ['delete'],
        },
      },
      {
        title: 'Toggle word case and go to next word',
        shortcut: {
          mac: ['⌘', 'c'],
          windows: ['ctrl', 'c'],
        },
      },
      {
        title: 'Split transcript',
        shortcut: {
          mac: ['⌘', 's'],
          windows: ['ctrl', 's'],
        },
      },
      {
        title: 'Merge transcript',
        shortcut: {
          mac: ['⌘', 'delete ⌫'],
          windows: ['ctrl', 'delete ⌫'],
        },
      },
      {
        title: 'Create new transcript at the end',
        shortcut: {
          mac: ['⌘', 'shift ⇧', 'enter ⏎'],
          windows: ['ctrl', 'shift ⇧', 'enter ⏎'],
        },
      },
      {
        title: 'Create new transcript',
        shortcut: {
          mac: ['⌘', 'enter ⏎'],
          windows: ['ctrl', 'enter ⏎'],
        },
      },
      {
        title: 'Remove punctuation',
        shortcut: {
          mac: ['⌘', 'd'],
          windows: ['ctrl', 'd'],
        },
      },
      {
        title: 'Highlight a transcript',
        shortcut: {
          mac: ['⌘', 'h'],
          windows: ['ctrl', 'h'],
        },
      },
      {
        title: 'Enter Insert Speaker Name mode',
        shortcut: {
          mac: ['⌘', 'x'],
          windows: ['ctrl', 'x'],
        },
      },
    ],
  },
  {
    title: 'Audio',
    content: [
      {
        title: 'Play / Pause',
        shortcut: {
          mac: ['⌘', 'p'],
          windows: ['ctrl', 'p'],
        },
      },
      {
        title: 'Enter Update Audio Source mode',
        shortcut: {
          mac: ['⌘', 'y'],
          windows: ['ctrl', 'y'],
        },
      },
      {
        title: 'Increase volume',
        shortcut: {
          mac: ['⌘', 'h'],
          windows: ['ctrl', 'h'],
        },
      },
      {
        title: 'Decrese volume',
        shortcut: {
          mac: ['⌘', 'g'],
          windows: ['ctrl', 'g'],
        },
      },
      {
        title: 'Add delay (5s)',
        shortcut: {
          mac: ['⌘', 'j'],
          windows: ['ctrl', 'j'],
        },
      },
      {
        title: 'Add tiny delay (1s)',
        shortcut: {
          mac: ['⌘', 'u'],
          windows: ['ctrl', 'u'],
        },
      },
      {
        title: 'Remove delay (5s)',
        shortcut: {
          mac: ['⌘', 'k'],
          windows: ['ctrl', 'k'],
        },
      },
      {
        title: 'Remove tiny delay (1s)',
        shortcut: {
          mac: ['⌘', 'i'],
          windows: ['ctrl', 'i'],
        },
      },
    ],
  },
  {
    title: 'Jump Search - deprecated',
    content: [
      {
        title: 'Open search mode',
        shortcut: {
          mac: ['ctrl', ','],
          windows: ['ctrl', ','],
        },
      },
      {
        title: 'Select next search match',
        shortcut: {
          mac: ['tab'],
          windows: ['tab'],
        },
      },
      {
        title: 'Select previous search match',
        shortcut: {
          mac: ['tab ⇥', 'shift ⇧'],
          windows: ['tab ⇥', 'shift ⇧'],
        },
      },
      {
        title: 'Start editing search match',
        shortcut: {
          mac: ['enter ⏎'],
          windows: ['enter ⏎'],
        },
      },
      {
        title: 'Exit search mode',
        shortcut: {
          mac: ['esc ⏎'],
          windows: ['echap'],
        },
      },
    ],
  },
  {
    title: 'Selection - deprecated',
    content: [
      {
        title: 'Include previous word in selection',
        shortcut: {
          mac: ['shift ⇧', '←'],
          windows: ['shift ⇧', '←'],
        },
      },
      {
        title: 'Include next word in selection',
        shortcut: {
          mac: ['shift ⇧', '→'],
          windows: ['shift ⇧', '→'],
        },
      },
      {
        title: 'Deselect word',
        shortcut: {
          mac: ['esc'],
          windows: ['echap'],
        },
      },
    ],
  },
  {
    title: 'Edit',
    content: [
      {
        title: 'Submit edit',
        shortcut: {
          mac: ['enter ⏎'],
          windows: ['enter ⏎'],
        },
      },
    ],
  },
  {
    title: 'Meta - Roger voice only',
    content: [
      {
        title: 'Accept a conversation',
        shortcut: {
          mac: ['y'],
          windows: ['y'],
        },
      },
      {
        title: 'Accept a conversation',
        shortcut: {
          mac: ['enter ⏎'],
          windows: ['enter ⏎'],
        },
      },
    ],
  },
];
export default shortcutList;
