import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import AvatarLogo from '../Icons/AvatarLogo';
const UserAvatar = ({ width, height, user }) => {
    var _a;
    const theme = useTheme();
    if ((_a = user === null || user === void 0 ? void 0 : user.userPhoto) === null || _a === void 0 ? void 0 : _a.url) {
        return (React.createElement(Avatar, { src: user.userPhoto.url, alt: "profile_photo", style: {
                width: width || 40,
                height: height || 40,
            } }));
    }
    return (React.createElement("div", null,
        React.createElement(AvatarLogo, { color: theme.palette.mode === 'light'
                ? theme.palette.ava.navyGroup.convoText
                : theme.palette.ava.navyGroup.convoTextDark, width: width || 40, height: height || 40 })));
};
export default UserAvatar;
