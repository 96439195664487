import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { selectDisplayName, selectOrgProperties } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import DropdownMenu from '../Dropdowns/DropdownMenu';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import UserIcon from '../Icons/UserIcon';
import ProfileDetails from './ProfileDetails';
import SignInSignOut from './SignInSignOut';
const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 238,
        paddingLeft: 0,
        paddingRight: 0,
        '&:hover': {
            backgroundColor: 'inherit',
        },
    },
    name: {
        flex: 1,
        marginLeft: 16,
        marginRight: 12,
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 20,
        lineHeight: '26px',
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    list: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    item: {
        padding: '6px',
        fontSize: 18,
        lineHeight: '27px',
        width: '100%',
        '&:hover': {
            background: theme.palette.mode === 'light' ? theme.palette.ava.navy5 : theme.palette.ava.grey5,
        },
    },
    link: {
        padding: '0px 18px',
        width: '100%',
        height: '100%',
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        '&:hover, &:focus': {
            color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
            textDecoration: 'none',
        },
    },
    blue: {
        color: theme.palette.ava.blue2,
    },
}));
const ProfileMenu = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const displayName = useAppSelector(selectDisplayName);
    const orgProperties = useAppSelector(selectOrgProperties);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const onClick = (event) => setAnchorEl(event.currentTarget);
    const onClose = () => setAnchorEl(null);
    if (!displayName)
        return null;
    return (React.createElement("div", { className: classes.container },
        React.createElement(Button, { classes: {
                root: classes.button,
            }, id: "profile-button", "aria-controls": "profile-menu", "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, disableElevation: true, disableRipple: true, onClick: onClick },
            React.createElement(UserIcon, null),
            React.createElement(Typography, { className: classnames(classes.name, { [classes.blue]: open }), component: "div" }, displayName),
            React.createElement(ArrowDownIcon, { className: classnames({ [classes.blue]: open }), rotated: open })),
        React.createElement(DropdownMenu, { id: "profile-menu", anchorEl: anchorEl, width: 325, onClose: onClose, anchorOrigin: { horizontal: 'right' }, transformOrigin: { horizontal: 'right' } },
            React.createElement(MenuItem, { disableGutters: true, disabled: true, style: { opacity: 1 } },
                React.createElement(ProfileDetails, null)),
            React.createElement(Divider, null),
            React.createElement(MenuItem, { className: classes.item },
                React.createElement(Link, { className: classes.link, to: "/web/account-settings/my-profile" }, t('profileMenu.myProfile'))),
            React.createElement(MenuItem, { className: classes.item },
                React.createElement(Link, { className: classes.link, to: "/web/account-settings/general" }, t('profileMenu.generalSettings'))),
            (orgProperties === null || orgProperties === void 0 ? void 0 : orgProperties.role) === 'admin' && (React.createElement(MenuItem, { className: classes.item },
                React.createElement("a", { className: classes.link, target: "_blank", rel: "noreferrer noopener", href: "/account/dashboard" },
                    t('sidebar.adminDashboard'),
                    React.createElement(OpenInNewOutlinedIcon, { style: {
                            fontSize: '1em',
                            transform: 'translate(0.2em, 0.16em)',
                        } })))),
            React.createElement(SignInSignOut, null))));
};
export default ProfileMenu;
