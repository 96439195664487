import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddParticipantIcon from '../../../assets/icons/add-participant-filled.svg';
import { setIsInviteCardOpen } from '../../store/slices/uiState';
import { useAppDispatch } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    tooltipPlacementBottom: {
        margin: '20px 0',
    },
    addParticipantButton: {
        minWidth: 44,
        minHeight: 44,
        marginLeft: 4,
        marginRight: 4,
        marginTop: -4,
        padding: 10,
        backgroundColor: '#263252',
        border: 'none',
        outline: 'none',
        borderRadius: '50%',
        boxSizing: 'border-box',
        overflow: 'hidden',
        userSelect: 'none',
        '&:hover': {
            backgroundColor: theme.palette.ava.grey5,
        },
    },
    addParticipantButtonClicked: {
        backgroundColor: theme.palette.ava.deepBlue,
        pointerEvents: 'none',
    },
    addParticipantIcon: {
        width: '100%',
        height: '100%',
        marginLeft: 2,
    },
}));
const AddParticipantButton = ({ isActive }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const handleClick = () => {
        dispatch(setIsInviteCardOpen(!isActive));
    };
    return (React.createElement(Tooltip, { classes: {
            tooltipPlacementBottom: classes.tooltipPlacementBottom,
        }, title: t('ccMode.participants.tooltip.addParticipant'), placement: "bottom", disableInteractive: true },
        React.createElement("button", { className: classnames(classes.addParticipantButton, {
                [classes.addParticipantButtonClicked]: isActive,
            }), onClick: handleClick },
            React.createElement("img", { className: classes.addParticipantIcon, src: AddParticipantIcon, alt: "" }))));
};
export default AddParticipantButton;
