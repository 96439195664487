import { useTheme } from '@mui/material/styles';
import React from 'react';
const AvatarLogo = ({ bgTheme, color, width, height }) => {
    const theme = useTheme();
    if (bgTheme === 'light' || theme.palette.mode === 'light') {
        return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 61 56", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("circle", { cx: "28", cy: "28", r: "28", fill: "#F4F9FF" }),
            React.createElement("g", { clipPath: "url(#clip0)" },
                React.createElement("path", { d: "M28.0029 11.2002C23.2028 11.2002 19.2791 15.124 19.2791 19.924C19.2791 24.7241 23.2028 28.6478 28.0029 28.6478C32.8029 28.6478 36.7267 24.7241 36.7267 19.924C36.7267 15.124 32.8029 11.2002 28.0029 11.2002Z", fill: color || '#035A98' }),
                React.createElement("path", { d: "M42.9749 35.6188C42.7463 35.0474 42.4416 34.5141 42.0988 34.0188C40.3464 31.4283 37.6417 29.714 34.594 29.295C34.2131 29.2569 33.7941 29.3331 33.4893 29.5617C31.8893 30.7426 29.9845 31.3521 28.0035 31.3521C26.0225 31.3521 24.1178 30.7426 22.5178 29.5617C22.213 29.3331 21.7939 29.2188 21.413 29.295C18.3654 29.714 15.6226 31.4283 13.9083 34.0188C13.5654 34.5141 13.2607 35.0855 13.0321 35.6188C12.9179 35.8474 12.9559 36.1141 13.0702 36.3427C13.375 36.876 13.7559 37.4094 14.0988 37.8665C14.6321 38.5903 15.2035 39.2379 15.8512 39.8474C16.3845 40.3807 16.994 40.876 17.6036 41.3712C20.6131 43.6189 24.2321 44.7998 27.9655 44.7998C31.6988 44.7998 35.3179 43.6188 38.3274 41.3712C38.9369 40.9141 39.5464 40.3807 40.0798 39.8474C40.6893 39.2379 41.2988 38.5903 41.8322 37.8665C42.2131 37.3712 42.556 36.876 42.8607 36.3427C43.0511 36.1141 43.0892 35.8474 42.9749 35.6188Z", fill: color || '#035A98' })),
            React.createElement("mask", { id: "mask0", style: { maskType: 'alpha' }, maskUnits: "userSpaceOnUse", x: "41", y: "36", width: "20", height: "20" },
                React.createElement("rect", { x: "41", y: "36", width: "20", height: "20", fill: "white" })),
            React.createElement("g", { mask: "url(#mask0)" },
                React.createElement("path", { d: "M51 55.5919C56.2975 55.5919 60.5919 51.2975 60.5919 46C60.5919 40.7026 56.2975 36.4082 51 36.4082C45.7026 36.4082 41.4082 40.7026 41.4082 46C41.4082 51.2975 45.7026 55.5919 51 55.5919Z", fill: "#50ACED" }),
                React.createElement("g", { filter: "url(#filter0_f)" },
                    React.createElement("path", { d: "M51 53.9594C55.3957 53.9594 58.9591 50.3959 58.9591 46.0002C58.9591 41.6045 55.3957 38.041 51 38.041C46.6042 38.041 43.0408 41.6045 43.0408 46.0002C43.0408 50.3959 46.6042 53.9594 51 53.9594Z", fill: "#50ACED" })),
                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M55.6046 49.7365C55.3466 49.5669 55.0976 49.3333 54.8305 49.0646L54.54 48.7665C54.7595 48.4176 55.1746 47.6669 55.7705 47.2785C56.0494 47.0965 56.182 46.7643 56.061 46.4578L56.05 46.4303C55.8879 46.0212 55.3763 45.8732 55.0126 46.1281C54.2933 46.632 53.781 47.3595 53.5024 47.7441L53.5002 47.7419V47.7395L53.4994 47.7411L53.495 47.7365V47.7414L51.1812 45.4546C51.8301 45.0166 53.4276 43.9788 53.4328 42.4141C53.4375 40.9961 52.2958 39.8187 50.4695 39.813C48.4276 39.8063 47.4265 41.2048 47.4224 42.4436C47.4023 43.4214 47.9148 44.14 48.5599 44.8105L48.9406 45.2191L48.9403 45.2518C48.3415 45.5921 46.6282 46.5483 46.6216 48.5043C46.6161 50.134 47.8733 51.5399 49.965 51.5466C51.7916 51.5526 52.9896 50.7252 53.5899 50.0589L54.2801 49.12L53.7687 50.2226L54.0339 50.4937C54.2696 50.7195 54.5224 50.908 54.7939 51.0576C55.1936 51.2777 55.7017 51.0878 55.8816 50.6744C56.0288 50.3363 55.9157 49.9412 55.6046 49.7365ZM48.8999 42.4646C48.9026 41.6985 49.4525 41.0647 50.3823 41.0677C51.3784 41.0709 51.9572 41.7736 51.955 42.4743C51.9512 43.5664 50.7204 44.1981 50.2214 44.49L49.8575 44.1303C49.2946 43.5743 48.8812 43.1001 48.8999 42.4646ZM50.1026 50.178C49.0402 50.1745 48.1957 49.4384 48.1992 48.4114C48.2031 47.2731 49.0743 46.6093 49.6327 46.2577L49.1271 45.4049L49.9405 46.2434L49.9408 46.2461L52.5971 49.0125C52.1137 49.565 51.2981 50.1818 50.1026 50.178Z", fill: "white" })),
            React.createElement("defs", null,
                React.createElement("filter", { id: "filter0_f", x: "41.4783", y: "36.4785", width: "19.0434", height: "19.0434", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                    React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                    React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }),
                    React.createElement("feGaussianBlur", { stdDeviation: "0.78125", result: "effect1_foregroundBlur" })),
                React.createElement("clipPath", { id: "clip0" },
                    React.createElement("rect", { width: "33.6", height: "33.6", fill: "white", transform: "translate(11.2002 11.2002)" })))));
    }
    return (React.createElement("svg", { width: width, height: height, viewBox: "0 0 61 56", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("circle", { cx: "28", cy: "28", r: "28", fill: "#2D2F34" }),
        React.createElement("g", { clipPath: "url(#clip0)" },
            React.createElement("path", { d: "M28.0029 11.2002C23.2028 11.2002 19.2791 15.124 19.2791 19.924C19.2791 24.7241 23.2028 28.6478 28.0029 28.6478C32.8029 28.6478 36.7267 24.7241 36.7267 19.924C36.7267 15.124 32.8029 11.2002 28.0029 11.2002Z", fill: color || '#81C3F3' }),
            React.createElement("path", { d: "M42.9749 35.6188C42.7463 35.0474 42.4416 34.5141 42.0988 34.0188C40.3464 31.4283 37.6417 29.714 34.594 29.295C34.2131 29.2569 33.7941 29.3331 33.4893 29.5617C31.8893 30.7426 29.9845 31.3521 28.0035 31.3521C26.0225 31.3521 24.1178 30.7426 22.5178 29.5617C22.213 29.3331 21.7939 29.2188 21.413 29.295C18.3654 29.714 15.6226 31.4283 13.9083 34.0188C13.5654 34.5141 13.2607 35.0855 13.0321 35.6188C12.9179 35.8474 12.9559 36.1141 13.0702 36.3427C13.375 36.876 13.7559 37.4094 14.0988 37.8665C14.6321 38.5903 15.2035 39.2379 15.8512 39.8474C16.3845 40.3807 16.994 40.876 17.6036 41.3712C20.6131 43.6189 24.2321 44.7998 27.9655 44.7998C31.6988 44.7998 35.3179 43.6188 38.3274 41.3712C38.9369 40.9141 39.5464 40.3807 40.0798 39.8474C40.6893 39.2379 41.2988 38.5903 41.8322 37.8665C42.2131 37.3712 42.556 36.876 42.8607 36.3427C43.0511 36.1141 43.0892 35.8474 42.9749 35.6188Z", fill: color || '#81C3F3' })),
        React.createElement("mask", { id: "mask0", style: { maskType: 'alpha' }, maskUnits: "userSpaceOnUse", x: "41", y: "36", width: "20", height: "20" },
            React.createElement("rect", { x: "41", y: "36", width: "20", height: "20", fill: "white" })),
        React.createElement("g", { mask: "url(#mask0)" },
            React.createElement("path", { d: "M51 55.5919C56.2975 55.5919 60.5919 51.2975 60.5919 46C60.5919 40.7026 56.2975 36.4082 51 36.4082C45.7026 36.4082 41.4082 40.7026 41.4082 46C41.4082 51.2975 45.7026 55.5919 51 55.5919Z", fill: "#50ACED" }),
            React.createElement("g", { filter: "url(#filter0_f)" },
                React.createElement("path", { d: "M51 53.9594C55.3957 53.9594 58.9591 50.3959 58.9591 46.0002C58.9591 41.6045 55.3957 38.041 51 38.041C46.6042 38.041 43.0408 41.6045 43.0408 46.0002C43.0408 50.3959 46.6042 53.9594 51 53.9594Z", fill: "#50ACED" })),
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M55.6046 49.7365C55.3466 49.5669 55.0976 49.3333 54.8305 49.0646L54.54 48.7665C54.7595 48.4176 55.1746 47.6669 55.7705 47.2785C56.0494 47.0965 56.182 46.7643 56.061 46.4578L56.05 46.4303C55.8879 46.0212 55.3763 45.8732 55.0126 46.1281C54.2933 46.632 53.781 47.3595 53.5024 47.7441L53.5002 47.7419V47.7395L53.4994 47.7411L53.495 47.7365V47.7414L51.1812 45.4546C51.8301 45.0166 53.4276 43.9788 53.4328 42.4141C53.4375 40.9961 52.2958 39.8187 50.4695 39.813C48.4276 39.8063 47.4265 41.2048 47.4224 42.4436C47.4023 43.4214 47.9148 44.14 48.5599 44.8105L48.9406 45.2191L48.9403 45.2518C48.3415 45.5921 46.6282 46.5483 46.6216 48.5043C46.6161 50.134 47.8733 51.5399 49.965 51.5466C51.7916 51.5526 52.9896 50.7252 53.5899 50.0589L54.2801 49.12L53.7687 50.2226L54.0339 50.4937C54.2696 50.7195 54.5224 50.908 54.7939 51.0576C55.1936 51.2777 55.7017 51.0878 55.8816 50.6744C56.0288 50.3363 55.9157 49.9412 55.6046 49.7365ZM48.8999 42.4646C48.9026 41.6985 49.4525 41.0647 50.3823 41.0677C51.3784 41.0709 51.9572 41.7736 51.955 42.4743C51.9512 43.5664 50.7204 44.1981 50.2214 44.49L49.8575 44.1303C49.2946 43.5743 48.8812 43.1001 48.8999 42.4646ZM50.1026 50.178C49.0402 50.1745 48.1957 49.4384 48.1992 48.4114C48.2031 47.2731 49.0743 46.6093 49.6327 46.2577L49.1271 45.4049L49.9405 46.2434L49.9408 46.2461L52.5971 49.0125C52.1137 49.565 51.2981 50.1818 50.1026 50.178Z", fill: "white" })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_f", x: "41.4783", y: "36.4785", width: "19.0434", height: "19.0434", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }),
                React.createElement("feGaussianBlur", { stdDeviation: "0.78125", result: "effect1_foregroundBlur" })),
            React.createElement("clipPath", { id: "clip0" },
                React.createElement("rect", { width: "33.6", height: "33.6", fill: "white", transform: "translate(11.2002 11.2002)" })))));
};
export default AvatarLogo;
