import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Dialog } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import { AVA_URL } from '../../constants';
import { selectStatus } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
export function ApplessQRButton() {
    const status = useAppSelector(selectStatus);
    const [qrDialogOpen, setQrDialogOpen] = useState(false);
    const [showCopiedInfo, setShowCopiedInfo] = useState(false);
    const theme = useTheme();
    const joinUrl = `${AVA_URL}/&${status.host.avaName}`;
    const openQRDialog = () => {
        setQrDialogOpen(true);
        setShowCopiedInfo(false);
    };
    useEffect(() => {
        setTimeout(() => {
            QRCode.toCanvas(document.getElementById('canvas'), joinUrl, {
                width: 200,
                color: {
                    dark: theme.palette.ava.blue,
                },
            }, function (error) {
                if (error)
                    console.error(error);
                console.log('success!');
            });
        }, 100);
    }, [qrDialogOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { sx: {
                height: '48px',
                width: '48px',
                minWidth: '48px',
                borderRadius: '50%',
                border: '1px solid rgba(0, 0, 0, 0.2)',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 1,
            }, onClick: openQRDialog },
            React.createElement(QrCodeIcon, null)),
        React.createElement(Dialog, { open: qrDialogOpen, onClose: () => setQrDialogOpen(false) },
            React.createElement(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '300px',
                } },
                React.createElement(DialogTitle, { sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    } },
                    React.createElement(Typography, { sx: {
                            fontSize: 20,
                            fontWeight: 600,
                        } }, "Invite participant")),
                React.createElement(IconButton, { onClick: () => setQrDialogOpen(false), sx: {
                        flexGrow: 1,
                        position: 'absolute',
                        top: 8,
                        right: 0,
                    } },
                    React.createElement(CloseIcon, null)),
                React.createElement(Box, { sx: {
                        borderRadius: '12px',
                        padding: 1,
                        margin: 1,
                        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                    } },
                    React.createElement("canvas", { id: "canvas" })),
                React.createElement(Box, { sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    } },
                    React.createElement(Box, { sx: {
                            fontSize: 20,
                            backgroundColor: theme.palette.ava.blue5,
                            paddingRight: 3,
                            paddingLeft: 1,
                            paddingY: 2,
                            margin: 2,
                            borderRadius: 24,
                            display: 'flex',
                        } },
                        React.createElement(IconButton, { sx: {
                                color: theme.palette.ava.blue,
                            }, onClick: () => {
                                navigator.clipboard.writeText(joinUrl);
                                setShowCopiedInfo(true);
                            } },
                            React.createElement(ContentCopyIcon, null)),
                        showCopiedInfo && React.createElement(React.Fragment, null, "Copied!"),
                        !showCopiedInfo && (React.createElement(React.Fragment, null,
                            "ava.me/",
                            React.createElement(Typography, { sx: {
                                    fontSize: 20,
                                    color: theme.palette.ava.blue,
                                } },
                                "&",
                                status.host.avaName)))))))));
}
