import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        background: 'transparent',
        border: 'none',
        outline: 'none',
        userSelect: 'none',
        '&:hover': {
            background: theme.palette.ava.red2,
            '& $icon': {
                fill: theme.palette.ava.white,
            },
        },
    },
    icon: {
        fontSize: 30,
        fill: theme.palette.ava.red2,
    },
}));
const CloseButton = ({ className, onClick }) => {
    const classes = useStyles();
    return (React.createElement("button", { className: classnames(classes.root, className), onClick: onClick },
        React.createElement(CloseIcon, { className: classes.icon })));
};
export default CloseButton;
