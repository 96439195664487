import { connect } from 'react-redux';
import { createNewConversation } from '../../../actions';
import { addNotification, setCCMode, setShowBubbleAboveCCModeDebounced } from '../../../store/slices/ccMode';
import CCPage from './CCPage';
const mapStateToProps = ({ ccMode: { ccMode, showBubbleAboveCCMode } }) => ({
    ccMode,
    showBubbleAboveCCMode,
});
const mapDispatchToProps = {
    addNotification,
    createNewConversation,
    setCCMode,
    setShowBubbleAboveCCMode: setShowBubbleAboveCCModeDebounced,
};
export default connect(mapStateToProps, mapDispatchToProps)(CCPage);
