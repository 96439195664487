var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Sentry from '@sentry/browser';
import { createAudioProcessor, getFormat } from '../modules/AudioRecorder/audioProcessing';
import LocalStorage, { STORAGE_KEYS } from '../services/localStorage';
import { getBrowserName, isMac, isWindows } from './index';
import { INTERNAL_AUDIO_MIC_ID, isInternalAudio } from './internalAudio';
import { trackMicPermission } from './segment';
import * as segment from './segment';
import { sendAudioParams, sendMbackendMessage, sendWebRTCTrackMetadataMessage } from './ws-v1';
export const AUDIO_CONSTRAINTS = {
    echoCancellation: true,
    noiseSuppression: false,
    autoGainControl: false,
};
// TODO: Have a better way to check if a bluetooth mic is being used
// Google Chrome will include the label for a bluetooth microphone, while other
// browsers do not. We use the this check to know when to tell the user to use
// a headset and providing extra information to the backend. There might be
// better and more reliable ways to tell if a device is bluetooth than this.
export const isBluetoothMic = (mic) => {
    return mic && mic.label.toLowerCase().includes('bluetooth');
};
export const getMicrophoneByIdIfExist = (availableMics, microphoneId) => {
    return microphoneId && availableMics.find((mic) => mic.value === microphoneId);
};
export const getMicrophoneFromLocalStorage = (availableMics) => {
    const selectedMicId = LocalStorage.get(STORAGE_KEYS.SELECTED_MIC_ID);
    return getMicrophoneByIdIfExist(availableMics, selectedMicId);
};
const isDeviceAudioInput = (device) => {
    return device.kind === 'audioinput';
};
const isDeviceIdDefault = (device) => {
    return device.deviceId === 'default';
};
// TODO: Have a way to declare a default mic if browser doesn't give the info
// Google Chrome will label a default mic for us, but other browsers might not.
// So, it would be good to always make sure to have one default audio device.
// This makes it so that we can switch between the Ava Mic and the default mic
// depending on group convo or solo convo.
const getDefaultMic = (devices) => {
    return devices.find((device) => isDeviceAudioInput(device) && isDeviceIdDefault(device));
};
export const getCurrentAvailableMics = (devices, isAvaMicForbidden, phraseTranslation) => {
    // The list of devices may contain duplicates for 'default' device. Google Chrome will
    // contain duplicates, but other browsers might not.
    //
    // For example, we might have:
    //
    // * Default - MacBook Pro Microphone (Built-in)
    // * MacBook Pro Microphone (Built-in)
    //
    // We want to only show one to the user, so the 'default' will be filtered out.
    const defaultMic = getDefaultMic(devices);
    const availableMics = devices
        .filter((device) => isDeviceAudioInput(device) && !isDeviceIdDefault(device) && (!isInternalAudio(device) || !isAvaMicForbidden))
        .map((device) => ({
        value: device.deviceId,
        label: device.label,
        default: defaultMic ? device.groupId === defaultMic.groupId : false,
    }));
    const isFirefox = getBrowserName() === 'Firefox';
    const isAvaMicAvailableOnWindows = isWindows && !isFirefox && !isAvaMicForbidden && navigator.mediaDevices.getDisplayMedia;
    if (isAvaMicAvailableOnWindows) {
        // Capturing system-wide audio is currently only available on Windows, but not in
        // Firefox (see this issue: https://bugzilla.mozilla.org/show_bug.cgi?id=1541425).
        availableMics.unshift({
            value: INTERNAL_AUDIO_MIC_ID,
            label: 'Ava Computer Audio',
            default: false,
        });
    }
    if (!availableMics.length) {
        availableMics.push({
            value: 'Missing',
            label: phraseTranslation('micSettings.noMic'),
            default: false,
        });
    }
    return availableMics;
};
export const isPermissionDenied = (err) => {
    const chromePermissionDenied = err.message.includes('Permission denied');
    const firefoxSafariPermissionDenied = err.message.includes('not allowed by the user agent');
    return chromePermissionDenied || firefoxSafariPermissionDenied;
};
export const isPermissionDismissed = (err) => {
    return err.message.includes('Permission dismissed');
};
export const hasNoAudioInput = (err) => {
    // We treat unavailable mic(s) the same as if access is denied.
    return err.message.includes('no audio input');
};
export const hasNoAudioTracks = (err) => {
    // Relates to Windows users, where the UX is a bit confusing:
    // they have to allow desktop sharing, but also check the checkbox for audio,
    // which is not very clear and some users miss it.
    return err.message.includes('no audio input');
};
export const isMicAccessDenied = (err) => {
    return isPermissionDenied(err) || isPermissionDismissed(err) || hasNoAudioInput(err) || hasNoAudioTracks(err);
};
export const trackMicPermissionGrantedIfPromptExists = (trackMicPermissionPrompt) => {
    if (trackMicPermissionPrompt) {
        trackMicPermission('granted');
    }
};
export const trackMicPermissionIfPromptExists = (err, trackMicPermissionPrompt) => {
    if (trackMicPermissionPrompt) {
        if (isPermissionDenied(err)) {
            trackMicPermission('denied');
        }
        else {
            trackMicPermission('undetermined');
        }
    }
};
export const captureMicrophoneExceptionIfExists = (err, t, errorShown, setErrorShown, callback) => {
    const captureError = !isPermissionDenied(err) && !isPermissionDismissed(err) && !hasNoAudioInput(err);
    if (captureError) {
        if (!hasNoAudioTracks(err)) {
            Sentry.captureException(err);
        }
        let errorMessage;
        if (err.message.includes('no audio tracks')) {
            errorMessage = t('snackbar.request.shareAudioNeeded');
        }
        else {
            errorMessage = t('snackbar.request.microphoneMissing');
        }
        if (errorMessage && !errorShown) {
            setErrorShown(true);
            callback(errorMessage);
        }
    }
};
export const getWindowsAudioStream = () => __awaiter(void 0, void 0, void 0, function* () {
    let audioStream;
    const { mediaDevices } = window.navigator;
    if (window.isElectron) {
        audioStream = yield mediaDevices.getUserMedia({
            audio: {
                // @ts-ignore
                mandatory: { chromeMediaSource: 'desktop' },
            },
            video: {
                // @ts-ignore
                mandatory: { chromeMediaSource: 'desktop' },
            },
        });
    }
    else {
        audioStream = yield mediaDevices.getDisplayMedia({
            audio: AUDIO_CONSTRAINTS,
            // Even though we do not need video, 'Audio only requests are not
            // supported' is the error given by Windows.
            video: true,
        });
        if (!audioStream.getAudioTracks().length) {
            throw new Error('no audio tracks included');
        }
    }
    return audioStream;
});
export const stopAudioProcessor = (audioProcessor) => {
    if (audioProcessor) {
        audioProcessor.stop();
    }
};
export const stopStreams = (streams) => {
    if (streams) {
        streams.forEach((stream) => {
            stream.getTracks().forEach((track) => track.stop());
        });
    }
};
export const disconnectSources = (sources) => {
    if (sources) {
        sources.forEach((source) => source.disconnect());
    }
};
export const closeAudioContext = (audioContext) => __awaiter(void 0, void 0, void 0, function* () {
    if (audioContext && audioContext.state !== 'closed') {
        try {
            yield audioContext.close();
        }
        catch (err) {
            Sentry.captureException(err);
        }
    }
});
export const clearVolumeInterval = (volumeInterval) => {
    if (volumeInterval) {
        clearInterval(volumeInterval);
    }
};
// Microphone is selected in the following priority:
//  1. Selected mic from Redux state
//  2. Saved mic from local storage if user manually selects a mic
//  3. Ava mic if available (Desktop app or Windows browser (except Firefox))
//  4. Default mic, Google Chrome will label this while other browsers might not
//  5. First available mic
export const getMicIdToSelect = (availableMics, selectedMicId) => {
    if (!availableMics.length || availableMics[0].value === 'Missing') {
        return null;
    }
    const savedMicId = LocalStorage.get(STORAGE_KEYS.SELECTED_MIC_ID);
    const selectedMic = getMicrophoneByIdIfExist(availableMics, selectedMicId);
    const savedMic = getMicrophoneByIdIfExist(availableMics, savedMicId);
    const avaMic = availableMics.find((mic) => isInternalAudio(mic));
    const defaultMic = availableMics.find((mic) => mic.default);
    const firstAvailableMic = availableMics[0];
    if (!selectedMic || selectedMicId !== savedMicId) {
        const { value } = selectedMic || savedMic || avaMic || defaultMic || firstAvailableMic;
        return value;
    }
    return selectedMicId;
};
export const getMediaDevices = () => __awaiter(void 0, void 0, void 0, function* () {
    // Note that the following doesn't get full device info (enumerates only default devices and
    // without labels or ids) until the user grants permission - a manual process which
    // is triggered as part of recording with `navigator.mediaDevices.getUserMedia()` and
    // `navigator.mediaDevices.getDisplayMedia()` (or by the user changing browser settings,
    // which needs to happen if they denied access at one point. We nudge them in the right
    // direction with <MicrophoneDenied /> modal).
    return yield navigator.mediaDevices.enumerateDevices();
});
export const getAvailableMicsFromMediaDevices = (devices, properties) => {
    const { isMuted, participants, t } = properties;
    const isAvaMicForbidden = (participants.length > 1 && !isMuted) || (isMac && !window.isElectron);
    const availableMics = getCurrentAvailableMics(devices, isAvaMicForbidden, t);
    return availableMics;
};
const prepareTrainingStreams = (scribeTrainingSourceNode, scribeTrainingMediaStreamAC) => __awaiter(void 0, void 0, void 0, function* () {
    if (!scribeTrainingMediaStreamAC || !scribeTrainingSourceNode)
        return { streams: [], streamNames: [], metadata: [] };
    const dest = scribeTrainingMediaStreamAC.createMediaStreamDestination();
    scribeTrainingSourceNode.connect(dest);
    scribeTrainingMediaStreamAC.resume();
    const track = dest.stream.getAudioTracks()[0];
    return {
        streams: [dest.stream],
        streamNames: ['training'],
        metadata: [
            {
                streamId: dest.stream.id,
                name: track.label,
                isInternal: false,
            },
        ],
    };
});
const initAudioStreams = (availableMics, micIdSelected, onlyInternalAudio, setIsPermissionGranted) => __awaiter(void 0, void 0, void 0, function* () {
    const { mediaDevices } = window.navigator;
    const streams = [];
    const streamNames = [];
    const metadata = [];
    const mic = availableMics.find((m) => m.value === micIdSelected);
    // First time users will be asked to grant access to microphone
    // (via mediaDevices.getUserMedia or mediaDevices.getDisplayMedia).
    // If they don't grant it, then an exception will be thrown
    // ('Permission denied' or 'Permission dismissed' DOMException).
    if (mic) {
        let audioStream;
        let listeningToInternalAudio = false;
        if (INTERNAL_AUDIO_MIC_ID === mic.value) {
            // This part is only relevant for Windows, because only in Windows a browser can record
            // system sound/speakers.
            audioStream = yield getWindowsAudioStream();
            listeningToInternalAudio = true;
        }
        else {
            const deviceId = !isWindows && mic.value ? { exact: mic.value } : { ideal: 'default' };
            audioStream = yield mediaDevices.getUserMedia({
                audio: Object.assign(Object.assign({}, AUDIO_CONSTRAINTS), { deviceId }),
            });
            if (isInternalAudio(mic)) {
                listeningToInternalAudio = true;
            }
        }
        streams.push(audioStream);
        streamNames.push(mic.label);
        const track = audioStream.getAudioTracks()[0];
        metadata.push({
            streamId: audioStream.id,
            name: track.label,
            isInternal: listeningToInternalAudio,
        });
        // If using internal audio, we need to check that there is at least one
        // available microphone.
        if (listeningToInternalAudio && !onlyInternalAudio && availableMics.length > 1) {
            // There is internal audio, adding a second stream for mic
            try {
                const secondAudioStream = yield mediaDevices.getUserMedia({
                    audio: Object.assign(Object.assign({}, AUDIO_CONSTRAINTS), { deviceId: 'default' }),
                });
                if (!streams.find((stream) => stream && stream.id === secondAudioStream.id)) {
                    streams.push(secondAudioStream);
                    const track = secondAudioStream.getAudioTracks()[0];
                    streamNames.push(track.label);
                    metadata.push({
                        streamId: secondAudioStream.id,
                        name: track.label,
                        isInternal: false,
                    });
                }
            }
            catch (e) {
                // Getting the other stream is nice to have, but not a blocker to initiate
                // recording, so any errors during the second stream capture are ignored.
                Sentry.captureException(e);
            }
        }
        if (audioStream.getAudioTracks().length > 0) {
            setIsPermissionGranted(true);
        }
    }
    return { streams, streamNames, metadata };
});
const setupVolumeMetering = (audioContext, setVolume) => __awaiter(void 0, void 0, void 0, function* () {
    // Volume metering is needed to display volume levels
    // to the user (shadow on the mic button).
    //
    // Because it is loaded as a separate script/file, it can happen
    // that audioContext fails to fetch it. For example, if recording
    // is requested in offline mode, the vumeter-processor.js will
    // fail to load in case it hasn't been cached yet.
    //
    // To avoid showing the user red error banner in such cases,
    // we wrap this setup in a try .. catch block.
    let volumeMeterWorkletNode;
    let volumeInterval;
    try {
        volumeMeterWorkletNode = new AudioWorkletNode(audioContext, 'vumeter');
        const volumeHandler = () => {
            let volumeAverage = 0;
            let volumeCount = 0;
            let prevVolumeAverage = 0;
            volumeInterval = setInterval(() => {
                if (Math.abs(prevVolumeAverage - volumeAverage) > 1) {
                    setVolume(volumeAverage);
                    prevVolumeAverage = volumeAverage;
                }
                volumeCount = 0;
                volumeAverage = 0;
            }, 250);
            return (event) => {
                const eventVolume = event.data.volume || 0;
                const newVolumeSum = volumeAverage * volumeCount + eventVolume * 100;
                volumeCount += 1;
                volumeAverage = newVolumeSum / volumeCount;
            };
        };
        volumeMeterWorkletNode.port.onmessage = volumeHandler();
    }
    catch (err) {
        Sentry.captureException(err);
    }
    finally {
        return { volumeInterval, volumeMeterWorkletNode };
    }
});
const notifyAudioParamsUpdated = (socket, options, format, selectedMic, recordingStart) => {
    const { curseFilter, lang, setAudioParamsSent, speechLang } = options;
    const mic = isBluetoothMic(selectedMic) ? 'BT' : 'BUILTIN';
    const translation = lang && lang !== '~' ? { target: lang } : undefined;
    if (socket) {
        const updatedAudio = {
            lang: speechLang,
            pFilter: curseFilter,
            mic,
            format,
            sampleRateHz: 16000,
            recordingMode: 'web',
            chunkLengthMs: 60,
            recordingStart,
            translation,
        };
        sendAudioParams(socket, updatedAudio);
        setAudioParamsSent(true);
        return { socketUrl: socket.url, sent: true };
    }
    return { socketUrl: null, sent: false };
};
function createDataSender(socket, onFirstPacket) {
    let buffer = [];
    let firstPacketSent = false;
    let firstPacketUrl = null;
    return (data) => {
        const packet = Object.assign(Object.assign({}, data), { type: 'write' });
        if (socket && socket.readyState === WebSocket.OPEN) {
            // Check that the first packet (connection params) has been sent and
            // that it's the same socket url. Url to the backend contains unique token,
            // so a different socket will have different url.
            if (firstPacketSent && firstPacketUrl === socket.url) {
                // empty the buffer, if it's been accumulated for some reason
                // (websocket down/reconnecting).
                while (buffer.length > 0) {
                    sendMbackendMessage(socket, buffer.shift());
                }
                sendMbackendMessage(socket, packet);
            }
            else {
                const { socketUrl, sent } = onFirstPacket();
                firstPacketSent = sent;
                firstPacketUrl = socketUrl;
                buffer.push(packet);
            }
        }
        else {
            firstPacketSent = false;
            firstPacketUrl = null;
            buffer.push(packet);
            if (buffer.length > 100) {
                // If 6s pass we throw away the packets, because we can't
                // hold them in the buffer forever.
                buffer = [];
            }
        }
    };
}
export function setupRecording(socket, properties, handleMicrophoneError, isScribeTraining) {
    return __awaiter(this, void 0, void 0, function* () {
        const { availableMics, audioParamsSent, micIdSelected, onlyInternalAudio, scribeTrainingSourceNode, scribeTrainingAudioStreamAC, setAudioParamsSent, setIsPermissionGranted, setVolume, trackMicPermissionPrompt, useWebRTC, } = properties;
        if (window.__TAURI__) {
            if (!micIdSelected)
                return;
            yield window.__TAURI__.invoke('start_recording', { deviceName: micIdSelected });
            notifyAudioParamsUpdated(socket, properties, 'webrtc', { label: 'webrtc' }, Date.now());
            setAudioParamsSent(true);
            return micIdSelected;
        }
        if (audioParamsSent) {
            setAudioParamsSent(false);
        }
        try {
            const audioContext = new AudioContext();
            // By giving direct url of the worklet module it is less error prone
            // (in case of double slash or (not) using hash in routing).
            const moduleUrl = new URL('/vumeter-processor.js', window.location.href);
            yield audioContext.audioWorklet.addModule(moduleUrl);
            const { streams, streamNames, metadata } = isScribeTraining
                ? yield prepareTrainingStreams(scribeTrainingSourceNode, scribeTrainingAudioStreamAC)
                : yield initAudioStreams(availableMics, micIdSelected, onlyInternalAudio, setIsPermissionGranted);
            trackMicPermissionGrantedIfPromptExists(trackMicPermissionPrompt);
            const { volumeInterval, volumeMeterWorkletNode } = yield setupVolumeMetering(audioContext, setVolume);
            const sources = streams.map((stream) => {
                const mediaStream = audioContext.createMediaStreamSource(stream);
                if (volumeMeterWorkletNode) {
                    mediaStream.connect(volumeMeterWorkletNode);
                }
                return mediaStream;
            });
            const recordingStart = Date.now();
            const format = getFormat(useWebRTC);
            segment.track('Web - Audio - Format', {
                format,
            });
            const mic = availableMics.find((m) => m.value === micIdSelected);
            const notifyAudioParamsUpdatedOnRecordingStart = () => {
                return notifyAudioParamsUpdated(socket, properties, format, mic, recordingStart);
            };
            if (format === 'webrtc') {
                notifyAudioParamsUpdated(socket, properties, format, mic, recordingStart);
                metadata.forEach((oneMetadata) => sendWebRTCTrackMetadataMessage(socket, oneMetadata));
            }
            let audioProcessor = null;
            if (sources.length && streams.length && streamNames.length) {
                audioProcessor = createAudioProcessor(audioContext, sources, streams, format, streamNames, createDataSender(socket, notifyAudioParamsUpdatedOnRecordingStart));
            }
            return {
                streams,
                streamNames,
                sources,
                audioProcessor,
                audioContext,
                volumeInterval,
            };
        }
        catch (err) {
            handleMicrophoneError(err);
        }
    });
}
export const startRecording = (socket, properties, setRecordingContext, handleMicrophoneError, isScribeTraining) => __awaiter(void 0, void 0, void 0, function* () {
    const recordingContext = yield setupRecording(socket, properties, handleMicrophoneError, isScribeTraining);
    setRecordingContext(recordingContext);
});
export const stopRecording = (resetRecordingContext) => {
    resetRecordingContext();
};
export const destroyRecordingContext = (recordingContext) => __awaiter(void 0, void 0, void 0, function* () {
    if (!recordingContext)
        return;
    if (window.__TAURI__ && typeof recordingContext === 'string') {
        yield window.__TAURI__.invoke('stop_recording', { deviceName: recordingContext });
        return;
    }
    const { audioContext, audioProcessor, sources, streams, volumeInterval } = recordingContext;
    stopAudioProcessor(audioProcessor);
    stopStreams(streams);
    disconnectSources(sources);
    yield closeAudioContext(audioContext);
    clearVolumeInterval(volumeInterval);
});
