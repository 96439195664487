/* eslint-disable no-shadow */
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import Help from '@mui/icons-material/Help';
import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Toggle from 'react-toggled';
import { switchLang } from '../../actions';
import ConversationModeSelector from '../../modules/scribe/ConversationModeSelector';
import LangSelectorContainer from '../../modules/scribe/LangSelectorContainer';
import ScribeReportModalContainer from '../../modules/scribe/ScribeReportModalContainer';
import ScribeShortcutModal from '../../modules/scribe/ScribeShortcutModal';
import TranslationSelector from '../../modules/scribe/TranslationSelector';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectConversationMuted, selectQuickSetupShown } from '../../selectors/combined';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectRecallAIStatus } from '../../selectors/recallAI';
import { selectElectronCaptionMode, selectIsSideBarOpen } from '../../selectors/ui';
import { setIsBoosting } from '../../store/slices/boost';
import { toggleSideBar } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { SNCF } from '../../utils';
import { getIsMac } from '../../utils/electron';
import { show } from '../../utils/intercom';
import { useShouldShowNewSidebarAndHeader } from '../../utils/layout';
import { canTranslate } from '../../utils/status';
import OpenOrDownloadAvaAppComponent from '../OpenOrDownloadAvaAppComponent';
import CaptionsSettings from './CaptionsSettings';
import HomeView from './HomeView';
import LanguageSwitcher from './LanguageSwitcher';
import LogOut from './LogOut';
import OpenOrDownloadAva from './OpenOrDownloadAva';
import ScribePreferences from './ScribePreferences';
import SelectMicrophone from './SelectMicrophone';
import ServerSettings from './ServerSettings';
import User from './User';
const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        [theme.breakpoints.down('sm')]: {
            width: (props) => (props.sidebarOpen ? 'calc(var(--vw) * 100)' : 0),
            display: (props) => !props.firebaseUser && 'none',
        },
        [theme.breakpoints.up('sm')]: {
            width: 270,
        },
        marginTop: 67,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            width: '0 !important',
        },
        backgroundColor: theme.palette.secondary[theme.palette.mode],
    },
    listItemText: {
        paddingRight: 0,
    },
    expandIcon: {
        minWidth: 0,
        '& i': {
            marginLeft: 0,
        },
    },
}));
function Sidebar({ features, i18n, sidebarShown, canTranslate, t, className, }) {
    const [openOrDownloadAvaApp, setOpenOrDownloadAvaApp] = useState(false);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const isInConversation = useAppSelector(selectIsInConversation);
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const sidebarOpen = useAppSelector(selectIsSideBarOpen);
    const quickSetupShown = useAppSelector(selectQuickSetupShown);
    const conversationMuted = useAppSelector(selectConversationMuted);
    const dispatch = useAppDispatch();
    const classes = useStyles({ firebaseUser, sidebarOpen });
    // conversationMuted doesn't apply to host, so to disable for all users
    // in AvaConnect we use recallAIStatus
    const shouldDisplaySpokenLang = isInConversation && recallAIStatus !== 'CAPTIONING' && !conversationMuted;
    let marginTop = 67;
    if (window.isElectron) {
        if (getIsMac()) {
            marginTop += 28;
        }
        else {
            marginTop += 32;
        }
    }
    const showNewSidebarAndHeader = useShouldShowNewSidebarAndHeader({
        electronCaptionMode,
        firebaseUser,
        isInConversation,
    });
    if (quickSetupShown)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: showNewSidebarAndHeader ? 'inherit' : 'none' } },
            React.createElement(HomeView, { className: classnames(className) }),
            ";"),
        React.createElement(Drawer, { classes: {
                paper: classes.drawerPaper,
            }, className: classnames('invisible-scrollbar', className), open: sidebarShown, onClose: () => dispatch(toggleSideBar(false)), anchor: "left", variant: "persistent", style: {
                width: sidebarShown && i18n.language === 'fr' ? 300 : null,
                display: showNewSidebarAndHeader ? 'none' : 'inherit',
            }, PaperProps: {
                style: {
                    width: sidebarShown && i18n.language === 'fr' ? 300 : null,
                    height: `calc(100% - ${marginTop}px)`,
                    marginTop,
                    visibility: 'visible',
                },
            } },
            React.createElement(List, { component: "nav", disablePadding: true },
                React.createElement(User, null),
                React.createElement(React.Fragment, null, features.scribe && (React.createElement(ListItem, { onClick: () => {
                        window.location.href = '/web/scribe-dashboard';
                    }, key: "scribe_dashboard_toggle", role: "button", button: true },
                    React.createElement(ListItemIcon, null,
                        React.createElement(ViewAgendaOutlinedIcon, null)),
                    React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: t('sidebar.scribeDashboard') })))),
                React.createElement(Divider, null),
                React.createElement(OpenOrDownloadAva, { setOpenOrDownloadAvaApp: setOpenOrDownloadAvaApp }),
                React.createElement(SelectMicrophone, null),
                React.createElement(CaptionsSettings, null),
                React.createElement(ScribePreferences, null),
                React.createElement(ConversationModeSelector, null),
                React.createElement("div", { style: {
                        display: shouldDisplaySpokenLang ? 'inherit' : 'none',
                    } },
                    React.createElement(LangSelectorContainer, null)),
                React.createElement("div", { style: {
                        display: isInConversation && canTranslate ? 'inherit' : 'none',
                    } },
                    React.createElement(TranslationSelector, null)),
                features.scribe && React.createElement(Divider, null),
                features.scribe && [
                    React.createElement(Toggle, { key: "1_toggle_array" }, ({ on, getTogglerProps, setOff }) => (React.createElement(ListItem, { button: true },
                        React.createElement(ListItemIcon, null,
                            React.createElement(ReportOutlinedIcon, null)),
                        React.createElement(ListItemText, Object.assign({ classes: { root: classes.listItemText } }, getTogglerProps(), { primary: t('sidebar.reportIssue') })),
                        React.createElement(ScribeReportModalContainer, { open: on, close: setOff, old: false })))),
                ],
                features.scribe && (React.createElement(Toggle, { key: "2_toggle_array" }, ({ on: on2, getTogglerProps: getTogglerProps2, setOff: setOff2 }) => (React.createElement(ListItem, { button: true },
                    React.createElement(ListItemIcon, null,
                        React.createElement(KeyboardOutlinedIcon, null)),
                    React.createElement(ListItemText, Object.assign({ classes: { root: classes.listItemText } }, getTogglerProps2(), { primary: t('sidebar.viewShortcutList') })),
                    React.createElement(ScribeShortcutModal, { open: on2, close: setOff2 }))))),
                features.scribe && (React.createElement(ListItem, { button: true, onClick: () => {
                        window.open('https://www.notion.so/ava/Scribe-guide-fr-translated-en-7f83c1e0158b4df3b9a5dbbeca3143f0', '_blank');
                    } },
                    React.createElement(ListItemIcon, null,
                        React.createElement(BookOutlinedIcon, null)),
                    React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: t('sidebar.scribeUserGuide') }))),
                features.scribe && (React.createElement(ListItem, { key: "boost", button: true, onClick: () => dispatch(setIsBoosting(true)) },
                    React.createElement(ListItemIcon, null,
                        React.createElement(CachedOutlinedIcon, null)),
                    React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: t('Boost') }))),
                React.createElement(ListItem, { button: true },
                    React.createElement(ListItemIcon, null,
                        React.createElement(Help, null)),
                    React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: t('sidebar.contactSupport'), onClick: () => {
                            show();
                            dispatch(toggleSideBar());
                        } })),
                SNCF && (React.createElement(React.Fragment, null,
                    React.createElement(ListItem, { button: true },
                        React.createElement(ListItemIcon, null,
                            React.createElement(Help, null)),
                        React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: t('sncf.privacyPolicy'), onClick: () => {
                                window.open('https://www.ava.me/privacy-sncf', '_blank');
                            } })),
                    React.createElement(ListItem, { button: true },
                        React.createElement(ListItemIcon, null,
                            React.createElement(Help, null)),
                        React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: t('sncf.termsAndConditions'), onClick: () => {
                                window.open('https://www.ava.me/terms-and-conditions-sncf', '_blank');
                            } })))),
                React.createElement(ServerSettings, null),
                React.createElement(Divider, null),
                React.createElement(LogOut, null),
                React.createElement(LanguageSwitcher, null)),
            openOrDownloadAvaApp && React.createElement(OpenOrDownloadAvaAppComponent, null))));
}
const mapStateToProps = ({ scribeConversation: { status }, userProfile: { features, subscription } }) => ({
    canTranslate: canTranslate(subscription, status),
    features,
});
export default connect(mapStateToProps, {
    switchLang,
})(withTranslation()(Sidebar));
