import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import DropdownItem from '../../components/Dropdowns/DropdownItem';
import SelectionInput from '../../components/Inputs/SelectionInput';
import { selectHearingProfile, selectUpdateHearingProfileLoading } from '../../selectors/userProfile';
import { updateHearingProfile } from '../../store/slices/userProfile';
import { useAppSelector } from '../../store/store';
import { HEARING_PROFILES } from '../../utils/user';
const HearingProfileDropdown = () => {
    const { t } = useTranslation();
    const hearingProfile = useAppSelector(selectHearingProfile);
    const updateUserHearingProfileLoading = useAppSelector(selectUpdateHearingProfileLoading);
    const dispatch = useDispatch();
    const onUpdateHearingProfile = (profileNumber) => {
        dispatch(updateHearingProfile(profileNumber));
    };
    const hearingProfilePhraseKey = HEARING_PROFILES[hearingProfile];
    return (React.createElement(React.Fragment, null,
        React.createElement(SelectionInput, { buttonId: "hearing-profile-button", dropdownId: "hearing-profile-menu", placeholder: t('options.select'), value: t(hearingProfilePhraseKey), loading: updateUserHearingProfileLoading, transparentBg: true }, Object.keys(HEARING_PROFILES).map((profileNumber) => (React.createElement(DropdownItem, { key: profileNumber, active: hearingProfilePhraseKey === HEARING_PROFILES[profileNumber], onClick: () => onUpdateHearingProfile(parseInt(profileNumber)) }, t(HEARING_PROFILES[profileNumber])))))));
};
export default HearingProfileDropdown;
