import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: 16,
        marginBottom: 24,
        backgroundColor: theme.palette.ava.grey6,
    },
}));
const SectionDivider = () => {
    const classes = useStyles();
    return React.createElement(Divider, { className: classes.divider });
};
export default SectionDivider;
