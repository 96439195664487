import React from 'react';
const WorkEmail = () => {
    return (React.createElement("svg", { width: "32", height: "33", viewBox: "0 0 32 33", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("circle", { cx: "16", cy: "16.3223", r: "16", fill: "#50ACED" }),
        React.createElement("path", { d: "M25.169 10.6973L19.5078 16.322L25.169 21.9468C25.2714 21.7329 25.3335 21.4964 25.3335 21.2439V11.4001C25.3335 11.1476 25.2714 10.9112 25.169 10.6973Z", fill: "white" }),
        React.createElement("path", { d: "M23.6938 9.75977H8.30835C8.0558 9.75977 7.81937 9.82185 7.60547 9.92419L14.8411 17.1234C15.4809 17.7631 16.5212 17.7631 17.161 17.1234L24.3966 9.92419C24.1827 9.82185 23.9463 9.75977 23.6938 9.75977Z", fill: "white" }),
        React.createElement("path", { d: "M6.8324 10.6973C6.73006 10.9112 6.66797 11.1476 6.66797 11.4001V21.2439C6.66797 21.4964 6.73006 21.7329 6.8324 21.9468L12.4936 16.322L6.8324 10.6973Z", fill: "white" }),
        React.createElement("path", { d: "M18.7354 17.0957L17.9343 17.8968C16.8683 18.9628 15.1338 18.9628 14.0677 17.8968L13.2667 17.0957L7.60547 22.7205C7.81937 22.8228 8.0558 22.8849 8.30835 22.8849H23.6938C23.9463 22.8849 24.1827 22.8228 24.3966 22.7205L18.7354 17.0957Z", fill: "white" })));
};
export default WorkEmail;
