export default function (ev) {
    return {
        ESC: ev.key === 'Escape',
        TAB: ev.key === 'Tab',
        RETURN: ev.key === 'Enter',
        SHIFT: ev.shiftKey,
        LEFT_ARROW: ev.key === 'ArrowLeft',
        UP_ARROW: ev.key === 'ArrowUp',
        RIGHT_ARROW: ev.key === 'ArrowRight',
        DOWN_ARROW: ev.key === 'ArrowDown',
        BACKSPACE: ev.key === 'Backspace',
        SPACE: ev.key === ' ',
        PERIOD: ev.key === '.',
        COMMA: ev.key === ',',
        1: ev.keyCode === '1',
        SEMI_COLON: ev.key === ';',
        COLON: ev.key === ':',
        FORWARD_SLASH: ev.key === '/',
        ALT: ev.altKey,
        CTRL: ev.ctrlKey,
        META: ev.metaKey,
        Y: ev.key === 'y',
        h: ev.keyCode === 72,
        p: ev.keyCode === 80,
    };
}
