var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { createNewConversation } from '../../actions';
import { selectIsSideBarOpen } from '../../selectors/ui';
import { useAppDispatch, useAppSelector } from '../../store/store';
import SidebarButton from '../Buttons/SidebarButton';
import Mic from '../Icons/Mic';
const useStyles = makeStyles({
    button: {
        marginTop: 10,
        marginBottom: 16,
    },
    text: {
        marginLeft: 16,
        fontSize: 18,
        maxWidth: 170,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});
const StartCaptionsButton = ({ createNewConversation }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const sidebarOpen = useAppSelector(selectIsSideBarOpen);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const onClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!pathname.includes('/web/transcript')) {
            navigate('/web/transcript');
        }
        yield createNewConversation();
    });
    return (React.createElement(SidebarButton, { sidebarOpen: sidebarOpen, className: classes.button, color: "blue", icon: React.createElement(Mic, { width: 36, height: 44 }), onClick: onClick },
        React.createElement("span", { className: classes.text }, t('sidebar.buttons.startCaptions'))));
};
const mapStateToProps = () => ({});
const mapDispatchToProps = {
    createNewConversation,
};
export default connect(mapStateToProps, mapDispatchToProps)(StartCaptionsButton);
