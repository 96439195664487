import { withTheme } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { addNotification } from '../../store/slices/ccMode';
import AudioRecorder from './AudioRecorder';
import withMediaDevices from './withMediaDevices';
const mapStateToProps = ({ scribeConversation: { lang, speechLang, status }, scribeDashboard: { scribeTrainingSourceNode, scribeTrainingAudioStreamAC }, }) => ({
    lang,
    participants: status.participants,
    scribeTrainingSourceNode,
    scribeTrainingAudioStreamAC,
    speechLang,
    status,
});
const mapDispatchToProps = {
    addNotification,
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withTheme(withMediaDevices(AudioRecorder))));
