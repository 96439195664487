import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CancelSaveControl from '../../components/Controls/CancelSaveControl';
import InputField from '../../components/Inputs/InputField';
import { selectDisplayName, selectUpdateUserNameLoading, selectUpdateUserNameSuccess, } from '../../selectors/userProfile';
import { updateUserName } from '../../store/slices/userProfile';
import { useAppSelector } from '../../store/store';
import { handleCancelSaveEvent } from '../../utils/keyboardEvent';
import { isUserNameValid } from '../../utils/user';
const useStyles = makeStyles(() => ({
    input: {
        width: '60%',
        marginRight: 10,
    },
}));
const UserNameInput = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const displayName = useAppSelector(selectDisplayName);
    const updateUserNameLoading = useAppSelector(selectUpdateUserNameLoading);
    const updateUserNameSuccess = useAppSelector(selectUpdateUserNameSuccess);
    const updateUserNameRequestAsync = (userName) => {
        dispatch(updateUserName(userName));
    };
    const [value, setValue] = useState(displayName || '');
    return (React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
        React.createElement(InputField, { className: classes.input, value: value, onChange: (e) => setValue(e.target.value), onKeyDown: (event) => {
                handleCancelSaveEvent({
                    event,
                    saveCondition: !updateUserNameLoading && !updateUserNameSuccess && value.trim() !== displayName,
                    onSave: () => updateUserNameRequestAsync(value.trim()),
                    onCancel: () => setValue(displayName),
                });
            } }),
        React.createElement(CancelSaveControl, { showControls: isUserNameValid(value) && value.trim() !== displayName, cancelText: t('options.cancel'), saveText: t('options.save'), saveDisabled: !isUserNameValid(value.trim()), loading: updateUserNameLoading, resetValue: () => setValue(displayName || ''), resetSuccess: () => { }, success: updateUserNameSuccess, successMessage: t('options.saved'), onCancelClick: () => setValue(displayName || ''), onSaveClick: () => updateUserNameRequestAsync(value.trim()) })));
};
export default UserNameInput;
