var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import 'audioworklet-polyfill';
import uniqBy from 'lodash/uniqBy';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import useMicPermission from '../../hooks/useMicPermission';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectAudioParamsSent, selectAudioRecordingContext, selectAvailableMics, selectHasMicrophoneRestarted, selectIsRecording, selectIsRestartingMicrophone, selectMicIdSelected, selectOnlyInternalAudio, } from '../../selectors/audioRecorder';
import { selectConfigUseWebRTC } from '../../selectors/auth';
import { selectConversationMuted } from '../../selectors/combined';
import { selectCaptionQuality, selectCurseFilter } from '../../selectors/conversation';
import { selectRecallAIStatus } from '../../selectors/recallAI';
import { selectScribeTrainingRequested } from '../../selectors/scribe-dashboard';
import { selectElectronCaptionMode, selectFullScreen } from '../../selectors/ui';
import { selectAvaId, selectFeatures } from '../../selectors/userProfile';
import { resetAudioRecorderState, resetRecordingContext, setAudioParamsSent, setHasMicrophoneRestarted, setIsRestartingMicrophone, setRecordingContext as recordingContextSetter, } from '../../store/slices/audioRecorder';
import { setAvailableMics, setMicId, setRecording } from '../../store/slices/audioRecorder';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { captureMicrophoneExceptionIfExists, destroyRecordingContext, getAvailableMicsFromMediaDevices, getMediaDevices, getMicIdToSelect, isBluetoothMic, isMicAccessDenied, startRecording, stopRecording, stopStreams, trackMicPermissionIfPromptExists, } from '../../utils/audioRecorder';
import { ipcRendererWithDeregistering } from '../../utils/electron';
import { getSnack } from '../../utils/snackbar';
import { hasScribe } from '../../utils/status';
import AudioRecorderUI from './AudioRecorderUI';
const AudioRecorder = (props) => {
    const { addNotification, conversationEnded, hideButton, lang, participants: unfilteredParticipants, scribeTrainingSourceNode, scribeTrainingAudioStreamAC, showExpandedMic, speechLang, status, t, theme, } = props;
    const participants = uniqBy(unfilteredParticipants || [], 'avaId').filter((p) => !p.scribe);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { micPermissionStatus, trackMicPermissionPrompt } = useMicPermission();
    const [socket, socketStatus] = useV1Socket();
    const avaId = useAppSelector(selectAvaId);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const isMicrophoneActive = useAppSelector(selectIsRecording);
    const availableMics = useAppSelector(selectAvailableMics);
    const recordingContext = useAppSelector(selectAudioRecordingContext);
    const hasMicrophoneRestarted = useAppSelector(selectHasMicrophoneRestarted);
    const isRestartingMicrophone = useAppSelector(selectIsRestartingMicrophone);
    const micIdSelected = useAppSelector(selectMicIdSelected);
    const onlyInternalAudio = useAppSelector(selectOnlyInternalAudio);
    const audioParamsSent = useAppSelector(selectAudioParamsSent);
    const selectedCaptions = useAppSelector(selectCaptionQuality);
    const curseFilter = useAppSelector(selectCurseFilter);
    const conversationMuted = useAppSelector(selectConversationMuted);
    const useWebRTCFeature = useAppSelector(selectFeatures)['web-use-webrtc'];
    const useWebRTCConfig = useAppSelector(selectConfigUseWebRTC);
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const useWebRTC = useWebRTCFeature || useWebRTCConfig;
    const fullScreen = useAppSelector(selectFullScreen);
    const [microphoneLoading, setMicrophoneLoading] = useState(true);
    // We add 'isRecording' state variable, because we want to decouple app-wide
    // 'recording' state changes (managed by setRecording(boolean))
    // actions) with actual audio recording process. The reason is that setting up
    // and tearing down audio recording is a heavy/async process and random calls to start
    // or stop recording in the codebase can casue race conditions in this component.
    // Having a separate state variable gives us the ability to gracefully manage
    // this process.
    const [isRecording, setIsRecording] = useState(false);
    const [micAccessDenied, setMicAccessDenied] = useState(false);
    const [volume, setVolume] = useState(0);
    const [errorShown, setErrorShown] = useState(false);
    const [isPermissionGranted, setIsPermissionGranted] = useState(false);
    const isScribeTraining = useAppSelector(selectScribeTrainingRequested);
    const dispatch = useAppDispatch();
    const setRecordingContext = (Context) => dispatch(recordingContextSetter(Context));
    const handleMicrophoneError = (err) => {
        dispatch(setRecording(false));
        if (isMicAccessDenied(err)) {
            setMicAccessDenied(true);
        }
        trackMicPermissionIfPromptExists(err, trackMicPermissionPrompt);
        captureMicrophoneExceptionIfExists(err, t, errorShown, setErrorShown, (errorMessage) => {
            getSnack({ enqueueSnackbar, closeSnackbar })(errorMessage, {
                variant: 'error',
                preventDuplicate: true,
                autoHideDuration: 10000,
            });
        });
    };
    const retrieveAndSetAvailableMics = () => __awaiter(void 0, void 0, void 0, function* () {
        if (window.__TAURI__) {
            const inputDevices = (yield window.__TAURI__.invoke('get_input_device_names')).map((name) => ({
                label: name,
                value: name,
                default: false,
            }));
            dispatch(setAvailableMics(inputDevices));
            return inputDevices;
        }
        try {
            const mediaDevices = yield getMediaDevices();
            // NOTE: This:
            // * removes the default device and adds a `default` bit to one of the other devices
            // * creates fake avaMic device
            // does a lot of things with respect to muting and participants
            const currentAvailableMics = getAvailableMicsFromMediaDevices(mediaDevices, {
                isMuted: conversationMuted,
                participants,
                t,
            });
            dispatch(setAvailableMics(currentAvailableMics));
            // NOTE: This seems to be the main place where mics are set
            // mediaDevices = [{
            //deviceId : "5fc826dc07c6c78b906d381b3d2ad7cfd6aef8cad26c5a2fa5bc0f69e0d28d9a"
            //groupId : "6d8e09f247ce207dd25cf3cd57fefbad069232ef2e03176ad521b9e32f87bb74"
            //kind : "audioinput"
            //label : "Built-in Audio Analog Stereo"
            // currentAvailableMics = [{
            // default: false
            // label: "Nice looking label"
            // value: "id-that-looks-like-id"
            // When the component loads, available mics will be empty initially.
            // Checking microphoneLoading lets the audio devices load first.
            if (!microphoneLoading && !availableMics.length) {
                throw new Error('no audio input');
            }
        }
        catch (err) {
            handleMicrophoneError(err);
        }
    });
    const onDeviceChange = () => __awaiter(void 0, void 0, void 0, function* () { return yield retrieveAndSetAvailableMics(); });
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () { return yield retrieveAndSetAvailableMics(); }))();
        if (!window.__TAURI__) {
            navigator.mediaDevices.ondevicechange = onDeviceChange;
            if (window.isElectron) {
                ipcRendererWithDeregistering.addEventListener('multiOutputChanged', onDeviceChange);
            }
            return () => {
                navigator.mediaDevices.ondevicechange = null;
                if (window.isElectron) {
                    ipcRendererWithDeregistering.removeEventListener('multiOutputChanged', onDeviceChange);
                }
                dispatch(resetAudioRecorderState());
            };
        }
    }, []);
    useEffect(() => {
        if (availableMics.length) {
            let micIdToSelect = getMicIdToSelect(availableMics, micIdSelected) || '';
            const defaultMic = availableMics.find((mic) => mic.default);
            if (participants.length > 1 && defaultMic) {
                micIdToSelect = defaultMic.value;
                // When the component first loads and this is a group convo, we make sure
                // the Ava Mic is NOT available. If it is available, we reload the list
                // of available mics again.
                const enabledAvaMic = availableMics.find((mic) => mic.label.includes('Ava Computer Audio'));
                if (enabledAvaMic) {
                    onDeviceChange();
                    return;
                }
            }
            dispatch(setMicId({ micId: micIdToSelect }));
            setMicrophoneLoading(false);
        }
    }, [availableMics]);
    useEffect(() => {
        if (isScribeTraining && !scribeTrainingSourceNode) {
            // Scribe training has been requested, but the stream isn't ready yet
            // We should wait for it in the next useEffect call.
            return;
        }
        if (isRestartingMicrophone) {
            stopRecording(() => dispatch(resetRecordingContext()));
            dispatch(setHasMicrophoneRestarted(true));
            dispatch(setIsRestartingMicrophone(false));
            return;
        }
        if (isRecording && recallAIStatus !== 'CAPTIONING' && recallAIStatus !== 'DISCONNECT') {
            (() => __awaiter(void 0, void 0, void 0, function* () {
                // Firefox has a bug with concurrrent microphones. Therefore we need
                // to stop previous tracks before starting a new microphone.
                // https://bugzilla.mozilla.org/show_bug.cgi?id=1238038
                if (recordingContext) {
                    const { streams } = recordingContext;
                    stopStreams(streams);
                    setIsPermissionGranted(false);
                }
                yield startRecording(socket, {
                    scribeTrainingSourceNode,
                    scribeTrainingAudioStreamAC,
                    curseFilter,
                    lang,
                    speechLang,
                    availableMics,
                    audioParamsSent,
                    micIdSelected,
                    onlyInternalAudio,
                    setAudioParamsSent: (bool) => dispatch(setAudioParamsSent(bool)),
                    setIsPermissionGranted,
                    setVolume,
                    trackMicPermissionPrompt,
                    useWebRTC,
                }, setRecordingContext, handleMicrophoneError, isScribeTraining);
                if (hasMicrophoneRestarted) {
                    dispatch(setHasMicrophoneRestarted(false));
                }
            }))();
        }
        else {
            stopRecording(() => dispatch(resetRecordingContext()));
        }
    }, [micIdSelected, isRecording, isRestartingMicrophone, scribeTrainingSourceNode, isScribeTraining]);
    useEffect(() => {
        if (isPermissionGranted) {
            onDeviceChange();
        }
    }, [isPermissionGranted]);
    useEffect(() => {
        setIsRecording(isMicrophoneActive);
    }, [isMicrophoneActive]);
    useEffect(() => {
        dispatch(setRecording(socketStatus === 'online'));
    }, [socketStatus]);
    useEffect(() => {
        if (micAccessDenied && micPermissionStatus === 'granted') {
            // User granted mic access after previously denying it,
            // so we try to start recording again.
            dispatch(setRecording(true));
        }
    }, [micPermissionStatus]);
    useEffect(() => {
        if (!microphoneLoading) {
            dispatch(setIsRestartingMicrophone(true));
        }
    }, [onlyInternalAudio]);
    useEffect(() => {
        const convoSettingChanged = curseFilter || lang || speechLang;
        const isMicOn = isRecording && audioParamsSent;
        if (convoSettingChanged && isMicOn) {
            dispatch(setIsRestartingMicrophone(true));
        }
    }, [curseFilter, lang, speechLang]);
    useEffect(() => {
        if (!microphoneLoading && !conversationMuted) {
            const enabledAvaMic = availableMics.find((mic) => mic.label.includes('Ava Computer Audio'));
            if (participants.length > 1) {
                // We make the Ava Mic forbidden when there is more than one
                // participant. So we reload the devices again. To prevent the call from
                // happening every time a new participant joins, we only reload devices
                // when the Ava Mic is available.
                if (enabledAvaMic) {
                    onDeviceChange();
                }
            }
            else if (participants.length < 2) {
                // If this turns into a solo convo, we reload devices again because the
                // Ava Mic will be available now.
                onDeviceChange();
            }
        }
    }, [participants.length]);
    useEffect(() => {
        // Conversation's audio streams can be updated remotely and backend sends this info
        // via 'room-status-update' message. Here we are interested if our audio has been
        // muted remotely.
        //
        // There could be more than one stream for each participant. We check the last (latest)
        // for this user.
        const myStreams = (status.audioStreams || []).filter((stream) => stream.id === avaId);
        if (myStreams.length > 0) {
            const [last] = myStreams.slice(-1);
            if (last.forceMuted && isMicrophoneActive) {
                dispatch(setRecording(false));
                if (window.isElectron && electronCaptionMode) {
                    addNotification({
                        text: t('conversation.notifications.youHaveBeenMuted'),
                        timestamp: Date.now(),
                        type: 'default',
                    });
                }
                else {
                    getSnack(props)(t('conversation.notifications.youHaveBeenMuted'));
                }
            }
        }
    }, [status.audioStreams]);
    useEffect(() => {
        return () => {
            destroyRecordingContext(recordingContext);
        };
    }, [recordingContext]);
    useEffect(() => {
        if (conversationEnded && isRecording) {
            dispatch(setRecording(false));
        }
    }, [conversationEnded]);
    /*
    This logic is partially copied from AudioRecorderUI. The purpose of putting it
    here is to reduce the number of React state changed when `volume` changes.
    `volume` is updated 5-8 times a second, and reducing the size of the tree
    that is updated as that happens helps performance.
     */
    const showMinimalMic = window.isElectron && electronCaptionMode && !showExpandedMic;
    let shadowColor;
    if (isRecording && audioParamsSent) {
        if (hasScribe(status)) {
            shadowColor = theme.palette.ava.green;
        }
        else if (selectedCaptions === undefined || selectedCaptions === 'premium') {
            shadowColor = theme.palette.ava.gradientBlue;
            shadowColor = theme.palette.ava.darkBlue;
        }
        else {
            shadowColor = theme.palette.ava.blue;
        }
    }
    // Volume is currently between 0 and 128, but this is browser-dependent
    const shadowSpread = Math.min(8, Math.floor(Math.log2(volume) * 3));
    const width = showMinimalMic ? 20 : 56;
    const height = showMinimalMic ? 20 : 56;
    const positionLeft = window.isElectron && electronCaptionMode ? `calc(50% - ${width / 2}px)` : 0;
    const positionTop = window.isElectron && electronCaptionMode ? `calc(50% - ${height / 2 - 2}px)` : 0;
    return (React.createElement("div", { style: {
            visibility: fullScreen && !electronCaptionMode ? 'hidden' : 'visible',
            display: hideButton ? 'none' : 'inherit',
        } },
        React.createElement("div", { style: {
                position: 'absolute',
                left: positionLeft,
                top: positionTop,
                height,
                width,
                minHeight: height,
                borderRadius: '50%',
                transition: 'all 120ms ease-in 0s, box-shadow 250ms ease-in 0s',
                boxShadow: isRecording ? `0px 0px 5px ${shadowSpread}px ${shadowColor}90` : 'black 0px 0px 0px 0px',
            } }),
        React.createElement(AudioRecorderUI, Object.assign({}, props, { showMinimalMic: showMinimalMic, isRecording: isRecording, bluetooth: isBluetoothMic(availableMics.find((mic) => mic.value === micIdSelected)), microphoneDenied: micAccessDenied }))));
};
export default AudioRecorder;
