import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { selectAvaName, selectDisplayName, selectSubscription } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import { getPlanType } from '../../utils/status';
import UserIcon from '../Icons/UserIcon';
const useStyles = makeStyles((theme) => ({
    details: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        paddingTop: 18,
        paddingRight: 24,
        paddingBottom: 8,
        paddingLeft: 24,
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 16,
        overflow: 'hidden',
        '& > :not(:first-child)': {
            marginBottom: 4,
        },
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    name: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 20,
        lineHeight: '26px',
    },
    avaName: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.text.dark2,
        fontSize: 16,
        lineHeight: '20.8px',
    },
    plan: {
        fontSize: 14,
        lineHeight: '18.2px',
    },
    blue: {
        color: theme.palette.ava.blue2,
    },
}));
const ProfileDetails = () => {
    const classes = useStyles();
    const displayName = useAppSelector(selectDisplayName);
    const avaName = useAppSelector(selectAvaName);
    const subscription = useAppSelector(selectSubscription);
    const subscriptionLabel = getPlanType(subscription);
    return (React.createElement("section", { className: classes.details },
        React.createElement(UserIcon, { width: 80, height: 80 }),
        React.createElement("div", { className: classes.info },
            React.createElement("span", { className: classnames(classes.text, classes.name) }, displayName),
            React.createElement("span", { className: classnames(classes.text, classes.avaName) },
                React.createElement("span", { className: classes.blue }, "&"),
                avaName),
            React.createElement("span", { className: classnames(classes.text, classes.plan, classes.blue) },
                subscriptionLabel,
                " Plan"))));
};
export default ProfileDetails;
