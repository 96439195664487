import { connect } from 'react-redux';
import { createNewConversation } from '../../../actions';
import { setCCMode, setShowBubbleAboveCCModeDebounced } from '../../../store/slices/ccMode';
import HomeView from './HomeView';
const mapStateToProps = ({ ccMode: { showBubbleAboveCCMode } }) => ({
    showBubbleAboveCCMode,
});
const mapDispatchToProps = {
    createNewConversation,
    setCCMode,
    setShowBubbleAboveCCMode: setShowBubbleAboveCCModeDebounced,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
