import React from 'react';
const DarkMode = () => {
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("mask", { id: "mask0_628:1168", style: { maskType: 'alpha' }, maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "24", height: "24" },
            React.createElement("circle", { cx: "12", cy: "12", r: "11.7612", fill: "#C4C4C4", stroke: "#1B367C", strokeWidth: "0.477661" })),
        React.createElement("g", { mask: "url(#mask0_628:1168)" },
            React.createElement("path", { d: "M11.779 22.1734C17.8905 22.1734 22.8448 17.2191 22.8448 11.1076C22.8448 4.99614 17.8905 0.0418095 11.779 0.0418095C5.66753 0.0418095 0.713196 4.99614 0.713196 11.1076C0.713196 17.2191 5.66753 22.1734 11.779 22.1734Z", fill: "black", stroke: "white", strokeWidth: "1.43298" }),
            React.createElement("path", { d: "M14.4598 3.14863C14.4598 8.7325 9.93319 13.2591 4.34932 13.2591C-1.23455 13.2591 -5.76117 8.7325 -5.76117 3.14863C-5.76117 -2.43523 -1.23455 -6.96185 4.34932 -6.96185C9.93319 -6.96185 14.4598 -2.43523 14.4598 3.14863Z", fill: "white", stroke: "white", strokeWidth: "0.477661" }))));
};
export default DarkMode;
