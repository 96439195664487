import { call, put } from 'redux-saga/effects';
import { newTrial } from '../../actions/account/licence';
import { fetchOrganizations, fetchUserInfos, newOrganization } from '../../actions/account/user';
import firebase from '../../firebase';
import { organizations as avaOrganizations } from '../../services/api/ava';
import { organizations as saasOrganizations } from '../../services/api/saas';
export function* postOrganization({ payload }) {
    try {
        const res = yield call(saasOrganizations.createOrganization, Object.assign({}, payload));
        yield put(fetchUserInfos.request());
        yield put(newTrial.request(res.data.organization));
        yield put(newOrganization.success(res.data));
    }
    catch (err) {
        yield put(newOrganization.failure(err.response));
    }
}
export function* searchOrganizations({ orgName, orgType }) {
    try {
        const firebaseUser = firebase.auth().currentUser;
        if (!firebaseUser) {
            return;
        }
        const data = {
            firebaseAuthUID: firebaseUser.uid,
            orgName,
            orgType,
        };
        const res = yield call(avaOrganizations.findOrganizations, data);
        yield put(fetchOrganizations.success(res.data));
    }
    catch (err) {
        yield put(fetchOrganizations.failure(err.response));
    }
}
