var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import 'react-phone-input-2/lib/material.css';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as emailValidator from 'email-validator';
import { auth } from 'firebase';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useV1Socket } from '../../hooks/useV1Socket';
import { firebaseFunctionsEndpoint } from '../../utils/http';
import * as segment from '../../utils/segment';
import { sendEmailQueryMessage } from '../../utils/ws-v1';
export const checkForSSOProvider = (email) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const fetchResponse = yield fetch(`${firebaseFunctionsEndpoint}/widgets/getProviderIdByEmail`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });
        const providerId = yield fetchResponse.text();
        return providerId;
    }
    catch (error) {
        if (error.message === 'no device certificate provided') {
            return null;
        }
        console.error('Error checking SSO provider:', error);
        return null;
    }
});
export const initiateSSOLogin = (providerId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const provider = providerId.includes('saml')
            ? new auth.SAMLAuthProvider(providerId)
            : new auth.OAuthProvider(providerId);
        // Safari thinks two await calls aren't related
        // so we're using Promise.all to ensure popups aren't being blocked for our flow
        yield Promise.all([auth().signInWithRedirect(provider)]);
    }
    catch (error) {
        console.error('Error initiating SSO login:', error);
    }
});
const useStyles = makeStyles((theme) => {
    return {
        form: {
            marginTop: 4,
            width: '100%',
            zIndex: 100,
            fontSize: '1rem',
        },
        paper: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 32,
            maxWidth: 420,
        },
        input: {
            '&:focus': {
                borderColor: `${theme.palette.ava.blue} !important`,
                boxShadow: `0 0 0 1px ${theme.palette.ava.blue} !important`,
            },
            fontFamily: 'inherit !important',
            width: '100% !important',
            height: 56,
            fontSize: 16,
        },
        errorText: {
            [theme.breakpoints.up('sm')]: {
                minWidth: 'max-content',
                textAlign: 'left',
            },
            minWidth: 280,
            maxWidth: 420,
            textAlign: 'center',
            margin: '1% 0 0 0',
            padding: 0,
            color: '#F44336',
        },
        submit: {
            boxShadow: 'none',
            color: 'white',
            margin: '14px 0 8px',
            width: '100%',
            borderRadius: 100,
            fontSize: 18,
        },
        title: {
            fontWeight: 700,
            textAlign: 'center',
        },
    };
});
const ScribeLoginSSO = (props) => {
    const { t } = props;
    const [authError, setAuthError] = useState('');
    const classes = useStyles({ authError });
    const [email, setEmail] = useState('');
    const [emailToShow, setEmailToShow] = useState('');
    const [loading, setLoading] = useState(false);
    const [ws] = useV1Socket();
    const validateEmail = (email) => {
        return emailValidator.validate(email);
    };
    const handleEmailChange = (email) => {
        setEmailToShow(email);
        setEmail(email.toLowerCase());
        if (validateEmail(email)) {
            if (ws) {
                sendEmailQueryMessage(ws, email);
            }
        }
    };
    const loginSSO = (email, e) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            segment.track('Web - Login - SSO Requested');
            e.preventDefault();
            setLoading(true);
            const provider = yield checkForSSOProvider(email);
            if (provider) {
                yield initiateSSOLogin(provider);
            }
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            setAuthError(t('account.login.signin-sso.error'));
        }
    });
    return (React.createElement("div", null,
        React.createElement("div", { className: classes.paper },
            React.createElement(Typography, { variant: "h4", className: classes.title, gutterBottom: true }, t('account.login.signin-sso.title')),
            React.createElement("form", { className: classes.form, noValidate: true },
                React.createElement(TextField, { variant: "outlined", margin: "normal", color: "primary", required: true, fullWidth: true, id: "email", label: "Email Address", name: "email", autoComplete: "email", autoFocus: true, value: emailToShow || '', onChange: (event) => handleEmailChange(event.target.value), error: !!authError, className: classes.input }),
                authError && React.createElement("p", { className: classes.errorText }, authError),
                React.createElement(Button, { type: "submit", variant: "contained", color: "primary", className: classes.submit, disabled: !validateEmail(email) || loading, onClick: (e) => loginSSO(email, e), "data-qa": "ssoLogin:ContinueWithSSOButton" },
                    loading && React.createElement(CircularProgress, { color: "secondary", size: "1.6em" }),
                    !loading && t('continueWithSSO'))))));
};
export default withTranslation()(ScribeLoginSSO);
