import { useTheme } from '@mui/material/styles';
import React from 'react';
const BoostScreen = () => {
    const theme = useTheme();
    const fill = theme.palette.mode === 'light' ? 'white' : 'black';
    const stroke = theme.palette.mode === 'light' ? 'black' : 'white';
    return (React.createElement("svg", { width: "211", height: "146", viewBox: "0 0 211 146", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M205.566 124.38C211.087 127.975 209.803 135.015 203.277 135.015H6.70338C1.20378 135.015 -0.679362 127.59 4.71324 124.187L205.566 124.38Z", fill: stroke, stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M180.273 104.8H35.9785V21.3642C35.9785 15.7148 40.5579 11.1354 46.2073 11.1354H170.045C175.694 11.1354 180.273 15.7148 180.273 21.3642V104.8Z", fill: fill, stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M203.276 130.115H6.7033C3.19383 130.115 1.80287 125.985 4.71316 124.188L36.0202 104.8H179.866L205.545 124.38C208.113 126.328 206.615 130.115 203.276 130.115Z", fill: fill, stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M180.593 124.273H29.3863C26.69 124.273 25.62 121.085 27.8455 119.715L49.5871 104.8H162.575L182.326 119.865C184.317 121.363 183.161 124.273 180.593 124.273Z", fill: fill, stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M152.796 104.799L172.568 124.273", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M142.31 104.799L159.729 124.273", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M132.51 104.799L147.618 124.273", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M121.553 104.799L134.329 124.273", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M112.459 104.8L118.686 124.273", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M105.269 124.508V104.8", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M58.7692 104.8L38.9963 124.273", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M69.2555 104.799L51.8365 124.273", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M79.0559 104.799L63.9481 124.273", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M90.0113 104.799L77.236 124.273", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M99.1045 104.799L92.8773 124.273", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M38.9963 112.076H172.142", stroke: stroke, strokeWidth: "2.4296", strokeMiterlimit: "10" }),
        React.createElement("path", { d: "M169.632 99.3646H47.7211C44.5112 99.3646 41.9005 96.7539 41.9005 93.544V22.0278C41.9005 18.818 44.5112 16.2072 47.7211 16.2072H169.632C172.842 16.2072 175.453 18.818 175.453 22.0278V93.5654C175.453 96.7753 172.842 99.3646 169.632 99.3646Z", fill: "#F4F9FF" }),
        React.createElement("path", { d: "M146.879 24.6688L144.775 32.1711C122.542 34.6105 108.859 49.5631 108.859 49.5631C108.859 49.5631 94.3024 33.6621 70.8378 31.9823V24.6688L67.0631 33.8319V78.8879C67.0631 78.8879 92.3443 74.6885 108.859 88.9664C125.373 74.6885 150.654 78.8879 150.654 78.8879V33.8319L146.879 24.6688Z", fill: "#D0E9FB" }),
        React.createElement("path", { d: "M108.861 89.4407C108.748 89.4394 108.639 89.3975 108.554 89.3227C92.3703 75.3515 67.3958 79.315 67.141 79.3575C67.0732 79.3682 67.0039 79.3641 66.9379 79.3454C66.8719 79.3266 66.8108 79.2937 66.7588 79.2489C66.707 79.2046 66.6654 79.1496 66.6369 79.0877C66.6084 79.0258 66.5937 78.9585 66.5937 78.8903V33.8344C66.5929 33.773 66.6041 33.7121 66.6267 33.6551L70.4014 24.492C70.443 24.3908 70.5185 24.3073 70.6149 24.2557C70.7113 24.2041 70.8226 24.1875 70.9299 24.2089C71.0389 24.2299 71.137 24.2888 71.2069 24.3751C71.2769 24.4614 71.3141 24.5696 71.3121 24.6807V31.5554C92.4693 33.2304 106.502 46.541 108.861 48.9002C111.135 46.6024 124.37 34.0609 144.409 31.7489L146.429 24.5533C146.455 24.4582 146.511 24.3738 146.588 24.312C146.665 24.2502 146.76 24.2141 146.858 24.2089C146.958 24.2015 147.057 24.2258 147.142 24.2784C147.227 24.3309 147.292 24.409 147.33 24.5014L151.105 33.6645C151.129 33.7212 151.142 33.7822 151.143 33.8438V78.8998C151.143 78.9679 151.128 79.0353 151.099 79.0972C151.071 79.1591 151.029 79.2141 150.977 79.2584C150.925 79.3032 150.864 79.3361 150.798 79.3548C150.732 79.3736 150.663 79.3777 150.595 79.3669C150.34 79.3244 125.357 75.3704 109.182 89.3321C109.092 89.4068 108.978 89.4454 108.861 89.4407ZM75.5916 77.8664C84.4434 77.8664 98.3815 79.499 108.861 88.346C124.017 75.5544 146.405 77.8523 150.185 78.3477V33.9287L146.967 26.1151L145.23 32.3009C145.205 32.3909 145.153 32.4713 145.082 32.5318C145.01 32.5923 144.922 32.6302 144.829 32.6406C123.045 35.0329 109.347 49.7401 109.21 49.8864C109.166 49.935 109.112 49.9739 109.052 50.0005C108.992 50.0271 108.927 50.0408 108.861 50.0408C108.795 50.0408 108.73 50.0271 108.67 50.0005C108.61 49.9739 108.556 49.935 108.512 49.8864C108.37 49.7307 93.8046 34.1033 70.8072 32.4566C70.6879 32.4482 70.5762 32.3949 70.4947 32.3073C70.4132 32.2197 70.3681 32.1044 70.3684 31.9848V27.054L67.5373 33.9287V78.3477C70.2091 78.0156 72.8994 77.8549 75.5916 77.8664Z", fill: "black" }),
        React.createElement("path", { d: "M108.86 84.1395V42.3345C108.86 42.3345 94.1054 23.376 70.839 24.6688V73.3626C70.839 73.3626 93.8176 68.866 108.86 84.1395Z", fill: "white" }),
        React.createElement("path", { d: "M108.859 84.6109C108.797 84.6106 108.735 84.598 108.678 84.5737C108.62 84.5494 108.568 84.5139 108.524 84.4693C93.8407 69.5592 71.1546 73.7775 70.9281 73.8246C70.8591 73.838 70.788 73.8358 70.72 73.8181C70.6519 73.8005 70.5887 73.7679 70.5349 73.7226C70.481 73.6774 70.438 73.6207 70.4089 73.5568C70.3798 73.4928 70.3654 73.4231 70.3667 73.3528V24.6684C70.3664 24.548 70.4122 24.4321 70.4947 24.3444C70.5772 24.2567 70.69 24.2038 70.8102 24.1966C94.0624 22.8896 109.081 41.8528 109.232 42.0415C109.297 42.1252 109.332 42.2282 109.331 42.3341V84.139C109.331 84.2642 109.281 84.3842 109.193 84.4727C109.104 84.5612 108.984 84.6109 108.859 84.6109ZM78.5955 72.3006C86.5177 72.3006 98.8846 73.9851 108.387 83.0114V42.4992C107.066 40.8855 92.8168 24.1494 71.3103 25.1167V72.8055C73.723 72.4579 76.158 72.2891 78.5955 72.3006Z", fill: "#101010" }),
        React.createElement("path", { d: "M108.86 84.1395V42.3345C108.86 42.3345 123.614 23.376 146.881 24.6688V73.3626C146.881 73.3626 123.902 68.866 108.86 84.1395Z", fill: "white" }),
        React.createElement("path", { d: "M108.859 84.6114C108.734 84.6114 108.614 84.5616 108.525 84.4732C108.437 84.3847 108.387 84.2647 108.387 84.1395V42.3345C108.386 42.2287 108.421 42.1256 108.486 42.042C108.637 41.8533 123.647 22.8948 146.908 24.197C147.028 24.2043 147.141 24.2571 147.224 24.3448C147.306 24.4326 147.352 24.5485 147.352 24.6689V73.3627C147.352 73.432 147.337 73.5005 147.307 73.5633C147.278 73.6261 147.235 73.6817 147.182 73.726C147.128 73.7705 147.065 73.8025 146.997 73.8196C146.929 73.8367 146.859 73.8386 146.79 73.8251C146.564 73.7779 123.878 69.5786 109.194 84.4698C109.15 84.5144 109.098 84.5499 109.041 84.5742C108.983 84.5985 108.921 84.6111 108.859 84.6114ZM109.331 42.4997V83.0118C123.047 69.956 142.832 72.2586 146.408 72.8059V25.1171C124.902 24.1546 110.652 40.8766 109.331 42.4997Z", fill: "black" }),
        React.createElement("path", { d: "M114.05 46.0663C113.985 46.0661 113.921 46.0526 113.862 46.0266C113.802 46.0006 113.749 45.9627 113.705 45.9153C113.617 45.8269 113.568 45.7073 113.568 45.5826C113.568 45.458 113.617 45.3384 113.705 45.25C120.528 38.8754 127.356 36.134 131.88 34.9544C136.802 33.6758 140.067 33.907 140.204 33.9211C140.328 33.9308 140.444 33.9893 140.525 34.0839C140.606 34.1785 140.647 34.3015 140.638 34.426C140.628 34.5504 140.57 34.6659 140.475 34.7473C140.38 34.8286 140.257 34.8692 140.133 34.8601C140.006 34.8601 127.105 34.0249 114.352 45.9389C114.269 46.0161 114.162 46.0612 114.05 46.0663Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M103.378 46.0663C103.443 46.0661 103.507 46.0526 103.566 46.0266C103.625 46.0006 103.679 45.9627 103.723 45.9153C103.81 45.8269 103.86 45.7073 103.86 45.5826C103.86 45.458 103.81 45.3384 103.723 45.25C96.8997 38.8754 90.0722 36.134 85.5473 34.9544C80.626 33.6758 77.3609 33.907 77.224 33.9211C77.0996 33.9308 76.9841 33.9893 76.9027 34.0839C76.8214 34.1785 76.7808 34.3015 76.7899 34.426C76.7996 34.5504 76.8581 34.6659 76.9527 34.7473C77.0474 34.8286 77.1703 34.8692 77.2948 34.8601C77.4222 34.8601 90.3223 34.0249 103.076 45.9389C103.158 46.0161 103.265 46.0612 103.378 46.0663Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M114.05 54.3894C113.985 54.3892 113.921 54.3757 113.862 54.3498C113.802 54.3238 113.749 54.2859 113.705 54.2385C113.617 54.1501 113.568 54.0305 113.568 53.9058C113.568 53.7812 113.617 53.6616 113.705 53.5732C120.528 47.1986 127.356 44.4572 131.88 43.2823C136.802 41.9989 140.067 42.2349 140.204 42.2443C140.328 42.2539 140.444 42.3125 140.525 42.4071C140.606 42.5017 140.647 42.6247 140.638 42.7492C140.628 42.8736 140.57 42.9891 140.475 43.0705C140.38 43.1518 140.257 43.1924 140.133 43.1833C140.006 43.1833 127.105 42.3481 114.352 54.262C114.269 54.3392 114.162 54.3844 114.05 54.3894Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M103.378 54.3894C103.443 54.3892 103.507 54.3757 103.566 54.3498C103.625 54.3238 103.679 54.2859 103.723 54.2385C103.81 54.1501 103.86 54.0305 103.86 53.9058C103.86 53.7812 103.81 53.6616 103.723 53.5732C96.8997 47.1986 90.0722 44.4572 85.5473 43.2823C80.626 41.9989 77.3609 42.2349 77.224 42.2443C77.0996 42.2539 76.9841 42.3125 76.9027 42.4071C76.8214 42.5017 76.7808 42.6247 76.7899 42.7492C76.7996 42.8736 76.8581 42.9891 76.9527 43.0705C77.0474 43.1518 77.1703 43.1924 77.2948 43.1833C77.4222 43.1833 90.3223 42.3481 103.076 54.262C103.158 54.3392 103.265 54.3844 103.378 54.3894Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M114.05 62.713C113.985 62.7128 113.921 62.6993 113.862 62.6733C113.802 62.6473 113.749 62.6094 113.705 62.562C113.617 62.4736 113.568 62.354 113.568 62.2293C113.568 62.1047 113.617 61.9851 113.705 61.8967C120.528 55.5221 127.356 52.7808 131.88 51.6059C136.802 50.3225 140.067 50.5584 140.204 50.5678C140.328 50.5775 140.444 50.636 140.525 50.7306C140.606 50.8252 140.647 50.9482 140.638 51.0727C140.628 51.1971 140.57 51.3126 140.475 51.394C140.38 51.4753 140.257 51.5159 140.133 51.5068C140.006 51.5068 127.101 50.6763 114.352 62.5856C114.269 62.6628 114.162 62.708 114.05 62.713Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M103.378 62.713C103.443 62.7128 103.507 62.6993 103.566 62.6733C103.625 62.6473 103.679 62.6094 103.723 62.562C103.81 62.4736 103.86 62.354 103.86 62.2293C103.86 62.1047 103.81 61.9851 103.723 61.8967C96.8997 55.5221 90.0722 52.7808 85.5473 51.6059C80.626 50.3225 77.3609 50.5584 77.224 50.5678C77.0996 50.5775 76.9841 50.636 76.9027 50.7306C76.8214 50.8252 76.7808 50.9482 76.7899 51.0727C76.7996 51.1971 76.8581 51.3126 76.9527 51.394C77.0474 51.4753 77.1703 51.5159 77.2948 51.5068C77.4222 51.5068 90.327 50.6763 103.076 62.5856C103.158 62.6628 103.265 62.708 103.378 62.713Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M114.05 71.0356C113.985 71.0354 113.921 71.0219 113.862 70.9959C113.802 70.9699 113.749 70.932 113.705 70.8846C113.617 70.7962 113.568 70.6766 113.568 70.552C113.568 70.4273 113.617 70.3077 113.705 70.2193C120.528 63.8448 127.356 61.1034 131.88 59.9285C136.802 58.6451 140.067 58.881 140.204 58.8904C140.328 58.9001 140.444 58.9586 140.525 59.0533C140.606 59.1479 140.647 59.2709 140.638 59.3953C140.628 59.5197 140.57 59.6352 140.475 59.7166C140.38 59.798 140.257 59.8385 140.133 59.8294C140.006 59.8294 127.101 58.999 114.352 70.9082C114.269 70.9854 114.162 71.0306 114.05 71.0356Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M103.378 71.0356C103.443 71.0354 103.507 71.0219 103.566 70.9959C103.625 70.9699 103.679 70.932 103.723 70.8846C103.81 70.7962 103.86 70.6766 103.86 70.552C103.86 70.4273 103.81 70.3077 103.723 70.2193C96.8997 63.8448 90.0722 61.1034 85.5473 59.9285C80.626 58.6451 77.3609 58.881 77.224 58.8904C77.0996 58.9001 76.9841 58.9586 76.9027 59.0533C76.8214 59.1479 76.7808 59.2709 76.7899 59.3953C76.7996 59.5197 76.8581 59.6352 76.9527 59.7166C77.0474 59.798 77.1703 59.8385 77.2948 59.8294C77.4222 59.8294 90.327 58.999 103.076 70.9082C103.158 70.9854 103.265 71.0306 103.378 71.0356Z", fill: "#50ACED" }),
        React.createElement("path", { d: "M108.859 89.4379C108.734 89.4379 108.614 89.3882 108.525 89.2997C108.437 89.2112 108.387 89.0912 108.387 88.9661V84.1391C108.387 84.014 108.437 83.894 108.525 83.8055C108.614 83.717 108.734 83.6673 108.859 83.6673C108.984 83.6673 109.104 83.717 109.193 83.8055C109.281 83.894 109.331 84.014 109.331 84.1391V88.9661C109.331 89.0912 109.281 89.2112 109.193 89.2997C109.104 89.3882 108.984 89.4379 108.859 89.4379Z", fill: "black" }),
        React.createElement("path", { d: "M150.656 79.3598C150.579 79.3599 150.503 79.3411 150.434 79.3049C150.366 79.2687 150.308 79.2162 150.264 79.1522L146.49 73.627C146.454 73.5759 146.428 73.5182 146.415 73.4572C146.402 73.3962 146.4 73.3332 146.411 73.2717C146.423 73.2103 146.446 73.1516 146.48 73.0992C146.514 73.0468 146.558 73.0016 146.609 72.9663C146.661 72.931 146.719 72.9063 146.78 72.8936C146.841 72.8809 146.904 72.8805 146.965 72.8924C147.027 72.9042 147.085 72.9281 147.137 72.9627C147.189 72.9973 147.234 73.0418 147.268 73.0938L151.043 78.6238C151.078 78.6752 151.102 78.7329 151.115 78.7937C151.127 78.8545 151.127 78.9172 151.115 78.9781C151.104 79.039 151.08 79.097 151.046 79.1488C151.011 79.2005 150.967 79.245 150.916 79.2796C150.839 79.3312 150.749 79.3591 150.656 79.3598Z", fill: "black" }),
        React.createElement("path", { d: "M67.0645 79.3598C66.9703 79.36 66.8783 79.3321 66.8002 79.2796C66.7487 79.245 66.7046 79.2005 66.6703 79.1488C66.636 79.097 66.6123 79.039 66.6005 78.9781C66.5886 78.9172 66.5889 78.8545 66.6013 78.7937C66.6138 78.7329 66.6381 78.6752 66.6728 78.6238L70.4475 73.0938C70.4822 73.0418 70.5268 72.9973 70.5788 72.9627C70.6308 72.9281 70.6892 72.9042 70.7505 72.8924C70.8118 72.8805 70.8749 72.8809 70.936 72.8936C70.9971 72.9063 71.0552 72.931 71.1067 72.9663C71.1582 73.0016 71.2022 73.0468 71.2362 73.0992C71.2701 73.1516 71.2933 73.2102 71.3044 73.2717C71.3155 73.3332 71.3143 73.3962 71.3009 73.4572C71.2874 73.5182 71.262 73.5759 71.2261 73.627L67.4514 79.1522C67.4085 79.2156 67.3509 79.2676 67.2835 79.3038C67.2161 79.3399 67.1409 79.3592 67.0645 79.3598Z", fill: "black" })));
};
export default BoostScreen;
