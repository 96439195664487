import InvertColorsOutlinedIcon from '@mui/icons-material/InvertColorsOutlined';
import { ListItem, ListItemIcon, ListItemText, Switch } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { SiteThemeContext } from '../../contexts/SiteThemeProvider';
const ScribeSetThemeColor = ({ classes, t }) => (React.createElement(SiteThemeContext.Consumer, null, ({ handleThemeChange, theme }) => (React.createElement(ListItem, { style: {
        paddingLeft: 32,
    } },
    React.createElement(ListItemIcon, null,
        React.createElement(InvertColorsOutlinedIcon, null)),
    React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: t('theme.darkMode') }),
    React.createElement(ListItemIcon, { style: { marginRight: -7 } },
        React.createElement(Switch, { color: "primary", onClick: () => handleThemeChange(), checked: theme === 'dark' }))))));
export default withTranslation()(withStyles({})(ScribeSetThemeColor));
