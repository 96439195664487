import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        zIndex: 103,
        display: 'flex',
        justifyContent: 'center',
        left: 15,
        top: 15,
        paddingTop: 8,
        paddingRight: 12,
        paddingBottom: 8,
        paddingLeft: 12,
        height: 'max-content',
        background: 'rgba(0, 0, 0, 0.5)',
        backgroundBlendMode: 'overlay',
        backdropFilter: 'blur(50px)',
        borderRadius: 8,
        WebkitAppRegion: 'no-drag',
    },
    text: {
        color: theme.palette.ava.white,
        fontSize: 16,
        lineHeight: '20px',
    },
    successText: {
        color: theme.palette.ava.states.online,
        marginRight: 8,
    },
}));
export default useStyles;
