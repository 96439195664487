import DragIndicator from '@mui/icons-material/DragIndicator';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StartConvoMicIcon from '../../../assets/icons/start-convo-mic.svg';
import { useV1Socket } from '../../hooks/useV1Socket';
import { isMac } from '../../utils';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.ava.blue2,
        padding: 4,
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        '&:hover': {
            backgroundColor: theme.palette.ava.blue1,
        },
    },
    dragIndicator: {
        position: 'absolute',
        right: 0,
        height: 36,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        WebkitAppRegion: 'drag',
        cursor: 'grab',
        '&:active': {
            cursor: 'grabbing',
        },
    },
    button: {
        width: 48,
        height: 48,
        background: theme.palette.ava.whiteGradient,
        boxShadow: '0px -2.20326px 2.20326px rgba(255, 255, 255, 0.25), 0px 2.20326px 2.20326px rgba(112, 144, 176, 0.4)',
        border: 'none',
        outline: 'none',
        borderRadius: 100,
        padding: 10,
        WebkitAppRegion: isMac ? 'drag' : undefined,
    },
    icon: {
        width: '100%',
        height: '100%',
        userSelect: 'none',
    },
}));
const StartConversationButton = ({ onClick, onMouseEnter }) => {
    const classes = useStyles();
    const [_, websocketStatus] = useV1Socket();
    const { t } = useTranslation();
    // For MacOS, we can drag this entire component. To prevent a conversation
    // from starting when dragging on the button, this state is used to manually
    // handle when the click interaction takes place.
    const [canClickButton, setCanClickButton] = useState(true);
    return (React.createElement("div", { className: classes.root, onMouseEnter: onMouseEnter },
        React.createElement("div", { className: classes.dragIndicator },
            React.createElement(DragIndicator, null)),
        React.createElement("button", { className: classes.button, onMouseDown: () => setCanClickButton(true), onMouseMove: () => setCanClickButton(false), onMouseUp: () => {
                // Only MacOS can drag at the button. Windows cannot, so don't
                // prevent the action on Windows.
                if (!isMac || canClickButton) {
                    onClick();
                }
            }, disabled: websocketStatus !== 'online' },
            React.createElement("img", { className: classes.icon, src: StartConvoMicIcon, alt: t('ccMode.startConversationButton') }))));
};
export default StartConversationButton;
