import { connect } from 'react-redux';
import { addNotification, clearNotifications, setShowBubbleAboveCCModeDebounced } from '../../../store/slices/ccMode';
import ConversationView from './ConversationView';
const mapStateToProps = ({ ccMode: { showBubbleAboveCCMode }, scribeConversation: { speakers, status }, userProfile: { features }, }) => ({
    features,
    showBubbleAboveCCMode,
    speakers,
    status,
});
const mapDispatchToProps = {
    addNotification,
    clearNotifications,
    setShowBubbleAboveCCMode: setShowBubbleAboveCCModeDebounced,
};
export default connect(mapStateToProps, mapDispatchToProps)(ConversationView);
