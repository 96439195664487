import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        backgroundColor: 'inherit',
        height: '100vh',
        width: '100%',
    },
}));
export default useStyles;
