import { useEffect, useRef } from 'react';
// This custom hook should should be used when wanting to use
// setInterval() in React functional components. The reason is
// that if we use `setInterval()` directly in useEffect hook,
// then we have to be careful which function instance is fired
// (can be tricky to do correctly).
//
// This hook is copied from:
// https://overreacted.io/making-setinterval-declarative-with-react-hooks
const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};
export default useInterval;
