import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as i18nUtils from '../../utils/i18n';
import DropdownItem from '../Dropdowns/DropdownItem';
import DropdownSelection from '../Dropdowns/DropdownSelection';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
const MENU_WIDTH = 224;
const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: MENU_WIDTH,
        paddingLeft: 20,
        paddingRight: 10,
        background: theme.palette.ava.blue5,
        borderRadius: 100,
        color: theme.palette.ava.dark1,
        fontSize: 18,
        lineHeight: '27px',
        '&:hover': {
            background: theme.palette.ava.blue4,
        },
    },
    language: {
        marginLeft: 12,
        fontWeight: 400,
    },
    heading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        height: 22,
        color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.text.dark2,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxSizing: 'content-box',
    },
    headingText: {
        fontSize: 'inherit',
    },
    closeIcon: {
        position: 'absolute',
        top: -3,
        right: -16,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    divider: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.text.dark2,
    },
}));
const LanguageButton = () => {
    const classes = useStyles();
    const { i18n, t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const onClick = (event) => setAnchorEl(event.currentTarget);
    const onClose = () => setAnchorEl(null);
    const updateLanguage = (lang) => {
        i18n.changeLanguage(lang, () => {
            window.document.title = t('title');
        });
        onClose();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { id: "language-button", className: classes.button, "aria-controls": "language-menu", "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, disableRipple: true, onClick: onClick },
            React.createElement("div", null,
                React.createElement("span", { className: classes.language }, i18nUtils.getCurrentLanguage())),
            React.createElement(ArrowDownIcon, { rotated: open })),
        React.createElement(DropdownSelection, { id: "language-menu", anchorEl: anchorEl, prompt: t('accountSettings.language.prompt'), width: MENU_WIDTH, onClose: onClose }, i18nUtils.langs
            .filter((lg) => lg !== i18nUtils.getLanguageCode())
            .map((lg) => (React.createElement(DropdownItem, { key: lg, onClick: () => updateLanguage(lg) },
            React.createElement("span", { className: classes.language }, i18nUtils.langsDisplay[lg])))))));
};
export default LanguageButton;
