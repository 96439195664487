var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Recorder from 'opus-recorder';
import encoderPath from 'opus-recorder/dist/encoderWorker.min';
import { getBrowserName, isMobile } from '../../utils';
import { getSingletonWebRTCManager } from '../../utils/webrtc';
import Worker from './audioWebWorker';
/*
This module contains functions for processing of audio packets.
In case of 'webm' this is essentially just a pass-through, whereas in case of
'Safari' browser there is an additional/custom step handled by 'opus-recorder' library.

There is also a code path using deprecated script processor API for 'pcm' audio, which
is used as a fallback for browsers/devices that don't support 'webm'. For example, iOS devices.
*/
const toBase64 = (buffer) => btoa(buffer.reduce((data, byte) => data + String.fromCharCode(byte), ''));
// Base64 is padded with '=', but we use those constants to compare with
// begginings of strings, so we need to strip out the trailing '='.
// Ignoring lint warnings since using template strings here would make it worse.
// eslint-disable-next-line prefer-template
const OpusHeadBase64 = new RegExp('^' + btoa('OpusHead').replace('=', ''));
// eslint-disable-next-line prefer-template
const OpusTagsBase64 = new RegExp('^' + btoa('OpusTags').replace('=', ''));
const handleOpus = (onData, sources) => {
    let recordingTimestamp = 0;
    let opusRecorder;
    const audioHandler = (arrayBuffer) => {
        // encodedSample position is always in 48 kHz, and we want the TS in ms
        const currentTimestamp = opusRecorder.encodedSamplePosition / 48;
        const audioPacket = {
            // The magic 28: opus-recorder provides arrayBuffers that are real OGG
            // pages (see https://en.wikipedia.org/wiki/Ogg_page). Ava backend expects
            // raw Opus data. The good thing is that the Ogg Page is just header+data.
            // The header is 28 bytes long.
            audio_chunk: toBase64(arrayBuffer.slice(28)),
            timestamp: recordingTimestamp + currentTimestamp,
            audio_timestamp: Date.now(),
        };
        // Opus Ogg stream includes frames made of data (we want to send those),
        // but it also includes Opus headers and Tags - the backend does not
        // understand those and crashes the whole socket. That's why they are
        // not sent to the backend.
        if (OpusHeadBase64.test(audioPacket.audio_chunk) || OpusTagsBase64.test(audioPacket.audio_chunk)) {
            return;
        }
        onData(audioPacket);
    };
    return {
        init: () => {
            opusRecorder = new Recorder({
                encoderPath,
                sourceNode: sources[0],
                encoderApplication: 2048,
                encoderComplexity: 2,
                encoderSampleRate: 16000,
                encoderFrameSize: 60,
                maxFramesPerPage: 1,
                originalSampleRateOverride: 16000,
                streamPages: true,
            });
            recordingTimestamp = Date.now();
            opusRecorder.ondataavailable = audioHandler;
            opusRecorder.start();
            return opusRecorder;
        },
        handleAudio: audioHandler,
    };
};
const handleWebm = (onData, track) => {
    let currentAudioTimestamp = 0;
    return {
        init: () => {
            currentAudioTimestamp = Date.now();
        },
        handleAudio: ({ data }) => __awaiter(void 0, void 0, void 0, function* () {
            const currentData = new Uint8Array(yield data.arrayBuffer());
            if (!currentData.length) {
                return;
            }
            const audioPacket = {
                audio_chunk: toBase64(currentData),
                timestamp: currentAudioTimestamp,
                audio_timestamp: Date.now(),
                track,
            };
            currentAudioTimestamp += 60;
            onData(audioPacket);
        }),
    };
};
const handlePcm = (onData) => {
    let audioWebWorker;
    let initPacketSent = false;
    return {
        init: () => {
            audioWebWorker = new Worker();
            audioWebWorker.onmessage = ({ data }) => {
                onData(Object.assign(Object.assign({}, data.audio), { type: 'write' }));
            };
        },
        handleAudio: ({ inputBuffer, playbackTime }) => {
            if (!initPacketSent) {
                audioWebWorker.postMessage({
                    type: 'init',
                    config: {
                        sampleRateHz: inputBuffer.sampleRate,
                        chunkLengthMs: 60,
                        startTimestamp: Date.now(),
                    },
                });
                initPacketSent = true;
            }
            else {
                audioWebWorker.postMessage({
                    type: 'audio',
                    playbackTimeMs: 1000 * playbackTime,
                    chunk: inputBuffer.getChannelData(0),
                });
            }
        },
    };
};
const WEBM_MIME_TYPE = 'audio/webm;codecs=opus';
export const getFormat = (useWebRTC) => {
    if (useWebRTC)
        return 'webrtc';
    // Prefer WebM/Opus if available (Chrome, Firefox, Edge; desktop and Android), because it can be streamed
    // to backend without transocding.
    const canUseWebm = window.MediaRecorder && window.MediaRecorder.isTypeSupported(WEBM_MIME_TYPE);
    if (canUseWebm) {
        return 'webm';
    }
    // Safari only supports mp4 with aac codec, so it needs custom transcoding to Opus.
    const isDesktopSafari = !isMobile() && getBrowserName() === 'Safari';
    if (isDesktopSafari) {
        return 'opus';
    }
    // Fallback to other browsers/devices such as iPhone/iPad.
    return 'pcm';
};
export function createAudioProcessor(audioContext, sources, streams, format, streamNames, onData) {
    if (format === 'webrtc') {
        const webRTCManager = getSingletonWebRTCManager();
        if (!webRTCManager) {
            throw new Error('WebRTC manager not initialized');
        }
        const tracks = [];
        const senders = [];
        streams.forEach((stream) => stream.getAudioTracks().forEach((track) => {
            senders.push(webRTCManager.addTrack(track, stream));
            tracks.push(track);
        }));
        return {
            stop: () => {
                tracks.forEach((track) => track.stop());
                senders.forEach((sender) => webRTCManager.removeSender(sender));
            },
        };
    }
    else if (format === 'webm') {
        const mediaRecorders = streams.map((stream, i) => {
            const mediaRecorder = new window.MediaRecorder(new MediaStream(stream.getAudioTracks()), {
                mimeType: WEBM_MIME_TYPE,
                audioBitsPerSecond: 32768,
            });
            const processor = handleWebm(onData, streamNames[i]);
            processor.init();
            mediaRecorder.ondataavailable = processor.handleAudio;
            mediaRecorder.start(100);
            return mediaRecorder;
        });
        return {
            stop: () => {
                mediaRecorders.forEach((mr) => {
                    if (mr.state !== 'inactive') {
                        mr.stop();
                    }
                });
            },
        };
    }
    else if (format === 'opus') {
        const processor = handleOpus(onData, sources);
        const opusRecorder = processor.init();
        return {
            stop: () => {
                opusRecorder.stop();
                opusRecorder.close();
            },
        };
    }
    else {
        const scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);
        // No merging of sterams in PCM
        sources[0].connect(scriptProcessor);
        const destination = audioContext.createMediaStreamDestination
            ? audioContext.createMediaStreamDestination()
            : audioContext.destination;
        scriptProcessor.connect(destination);
        const processor = handlePcm(onData);
        processor.init();
        scriptProcessor.addEventListener('audioprocess', processor.handleAudio);
        return {
            stop: () => {
                destination.disconnect();
                scriptProcessor.disconnect();
                scriptProcessor.removeEventListener('audioprocess', processor.handleAudio);
            },
        };
    }
}
