var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { invoke } from '@tauri-apps/api';
import { open } from '@tauri-apps/plugin-shell';
import { getCurrent } from '@tauri-apps/plugin-window';
export const setupDesktopIntegration = () => {
    if (window.isElectron)
        return;
    if (!window.__TAURI__)
        return;
    window.desktopIntegration = {};
    window.desktopIntegration = {
        openExternalURL: (url) => __awaiter(void 0, void 0, void 0, function* () {
            // Unfortunately Safari does not like us (it does not allow calls to
            // http://localhost from https contexts), so we need to use Chrome or
            // Firefox. If the user only has Safari - out of luck. We will take care
            // of such use case in case it arises to any of our users.
            try {
                yield open(url, 'chrome');
            }
            catch (e) {
                try {
                    yield open(url, 'firefox');
                }
                catch (e) {
                    open(url);
                }
            }
        }),
        resizeWindowToDefault: () => { },
    };
    window.isElectron = {
        electronOS: 'linux',
    };
    window.electronCurrentWindow = {
        minimize: () => {
            getCurrent().minimize();
        },
        maximize: () => {
            getCurrent().maximize();
        },
        unmaximize: () => {
            getCurrent().unmaximize();
        },
        close: () => {
            getCurrent().close();
        },
        // This is used only on Mac, in order to make the transparent window click-through
        setIgnoreMouseEvents: (...args) => { },
    };
    window.electronApp = {
        getVersion: () => 'tauri-experiments',
    };
    window.electronIPC = {
        sendCCMode: (ccMode, value = true) => {
            invoke('position_cc', { ccMode, value });
        },
        sendSetInCaptionMode: (value) => {
            if (value) {
                document.addEventListener('mousedown', dragListener);
            }
            else {
                document.removeEventListener('mousedown', dragListener);
            }
            invoke('position_cc', { ccMode: value ? 'begin_cc' : 'expand', value: false });
        },
        // TO OBVIATE BEFORE FULL MIGRATION
        // 1. In CCPage, this is triggered on window-activated, before initiating
        // new conversation.
        // 2. In SetupAudio it's called on last step.
        // On electron side this is equivalent to setInCaptionMode(true).
        // TASK: In electron: obviate.
        sendGoInFloatingMode: () => { },
        // This dispatches a redux action and is issued in electron on did-finish-load
        // This is read only by ScribeWelcome, and used to decide whether we should
        // start a new conversation.
        // TASK: To Obviate
        onDesktopAppLoaded: (callback) => { },
        // This is currently not used
        onLog: (callback) => { },
        // This is supposed to pass events to segment, but is currently unused
        onTrack: (callback) => { },
        // This is sent by the menu and used to dispatch an action.
        onSetElectronCaptionMode: (callback) => { },
        // This is sent by electrona as described, and then it issues some calls
        // to start a new conversation and go into cc-mode.
        // TASK: To obviate, move the logic to inside electron, and re-implement in Tauri
        onWindowActivated: (callback) => { },
        // This is currently only listened to as directly after the relevant
        // call, so it could be obviated.
        // TASK: obviate
        onceMacInternalAudioInstallation: (callback) => { },
        // Only listened to once, and directly after the relevant call. As such
        // can be obviated.
        // TASK: obviate
        onceMacMicrophoneAccessResult: (callback) => { },
        // NEEDED FOR MENU
        // This is called after the v1 socket is initiated. It is only used
        // by the menu bar in order to initiate the conversation in the right momement.
        // It could be replaced by a better URL selection (with startCnonvo).
        // TASK: In electron, figure out getting rid of this call.
        sendDoneLoading: () => { },
        // This is sent by an action updateIsInConversation
        // It's used to change the menu setting in Electron
        // TASK: In Tauri: implement as is
        sendSetIsInConversation: (arg) => { },
        // This is sent after ava_session is fetched, or when suer is logged out
        // Used by MenuBar to show appropriate menu settings
        // TASK: In Tauri: implement as is
        sendSetIsLoggedIn: (arg) => { },
        // This is sent by Menu in Electron, and transformed into a dispatched action
        onCreateNewConversation: (callback) => { },
        // This is sent by menu and accepted in one place to end the conversation.
        onEndConversation: (callback) => { },
        // This is called by the menu to sign off and accessed in one place
        onSignOutAndShowSignIn: (callback) => { },
        // NEEDED FOR VOLUME MANIPULATION
        // This is used to set volume
        // TASK: Understand why this feature was necessary and potentially re-implement.
        sendChangeVolume: (newVolume) => { },
        invokeGetVolume: () => __awaiter(void 0, void 0, void 0, function* () { }),
        // NEEDED FOR OFFLINE WINDOW
        // Related to internal offline mode window
        // TASK: Implement the whole behavior in Tauri differently.
        sendAvaWebAvailable: () => { },
        // This is used by the Offline window to know where to redirect to.
        // TASK: In Tauri relate on a different mechanism for the offline window,
        // maybe employ local vite.
        getAppUrl: () => { },
        // NEEDED FOR USING AVA_MIC
        // Related to AvaMic on Mac
        // This is sent when user selects Ava Computer Audio on Mac, or when the conversation
        // begins with it as selected mic. In Electron it creates and selects the
        // multi-output MIDI device.
        // TASK: In Tauri this will automatically be obviated, as Tauri can handle
        // the special Ava Computer Audio case on start_recording.
        sendActivateMacInternalAudio: () => { },
        sendDeactivateMacInternalAudio: () => { },
        // THis is called (only in certain scenarios) when the multi output device
        // gets recreated. It is then read in the webapp to refetch available devices.
        // TASK: In Tauri this will be obviated by re-doing the flow
        onMultiOutputChanged: (callback) => { },
        // NEEDED FOR SETTING UP AUDIO (ON MAC?)
        // This is sent in SetupAudio
        // In Electron it resizes the window.
        // TASK: In Tauri, re-imagine the setup-audio flow, as potentially built-in
        // into the app.
        sendSetupAudioLoaded: () => { },
        // This is sent in SetupAudio.
        // In Electron, it asks for microphone permission.
        // TASK: In Tauri: Potentially re-implement once we tackle SetupAudio.
        sendAskMacMicrophoneAccess: () => { },
        // This is called in SetupAudio.
        // In Electron it attempts to install the Black Hole driver
        // TASK: Tackle once we approach Ava Computer Audio
        sendSetupMacInternalAudio: () => { },
        // This is bad, but it's really hard to get rid of it, and it's only called once,
        // while the app is being loaded.
        sendSyncCheckAudioSetup_UNSAFE: () => { },
        // It's invoked in SetupAudio, but the logic is a little complex
        // In Electron it checks 2 things: whether Black Hole driver exists, and whether
        // mic permission has been given
        invokeCheckAudioSetup: () => __awaiter(void 0, void 0, void 0, function* () { }),
        // THIS IS NEEDED FOR POST-MVP FEATURES
        // This is called when the app is loading, and if it returns true, then
        // a new conversation is NOT loaded.
        // In Electron it does what it says
        // TASK: Re-implement in Tauri
        invokeCheckLaunchedOnLogin: () => __awaiter(void 0, void 0, void 0, function* () { }),
        // This is invoked when the user begins a conversation on mac
        invokeHasSeenInternalAudioMicTour: () => __awaiter(void 0, void 0, void 0, function* () { }),
    };
};
const dragListener = (e) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const noDragSelector = 'input, a, button, .tauri-no-drag'; // CSS selector
    if ((_a = e.target) === null || _a === void 0 ? void 0 : _a.closest(noDragSelector))
        return; // a non-draggable element either in target or its ancestors
    yield getCurrent().startDragging();
});
