import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import OkIcon from '../../../assets/icons/ok.svg';
import ScribeAccurate from '../../../assets/icons/scribe-accurate.svg';
import ScribeFast from '../../../assets/icons/scribe-fast.svg';
import { selectCorrectionMode } from '../../selectors/ui';
import { setScribeCorrectionMode } from '../../store/slices/uiState';
import { ScribeCorrectionMode } from '../../store/slices/uiState';
import { useAppSelector as useSelector } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 'bold',
        fontSize: 30,
        textAlign: 'center',
        paddingTop: 16,
    },
    scribeCorrectionTitle: {
        fontWeight: 500,
    },
    button: {
        '&:hover': {
            opacity: 0.9,
        },
        width: 265,
        height: 55,
        flexDirection: 'column',
        padding: 4,
        color: 'white',
        fontWeight: 600,
        fontSize: 16,
        borderRadius: 24.8,
        border: '1px solid',
        boxShadow: '-8.928px -8.928px 14.88px rgba(100, 218, 255, 0.160784)',
    },
    scribeCorrectionButton: {
        background: theme.palette.ava.green2,
        fontWeight: 500,
        width: '100%',
    },
    green2: {
        color: theme.palette.ava.green2,
    },
    green2Background: {
        backgroundColor: theme.palette.ava.green2,
    },
    scribeCorrectionModeOptionBox: {
        border: '1px solid #DADCE0',
        marginTop: '12px',
        marginBottom: '35px',
        borderRadius: '15px',
        padding: '20px',
        '&.modeSelected': {
            borderColor: theme.palette.ava.green2,
            backgroundColor: theme.palette.ava.transparent.green[theme.palette.mode],
            border: '4px solid',
        },
    },
    scribeCorrectionModeIcon: {
        marginRight: '10px',
        verticalAlign: 'baseline',
        height: '0.7em',
    },
    checkboxIcon: {
        backgroundColor: theme.palette.ava.green2,
        borderRadius: '100px',
        marginLeft: '10px',
        height: '0.8em',
    },
    scribeCorrectionModeProgressRow: {
        alignItems: 'end',
        fontWeight: 500,
    },
    scribeCorrectionModeProgressTiny: {
        textAlign: 'end',
        fontSize: 10,
    },
    scribeCorrectionModeProgressBar: {
        width: '100%',
        height: '0.5em',
        borderRadius: '15px',
        backgroundColor: theme.palette.ava.green2,
    },
    divider: {
        width: '100%',
        borderTop: '1px solid #DADCE0',
        margin: '10px 0px',
    },
    currentSelectionText: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.green2 : theme.palette.ava.gray6,
        textAlign: 'center',
        width: '100%',
        fontSize: '18px',
        height: 55,
        lineHeight: '55px',
    },
}));
function ScribeCorrectionModeBox(props) {
    const { t, theme, onClose } = props;
    const dispatch = useDispatch();
    const classes = useStyles(theme);
    const currentCorrectionMode = useSelector(selectCorrectionMode);
    const addButtonClass = (klass) => `${classes.button} ${klass}`;
    const selectFastMode = () => {
        dispatch(setScribeCorrectionMode(ScribeCorrectionMode.Fast));
        onClose();
    };
    const selectAccurateMode = () => {
        dispatch(setScribeCorrectionMode(ScribeCorrectionMode.Accurate));
        onClose();
    };
    const makeProgressBarRow = (name, label, width) => {
        return (React.createElement(Grid, { item: true, style: { width: '100%' }, className: classes.green2 },
            React.createElement(Grid, { container: true, direction: "row", className: classes.scribeCorrectionModeProgressRow },
                React.createElement(Grid, { item: true, xs: 3, style: { fontSize: 12 } }, name),
                React.createElement(Grid, { item: true, xs: 9 },
                    React.createElement(Grid, { container: true, direction: "column", style: { width: width, paddingBottom: 5 } },
                        React.createElement(Grid, { item: true, className: classes.scribeCorrectionModeProgressTiny }, label),
                        React.createElement(Grid, { item: true, className: classes.scribeCorrectionModeProgressBar }))))));
    };
    const makeScribeCorrectionOption = (selected, name, icon, speedNum, speedName, accuracyNum, accuracyName, desc, onClick) => {
        return (React.createElement(Grid, { item: true, xs: 12, md: 5, className: `${classes.scribeCorrectionModeOptionBox} ${selected ? 'modeSelected' : ''}` },
            React.createElement(Grid, { container: true, direction: "column", justifyContent: "flex-start", alignItems: "flex-start", style: { height: '100%', justifyContent: 'space-between' } },
                React.createElement(Typography, { variant: "h5", className: classes.scribeCorrectionTitle },
                    React.createElement("img", { className: classes.scribeCorrectionModeIcon, src: icon, alt: "Scribe Correction Option" }),
                    name,
                    selected && (React.createElement("img", { className: `${classes.scribeCorrectionModeIcon} ${classes.checkboxIcon}`, src: OkIcon, alt: "selected" }))),
                makeProgressBarRow(t('scribeCorrectionMode.speed'), speedName, `${speedNum}%`),
                makeProgressBarRow(t('scribeCorrectionMode.accuracy'), accuracyName, `${accuracyNum}%`),
                React.createElement(Box, { my: 3 },
                    React.createElement("span", null,
                        t('scribeCorrectionMode.descBegin'),
                        " "),
                    React.createElement("span", { style: { fontWeight: 'bold' } }, desc)),
                React.createElement("div", { className: classes.divider }),
                !selected && (React.createElement(ButtonBase, { component: "div", className: addButtonClass(classes.scribeCorrectionButton), focusRipple: true, onClick: onClick },
                    React.createElement(Box, { textAlign: "center", whiteSpace: "nowrap" }, t('scribeCorrectionMode.select')))),
                selected && (React.createElement(Box, { className: classes.currentSelectionText }, t('scribeCorrectionMode.currentSelection'))))));
    };
    return (React.createElement(Box, { style: { maxWidth: 750, overflowY: 'scroll' } },
        React.createElement(DialogTitle, null,
            React.createElement(Typography, { className: classes.title, variant: "h5" },
                React.createElement(Box, { px: 4 }, t('scribeCorrectionMode.title')))),
        React.createElement(DialogContent, { className: classes.content },
            React.createElement(Grid, { container: true, direction: "row", style: { justifyContent: 'space-evenly' } },
                makeScribeCorrectionOption(currentCorrectionMode === ScribeCorrectionMode.Fast, t('scribeCorrectionMode.fastMode'), ScribeFast, '100', t('scribeCorrectionMode.fastSpeed'), '75', t('scribeCorrectionMode.fastAccuracy'), t('scribeCorrectionMode.fastDesc'), selectFastMode),
                makeScribeCorrectionOption(currentCorrectionMode === ScribeCorrectionMode.Accurate, t('scribeCorrectionMode.accurateMode'), ScribeAccurate, '70', t('scribeCorrectionMode.accurateSpeed'), '100', t('scribeCorrectionMode.accurateAccuracy'), t('scribeCorrectionMode.accurateDesc'), selectAccurateMode)))));
}
export default withTheme(withSnackbar(withTranslation()(ScribeCorrectionModeBox)));
