var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import OutlinedInput from '@mui/material/OutlinedInput';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    inputRoot: {
        height: 48,
        backgroundColor: theme.palette.mode === 'light' ? alpha(theme.palette.ava.grey6, 0.3) : theme.palette.ava.dark1,
        borderRadius: 8,
        '& $notchedOutline, &$disabled $notchedOutline': {
            borderColor: 'transparent',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: 'transparent',
            '@media (hover: none)': {
                borderColor: 'transparent',
            },
        },
        '&$focused $notchedOutline': {
            borderColor: theme.palette.ava.blue2,
        },
        '&$error $notchedOutline': {
            borderColor: theme.palette.ava.red2,
        },
    },
    input: {
        height: 48,
        paddingRight: 16,
        paddingLeft: 16,
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '24px',
        '&::placeholder': {
            color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
            opacity: 1,
        },
        '&:focus::placeholder': {
            color: theme.palette.ava.grey5,
        },
    },
    focused: {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark1,
        caretColor: theme.palette.ava.blue2,
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
    },
    disabled: {
        color: theme.palette.ava.grey5,
    },
    notchedOutline: {},
    error: {},
}));
const InputField = (_a) => {
    var { className } = _a, otherProps = __rest(_a, ["className"]);
    const classes = useStyles();
    return (React.createElement(OutlinedInput, Object.assign({ className: className, classes: {
            root: classes.inputRoot,
            input: classes.input,
            notchedOutline: classes.notchedOutline,
            focused: classes.focused,
            disabled: classes.disabled,
            error: classes.error,
        } }, otherProps)));
};
export default InputField;
