import Button from '@mui/material/Button';
import React from 'react';
export function ApplessControlBarButton({ children, border, onClick }) {
    return (React.createElement(Button, { onClick: onClick || (() => { }), sx: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '56px',
            minWidth: '56px',
            height: '56px',
            border: border || '1px solid rgba(0, 0, 0, 0.2)',
            marginX: 1,
            borderRadius: '50%',
            padding: 0,
        } }, children));
}
