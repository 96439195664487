import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandIcon from '../../../../assets/icons/expand.svg';
import KeyboardArrowDown from '../../../../assets/icons/keyboard-arrow-down.svg';
import ConnectToMeetingsCard from '../../../components/Cards/ConnectToMeetingsCard/ConnectToMeetingsCard';
import InviteCard from '../../../components/Cards/InviteCard';
import AddParticipantButton from '../../../components/CCMode/AddParticipantButton';
import ConnectToMeetingsButton from '../../../components/CCMode/ConnectToMeetingsButton';
import LoadingText from '../../../components/LoadingText';
import WebsocketReconnectingBanner from '../../../components/Network/WebsocketReconnectingBanner';
import ParticipantList from '../../../components/ParticipantList';
import SnackbarWithCountdown from '../../../components/SnackbarWithCountdown';
import { useV1Socket } from '../../../hooks/useV1Socket';
import AudioRecorder from '../../../modules/AudioRecorder';
import ScribeKeypressHandlerContainer from '../../../modules/scribe/ScribeKeypressHandlerContainer';
import ScribeTranscriptListContainer from '../../../modules/scribe/ScribeTranscriptListContainer';
import ScribeVolumeController from '../../../modules/scribe/ScribeVolumeController';
import SoloDiaSpeakerNameModal, { SoloDiaNameModalProvider } from '../../../modules/scribe/SoloDiaSpeakerNameModal';
import { selectConversationMuted, selectShowKeyboardInput } from '../../../selectors/combined';
import { selectIsInConversation, selectJoiningStatus } from '../../../selectors/conversation';
import { selectRecallAIStatus } from '../../../selectors/recallAI';
import { selectIsScribing } from '../../../selectors/scribe';
import { selectIsConnectToMeetingOpen } from '../../../selectors/ui';
import { selectIsInviteCardOpen, selectKeyboardInputShown } from '../../../selectors/ui';
import { selectDisplayShortcuts, selectFontSize } from '../../../selectors/ui';
import { selectIsUserTemporary } from '../../../selectors/userProfile';
import { setJoiningStatus } from '../../../store/slices/conversation';
import { setElectronCaptionMode, setIsInviteCardOpen, updateKeyboardInputShown } from '../../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { isWindows } from '../../../utils';
import { isDefaultRoomId } from '../../../utils/ws-v1';
import KeyboardInput from '../KeyboardInput';
import NotificationContainer from '../NotificationContainer';
import useStyles from './ConversationView.styles';
const ConversationView = ({ addNotification, appSubmittedTypeform, clearNotifications, features, onEndConversation, roomEndingWarningCountdown, roomEndingWarningBegin, roomEndingWarningProlongUrl, setShowBubbleAboveCCMode, showBubbleAboveCCMode, speakers, status, }) => {
    const fontSize = useAppSelector(selectFontSize);
    const classes = useStyles({ showBubbleAboveCCMode, fontSize });
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isInviteCardOpen = useAppSelector(selectIsInviteCardOpen);
    const displayShortcuts = useAppSelector(selectDisplayShortcuts);
    const scribing = useAppSelector(selectIsScribing);
    const joiningStatus = useAppSelector(selectJoiningStatus);
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const muted = useAppSelector(selectConversationMuted);
    const isInConversation = useAppSelector(selectIsInConversation);
    const isConnectToMeetingsOpen = useAppSelector(selectIsConnectToMeetingOpen);
    const recalAIStatus = useAppSelector(selectRecallAIStatus);
    const tempUser = useAppSelector(selectIsUserTemporary);
    const showKeyboardButton = useAppSelector(selectShowKeyboardInput);
    const [_, websocketStatus] = useV1Socket();
    const keyboardInputComponent = useRef(null);
    const showAudioControlsAndKeyboard = showKeyboardButton || (!scribing && !muted && isInConversation);
    useEffect(() => {
        return () => {
            clearNotifications();
            setShowBubbleAboveCCMode(false);
            dispatch(setIsInviteCardOpen(false));
            dispatch(setJoiningStatus('none'));
        };
    }, []);
    useEffect(() => {
        if (joiningStatus === 'accepted') {
            addNotification({
                text: t('conversation.inviteCard.joinedAvaRoom'),
                timestamp: Date.now(),
                type: 'success',
            });
        }
    }, [joiningStatus]);
    return (React.createElement(SoloDiaNameModalProvider, null,
        React.createElement("div", { className: classes.root },
            React.createElement("section", { className: classnames(classes.topContainer, {
                    [classes.visible]: showBubbleAboveCCMode,
                }) },
                React.createElement("div", { className: classes.topContainerLeft },
                    React.createElement("div", { className: classes.participants },
                        (status === null || status === void 0 ? void 0 : status.id) && !isDefaultRoomId(status.id) && (React.createElement(ParticipantList, { scribe: features.scribe && scribing, theme: theme, speakers: speakers, roomSpeakers: status.speakers, participants: status.participants || [], transcriptOwnersId: status.transcriptsOwners || [], status: status, displayShortcuts: displayShortcuts })),
                        React.createElement(AddParticipantButton, { isActive: isInviteCardOpen }),
                        isConnectToMeetingsOpen && features['recall-ai'] ? (React.createElement(ConnectToMeetingsCard, null)) : features['recall-ai'] ? (React.createElement(ConnectToMeetingsButton, { isActive: isConnectToMeetingsOpen })) : (React.createElement("div", { style: { display: 'none' } }))),
                    React.createElement(SoloDiaSpeakerNameModal, null)),
                React.createElement("div", { className: classes.conversationButtons },
                    React.createElement(Tooltip, { title: t('ccMode.buttons.fullScreen'), placement: "bottom", disableInteractive: true },
                        React.createElement("div", null,
                            React.createElement("button", { className: classes.conversationButton, onClick: () => dispatch(setElectronCaptionMode(false)) },
                                React.createElement("img", { src: ExpandIcon, alt: "" })))),
                    React.createElement(Tooltip, { title: t('ccMode.buttons.closeRoom'), placement: "bottom", disableInteractive: true },
                        React.createElement("div", null,
                            React.createElement("button", { className: classnames(classes.conversationButton, classes.closeButton), onClick: onEndConversation },
                                React.createElement(CloseIcon, { className: classes.closeIcon })))))),
            React.createElement("section", { className: classnames(classes.conversationContainer, {
                    [classes.conversationContainerKeyboardOpen]: keyboardInputShown,
                    [classes.readOnlyConversation]: !showAudioControlsAndKeyboard,
                }), onMouseEnter: () => setShowBubbleAboveCCMode(true) },
                React.createElement("div", { className: classes.conversationDraggableContainer },
                    React.createElement(WebsocketReconnectingBanner, null),
                    React.createElement(NotificationContainer, null),
                    React.createElement(SnackbarWithCountdown, { classes: {
                            anchorOriginTopLeft: classes.snackbarCountdown,
                        }, message: t('snackbar.convoRestrictedSessionWarning'), countdown: roomEndingWarningCountdown, countdownBegin: roomEndingWarningBegin, additionalActionMessage: t('snackbar.convoRestrictedSessionProlong'), additionalActionUrl: roomEndingWarningProlongUrl, appSubmittedTypeform: appSubmittedTypeform }),
                    React.createElement("span", null, websocketStatus === 'online' && (!muted || (recalAIStatus === 'CAPTIONING' && !tempUser)) && (React.createElement(ScribeKeypressHandlerContainer, null))),
                    !isInConversation || joiningStatus === 'requested' ? (React.createElement("div", { className: classes.loadingTextContainer },
                        React.createElement(LoadingText, { className: classes.loadingText }, joiningStatus === 'requested'
                            ? t('ccMode.conversation.joiningAvaRoom')
                            : t('ccMode.conversation.startingAvaRoom')))) : (React.createElement(React.Fragment, null,
                        (isWindows || showAudioControlsAndKeyboard) && (React.createElement("div", { className: classes.controlsContainer }, showAudioControlsAndKeyboard && (React.createElement(React.Fragment, null,
                            React.createElement(ScribeVolumeController, { className: classes.volumeController, electronCaptionMode: true }),
                            React.createElement(AudioRecorder, { className: classes.audioRecorder, showExpandedMic: showBubbleAboveCCMode }),
                            React.createElement("div", { style: { height: 32 } }, !keyboardInputShown && (React.createElement(Tooltip, { classes: { tooltipPlacementTop: classes.tooltipPlacementTop }, title: t('ccMode.tooltip.keyboard'), placement: "top", disableInteractive: true },
                                React.createElement("button", { className: classes.openKeyboardInputButton },
                                    React.createElement("img", { src: KeyboardArrowDown, alt: t('ccMode.keyboard.keyboardArrowDownButton'), onClick: () => {
                                            window.electronIPC.sendCCMode('keyboard', true);
                                            dispatch(updateKeyboardInputShown(true));
                                        } }))))))))),
                        React.createElement("div", { className: classes.transcriptsContainer },
                            isInviteCardOpen && (React.createElement("div", { className: classes.inviteCardContainer },
                                React.createElement(InviteCard, null))),
                            React.createElement(ScribeTranscriptListContainer, null)))))),
            React.createElement("section", { ref: keyboardInputComponent, className: classnames(classes.bottomContainer, {
                    [classes.keyboardInputOpen]: keyboardInputShown,
                }), onMouseEnter: () => setShowBubbleAboveCCMode(true) }, keyboardInputShown && (React.createElement(KeyboardInput, { onCloseKeyboard: () => {
                    dispatch(updateKeyboardInputShown(false));
                    window.electronIPC.sendCCMode('keyboard', false);
                } }))))));
};
export default ConversationView;
