import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RoundedButton from '../../../components/Buttons/RoundedButton';
import CloseButton from '../../../components/CCMode/CloseButton';
import ExpandButton from '../../../components/CCMode/ExpandButton';
import StartConversationButton from '../../../components/CCMode/StartConversationButton';
import TranscriptIcon from '../../../components/Icons/Sidebar/Transcript';
import RatingSelector from '../../../components/Rating/RatingSelector';
import { selectFirebaseUser } from '../../../selectors/auth';
import { selectAvaId, selectFeatures, selectFeedbackMetrics } from '../../../selectors/userProfile';
import { conversations } from '../../../services/api/ava';
import { setElectronCaptionMode } from '../../../store/slices/uiState';
import { useAppSelector } from '../../../store/store';
import { trackConversationRating } from '../../../utils/conversation';
import useStyles from './RatingView.styles';
const RatingView = ({ conversationStatus, createNewConversation, isRatingOpen, rate, rateHover, setCCMode, setRate, setRateConversationOpen, setRateHover, setShowBubbleAboveCCMode, }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const features = useAppSelector(selectFeatures);
    const feedbackMetrics = useAppSelector(selectFeedbackMetrics);
    const avaId = useAppSelector(selectAvaId);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Used to keep track if user's mouse went over the conversation widget. We
    // only show the options when hovering over the widget, and not on the rating
    // container.
    const [showExpandCloseOptions, setShowExpandCloseOptions] = useState(false);
    useEffect(() => {
        return () => {
            setRate(undefined);
            setRateHover(undefined);
        };
    }, []);
    const trackRateEvent = (rating) => {
        trackConversationRating({
            rate: rating,
            status: conversationStatus,
            feedbackMetrics,
            avaId,
            firebaseAuthUID: firebaseUser.uid,
            dispatch,
        });
    };
    const submitRating = (rating) => {
        if (!rating) {
            trackRateEvent(0);
        }
        else {
            conversations.rateConversation({
                avaId: avaId || '',
                roomId: conversationStatus.id,
                rating,
                firebaseAuthUID: firebaseUser.uid,
            });
        }
    };
    const onRateChange = (newRate) => {
        // Don't submit rate if user already rated
        if (rate)
            return;
        setRate(newRate);
        trackRateEvent(newRate);
        setRateConversationOpen(false);
        submitRating(newRate);
        if (features['forbid-conversation-saving']) {
            setCCMode('home');
        }
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement("section", { className: classes.ratingContainer, onMouseEnter: () => setShowBubbleAboveCCMode(true) },
            React.createElement("button", { className: classes.closeRatingButton, onClick: () => {
                    setRateConversationOpen(false);
                    setCCMode('home');
                } },
                React.createElement(CloseIcon, null)),
            isRatingOpen && (React.createElement(React.Fragment, null,
                React.createElement(Typography, { className: classes.ratingText }, t('ccMode.rating.text')),
                React.createElement("div", null,
                    React.createElement(RatingSelector, { readonly: !!rate, initialRating: rate, rating: rateHover || rate || 0, onChange: onRateChange, onHover: (value) => {
                            if (rateHover !== value) {
                                setRateHover(value);
                            }
                        } })))),
            !features['forbid-conversation-saving'] && (React.createElement(RoundedButton, { className: classes.viewTranscriptsButton, onClick: () => {
                    dispatch(setElectronCaptionMode(false));
                    setTimeout(() => navigate('/web/conversations'), 0);
                }, disabledRipple: true, flat: true },
                React.createElement(TranscriptIcon, { height: 24, width: 24 }),
                React.createElement("span", { className: classes.viewTranscriptsText }, t('ccMode.rating.viewTranscripts'))))),
        React.createElement("section", { className: classes.startConversationContainer, onMouseLeave: () => setShowExpandCloseOptions(false) },
            React.createElement("div", { className: classnames(classes.buttonsContainer, {
                    [classes.buttonsVisible]: showExpandCloseOptions,
                }) },
                React.createElement(ExpandButton, { onClick: () => {
                        if (isRatingOpen) {
                            onRateChange(0);
                        }
                        dispatch(setElectronCaptionMode(false));
                    } }),
                React.createElement(CloseButton, { onClick: () => window.electronIPC.sendCCMode('close') })),
            React.createElement(StartConversationButton, { onClick: () => {
                    setCCMode('conversation');
                    createNewConversation();
                }, onMouseEnter: () => {
                    setShowExpandCloseOptions(true);
                    setShowBubbleAboveCCMode(true);
                } }))));
};
export default RatingView;
