import { useTheme } from '@mui/material/styles';
import classnames from 'classnames';
import React, { useState } from 'react';
import CheckmarkIcon from '../../../components/Icons/Checkmark';
import useInterval from '../../../hooks/useInterval';
import useStyles from './NotificationContainer.styles';
const NotificationContainer = ({ notifications }) => {
    const classes = useStyles();
    const theme = useTheme();
    const latestNotification = notifications[notifications.length - 1];
    const [currentTimestamp, setCurrentTimestamp] = useState(Date.now());
    useInterval(() => {
        setCurrentTimestamp(Date.now());
    }, 1000);
    if (!latestNotification)
        return null;
    const duration = (latestNotification === null || latestNotification === void 0 ? void 0 : latestNotification.duration) || 3000;
    const timeElapsed = currentTimestamp - latestNotification.timestamp;
    // Don't display a notification if it does not exist or is older than prescribed duration
    if (timeElapsed >= duration) {
        return null;
    }
    return (React.createElement("div", { className: classes.root },
        React.createElement("span", { className: classnames(classes.text, { [classes.successText]: latestNotification.type === 'success' }) },
            latestNotification.text,
            latestNotification.link && (React.createElement("a", { href: latestNotification.link, style: { color: theme.palette.ava.blue2, marginLeft: 20 }, target: "_blank", rel: "noopener noreferrer", className: classnames(classes.text) }, latestNotification.linkText))),
        latestNotification.type === 'success' && React.createElement(CheckmarkIcon, { width: 16, height: 16 })));
};
export default NotificationContainer;
