import { setIntercomOpen } from '../store/slices/uiState';
export const closeIntercomTour = () => {
    try {
        // Intercom provides an Intercom("startTour", tourId)
        // method but no method to programmatically close
        // a tour.
        const { frames } = window;
        let intercomTourFrame;
        for (let i = 0; i < frames.length; i += 1) {
            if (frames[i].name === 'intercom-tour-frame') {
                intercomTourFrame = frames[i];
            }
        }
        if (!intercomTourFrame)
            return;
        const intercomTourHeader = intercomTourFrame.document.getElementsByClassName('intercom-tour-step-header');
        if (intercomTourHeader.length === 0)
            return;
        const intercomTourCloseButton = intercomTourHeader[0].getElementsByTagName('span');
        if (intercomTourCloseButton.length === 0)
            return;
        intercomTourCloseButton[0].click();
    }
    catch (e) {
        //
    }
};
const IntercomAPI = (method, args) => {
    if (window.Intercom) {
        return window.Intercom.apply(null, [method, args]);
    }
    return null;
};
export const shutdown = () => {
    if (IntercomAPI) {
        IntercomAPI('shutdown');
    }
};
export const boot = (metaData) => {
    window.intercomSettings = metaData;
    if (IntercomAPI) {
        IntercomAPI('boot', metaData);
    }
};
export const hide = () => {
    if (IntercomAPI) {
        IntercomAPI('hide');
    }
};
export const show = () => {
    document.documentElement.style.setProperty('--intercom-display', `block`);
    if (IntercomAPI) {
        IntercomAPI('show');
    }
};
export const showNewMessage = (message) => {
    show();
    if (IntercomAPI) {
        IntercomAPI('showNewMessage', message);
    }
};
export const startTour = (tourId) => {
    if (IntercomAPI) {
        IntercomAPI('startTour', tourId);
    }
};
export const hideDefaultLauncher = (value) => {
    if (IntercomAPI) {
        IntercomAPI('update', {
            hide_default_launcher: value,
        });
    }
};
export const openOrClosed = (dispatch) => {
    IntercomAPI('onShow', () => dispatch(setIntercomOpen(true)));
    IntercomAPI('onHide', () => dispatch(setIntercomOpen(false)));
};
export const unreadCountChange = (callback) => {
    IntercomAPI('onUnreadCountChange', callback);
};
