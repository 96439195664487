var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authInstance, restName } from '../../firebase';
import { deleteManyTranscriptsFromUser, deleteTranscriptFromUser, updateTranscriptTitleForUser, } from '../../firebase/saved-transcript';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectLastReadTimestamp, selectTranscripts } from '../../selectors/saved-transcript';
import { selectAvaId } from '../../selectors/userProfile';
import { cloudTranscriptsEndpoint } from '../../utils/http';
import { loadIDToken } from './auth';
const INITIAL_STATE = {
    loading: false,
    deleteLoading: false,
    updateTitleLoading: false,
    hasMoreTranscripts: true,
    lastReadTimestamp: null,
    transcripts: [],
    selectedId: '',
    error: '',
};
const savedTranscriptSlice = createSlice({
    name: 'saved-transcript',
    initialState: INITIAL_STATE,
    reducers: {
        setSelectedTranscriptId: (state, { payload }) => {
            state.selectedId = payload;
        },
        resetTranscripts: (state) => {
            state.hasMoreTranscripts = true;
            state.lastReadTimestamp = null;
            state.loading = false;
            state.transcripts = [];
        },
        addNewTranscript: (state, { payload }) => {
            state.lastReadTimestamp = state.transcripts.length
                ? retrieveLastReadTranscript(state.transcripts).startTimestampMs
                : payload.startTimestampMs;
            state.transcripts = filterAccessibleTranscripts([payload], state.transcripts);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTranscriptsRequestAsync.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTranscriptsRequestAsync.fulfilled, (state, { payload }) => {
            state.loading = false;
            if (payload) {
                state.hasMoreTranscripts = payload.hasMoreTranscripts;
                if (payload.transcripts.length) {
                    state.lastReadTimestamp = retrieveLastReadTranscript(payload.transcripts).startTimestampMs;
                    state.transcripts = filterAccessibleTranscripts(state.transcripts, payload.transcripts);
                }
            }
        });
        builder.addCase(fetchTranscriptsRequestAsync.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
        });
        builder.addCase(deleteTranscriptRequestAsync.pending, (state) => {
            state.deleteLoading = true;
        });
        builder.addCase(deleteTranscriptRequestAsync.fulfilled, (state, { payload }) => {
            state.deleteLoading = false;
            state.transcripts = filterDeletedTranscript(state.transcripts, payload);
        });
        builder.addCase(deleteTranscriptRequestAsync.rejected, (state, { payload }) => {
            state.deleteLoading = false;
            state.error = payload;
        });
        builder.addCase(deleteManyTranscriptsRequestAsync.pending, (state) => {
            state.deleteLoading = true;
        });
        builder.addCase(deleteManyTranscriptsRequestAsync.fulfilled, (state, { payload }) => {
            state.deleteLoading = false;
            state.transcripts = filterDeletedManyTranscripts(state.transcripts, payload);
        });
        builder.addCase(deleteManyTranscriptsRequestAsync.rejected, (state, { payload }) => {
            state.deleteLoading = false;
            state.error = payload;
        });
        builder.addCase(updateTranscriptTitleRequestAsync.pending, (state) => {
            state.updateTitleLoading = true;
        });
        builder.addCase(updateTranscriptTitleRequestAsync.fulfilled, (state, { payload }) => {
            state.updateTitleLoading = false;
            state.transcripts = updateTranscripts(state.transcripts, payload);
        });
        builder.addCase(updateTranscriptTitleRequestAsync.rejected, (state, { payload }) => {
            state.updateTitleLoading = false;
            state.error = payload;
        });
    },
});
export const savedTranscriptReducer = savedTranscriptSlice.reducer;
export const { setSelectedTranscriptId, resetTranscripts, addNewTranscript } = savedTranscriptSlice.actions;
export const fetchTranscriptsRequestAsync = createAsyncThunk('savedTranscript/fetchTranscripts', (_, { getState, rejectWithValue, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const state = getState();
        const avaId = selectAvaId(state);
        const firebaseUser = selectFirebaseUser(state);
        if (!firebaseUser)
            return;
        let endpoint = `${cloudTranscriptsEndpoint}?avaId=${avaId}&firebaseAuthID=${firebaseUser.uid}&limit=10`;
        const transcripts = selectTranscripts(state);
        if (transcripts.length > 0) {
            const lastReadTimestamp = selectLastReadTimestamp(state);
            endpoint += `&before=${lastReadTimestamp}`;
        }
        const firebaseIdToken = yield dispatch(loadIDToken()).unwrap();
        const token = firebaseIdToken ? firebaseIdToken : (_a = authInstance.currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken();
        const response = yield fetch(endpoint, {
            headers: {
                Authorization: `Basic ${window.btoa(`${restName}:${token}`)}`,
            },
        });
        const data = yield response.json();
        if (data.length && filterAccessibleTranscripts(data, []).length === 0) {
            // Note: This means that all of the fetched transcripts are not accessible
            // so the infinite scroll that loads this will think there is more data, but
            // it will keep waiting for the current fetch to finish rather than refetching
            // (because the transcripts visible by it will not be updated). This means
            // we need to manually refetch with the next timestamp. This could potentially
            // keep refetching if we keep receiving non-empty response that contains
            // only not accessible transcripts.
            // This action needs to be dispatched after the lastReadTimestamp has updated
            // hence the need for setTimeout.
            setTimeout(() => {
                dispatch(fetchTranscriptsRequestAsync());
            }, 0);
        }
        if (data.length) {
            return { transcripts: data, hasMoreTranscripts: true };
        }
        else {
            return { transcripts: [], hasMoreTranscripts: false };
        }
    }
    catch (error) {
        return rejectWithValue(error.message);
    }
}));
export const deleteTranscriptRequestAsync = createAsyncThunk('savedTranscript/deleteTranscript', ({ avaId, transcript }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield deleteTranscriptFromUser({
            userId: avaId,
            roomId: transcript.roomId,
        });
        return transcript;
    }
    catch (err) {
        return err.message;
    }
}));
export const deleteManyTranscriptsRequestAsync = createAsyncThunk('savedTranscript/deleteManyTranscripts', ({ avaId, transcripts }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const roomIds = transcripts.map((t) => t.roomId);
        const deletedTranscriptRoomIds = yield deleteManyTranscriptsFromUser({
            userId: avaId,
            roomIds,
        });
        return deletedTranscriptRoomIds;
    }
    catch (err) {
        return err.message;
    }
}));
export const updateTranscriptTitleRequestAsync = createAsyncThunk('savedTranscript/updateTranscriptTitle', ({ avaId, transcript, title }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield updateTranscriptTitleForUser({
            userId: avaId,
            roomId: transcript.roomId,
            title,
        });
        const updatedTranscript = Object.assign(Object.assign({}, transcript), { title });
        return updatedTranscript;
    }
    catch (err) {
        return err.message;
    }
}));
export const filterAccessibleTranscripts = (currentTranscripts, newTranscripts) => {
    const updatedTranscripts = [];
    const transcriptMapping = {};
    currentTranscripts.forEach((transcript) => {
        if (!(transcript.roomId in transcriptMapping)) {
            transcriptMapping[transcript.roomId] = true;
            updatedTranscripts.push(transcript);
        }
    });
    newTranscripts.forEach((transcript) => {
        if (!(transcript.roomId in transcriptMapping)) {
            transcriptMapping[transcript.roomId] = true;
            updatedTranscripts.push(transcript);
        }
    });
    const filteredTranscripts = updatedTranscripts.filter((transcript) => {
        return transcript.hasTranscripts && transcript.isAllowedAccess && !transcript.deleted;
    });
    return filteredTranscripts;
};
export const retrieveLastReadTranscript = (newTranscripts) => newTranscripts[newTranscripts.length - 1];
export const filterDeletedTranscript = (currentTranscripts, deletedTranscript) => {
    const filteredTranscripts = currentTranscripts.filter((transcript) => transcript.roomId !== deletedTranscript.roomId);
    return filteredTranscripts;
};
export const filterDeletedManyTranscripts = (currentTranscripts, deletedTranscriptIds) => currentTranscripts.filter((transcript) => !deletedTranscriptIds.includes(transcript.roomId));
export const updateTranscripts = (currentTranscripts, updatedTranscript) => {
    const updatedTranscripts = [...currentTranscripts];
    for (let i = 0; i < updatedTranscripts.length; i += 1) {
        if (updatedTranscripts[i].roomId === updatedTranscript.roomId) {
            updatedTranscripts[i] = Object.assign({}, updatedTranscript);
            break;
        }
    }
    return updatedTranscripts;
};
