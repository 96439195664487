import CachedIcon from '@mui/icons-material/Cached';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useLongPress } from 'use-long-press';
import autoTranslateLangs from '../../../assets/auto_translate_langs.json';
import useAvaTranslateManager from '../../hooks/useAvaTranslateManager';
import { selectAutoTranslateActiveOption, selectAutoTranslateLanguage, selectGuestLanguage, selectGuestLanguageSelectorOpen, selectHostLanguage, selectToggleInProgress, } from '../../selectors/avaTranslate';
import { setGuestLanguageSelectorOpen } from '../../store/slices/avaTranslate';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { SNCF } from '../../utils';
import { canShowFlagEmoji } from '../../utils/platform';
import { AutoTranslateGuestLanguageSelector } from './AutoTranslateGuestLanguageSelector';
export const AvaTranslateButton = () => {
    const guestLanguageSelectorOpen = useAppSelector(selectGuestLanguageSelectorOpen);
    const avaTranslateManager = useAvaTranslateManager();
    const hostLang = useAppSelector(selectHostLanguage);
    const guestLang = useAppSelector(selectGuestLanguage);
    const autoTranslateLanguage = useAppSelector(selectAutoTranslateLanguage);
    const autoTranslateActiveOption = useAppSelector(selectAutoTranslateActiveOption);
    const loading = useAppSelector(selectToggleInProgress);
    const longClickTimestamp = useRef(0);
    const dispatch = useAppDispatch();
    useEffect(() => {
        return () => {
            dispatch(setGuestLanguageSelectorOpen(false));
        };
    }, []);
    const longPressBind = useLongPress(() => {
        handleLongPress();
    });
    const handleClick = () => {
        if (Date.now() - longClickTimestamp.current < 2000) {
            // If we just did a long click - let's not do a short click
            return;
        }
        if (!guestLang) {
            // If Guest Language isn't present, then simple click will let users
            // select it.
            handleLongPress();
            return;
        }
        avaTranslateManager === null || avaTranslateManager === void 0 ? void 0 : avaTranslateManager.handleSendAutoTranslateToggle();
    };
    const handleLongPress = () => {
        longClickTimestamp.current = Date.now();
        dispatch(setGuestLanguageSelectorOpen(true));
    };
    const changeGuestLanguage = (languageCode) => {
        console.log('changing guest language to ', languageCode);
        dispatch(setGuestLanguageSelectorOpen(false));
        avaTranslateManager === null || avaTranslateManager === void 0 ? void 0 : avaTranslateManager.handleSendSetGuestLanguage(languageCode);
    };
    const currentLang = autoTranslateLanguage && autoTranslateLangs[autoTranslateLanguage];
    const guestLangObj = guestLang && autoTranslateLangs[guestLang];
    if (!guestLang && !SNCF)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Fab, Object.assign({ sx: {
                padding: 1,
                backgroundColor: 'white',
                position: 'relative',
                userSelect: 'none',
            }, onClick: handleClick, disabled: loading }, longPressBind()),
            currentLang && (React.createElement(ReactCountryFlag, { style: {
                    height: !canShowFlagEmoji() ? 32 : undefined,
                    width: !canShowFlagEmoji() ? 32 : undefined,
                    fontSize: 32,
                }, svg: !canShowFlagEmoji(), countryCode: currentLang.flagCode })),
            !currentLang && (React.createElement(Typography, { sx: {
                    fontSize: 32,
                } }, "\uD83C\uDF10")),
            React.createElement(Box, { sx: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    display: loading ? 'block' : 'none',
                    //display: 'block',
                } }, loading && (React.createElement(CircularProgress, { sx: {
                    width: 48,
                    height: 48,
                    color: 'white',
                } }))),
            React.createElement(Box, { sx: {
                    position: 'absolute',
                    background: '#4c1173',
                    height: 24,
                    width: 24,
                    bottom: -5,
                    right: 0,
                    borderRadius: 10,
                    padding: '2px',
                } },
                React.createElement(CachedIcon, { sx: {
                        width: 20,
                        height: 20,
                        color: 'white',
                    } }))),
        React.createElement(AutoTranslateGuestLanguageSelector, { open: guestLanguageSelectorOpen, initialLanguageCode: guestLangObj === null || guestLangObj === void 0 ? void 0 : guestLangObj.languageCode, onLanguageChange: changeGuestLanguage })));
};
