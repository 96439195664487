var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { selectFirebaseUser } from '../../selectors/auth';
import { useAppSelector } from '../../store/store';
import { logoutAndClean } from '../../utils/user';
const useStyles = makeStyles((theme) => ({
    item: {
        paddingTop: 6,
        paddingRight: 24,
        paddingBottom: 6,
        paddingLeft: 24,
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '27px',
        '&:hover': {
            background: theme.palette.mode === 'light' ? theme.palette.ava.navy5 : theme.palette.ava.grey5,
        },
    },
    signout: {
        '&:hover': {
            background: theme.palette.mode === 'light' ? theme.palette.ava.red5 : theme.palette.ava.red4,
        },
    },
    red: {
        color: theme.palette.ava.red2,
    },
}));
const SignInSignOut = ({ className }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const navigate = useNavigate();
    const onClick = () => __awaiter(void 0, void 0, void 0, function* () {
        yield logoutAndClean();
        Sentry.configureScope((scope) => {
            scope.setUser(null);
        });
        navigate((firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) ? '/web/login' : '/web');
        window.location.reload();
    });
    return (React.createElement(MenuItem, { className: classnames(classes.item, className, {
            [classes.signout]: !(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous),
            [classes.red]: !(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous),
        }), onClick: onClick }, (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) ? t('sidebar.signIn') : t('sidebar.signOut')));
};
export default SignInSignOut;
