import { connect } from 'react-redux';
import { selectFurthestObservedByScribe, selectLangForTranscripts } from '../../selectors/combined';
import ScribeTranscriptList from './ScribeTranscriptList';
const mapStateToProps = (state) => {
    const { ui: { fontSize, correctionMode, electronCaptionMode }, scribeConversation: { speakers, status, transcripts, transcriptsCurrent, transcriptsFinal }, scribe: { scribing }, audioRecorder: { recording, audioParamsSent }, userProfile: { features }, v1Session: { conferenceCallRequested }, } = state;
    return {
        features,
        fontSize,
        electronCaptionMode,
        furthestObservedByScribe: selectFurthestObservedByScribe(state),
        langForTranscripts: selectLangForTranscripts(state),
        conferenceCallRequested,
        correctionMode,
        speakers,
        status,
        transcripts,
        transcriptsCurrent,
        transcriptsFinal,
        recording,
        audioParamsSent,
        scribing,
    };
};
export default connect(mapStateToProps)(ScribeTranscriptList);
