import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import { ListItem, ListItemIcon, ListItemText, Slider, Tooltip } from '@mui/material';
import { withStyles, withTheme } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { scribeSetFontSizeAndSave } from '../../actions';
import { selectFontSize } from '../../selectors/ui';
import { useAppDispatch, useAppSelector } from '../../store/store';
const styles = {
    root: {
        width: '150px',
    },
    avaSlider: {
        '& .MuiSlider-thumb': {
            backgroundColor: '#50aced',
            height: 12,
            width: 12,
            '&:hover': {
                boxShadow: '0px 0px 0px 8px rgba(80, 172, 237, 15%)',
                '&.MuiSlider-active': {
                    boxShadow: '0px 0px 0px 14px rgba(80, 172, 237, 15%)',
                },
            },
        },
        '& .MuiSlider-rail': {
            backgroundColor: 'rgba(80, 172, 237, 65%)',
        },
        '& .MuiSlider-track': {
            backgroundColor: '#50aced',
            border: 'none',
        },
    },
};
const ScribeFontSizeInput = (props) => {
    const { t, theme, classes } = props;
    const fontSize = useAppSelector(selectFontSize);
    const dispatch = useAppDispatch();
    const timeout = useRef();
    const [preview, setPreview] = useState(false);
    const scribeSetFontSizeAndSaveLocal = (data) => {
        const newFontSize = data.fontSize || Math.round(fontSize * (data.ratio || 1));
        dispatch(scribeSetFontSizeAndSave(newFontSize));
        clearTimeout(timeout.current);
        if (window.navigator.share) {
            timeout.current = setTimeout(() => setPreview(false), 1000);
            setPreview(true);
        }
    };
    useEffect(() => {
        return () => {
            clearTimeout(timeout.current);
        };
    }, []);
    const logFontSize = Math.log10(fontSize);
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { style: {
                zIndex: 0,
                paddingLeft: 32,
            } },
            React.createElement(ListItemIcon, null,
                React.createElement(TextFieldsOutlinedIcon, null)),
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column', flexGrow: 1 } },
                React.createElement(ListItemText, null, t('editorSettings.fontSize')),
                React.createElement("div", { style: {
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                    } },
                    React.createElement(Tooltip, { disableInteractive: true, title: t('theme.decreaseFontSize') },
                        React.createElement(ListItemIcon, { onClick: () => scribeSetFontSizeAndSaveLocal({ ratio: 0.93 }), style: {
                                color: theme.palette.contrast[theme.palette.mode],
                                cursor: 'pointer',
                                opacity: 0.9,
                                marginRight: 12,
                                minWidth: 0,
                            } },
                            React.createElement(RemoveOutlinedIcon, null))),
                    React.createElement(Slider, { style: {
                            flexGrow: 1,
                        }, className: classes.avaSlider, value: logFontSize, onChange: (e, v) => scribeSetFontSizeAndSaveLocal({ fontSize: Math.pow(10, v) }), min: 1, max: 2.2, step: 0.05 }),
                    React.createElement(Tooltip, { disableInteractive: true, title: t('theme.increaseFontSize') },
                        React.createElement(ListItemIcon, { onClick: () => scribeSetFontSizeAndSaveLocal({ ratio: 1.1 }), style: {
                                color: theme.palette.contrast[theme.palette.mode],
                                cursor: 'pointer',
                                opacity: 0.9,
                                marginRight: 0,
                                marginLeft: 12,
                                minWidth: 0,
                            } },
                            React.createElement(AddOutlinedIcon, null)))))),
        React.createElement("div", { style: {
                fontSize,
                position: 'absolute',
                backgroundColor: theme.palette.primary[theme.palette.mode],
                zIndex: preview ? 1 : -1,
                padding: 10,
                width: '100%',
                left: 0,
                color: theme.palette.contrast[theme.palette.mode],
                WebkitTransition: 'opacity 0.5s ease-in',
                MozTransition: 'opacity 0.5s ease-in',
                MsTransition: 'opacity 0.5s ease-in',
                OTransition: 'opacity 0.5s ease-in',
                transition: 'opacity 0.5s ease-in',
                textAlign: 'center',
                opacity: preview ? 0.9 : 0,
            } },
            React.createElement("span", null, t('theme.example')))));
};
export default withTranslation()(withStyles(styles)(withTheme(ScribeFontSizeInput)));
