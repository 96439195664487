import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    ratingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        paddingTop: 14,
        paddingBottom: 14,
        height: 180,
        background: theme.palette.ccMode.backgroundGradient,
        borderRadius: 20,
    },
    closeRatingButton: {
        position: 'absolute',
        top: 12,
        right: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
    },
    ratingText: {
        fontSize: 20,
        lineHeight: '26px',
        textAlign: 'center',
    },
    viewTranscriptsButton: {
        color: '#EEEEEE',
        minWidth: 225,
        paddingTop: 8,
        paddingBottom: 8,
        border: `1px solid ${theme.palette.ava.white}`,
        fontSize: 20,
        lineHeight: '26px',
        '&:hover': {
            border: '1px solid transparent',
            background: theme.palette.ava.darkBg2,
        },
    },
    viewTranscriptsText: {
        marginLeft: 10,
    },
    startConversationContainer: {
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        width: 82,
    },
    buttonsContainer: {
        display: 'flex',
        height: 32,
        background: theme.palette.ccMode.backgroundGradient,
        boxShadow: '0px 0.561661px 3.36997px rgba(112, 144, 176, 0.25)',
        borderRadius: 8,
        overflow: 'hidden',
        visibility: 'hidden',
        '& > *': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
        },
    },
    buttonsVisible: {
        visibility: 'visible',
    },
}));
export default useStyles;
