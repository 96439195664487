import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectUserPhotoURL } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import AvatarLogo from './AvatarLogo';
const UserIcon = ({ width, height }) => {
    const theme = useTheme();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const userPhotoURL = useAppSelector(selectUserPhotoURL);
    if (firebaseUser && userPhotoURL) {
        return (React.createElement(Avatar, { src: userPhotoURL, alt: "profile_photo", style: {
                width: width || 40,
                height: height || 40,
            } }));
    }
    return (React.createElement("div", null,
        React.createElement(AvatarLogo, { color: theme.palette.mode === 'light'
                ? theme.palette.ava.navyGroup.convoText
                : theme.palette.ava.navyGroup.convoTextDark, width: width || 40, height: height || 40 })));
};
export default UserIcon;
