import CloseIcon from '@mui/icons-material/Close';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectStatus } from '../../selectors/legacy-conversation';
import { selectAvaId } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import { isHost } from '../../utils/status';
const CloseButton = ({ onEndConversationClick }) => {
    const { t } = useTranslation();
    const avaId = useAppSelector(selectAvaId);
    const status = useAppSelector(selectStatus);
    const isUserHost = isHost(status, avaId);
    return (React.createElement(Tooltip, { title: isUserHost ? t('header.quitConversation') : t('header.exitConversation'), "aria-label": "quit-conversation", enterDelay: 300, leaveDelay: 200, disableInteractive: true },
        React.createElement(Fab, { size: "small", style: {
                width: 56,
                height: 56,
                backgroundColor: 'rgb(255, 0, 0, 1)',
                color: 'white',
            }, "data-qa": "endConvoButton", onClick: onEndConversationClick },
            React.createElement(CloseIcon, null))));
};
export default CloseButton;
