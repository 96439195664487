import { Avatar, Grid, Hidden, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AvaWhite from '../../../../../assets/images/ava_white.png';
import TestimonialEdu from '../../../../../assets/images/testimonial-logo-edu.png';
import TestimonialHr from '../../../../../assets/images/testimonial-logo-hr.png';
import TestimonialIndividual from '../../../../../assets/images/testimonial-logo-individual.png';
import TestimonialWorship from '../../../../../assets/images/testimonial-logo-worship.png';
import { getPhraseOrEmptyString } from '../../../../utils/i18n';
const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: 'white',
        marginRight: theme.spacing(2),
    },
    avatarImage: {
        height: '40px',
        width: '40px',
    },
    container: {
        height: '100%',
        overflow: 'hidden',
    },
    logo: {
        backgroundColor: theme.palette.ava.blue,
        padding: theme.spacing(6),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },
    },
    name: {
        color: '#dbdbdb',
        fontSize: '20px',
    },
    position: {
        color: '#dbdbdb',
        fontSize: '14px',
        fontWeight: 500,
    },
    root: {
        backgroundColor: theme.palette.ava.blue,
        height: '100%',
        [theme.breakpoints.down('lg')]: {
            // height: "100%",
            paddingTop: theme.spacing(2),
        },
    },
    testimonial: {
        color: '#dbdbdb',
        fontSize: 30,
        fontStyle: 'italic',
        fontWeight: 300,
        marginBottom: theme.spacing(3),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(4),
        textAlign: 'center',
    },
}));
function Testimonial() {
    const classes = useStyles();
    const { t } = useTranslation();
    const orgType = window.localStorage.getItem('account_orgType') || 'hr';
    let testimonialImage;
    switch (orgType) {
        case 'edu':
            testimonialImage = TestimonialEdu;
            break;
        case 'hr':
            testimonialImage = TestimonialHr;
            break;
        case 'individual':
            testimonialImage = TestimonialIndividual;
            break;
        case 'worship':
            testimonialImage = TestimonialWorship;
            break;
        default:
            testimonialImage = TestimonialIndividual;
            break;
    }
    return (React.createElement(Grid, { container: true, className: classes.container },
        React.createElement(Grid, { item: true, xs: 12, className: classes.logo },
            React.createElement("img", { src: AvaWhite, width: "155", height: "56", alt: "logo" })),
        React.createElement(Hidden, { mdDown: true },
            React.createElement(Grid, { item: true, className: classes.root },
                React.createElement(Typography, { variant: "h2", className: classes.testimonial },
                    "\"",
                    t(`account.login.testimonial.${orgType}`),
                    "\""),
                React.createElement(Grid, { container: true, direction: "row", alignItems: "center", justifyContent: "center" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Avatar, { className: classes.avatar },
                            React.createElement("img", { className: classes.avatarImage, src: testimonialImage, alt: "testimonial" }))),
                    React.createElement(Grid, { item: true },
                        React.createElement(Grid, { container: true, direction: "column" },
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "subtitle1", className: classes.name }, t(`account.login.testimonial-name.${orgType}`))),
                            React.createElement(Grid, { item: true },
                                React.createElement(Typography, { variant: "subtitle1", className: classes.position }, getPhraseOrEmptyString(`account.login.testimonial-position.${orgType}`))))))))));
}
export default Testimonial;
