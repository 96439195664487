import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { withStyles, withTheme } from '@mui/styles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import LoadingText from '../../components/LoadingText';
import ObservableWrapperWithHideableChildren from '../../components/ObservableWrapperWithHideableChildren';
import { ScribeCorrectionMode } from '../../store/slices/uiState';
import { getOS } from '../../utils';
import { uniq } from '../../utils/lodash';
import { getText } from '../../utils/scribeUtils';
import FinalTranscriptListContainer from './FinalTranscriptListContainer';
import ScribeScrollWithTranscript from './ScribeScrollWithTranscript';
import ScribeTranscriptContainer from './ScribeTranscriptContainer';
const styles = {
    loadingText: {
        fontSize: 40,
        userSelect: 'none',
        '&:after': {
            fontSize: 'unset',
            height: 'unset',
            marginTop: 'unset',
        },
    },
};
class ScribeTranscriptList extends React.Component {
    constructor(props) {
        super(props);
        this.initIntersectionObserver = () => {
            const STATE_CHANGE_DEBOUNCE_MS = 1000;
            const stateChanges = [];
            let timeout;
            const setStateFromStateChanges = () => {
                // clearTimeout is okay with passing it the same value twice, as well as
                // passing it undefined or an already cancelled timeouts.
                clearTimeout(timeout);
                this.setState((prevState) => stateChanges.reduce((state, f) => f(state), prevState));
                stateChanges.length = 0;
            };
            const callback = (entries) => {
                const newlyVisibleTranscripts = [];
                const newDimensions = {};
                const newlyHiddenTranscripts = [];
                entries.forEach((entry) => {
                    const transcriptId = entry.target.dataset.dataid;
                    if (entry.isIntersecting) {
                        // ON SCREEN
                        newlyVisibleTranscripts.push(transcriptId);
                    }
                    else {
                        // OFF SCREEN
                        // only get measurements once the element has left the screen, since they are unlikely to be corrected by a scribe and change size
                        // If the entry disappeared below us - keep rendering it
                        if (entry.boundingClientRect.y > entry.rootBounds.bottom)
                            return;
                        // If the entry disappeared above us - hide it
                        newDimensions[transcriptId] = {
                            height: entry.boundingClientRect.height,
                            width: entry.boundingClientRect.width,
                        };
                        newlyHiddenTranscripts.push(transcriptId);
                    }
                });
                const stateChange = (prevState) => {
                    const nextHiddenTranscriptBlockIds = uniq([
                        ...prevState.hiddenTranscriptBlockIds.filter((id) => !newlyVisibleTranscripts.includes(id)),
                        ...newlyHiddenTranscripts,
                    ]);
                    return {
                        hiddenTranscriptBlockIds: nextHiddenTranscriptBlockIds,
                        transcriptBlockDimensions: Object.assign(Object.assign({}, prevState.transcriptBlockDimensions), newDimensions),
                    };
                };
                clearTimeout(timeout);
                stateChanges.push(stateChange);
                const hiddenTranscriptsThatShouldBeShown = this.state.hiddenTranscriptBlockIds.filter((id) => newlyVisibleTranscripts.includes(id));
                if (hiddenTranscriptsThatShouldBeShown.length) {
                    // If something that is currently hidden should be shown - flush all changes immediately.
                    // This makes sure that we never show blank transcripts.
                    setStateFromStateChanges();
                }
                else {
                    // Otherwise (either there is no change, or we're hiding something) -> debounce the stateChange.
                    // This makes sure that in intense scrolling scenarios (e.g. scrolling all the way to the bottom)
                    // the scrolling smoothness is not impacted by many CSS changes (hiding components), and instead
                    // they happen when all the scrolling is finished.
                    timeout = setTimeout(setStateFromStateChanges, STATE_CHANGE_DEBOUNCE_MS);
                }
            };
            const options = {
                root: document.getElementById('TRANSCRIPT_CONTAINER'),
                // better to be conservative here and give a large buffer zone than risk elements disappearing in front of the user
                rootMargin: '200px',
                threshold: 0,
            };
            this.observer = new IntersectionObserver(callback, options);
            // if the window resizes, the dimensions and hidden elements may no longer be accurate
            window.addEventListener('resize', this.clearObservationState);
        };
        this.clearObservationState = () => {
            this.setState({
                hiddenTranscriptBlockIds: [],
                transcriptBlockDimensions: {},
            });
        };
        this.observe = (el) => {
            if (this.observer) {
                this.observer.observe(el);
            }
        };
        this.unobserve = (el) => {
            if (el && this.observer) {
                this.observer.unobserve(el);
            }
        };
        this.render = () => {
            const { classes, correctionMode, features, fontSize, electronCaptionMode, furthestObservedByScribe, conferenceCallRequested, langForTranscripts, scribing, speakers, t, theme, transcripts, transcriptsCurrent, transcriptsFinal, recording, audioParamsSent, } = this.props;
            const shortLangForTranscripts = langForTranscripts.split('-')[0];
            const { showLoadingCircle } = this.state;
            const showFirstAuthor = new Set(transcriptsCurrent.map((tid) => `${transcripts[tid].author}-${transcripts[tid].trackName}`)).size > 1;
            const showListeningNow = electronCaptionMode && recording && audioParamsSent && (!transcriptsFinal || transcriptsFinal.length < 1);
            let currentTranscriptsToShow = transcriptsCurrent.map((tid) => transcripts[tid]);
            let showEllipsis = false;
            if (correctionMode === ScribeCorrectionMode.Accurate && !scribing && furthestObservedByScribe) {
                currentTranscriptsToShow = currentTranscriptsToShow.filter((transcript) => {
                    return transcript.id <= furthestObservedByScribe.tid || Date.now() - transcript.timestampMs > 120000;
                });
                showEllipsis = currentTranscriptsToShow.length !== transcriptsCurrent.length;
                if (furthestObservedByScribe.tid &&
                    Date.now() - transcripts[furthestObservedByScribe.tid].timestampMs < 120000 &&
                    getText(transcripts[furthestObservedByScribe.tid], shortLangForTranscripts).split(' ').length >
                        furthestObservedByScribe.index + 1) {
                    showEllipsis = true;
                }
            }
            return (React.createElement(ScribeScrollWithTranscript, null,
                transcriptsFinal.length !== 0 && (React.createElement("div", { style: {
                        paddingTop: 24,
                        paddingLeft: window.isElectron && electronCaptionMode && !conferenceCallRequested ? 0 : 20,
                        paddingRight: window.isElectron && electronCaptionMode ? 0 : 20,
                        paddingBottom: 12,
                        position: 'relative',
                        backgroundColor: theme.palette.background[theme.palette.mode],
                    } },
                    React.createElement("div", { style: { margin: '0 auto' } },
                        React.createElement(FinalTranscriptListContainer, { features: features, lang: shortLangForTranscripts, transcriptsFinal: transcriptsFinal, fontSize: fontSize, speakers: speakers, theme: theme })))),
                conferenceCallRequested && showLoadingCircle && (React.createElement("div", { style: {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    } },
                    React.createElement(CircularProgress, { color: "secondary", style: {
                            marginBottom: '12px',
                        } }),
                    React.createElement(Typography, null, t('conversation.call.joiningConferenceCall')))),
                transcriptsCurrent && transcriptsCurrent.length > 0 ? (React.createElement("div", { style: {
                        background: theme.palette.background,
                        paddingLeft: window.isElectron && electronCaptionMode && !conferenceCallRequested ? 0 : 20,
                        paddingRight: window.isElectron && electronCaptionMode ? 0 : 20,
                    } },
                    React.createElement("div", { style: {
                            margin: '0 auto',
                            scrollBehavior: 'smooth',
                            height: '100%',
                            paddingTop: electronCaptionMode ? 24 : 0,
                            paddingBottom: electronCaptionMode ? 24 : 40,
                            WebkitAppRegion: getOS() !== 'Mac' ? 'no-drag' : undefined,
                        } },
                        currentTranscriptsToShow.map((transcript, i) => (React.createElement(ObservableWrapperWithHideableChildren, { dataId: transcript.id, dimensions: this.state.transcriptBlockDimensions[transcript.id], hidden: this.state.hiddenTranscriptBlockIds.includes(transcript.id), key: transcript.id, observe: this.observe, unobserve: this.unobserve },
                            React.createElement(ScribeTranscriptContainer, { id: transcript.id, key: transcript.id, lang: shortLangForTranscripts, 
                                // dividerHeight is just marginTop of this element
                                dividerHeight: transcript.authorShow && i !== 0 ? Math.floor(fontSize * 1.2) : 0, hideFirstInternal: !(transcriptsFinal === null || transcriptsFinal === void 0 ? void 0 : transcriptsFinal.length) && i === 0 && !showFirstAuthor })))),
                        showEllipsis && (React.createElement("div", { style: { fontSize: 32, height: '1em' } },
                            React.createElement(LoadingText, null)))))) : (React.createElement(React.Fragment, null, showListeningNow && (React.createElement("div", { style: {
                        background: theme.palette.background,
                        paddingTop: 24,
                        paddingLeft: window.isElectron && electronCaptionMode && !conferenceCallRequested ? 0 : 20,
                        paddingRight: window.isElectron && electronCaptionMode ? 0 : 20,
                        paddingBottom: 24,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    } },
                    React.createElement("div", { style: {
                            marginLeft: window.isElectron && electronCaptionMode ? 0 : 40,
                            WebkitAppRegion: getOS() !== 'Mac' ? 'no-drag' : undefined,
                        } },
                        React.createElement(LoadingText, { className: classes.loadingText }, t('captions.listeningNow')))))))));
        };
        this.state = {
            hiddenTranscriptBlockIds: [],
            showLoadingCircle: false,
            transcriptBlockDimensions: {},
        };
        this.observer = null;
    }
    componentDidMount() {
        this.initIntersectionObserver();
    }
    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
        window.removeEventListener('resize', this.clearObservationState);
    }
    shouldComponentUpdate(nextProps) {
        const { status } = this.props;
        const { twilioCalls: nextTwilioCalls } = nextProps.status;
        if (Object.keys(nextTwilioCalls || {}).length === 1 && Object.keys(status.twilioCalls || {}).length === 0) {
            this.setState({
                showLoadingCircle: true,
            });
            setTimeout(() => {
                this.setState({
                    showLoadingCircle: false,
                });
            }, 10000);
        }
        return true;
    }
}
export default withTranslation()(withStyles(styles)(withTheme(ScribeTranscriptList)));
