export const useMembers = () => {
    const mockMembers = [
        {
            avaName: '&mockAdmin',
            avatar: undefined,
            email: 'mockadmin@ava.me',
            license: 'Pro Trial',
            name: 'Mock Admin',
            role: 'Admin',
        },
        {
            avaName: '&mockUser',
            avatar: undefined,
            email: 'mockuser@ava.me',
            license: 'Free',
            name: 'Mock User',
            role: 'User',
        },
    ];
    return mockMembers;
};
