import { analytics } from '../../firebase';
import * as segment from '../segment';
class ParseUser {
    constructor(user) {
        this.user = user;
        this.signUpMethod = {
            type: '',
            value: '',
        };
    }
    exists() {
        return !!this.user;
    }
    trackSignUp() {
        if (this.hasPhoneNumber()) {
            this.setPhoneSignUpMethod();
        }
        else if (this.hasEmails()) {
            this.setProviderSignUpMethod();
        }
        const { parse: { avaId }, } = this.user;
        const identifyTraits = this.getIdentifyTraits();
        segment.identifyWithId(avaId, identifyTraits);
        const signedUpProperties = this.getSignedUpProperties();
        segment.track('Signed Up', signedUpProperties);
        const authType = this.signUpMethod.type.toLowerCase() === 'email' ? 'password' : this.signUpMethod.type.toLowerCase();
        analytics.logEvent('app_signed_up', {
            hearing_profile: this.user.hearingProfile,
            register_from: this.getPlatform(),
            user_id: this.user.parse.avaId,
            user_name: this.user.parse.userName,
            ava_name: this.user.parse.avaName,
            [`${authType}_auth`]: this.signUpMethod.value,
        });
    }
    trackSignIn() {
        if (this.hasPhoneNumber()) {
            this.setPhoneSignUpMethod();
        }
        else if (this.hasEmails()) {
            this.setProviderSignUpMethod();
        }
        const { parse: { avaId }, } = this.user;
        const identifyTraits = this.getIdentifyTraits();
        segment.identifyWithId(avaId, identifyTraits);
        const signedUpProperties = this.getSignedUpProperties();
        segment.track('Signed In', signedUpProperties);
        const authType = this.signUpMethod.type.toLowerCase() === 'email' ? 'password' : this.signUpMethod.type.toLowerCase();
        analytics.logEvent('app_signed_in', {
            hearing_profile: this.user.hearingProfile,
            register_from: this.getPlatform(),
            user_id: this.user.parse.avaId,
            user_name: this.user.parse.userName,
            ava_name: this.user.parse.avaName,
            [`${authType}_auth`]: this.signUpMethod.value,
        });
    }
    getPlatform() {
        return window.isElectron ? 'Desktop' : 'Web';
    }
    getEmailProviders() {
        return this.user && this.user.parse && this.user.parse.emails ? Object.keys(this.user.parse.emails) : [];
    }
    hasPhoneNumber() {
        return this.user.parse.phoneNumber;
    }
    setPhoneSignUpMethod() {
        this.signUpMethod = {
            type: 'Phone',
            value: this.user.parse.phoneNumber,
        };
    }
    hasEmails() {
        return this.user.parse.emails;
    }
    setProviderSignUpMethod() {
        const emailProviders = this.getEmailProviders();
        this.signUpMethod = {
            type: this.getProviderFromIdentifier(emailProviders[0]),
            value: this.user.parse.emails[emailProviders[0]],
        };
    }
    // Returns sign up method, capitalizing the first letter
    getProviderFromIdentifier(identifier) {
        let signUpMethod = identifier.replace('.com', ''); // Remove top level domain
        if (signUpMethod === 'password') {
            signUpMethod = 'email';
        }
        return signUpMethod.charAt(0).toUpperCase() + signUpMethod.slice(1);
    }
    getIdentifyTraits() {
        const { email, parse: { avaName, emails, userName, phoneNumber }, } = this.user;
        const emailProviders = this.getEmailProviders();
        const identifyTraits = {
            'Created From': this.getPlatform(),
            Signed_up_at: new Date(),
            name: userName,
            'Ava Name': avaName,
            email,
            phone: phoneNumber,
            'Sign Up Method': this.signUpMethod.type,
            referrer: undefined,
            referring_domain: undefined,
            sign_in_from_desktop_app: window.isElectron,
        };
        emailProviders.forEach((identifier) => {
            const provider = this.getProviderFromIdentifier(identifier);
            identifyTraits[`${provider} Auth`] = emails[identifier];
        });
        if (this.user.parse.phoneNuumber) {
            identifyTraits['Phone Auth'] = phoneNumber;
        }
        // If there are utm parameters, time to add them
        if (window.utm_parameters) {
            Object.keys(window.utm_parameters).forEach((utmKey) => {
                const identifyUtmKey = `utm_${utmKey === 'name' ? 'campaign' : utmKey}`;
                identifyTraits[identifyUtmKey] = window.utm_parameters[utmKey];
            });
        }
        if (document.referrer) {
            identifyTraits.referrer = document.referrer;
            const temp = document.createElement('a');
            temp.href = document.referrer;
            identifyTraits.referring_domain = temp.hostname;
        }
        return identifyTraits;
    }
    getSignedUpProperties() {
        const { email, parse: { avaName, phoneNumber }, } = this.user;
        return {
            'Created From': this.getPlatform(),
            'Ava Name': avaName,
            email,
            phone: phoneNumber,
            [`${this.signUpMethod.type} Auth`]: this.signUpMethod.value,
        };
    }
    setParseIdToLocalStorage() {
        window.localStorage.setItem('parseId', this.user.parse.objectId);
    }
    setAvaIdToLocalStorage() {
        window.localStorage.setItem('avaId', this.user.parse.avaId);
    }
    hasHadFirstConvo() {
        return this.user.convoMetrics.total_conversations > 0;
    }
}
export default ParseUser;
