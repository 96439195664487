import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getOS, isMobile } from '../../utils';
import MobileDownloadButton from './MobileDownloadButton';
const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    heading: {
        marginBottom: 20,
    },
    button: {
        width: '100%',
        minWidth: 210,
        backgroundColor: theme.palette.ava.blue,
        color: theme.palette.ava.white,
        display: 'flex',
        flexDirection: 'column',
    },
    buttonContained: {
        '&:hover': {
            backgroundColor: theme.palette.ava.blue,
        },
    },
    buttonMainText: {
        fontSize: 20,
        fontWeight: 600,
    },
    buttonSubText: {
        marginTop: -10,
        fontSize: 16,
        opacity: 0.8,
    },
}));
const RatingExcellent = ({ userExists, hostName, onClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const OS = getOS();
    const mobile = isMobile();
    return (React.createElement("div", { className: classes.content },
        userExists && !mobile && !window.isElectron && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { className: classes.heading, variant: "h5" }, t('conversation.over.doYouWantSomeMore')),
            React.createElement(Button, { key: "download_convo_btn", classes: {
                    root: classes.button,
                    contained: classes.buttonContained,
                }, variant: "contained", onClick: onClick },
                React.createElement("span", { className: classes.buttonMainText }, t('conversation.over.openAva', { OS })),
                React.createElement("span", { className: classes.buttonSubText }, t('conversation.over.forAMagicalExperience'))))),
        userExists && mobile && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { className: classes.heading, variant: "h5" }, t('conversation.over.doYouWantSomeMore')),
            isMobile() ? (React.createElement(MobileDownloadButton, null)) : (React.createElement(Button, { key: "download_convo_btn", classes: {
                    root: classes.button,
                    contained: classes.buttonContained,
                }, variant: "contained", onClick: onClick },
                React.createElement("span", { className: classes.buttonMainText }, t('conversation.over.getAva')),
                React.createElement("span", { className: classes.buttonSubText }, t('conversation.over.forAMagicalExperience')))))),
        !userExists && mobile && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { className: classes.heading, variant: "h5" },
                t('conversation.over.planOnSpeakWith'),
                " ",
                hostName,
                " ",
                t('conversation.over.again?')),
            isMobile() ? (React.createElement(MobileDownloadButton, null)) : (React.createElement(Button, { key: "download_convo_btn", classes: {
                    root: classes.button,
                    contained: classes.buttonContained,
                }, variant: "contained", onClick: onClick },
                React.createElement("span", { className: classes.buttonMainText }, t('conversation.over.downloadAvaApp')),
                React.createElement("span", { className: classes.buttonSubText }, t('conversation.over.forAMagicalExperience')))))),
        !userExists && !mobile && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { className: classes.heading, variant: "h5" },
                t('conversation.over.planOnSpeakWith'),
                " ",
                hostName,
                " ",
                t('conversation.over.again?')),
            React.createElement(Button, { key: "download_convo_btn", classes: {
                    root: classes.button,
                    contained: classes.buttonContained,
                }, variant: "contained", onClick: () => {
                    setTimeout(() => navigate('/web/login'), 300);
                } },
                React.createElement("span", { className: classes.buttonMainText }, t('conversation.over.transcribeYourOwnMeetings')))))));
};
export default RatingExcellent;
