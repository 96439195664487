/* eslint-disable no-shadow */
import React from 'react';
import MicSelector from '../../modules/scribe/MicSelector';
import { selectConversationMuted } from '../../selectors/combined';
import { selectInTwilioCalls, selectIsInConversation } from '../../selectors/conversation';
import { selectIsScribing } from '../../selectors/scribe';
import { useAppSelector } from '../../store/store';
function CaptionsSettings() {
    const scribing = useAppSelector(selectIsScribing);
    const isInConversation = useAppSelector(selectIsInConversation);
    const inTwilioCalls = useAppSelector(selectInTwilioCalls);
    const muted = useAppSelector(selectConversationMuted);
    const showComponent = !muted && !scribing && isInConversation && !inTwilioCalls;
    return (React.createElement("div", { style: { display: showComponent ? 'inherit' : 'none' } },
        React.createElement(MicSelector, null)));
}
export default CaptionsSettings;
