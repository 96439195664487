import { makeStyles } from '@mui/styles';
import React from 'react';
import CheckmarkIcon from './Icons/Checkmark';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
    },
    text: {
        marginRight: 12,
        color: theme.palette.ava.states.online,
        fontSize: 18,
        lineHeight: '23px',
    },
    icon: {
        marginTop: 4,
    },
}));
const ValidatedMessage = ({ message }) => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.container },
        React.createElement("div", { className: classes.text }, message),
        React.createElement("div", { className: classes.icon },
            React.createElement(CheckmarkIcon, { width: 20, height: 20 }))));
};
export default ValidatedMessage;
