import { connect } from 'react-redux';
import { addNotification } from '../../../store/slices/ccMode';
import InviteCard from './InviteCard';
const mapStateToProps = ({ scribeConversation: { status } }) => ({
    status,
});
const mapDispatchToProps = {
    addNotification,
};
export default connect(mapStateToProps, mapDispatchToProps)(InviteCard);
