import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectKeyboardInputShown } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import { ApplessControlBar } from './ApplessControlBar';
import { ApplessHeader } from './ApplessHeader';
import { ApplessKeyboardInput } from './ApplessKeyboardInput';
import { ApplessTranscriptList } from './ApplessTranscriptList';
export function ApplessConversationPage() {
    const isInConversation = useAppSelector(selectIsInConversation);
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const theme = useTheme();
    if (!isInConversation) {
        return null;
    }
    return (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(var(--vh, 1vh) * 100)',
            width: '100%',
            overflow: 'hidden',
            backgroundColor: theme.palette.grey[100],
        } },
        React.createElement(ApplessHeader, null),
        React.createElement(ApplessTranscriptList, null),
        React.createElement(ApplessControlBar, { open: !keyboardInputShown }),
        React.createElement(ApplessKeyboardInput, { open: keyboardInputShown })));
}
