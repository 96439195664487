import SendIcon from '@mui/icons-material/Send';
import Fab from '@mui/material/Fab';
import Input from '@mui/material/Input';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import TtsIcon from '../../../assets/icons/tts.svg';
import { scribeCreateTranscript, scribeDeselectTranscriptWord } from '../../actions';
import { selectIsRecording } from '../../selectors/audioRecorder';
import { selectFontSize, selectKeyboardInputShown } from '../../selectors/ui';
import { selectAvaId } from '../../selectors/userProfile';
import { setRecording } from '../../store/slices/audioRecorder';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { attemptSpeakWithVoice } from '../../utils/textToSpeech';
const useStyles = makeStyles((theme) => ({
    input: {
        position: 'absolute',
        bottom: 30,
        right: 160,
        width: 'calc(100% - 180px)',
        fontSize: (props) => props.fontSize,
    },
    fab: {
        position: 'absolute',
        bottom: 30,
        color: theme.palette.ava.white,
        zIndex: 101,
    },
}));
const KeyboardInput = ({ value, onChange, ttsBestVoice, scribeCreateTranscript, scribeDeselectTranscriptWord, }) => {
    const fontSize = useAppSelector(selectFontSize);
    const classes = useStyles({ fontSize });
    const theme = useTheme();
    const { t } = useTranslation();
    const avaId = useAppSelector(selectAvaId);
    const recording = useAppSelector(selectIsRecording);
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const dispatch = useAppDispatch();
    const createNewTranscript = () => {
        onChange('');
        scribeDeselectTranscriptWord();
        scribeCreateTranscript(undefined, value, avaId);
        scribeDeselectTranscriptWord();
    };
    const onSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        createNewTranscript();
    };
    const onInputChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        scribeDeselectTranscriptWord();
        onChange(event.target.value);
    };
    const onKeyDown = (event) => {
        if (['Enter'].includes(event.key))
            onSubmit(event);
    };
    const onSynthesizeSpeech = (event) => {
        event.preventDefault();
        event.stopPropagation();
        createNewTranscript();
        attemptSpeakWithVoice({
            message: value,
            recording,
            startRecord: () => dispatch(setRecording(true)),
            stopRecord: () => dispatch(setRecording(false)),
            voice: ttsBestVoice,
        });
    };
    return (React.createElement(React.Fragment, null,
        keyboardInputShown && (React.createElement("form", { onSubmit: onSubmit },
            React.createElement(Slide, { direction: "left", in: true, mountOnEnter: true, unmountOnExit: true },
                React.createElement(Input, { classes: { root: classes.input }, onChange: onInputChange, value: value, "data-qa": "ttsInputField", placeholder: t('conversation.typeTranscriptHere'), onKeyDown: onKeyDown, autoFocus: true })))),
        keyboardInputShown && value && ttsBestVoice && (React.createElement(Fab, { classes: { root: classes.fab }, "data-qa": "voiceTTS", onClick: onSynthesizeSpeech, style: {
                right: 90,
                backgroundColor: theme.palette.ava.white,
            } },
            React.createElement("img", { width: "30", src: TtsIcon, alt: "Synthesize text" }))),
        keyboardInputShown && value && (React.createElement(Fab, { classes: { root: classes.fab }, "data-qa": "sendTTS", onClick: createNewTranscript, style: {
                right: 20,
                backgroundColor: theme.palette.ava.blue,
            } },
            React.createElement(SendIcon, null)))));
};
const mapDispatchToProps = {
    scribeCreateTranscript,
    scribeDeselectTranscriptWord,
};
export default connect(({}) => ({}), mapDispatchToProps)(KeyboardInput);
