import { createSelector } from '@reduxjs/toolkit';
const selectAudioRecorder = (state) => state.audioRecorder;
export const selectIsRecording = createSelector([selectAudioRecorder], (audio) => audio.recording);
export const selectAvailableMics = createSelector([selectAudioRecorder], (audio) => audio.availableMics);
export const selectAudioParamsSent = createSelector([selectAudioRecorder], (audio) => audio.audioParamsSent);
export const selectOnlyInternalAudio = createSelector([selectAudioRecorder], (audio) => audio.onlyInternalAudio);
export const selectMicIdSelected = createSelector([selectAudioRecorder], (audio) => audio.micIdSelected);
export const selectAudioRecordingContext = createSelector([selectAudioRecorder], (audio) => audio.recordingContext);
export const selectHasMicrophoneRestarted = createSelector([selectAudioRecorder], (audio) => audio.hasMicrophoneRestarted);
export const selectIsRestartingMicrophone = createSelector([selectAudioRecorder], (audio) => audio.isRestartingMicrophone);
export const selectNeedInternalAudioAccess = createSelector([selectAudioRecorder], (audio) => audio.needInternalAudioAccess);
export const selectMicrophoneAccess = createSelector([selectAudioRecorder], (audio) => audio.microphoneAccess);
export const selectAllMicsSelected = createSelector([selectAudioRecorder], (audio) => audio.allMicsSelected);
