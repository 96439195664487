import { connect } from 'react-redux';
import { dispatchSpeakerChangeMutation, insertSpeakerName, mergeTranscript, scribeCreateTranscript, scribeDeselectTranscriptWord, scribeSelectTranscriptWord, splitTranscript, } from '../../actions';
import ScribeKeypressHandler from './ScribeKeypressHandler';
const mapStateToProps = ({ scribeConversation: { status, transcriptsCurrent, ui: { selectedTranscriptIndex, selectedWordIndex, selectedWordInputValue }, }, }) => ({
    selectedTranscriptIndex,
    selectedWordIndex,
    selectedWordInputValue,
    status,
    transcriptsCurrent,
});
export default connect(mapStateToProps, {
    dispatchSpeakerChangeMutation,
    insertSpeakerName,
    mergeTranscript,
    scribeCreateTranscript,
    scribeDeselectTranscriptWord,
    scribeSelectTranscriptWord,
    splitTranscript,
})(ScribeKeypressHandler);
