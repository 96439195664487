import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
const useStyles = makeStyles({
    rotated: {
        transform: 'rotate(180deg)',
    },
});
const ArrowDownIcon = ({ className, rotated }) => {
    const classes = useStyles();
    return React.createElement(KeyboardArrowDownIcon, { className: classnames(className, { [classes.rotated]: rotated }) });
};
export default ArrowDownIcon;
