import React from 'react';
const GoogleMeetIcon = () => {
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_4941_311175)" },
            React.createElement("path", { d: "M13.4453 11.9842L15.5901 14.4177L18.4743 16.2472L18.976 11.9995L18.4743 7.84766L15.5348 9.45477L13.4453 11.9842Z", fill: "#00832D" }),
            React.createElement("path", { d: "M1 15.8511V19.4707C1 20.2972 1.67581 20.9682 2.50855 20.9682H6.15488L6.90992 18.2333L6.15488 15.8511L3.65323 15.1016L1.00009 15.8511H1Z", fill: "#0066DA" }),
            React.createElement("path", { d: "M6.15488 3L1 8.11712L3.65341 8.86484L6.15488 8.11712L6.89617 5.76731L6.15488 3Z", fill: "#E94235" }),
            React.createElement("path", { d: "M1 15.8543H6.15479V8.11719H1V15.8543Z", fill: "#2684FC" }),
            React.createElement("path", { d: "M21.7676 5.16781L18.4746 7.84956V16.249L21.7812 18.9411C22.2762 19.3261 23.0003 18.9753 23.0003 18.3506V5.74637C23.0003 5.11484 22.259 4.76576 21.7676 5.1679", fill: "#00AC47" }),
            React.createElement("path", { d: "M13.4451 11.9844V15.8521H6.1543V20.9692H16.9656C17.7983 20.9692 18.474 20.2982 18.474 19.4717V16.2474L13.4451 11.9844Z", fill: "#00AC47" }),
            React.createElement("path", { d: "M16.9656 3H6.1543V8.11712H13.4451V11.9848L18.4741 7.84823V4.49758C18.4741 3.67095 17.7983 3.00009 16.9656 3.00009", fill: "#FFBA00" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_4941_311175" },
                React.createElement("rect", { width: "22", height: "18", fill: "white", transform: "translate(1 3)" })))));
};
export default GoogleMeetIcon;
