import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSelectedCaptions } from '../../selectors/conversation';
import RatingSelector from './RatingSelector';
const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    heading: {
        fontSize: 24,
        fontWeight: 400,
        lineHeight: '31px',
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
    },
    text: {
        fontSize: 20,
        lineHeight: '26px',
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
        },
    },
    ratingContainer: {
        margin: '0 auto',
    },
    ratingText: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    terribleText: {
        color: theme.palette.ava.red2,
    },
    amazingText: {
        color: theme.palette.ava.scribe2,
    },
}));
const RatingContent = ({ rate, rateHover, onRateChange, onRateHover }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const selectedCaptions = useSelector(selectSelectedCaptions);
    const getRatingHeading = () => {
        if (selectedCaptions === 'free') {
            return t('rateConversation.heading.free');
        }
        if (selectedCaptions === 'premium') {
            return t('rateConversation.heading.premium');
        }
        return t('rateConversation.heading.scribe');
    };
    return (React.createElement("div", { className: classes.content },
        React.createElement("h5", { className: classes.heading }, getRatingHeading()),
        React.createElement("p", { className: classes.text }, t('rateConversation.howDidAvaWorkForYou')),
        React.createElement("div", { className: classes.ratingContainer },
            React.createElement(RatingSelector, { initialRating: rate, onChange: onRateChange, onHover: onRateHover, rating: rateHover || rate || 0, readonly: !!rate }),
            React.createElement("div", { className: classnames(classes.text, classes.ratingText) },
                React.createElement("span", { className: classes.terribleText }, t('rateConversation.terrible')),
                React.createElement("span", { className: classes.amazingText }, t('rateConversation.amazing'))))));
};
export default RatingContent;
