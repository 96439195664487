import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles({
    paper: {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        right: 0,
    },
});
const JoinConversationMenu = ({ children, innerProps, options, selectProps }) => {
    const classes = useStyles();
    if (!options.length)
        return null;
    return (React.createElement(Paper, Object.assign({ square: true, className: classes.paper, style: { marginTop: selectProps.header ? 90 : 220 } }, innerProps), children));
};
export default JoinConversationMenu;
