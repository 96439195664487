var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Sentry from '@sentry/react';
import { authInstance, getFirestore } from '../../firebase';
import { users } from '../../services/api/ava';
import { scribeSetFontSize } from '../../store/slices/uiState';
import { throttle } from '../../utils/lodash';
export function fetchScribeSettings() {
    return (dispatch, getState) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const { userProfile: { parse }, } = getState();
        if (!(parse === null || parse === void 0 ? void 0 : parse.avaId)) {
            throw new Error('No "avaId" found in "user" object');
        }
        try {
            const docRef = (yield getFirestore()).collection('users').doc(parse.avaId);
            const doc = yield docRef.get();
            const settings = (_a = doc.data()) === null || _a === void 0 ? void 0 : _a.settings;
            if (settings && settings.webFontSize) {
                dispatch(scribeSetFontSize(settings.webFontSize));
            }
        }
        catch (e) {
            Sentry.captureException(e, {
                tags: {
                    category: 'firebase',
                },
            });
        }
    });
}
const updateFontSize = throttle((avaId, uid, fontSize) => users.updateSettings({
    avaId,
    firebaseAuthUID: uid,
    settings: {
        webFontSize: fontSize,
    },
}), 2000);
export const scribeSetFontSizeAndSave = (fontSize) => (dispatch, getState) => {
    const { userProfile: { parse }, } = getState();
    if (authInstance.currentUser) {
        updateFontSize(parse === null || parse === void 0 ? void 0 : parse.avaId, authInstance.currentUser.uid, fontSize);
        return dispatch(scribeSetFontSize(fontSize));
    }
};
