import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React from 'react';
import PhraseMarkdownText from '../PhraseMarkdownText';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minWidth: 210,
        backgroundColor: theme.palette.ava.blue,
        color: theme.palette.ava.white,
    },
    contained: {
        '&:hover': {
            background: theme.palette.ava.blue,
        },
    },
    label: {
        display: 'flex',
        flexDirection: 'column',
    },
    mainText: {
        fontSize: 20,
        fontWeight: 600,
    },
    subText: {
        marginTop: -10,
        fontSize: 16,
        opacity: 0.8,
    },
}));
const MobileDownloadButton = () => {
    const classes = useStyles();
    return (React.createElement("a", { href: "https://ava.app.link/download", target: "_blank" },
        React.createElement(Button, { classes: {
                root: classes.root,
                contained: classes.contained,
                label: classes.label,
            }, variant: "contained" },
            React.createElement(PhraseMarkdownText, { className: classes.mainText, phraseKey: "conversation.over.downloadAvaApp" }),
            React.createElement(PhraseMarkdownText, { className: classes.subText, phraseKey: "conversation.over.forAMagicalExperience" }))));
};
export default MobileDownloadButton;
