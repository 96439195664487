import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createNewConversation } from '../../actions';
import ConferenceCallPopup from './ConferenceCallPopup';
const mapStateToProps = ({ scribeConversation: { status, speechLang } }) => ({
    status,
    speechLang,
});
export default withTranslation()(withTheme(withSnackbar(connect(mapStateToProps, { createNewConversation })(ConferenceCallPopup))));
