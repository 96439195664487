import React from 'react';
const Close = ({ color, width, height }) => {
    return (React.createElement("svg", { width: width || '64', height: height || '64', viewBox: "0 0 64 64", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { filter: "url(#filter0_d)  " },
            React.createElement("path", { d: "M32 20C25.3676 20 20 25.367 20 32C20 38.6325 25.367 44 32 44C38.6324 44 44 38.633 44 32C44 25.3675 38.633 20 32 20ZM32 42.125C26.4039 42.125 21.875 37.5965 21.875 32C21.875 26.4038 26.4035 21.875 32 21.875C37.5961 21.875 42.125 26.4035 42.125 32C42.125 37.5962 37.5965 42.125 32 42.125Z", fill: color }),
            React.createElement("path", { d: "M36.1054 34.7801L33.3257 32.0003L36.1054 29.2205C36.4715 28.8544 36.4716 28.2609 36.1055 27.8947C35.7393 27.5286 35.1457 27.5286 34.7797 27.8947L31.9998 30.6745L29.22 27.8947C28.854 27.5286 28.2603 27.5286 27.8942 27.8947C27.5281 28.2609 27.5281 28.8544 27.8943 29.2205L30.674 32.0003L27.8943 34.7801C27.5281 35.1462 27.5281 35.7398 27.8942 36.1059C28.2604 36.4721 28.854 36.472 29.22 36.1059L31.9998 33.3261L34.7797 36.1059C35.1457 36.472 35.7393 36.472 36.1055 36.1059C36.4716 35.7398 36.4716 35.1462 36.1054 34.7801Z", fill: color })),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d", x: "-4", y: "0", width: "72", height: "72", filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB" },
                React.createElement("feFlood", { floodOpacity: "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "12" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.439216 0 0 0 0 0.564706 0 0 0 0 0.690196 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow", result: "shape" })))));
};
export default Close;
