import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import bowser from 'bowser';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import makeComponentTrashable from 'trashable-react';
import Transition from '../../components/Transition';
import { selectFirebaseUser } from '../../selectors/auth';
import { reports as avaReports } from '../../services/api/ava';
import { reports as scribeReports } from '../../services/api/scribe';
import { useAppSelector } from '../../store/store';
import { getAvaBackendEndpoint } from '../../utils/http';
import { getSnack } from '../../utils/snackbar';
const labels = {
    gt: 'Given That',
    wh: 'What Happened',
    wshh: 'What Should Have Happened',
};
const allLabels = ['gt', 'wh', 'wshh'];
const ScribeReportModal = (props) => {
    const { close, conversationId, old, open, registerPromise, status } = props;
    const [description, setDescription] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagChecked, setTagChecked] = useState({});
    const [gt, setGt] = useState('');
    const [wh, setWh] = useState('');
    const [wshh, setWshh] = useState('');
    const [title, setTitle] = useState('');
    const [priority, setPriority] = useState('p3');
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const { displayName, email, idToken, uid } = firebaseUser;
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const submit = () => {
        const browser = bowser.getParser(window.navigator.userAgent);
        const userTechData = browser.parse();
        const parsedTechData = userTechData.getResult();
        const payload = {
            name: displayName,
            email,
            idToken,
            description,
            conversationId,
            tags: tagChecked,
            roomId: (status || {}).id || '-',
            hostAvaName: ((status || {}).host || {}).avaName,
            data: {
                currentFrontUrl: window.location.href,
                currentBackUrl: localStorage.getItem('scribeBackendUrl'),
                stack: parsedTechData,
                release: process.env.HASH_COMMIT,
            },
            asanaMisc: Object.assign(Object.assign({ reporterEmail: email, reporterName: displayName, aiConversationId: (status || {}).conversationId, commitHash: process.env.HASH_COMMIT, release: process.env.TAG_COMMIT, currentFrontUrl: window.location.href, currentBackUrl: getAvaBackendEndpoint() }, Object.keys(parsedTechData).reduce((acc, key) => {
                Object.keys(parsedTechData[key]).forEach((subkey) => {
                    acc[`${key}.${subkey}`] = parsedTechData[key][subkey];
                });
                return acc;
            }, {})), { roomId: (status || {}).id }),
            wh,
            wshh,
            gt,
            title,
            priority,
            reduxState: (window.store || { getState: () => 'unknown' }).getState(),
        };
        setSubmitting(true);
        const reportsPromise = old ? scribeReports.saveReport : avaReports.saveReport;
        reportsPromise({ report: payload, firebaseAuthUID: uid })
            .then(() => {
            close();
            getSnack({ enqueueSnackbar, closeSnackbar })('Report was submitted, thanks!');
            setSubmitting(false);
            setTagChecked({});
        })
            .catch(() => {
            getSnack({ enqueueSnackbar, closeSnackbar })('Failed to submit report');
            setSubmitting(false);
            setTagChecked({});
        });
    };
    const handleChangeCheckbox = (name) => (event) => {
        const tagCheckedArray = tagChecked;
        tagCheckedArray[name] = event.target.checked;
        setTagChecked(tagCheckedArray);
    };
    const handleDescription = (event) => {
        setDescription(event.target.value);
    };
    const handleChange = (t) => (event) => {
        const value = event.target.value;
        switch (t) {
            case 'gt':
                setGt(value);
                break;
            case 'wh':
                setWh(value);
                break;
            case 'wshh':
                setWshh(value);
                break;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { TransitionComponent: Transition, open: open, onClose: close, "aria-labelledby": "form-dialog-title" },
            React.createElement(DialogTitle, { id: "form-dialog-title" }, "Ava Scribe Bug Report"),
            React.createElement(DialogContent, null,
                old && (React.createElement(React.Fragment, null,
                    React.createElement(FormGroup, { row: true }, tags.map((tag) => (React.createElement(FormControlLabel, { key: tag.id, control: React.createElement(Checkbox, { checked: !!tagChecked[tag.id], onChange: handleChangeCheckbox(tag.id), value: tag.id }), label: tag.text })))),
                    React.createElement("textarea", { value: description, style: { width: '100%', borderRadius: 5 }, rows: 5, disabled: submitting, onChange: handleDescription }))),
                !old && (React.createElement("div", { style: {
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px 0px',
                        minWidth: 500,
                    } },
                    React.createElement(TextField, { label: "Bug title", variant: "outlined", style: { margin: 5 }, value: title, onChange: (e) => setTitle(e.target.value) }),
                    React.createElement(FormControl, { variant: "outlined", style: { margin: 5 } },
                        React.createElement(InputLabel, { id: "priority" }, "Priority"),
                        React.createElement(Select, { variant: "standard", labelId: "priority", id: "priority", value: priority, onChange: (e) => setPriority(e.target.value), label: "Priority" },
                            React.createElement(MenuItem, { value: "p0" }, "P0"),
                            React.createElement(MenuItem, { value: "p1" }, "P1"),
                            React.createElement(MenuItem, { value: "p2" }, "P2"),
                            React.createElement(MenuItem, { value: "p3" }, "P3"))),
                    allLabels.map((t) => (React.createElement(TextField, { id: "outlined-multiline-static", label: labels[t], multiline: true, key: t, minRows: "4", defaultValue: "", variant: "outlined", style: { margin: 5 }, onChange: handleChange(t) })))))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: "text", onClick: close }, "Cancel"),
                React.createElement(Button, { disabled: submitting, variant: "contained", onClick: submit, color: "primary" },
                    "Report",
                    submitting && React.createElement(CircularProgress, { size: "18px", style: { position: 'absolute' } }))))));
};
export default makeComponentTrashable(ScribeReportModal);
