var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import DesktopAppCaptionsModal from '../modules/scribe/DesktopAppCaptionsModal';
import { users } from '../services/api/saas';
import { isMobile } from '../utils';
import { openNewWindowAndCloseOnFocus } from '../utils/domUtils';
export default () => {
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    useEffect(() => {
        // If we're already in app, do nothing
        if (window.isElectron) {
            return;
        }
        if (isMobile()) {
            // On mobile, immediately
            // let branch do the work
            window.open('https://ava.app.link/download');
        }
        else {
            // On desktop web try to open the the app, and show the modal in case
            // the link does not open for the user.
            const asyncInner = () => __awaiter(void 0, void 0, void 0, function* () {
                const urlWithToken = yield users.getCustomTokenURL();
                openNewWindowAndCloseOnFocus(urlWithToken);
                setShowDownloadModal(true);
            });
            asyncInner();
        }
    }, [isMobile]);
    return showDownloadModal ? React.createElement(DesktopAppCaptionsModal, { onClose: () => setShowDownloadModal(false) }) : null;
};
