import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import RoundedButton from './RoundedButton';
const useStyles = makeStyles({
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minWidth: 72,
        height: 56,
        marginLeft: 8,
        marginRight: 8,
        fontSize: 16,
        fontWeight: 400,
    },
});
const SidebarButton = ({ children, className, color, icon, onClick, sidebarOpen }) => {
    const classes = useStyles();
    const [textVisible, setTextVisible] = useState(true);
    const [centerContent, setCenterContent] = useState(false);
    useEffect(() => {
        if (sidebarOpen) {
            setCenterContent(false);
            setTimeout(() => setTextVisible(true), 200);
        }
        else {
            setTextVisible(false);
            setTimeout(() => setCenterContent(true), 180);
        }
    }, [sidebarOpen]);
    return (React.createElement(RoundedButton, { className: classnames(classes.button, className), style: { paddingLeft: centerContent ? 8 : 20 }, color: color, centered: centerContent, onClick: onClick, disableRipple: true },
        icon,
        textVisible && children));
};
export default SidebarButton;
