import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Collapse from '@mui/material/Collapse/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { withStyles, withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Toggle from 'react-toggled';
import translationLangs from '../../../assets/translation_langs.json';
import { switchLang } from '../../actions/conversation';
import { selectAutoTranslateActive } from '../../selectors/avaTranslate';
import { selectLang } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
import { getLanguageNativeName } from '../../utils/AvaTranslate';
import { canShowFlagEmoji } from '../../utils/platform';
import { EmptyIcon, styles } from './SidebarHelpers';
const TranslationSelector = React.memo(function TranslationSelector(props) {
    const { t, classes } = props;
    const lang = useAppSelector(selectLang);
    const autoTranslateActive = useAppSelector(selectAutoTranslateActive);
    const dispatch = useDispatch();
    const languageChange = (languageCode) => {
        dispatch(switchLang(languageCode));
    };
    const currentLangObject = translationLangs.find((lang_) => lang_.languageCode === lang);
    const currentFlagCode = currentLangObject && currentLangObject.flagCode;
    if (autoTranslateActive) {
        return null;
    }
    return (React.createElement(Toggle, null, ({ on, getTogglerProps, toggle }) => (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column',
        } },
        React.createElement("div", null,
            React.createElement(ListItem, Object.assign({ button: true }, getTogglerProps()),
                React.createElement(ListItemIcon, null,
                    React.createElement(LanguageOutlinedIcon, null)),
                React.createElement(ListItemText, { classes: { root: classes.listItemText } },
                    t('sidebar.switchLang'),
                    React.createElement("br", null),
                    React.createElement("b", null,
                        currentFlagCode && (React.createElement(ReactCountryFlag, { style: { marginRight: 5 }, svg: !canShowFlagEmoji(), countryCode: currentFlagCode })),
                        lang === '~'
                            ? `\uD83C\uDF0D \u00A0${t('sidebar.chooseLanguage')}`
                            : getLanguageNativeName(lang))),
                React.createElement(ListItemIcon, { classes: { root: classes.expandIcon } }, on ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null))),
            React.createElement(Collapse, { in: on },
                React.createElement(List, null,
                    lang !== '~' && (React.createElement(ListItem, { key: "~", button: true },
                        React.createElement(EmptyIcon, null),
                        React.createElement(ListItemText, { classes: { root: classes.listItemText }, onClick: () => {
                                toggle();
                                languageChange('~');
                            } },
                            React.createElement("span", { role: "img", "aria-label": "cancel" }, "\u274C"),
                            ' ',
                            t('sidebar.stopTranslation')))),
                    translationLangs.map(({ languageCode, flagCode }) => (React.createElement(ListItem, { key: languageCode, button: true },
                        React.createElement(EmptyIcon, null),
                        React.createElement(ListItemText, { classes: { root: classes.listItemText }, onClick: () => {
                                toggle();
                                languageChange(languageCode);
                            } },
                            React.createElement(ReactCountryFlag, { style: { marginRight: 5 }, svg: !canShowFlagEmoji(), countryCode: flagCode }),
                            getLanguageNativeName(languageCode))))))))))));
});
export default withStyles(styles)(withTheme(withSnackbar(withTranslation()(TranslationSelector))));
