import { Link, Menu, MenuItem, Typography } from '@mui/material';
import { withStyles, withTheme } from '@mui/styles';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AVA_URL } from '../constants';
import { selectDisplayDev } from '../selectors/v1Session';
import { getLanguageCode, langs, langsDisplay } from '../utils/i18n';
const styles = (theme) => ({
    footer: {
        backgroundColor: theme.palette.grey[100],
        marginTop: 'auto',
    },
    button: {
        '&:focus': {
            outline: 'none',
        },
        '&:hover p': {
            textDecoration: 'underline',
        },
        '& p': {
            color: theme.palette.ava.grey,
            fontSize: '0.875rem',
        },
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
        border: 'none',
        color: theme.palette.primary.main,
        display: 'flex',
        padding: '0 20px',
        WebkitAppearance: 'none',
    },
    listLink: {
        [theme.breakpoints.down('md')]: {
            marginRight: 10,
            marginLeft: 10,
        },
        color: theme.palette.ava.grey,
        textDecoration: 'none',
        marginRight: 20,
        marginLeft: 20,
    },
});
// eslint-disable-next-line
function Footer(props) {
    const { classes, t, theme, i18n } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const displayDev = useSelector(selectDisplayDev);
    const location = useLocation();
    function updateLanguage(lang) {
        i18n.changeLanguage(lang, () => {
            window.document.title = t('title');
        });
        setAnchorEl(null);
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const getAvaLanguageUrl = () => {
        const languageCode = getLanguageCode();
        if (languageCode !== 'en') {
            return `${AVA_URL}/${languageCode}`;
        }
        return AVA_URL;
    };
    return (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            padding: '12px 20px',
            position: 'fixed',
            bottom: 0,
            width: '100%',
            backgroundColor: theme.palette.background[theme.palette.mode],
        } },
        location.pathname !== '/web/login' && (React.createElement(React.Fragment, null,
            React.createElement(Link, { className: classes.link, target: "_blank", href: `${getAvaLanguageUrl()}/terms`, "data-qa": "termsLink", underline: "hover" },
                React.createElement(Typography, { variant: "subtitle2", color: "primary", className: classes.listLink }, t('footer.Terms'))),
            React.createElement(Link, { className: classes.link, target: "_blank", href: `${getAvaLanguageUrl()}/privacy`, "data-qa": "privacyLink", underline: "hover" },
                React.createElement(Typography, { variant: "subtitle2", color: "primary", className: classes.listLink }, t('footer.Privacy'))))),
        React.createElement(Link, { className: classes.link, target: "_blank", href: `https://intercom.help/ava/${getLanguageCode()}`, "data-qa": "faqLink", underline: "hover" },
            React.createElement(Typography, { variant: "subtitle2", color: "primary", className: classes.listLink }, t('footer.FAQ'))),
        React.createElement("button", { onClick: handleClick, className: classes.button, type: "submit", "data-qa": "languageButton" },
            React.createElement(Typography, { color: "primary", "aria-owns": anchorEl ? 'simple-menu' : undefined, "aria-haspopup": "true" }, t('footer.language'))),
        React.createElement(Menu, { id: "simple-menu", anchorEl: anchorEl, open: Boolean(anchorEl) }, langs.map((lang) => (React.createElement(MenuItem, { color: "primary", key: lang, onClick: () => updateLanguage(lang), selected: i18n.language === lang }, langsDisplay[lang])))),
        displayDev && (React.createElement(React.Fragment, null,
            React.createElement(Typography, { color: "primary", variant: "subtitle2", className: classes.listLink }, String(process.env.TAG_COMMIT))))));
}
export default withTranslation()(withStyles(styles)(withTheme(Footer)));
