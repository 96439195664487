import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import RaisedContainer from '../../Containers/RaisedContainer';
const useStyles = makeStyles((theme) => ({
    workspaceRaisedContainer: {
        marginTop: 16,
        padding: '24px 40px',
        boxShadow: theme.palette.mode === 'light'
            ? '2px 2px 3px rgba(112, 144, 176, 0.25)'
            : '2px 2px 3px rgba(112, 144, 176, 0.04)',
    },
    divider: {
        margin: '16px 0',
    },
    iconWrapper: {
        border: `1px solid ${theme.palette.ava.blue2}`,
        borderRadius: '100%',
        height: 32,
        width: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > svg': {
            fill: theme.palette.ava.blue2,
        },
    },
}));
export const WorkspaceRaisedContainer = ({ className, children }) => {
    const classes = useStyles();
    return (React.createElement(RaisedContainer, { className: classNames(classes.workspaceRaisedContainer, className) }, children));
};
export const WorkspaceDivider = ({ className }) => {
    const classes = useStyles();
    return React.createElement(Divider, { className: classNames(classes.divider, className) });
};
export const CircularIconWrapper = ({ children, onClick, }) => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.iconWrapper, onClick: onClick }, children));
};
