import { useTheme } from '@mui/material/styles';
import React from 'react';
const Transcript = ({ active, color }) => {
    const theme = useTheme();
    let fill = theme.palette.mode === 'light' ? 'black' : 'white';
    if (color) {
        fill = color;
    }
    if (active) {
        return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0_3234_35789)" },
                React.createElement("path", { d: "M23.269 2.16617C23.2674 1.91276 23.1653 1.67025 22.985 1.49208C22.8048 1.3139 22.561 1.21467 22.3075 1.21624L18.0002 1.242L23.3497 15.6707L23.269 2.16617Z", fill: "white" }),
                React.createElement("path", { d: "M4.76947 0.780549C4.61359 0.778989 4.4634 0.839578 4.35228 0.948954C4.2412 1.05836 4.17827 1.20755 4.17751 1.36349L4.15987 4.94151L15.4599 0.891623L4.76947 0.780549Z", fill: "white" }),
                React.createElement("path", { d: "M23.08 16.9684L17.4141 1.40157C17.2837 1.04337 16.8878 0.858802 16.5294 0.989052L0.962621 6.6549C0.604414 6.78525 0.419693 7.18135 0.550047 7.53956L6.2159 23.1064C6.27852 23.2785 6.40691 23.4186 6.57283 23.4959C6.7021 23.5562 6.84584 23.5746 6.98442 23.5501C7.02371 23.5432 7.06263 23.5328 7.10061 23.519L22.6674 17.8532C22.8395 17.7906 22.9796 17.6621 23.0569 17.4963C23.1343 17.3303 23.1426 17.1405 23.08 16.9684ZM5.03313 11.7842C4.90278 11.426 5.0875 11.0299 5.44571 10.8996L15.8236 7.12231C16.1822 6.9919 16.578 7.17663 16.7083 7.53484C16.8387 7.89304 16.654 8.28914 16.2958 8.4195L5.91784 12.1968C5.87916 12.2109 5.84001 12.2213 5.80086 12.2282C5.47716 12.2853 5.14943 12.1038 5.03313 11.7842ZM14.725 14.8676L7.80647 17.3858C7.76779 17.3998 7.72864 17.4102 7.68949 17.4171C7.36579 17.4742 7.03806 17.2928 6.92177 16.9732C6.79141 16.615 6.97613 16.2189 7.33434 16.0885L14.2529 13.5704C14.6114 13.4399 15.0072 13.6247 15.1376 13.9829C15.268 14.3411 15.0832 14.7372 14.725 14.8676ZM17.24 11.014L6.86211 14.7913C6.82342 14.8053 6.78428 14.8157 6.74512 14.8227C6.42142 14.8797 6.0937 14.6983 5.9774 14.3787C5.84705 14.0205 6.03177 13.6244 6.38997 13.494L16.7679 9.71678C17.1264 9.58637 17.5222 9.7711 17.6526 10.1293C17.783 10.4876 17.5982 10.8837 17.24 11.014Z", fill: "white" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0_3234_35789" },
                    React.createElement("rect", { width: "24", height: "24", fill: "white" })))));
    }
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_3235_36323)" },
            React.createElement("path", { d: "M20.7892 20.1985L20.7892 3.63262C20.7893 3.25143 20.4803 2.94258 20.099 2.94239L3.53315 2.94239C3.15197 2.94237 2.84291 3.2514 2.84289 3.63259L2.84286 20.1985C2.84287 20.3816 2.9156 20.5572 3.04505 20.6866C3.1459 20.7875 3.2747 20.8539 3.41328 20.8783C3.45257 20.8852 3.49269 20.8888 3.53311 20.8888L20.099 20.8888C20.282 20.8888 20.4576 20.8161 20.5871 20.6866C20.7165 20.5572 20.7892 20.3816 20.7892 20.1985ZM5.60384 9.1546C5.60387 8.77341 5.91292 8.46438 6.29411 8.4644L17.3381 8.46438C17.7196 8.46446 18.0283 8.77342 18.0283 9.15461C18.0283 9.5358 17.7192 9.84484 17.3381 9.84482L6.29409 9.84488C6.25293 9.84486 6.21258 9.84125 6.17343 9.83435C5.84973 9.77727 5.60383 9.49467 5.60384 9.1546ZM13.6567 15.3668L6.2941 15.3669C6.25293 15.3668 6.21259 15.3632 6.17344 15.3563C5.84974 15.2992 5.60384 15.0166 5.60385 14.6766C5.60387 14.2954 5.91293 13.9864 6.29412 13.9864L13.6567 13.9864C14.0382 13.9864 14.3469 14.2954 14.347 14.6766C14.3469 15.0578 14.0379 15.3668 13.6567 15.3668ZM17.338 12.6058L6.29405 12.6058C6.25289 12.6058 6.21255 12.6022 6.17339 12.5953C5.84969 12.5382 5.60379 12.2556 5.60381 11.9156C5.60383 11.5344 5.91288 11.2253 6.29407 11.2254L17.338 11.2253C17.7195 11.2254 18.0283 11.5344 18.0283 11.9156C18.0283 12.2968 17.7192 12.6058 17.338 12.6058Z", fill: fill })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_3235_36323" },
                React.createElement("rect", { width: "24", height: "24", fill: "white" })))));
};
export default Transcript;
