import { setBoostWords } from '../store/slices/boost';
import { isBoostEnabled } from './status';
import { sendAccessBoostWords, sendBoostMessage } from './ws-v1';
export class BoostManager {
    constructor(ws, dispatch, subscription) {
        this.ws = ws;
        this.dispatch = dispatch;
        this.ws.addEventListener('message', (event) => {
            const message = JSON.parse(event.data);
            this.handleMessage(message);
        });
        this.boostEnabled = isBoostEnabled(subscription);
        this.handleMessage = this.handleMessage.bind(this);
        this.loading = false;
    }
    handleMessage(message) {
        if (!this.boostEnabled)
            return;
        switch (message.type) {
            case 'user-boost-words': {
                if (this.loading)
                    this.loading = false;
                this.dispatch(setBoostWords(message.words));
                break;
            }
        }
    }
    handleLoadBoostWords() {
        if (!this.boostEnabled)
            return;
        sendAccessBoostWords(this.ws, 'list');
        this.loading = true;
    }
    handleUserAddOrRemoveBoostWords(action, words) {
        if (!this.boostEnabled)
            return;
        switch (action) {
            case 'add': {
                if (words.length) {
                    sendAccessBoostWords(this.ws, action, words);
                }
                break;
            }
            case 'remove': {
                sendAccessBoostWords(this.ws, action, words);
                break;
            }
        }
    }
    handleScribeAddOrRemoveBoostWords(action, word) {
        switch (action) {
            case 'add': {
                if (word.length) {
                    sendBoostMessage(this.ws, { action, word });
                }
                break;
            }
            case 'remove': {
                sendBoostMessage(this.ws, { action, word });
                break;
            }
        }
    }
}
let singletonBoostManager;
let oldWs;
export const getsingletonBoostManager = (ws, dispatch, subscription) => {
    if (!oldWs)
        oldWs = ws;
    if (!singletonBoostManager || oldWs !== ws) {
        singletonBoostManager = new BoostManager(ws, dispatch, subscription);
    }
    return singletonBoostManager;
};
