// Responsible for managing the different states of the CC mode window.
//
// Currently, just the conversation view is available. With the new CC
// navigation design, there will be two other states while staying in this CC
// mode window:
//  - Rating: Displays a rating section for the user to rate the conversation
//  - Home: Displays a button to quickly start a conversation or close the app
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SiteThemeContext } from '../../../contexts/SiteThemeProvider';
import usePrevious from '../../../hooks/usePrevious';
import { selectFirebaseUser } from '../../../selectors/auth';
import { selectIsInConversation } from '../../../selectors/conversation';
import { selectIsInviteCardOpen } from '../../../selectors/ui';
import { setIsInviteCardOpen } from '../../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { isLinux, isWindows } from '../../../utils';
import { getAppVersion, ipcRendererWithDeregistering } from '../../../utils/electron';
import ConversationView from '../ConversationView';
import HomeView from '../HomeView';
import RatingView from '../RatingView';
import useStyles from './CCPage.styles';
const CCPage = ({ addNotification, appSubmittedTypeform, ccMode, createNewConversation, onEndConversation, roomEndingWarningCountdown, roomEndingWarningBegin, roomEndingWarningProlongUrl, setCCMode, setShowBubbleAboveCCMode, showBubbleAboveCCMode, }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const isInviteCardOpen = useAppSelector(selectIsInviteCardOpen);
    const isInConversation = useAppSelector(selectIsInConversation);
    const dispatch = useAppDispatch();
    const previousTheme = useRef(undefined);
    const { handleThemeChange, theme: currentTheme } = useContext(SiteThemeContext);
    const goInFloatingModeAndAttemptStartConversation = () => {
        window.electronIPC.sendGoInFloatingMode();
        if (firebaseUser && !firebaseUser.isAnonymous) {
            setCCMode('conversation');
            createNewConversation();
        }
    };
    // Mac users can close the application and still be open in the background.
    // If the user closed the application from CC widget mode, then opening the
    // app again will start a new conversation immediately.
    useEffect(() => {
        if (window.isElectron) {
            ipcRendererWithDeregistering.addEventListener('window-activated', goInFloatingModeAndAttemptStartConversation);
        }
        return () => {
            if (window.isElectron) {
                ipcRendererWithDeregistering.removeEventListener('window-activated', goInFloatingModeAndAttemptStartConversation);
            }
        };
    }, []);
    const prevIsInConversation = usePrevious(isInConversation);
    useEffect(() => {
        if (isInConversation && currentTheme === 'light') {
            previousTheme.current = 'light';
            handleThemeChange('dark');
        }
        return () => {
            if (previousTheme.current) {
                handleThemeChange(previousTheme.current);
                previousTheme.current = undefined;
            }
        };
    }, [isInConversation]);
    useEffect(() => {
        window.electronIPC.sendCCMode(ccMode);
        if (ccMode === 'conversation') {
            // This code is temporarily here to facilitate conversion from squirrel
            // to nsis. See WEB-173 for more details.
            const version = getAppVersion();
            // version is either our version name, or (if that's not available) electron
            // version. The way to distinguish this is by knowing that electron versions
            // are 18+, and our versions are 1.6+
            const match = version && version.match(/^\d\.\d\.\d/);
            const intVersion = match && parseInt(match[0].replaceAll('.', ''));
            if (isWindows && match && intVersion < 172) {
                addNotification({
                    text: t('ccMode.newVersionAvailable'),
                    timestamp: Date.now(),
                    type: 'default',
                    link: 'https://cc.ava.me/download',
                    linkText: t('ccMode.update'),
                    duration: 10000,
                });
            }
        }
        setShowBubbleAboveCCMode(false);
    }, [ccMode]);
    // Electron has a limitation of not being able to click through a transparent
    // window. The workaround is to directly handle ignoring mouse events to allow
    // clicks to pass through the window. If the invisible items are hidden, we
    // know to ignore mouse events.
    //
    // This is not done for Windows machine because when entering a draggable
    // region, all pointer events are lost. This would cause showBubbleAboveCCMode
    // to become false and ignore mouse events when it shouldn't have.
    useEffect(() => {
        const currentWindow = window.electronCurrentWindow;
        if (!isLinux && !isWindows) {
            if (showBubbleAboveCCMode) {
                currentWindow.setIgnoreMouseEvents(false);
            }
            else {
                currentWindow.setIgnoreMouseEvents(true, { forward: true });
                if (isInviteCardOpen) {
                    dispatch(setIsInviteCardOpen(false));
                }
            }
        }
    }, [showBubbleAboveCCMode]);
    return (React.createElement("div", { className: classes.root, onMouseEnter: () => {
            if (isWindows || isLinux)
                setShowBubbleAboveCCMode(true);
        }, onMouseLeave: () => setShowBubbleAboveCCMode(false) },
        ccMode === 'home' && React.createElement(HomeView, null),
        ccMode === 'conversation' && (React.createElement(ConversationView, { onEndConversation: onEndConversation, roomEndingWarningCountdown: roomEndingWarningCountdown, roomEndingWarningBegin: roomEndingWarningBegin, roomEndingWarningProlongUrl: roomEndingWarningProlongUrl, appSubmittedTypeform: appSubmittedTypeform })),
        ccMode === 'rating' && React.createElement(RatingView, null)));
};
export default CCPage;
