import React from 'react';
const GoogleLogo = () => {
    return (React.createElement("svg", { width: "32", height: "33", viewBox: "0 0 32 33", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("circle", { cx: "16", cy: "16.3223", r: "16", fill: "white" }),
        React.createElement("g", { clipPath: "url(#clip0_13640_33821)" },
            React.createElement("path", { d: "M10.8049 18.2688L10.1551 20.6944L7.78028 20.7447C7.07054 19.4283 6.66797 17.9221 6.66797 16.3216C6.66797 14.7739 7.04436 13.3144 7.71155 12.0293H7.71206L9.82635 12.4169L10.7525 14.5185C10.5587 15.0837 10.453 15.6903 10.453 16.3216C10.4531 17.0067 10.5772 17.6632 10.8049 18.2688Z", fill: "#FBBB00" }),
            React.createElement("path", { d: "M25.1721 14.5781C25.2793 15.1427 25.3352 15.7258 25.3352 16.3217C25.3352 16.9899 25.2649 17.6417 25.1311 18.2704C24.6767 20.4099 23.4895 22.2781 21.8449 23.6002L21.8444 23.5997L19.1814 23.4638L18.8045 21.1109C19.8957 20.471 20.7486 19.4694 21.1978 18.2704H16.207V14.5781H21.2706H25.1721Z", fill: "#518EF8" }),
            React.createElement("path", { d: "M21.8448 23.6003L21.8453 23.6008C20.2459 24.8864 18.2141 25.6557 16.0023 25.6557C12.4479 25.6557 9.35767 23.669 7.78125 20.7454L10.8059 18.2695C11.5941 20.3731 13.6233 21.8706 16.0023 21.8706C17.0248 21.8706 17.9828 21.5941 18.8048 21.1116L21.8448 23.6003Z", fill: "#28B446" }),
            React.createElement("path", { d: "M21.9581 9.13699L18.9345 11.6124C18.0838 11.0806 17.0781 10.7734 16.0007 10.7734C13.5679 10.7734 11.5007 12.3395 10.752 14.5185L7.71145 12.0293H7.71094C9.26428 9.03443 12.3935 6.98828 16.0007 6.98828C18.2653 6.98828 20.3417 7.79496 21.9581 9.13699Z", fill: "#F14336" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_13640_33821" },
                React.createElement("rect", { width: "18.6667", height: "18.6667", fill: "white", transform: "translate(6.66797 6.98828)" })))));
};
export default GoogleLogo;
