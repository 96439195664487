import formatDate from 'date-fns/format';
import enUS from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { SnackbarProvider } from 'notistack';
import * as React from 'react';
import { initReactI18next } from 'react-i18next';
import TranslationEn from '../assets/translation/en.json';
import TranslationFr from '../assets/translation/fr.json';
import { SiteThemeProvider } from './contexts/SiteThemeProvider';
import AppRoutes from './routes';
import { getUrlParams } from './utils';
import { langs } from './utils/i18n';
import { init } from './utils/log';
init()
    // eslint-disable-next-line
    .then(console.log)
    // eslint-disable-next-line
    .catch(console.error);
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
    resources: {
        en: {
            translation: TranslationEn,
        },
        fr: {
            translation: TranslationFr,
        },
    },
    detection: {
        order: ['cookie', 'localStorage', 'navigator'],
        caches: ['localStorage', 'cookie'],
    },
    lngs: langs,
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
            if (format === 'uppercase') {
                return value.toUpperCase();
            }
            if (format === 'number') {
                return value.toLocaleString(lng);
            }
            if (value instanceof Date) {
                return formatDate(value, format, { locale: lng === 'fr' ? fr : enUS });
            }
            return value;
        },
    },
}, (err, t) => {
    window.document.title = t('title');
});
const urlParams = getUrlParams();
if (urlParams.lang === 'fr') {
    i18n.changeLanguage('fr');
}
export const AppWrapper = ({ children }) => (React.createElement(SiteThemeProvider, null,
    React.createElement(SnackbarProvider, { preventDuplicate: true, iconVariant: {
            warning: '',
        }, maxSnack: 3, anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
        }, autoHideDuration: 5000, classes: {
            root: 'a-snackbar',
        }, disableWindowBlurListener: true }, children)));
const App = () => React.createElement(AppRoutes, null);
export default App;
