import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleNewRoomStatus } from '../../actions/conversation';
import { BackendBadge, getBadge } from '../../modules/scribe/BackendBadge';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectAvaId, selectFeedbackMetrics } from '../../selectors/userProfile';
import { selectBackends, selectDisplayDev } from '../../selectors/v1Session';
import { clearConversationStatus, setRateConversationOpen } from '../../store/slices/rateConversation';
import { setSelectedTranscriptId } from '../../store/slices/savedTranscript';
import { useAppSelector } from '../../store/store';
import { trackConversationRating } from '../../utils/conversation';
import ProfileMenu from '../Profile/ProfileMenu';
const useStyles = makeStyles((theme) => ({
    appBar: {
        right: 0,
        WebkitAppRegion: 'no-drag',
        paddingTop: 6,
        paddingRight: 8,
        paddingBottom: 6,
        paddingLeft: 30,
        background: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark2,
        boxShadow: theme.palette.mode === 'light'
            ? '0px 4px 4px rgba(112, 144, 176, 0.25)'
            : '0px 4px 4px rgba(112, 144, 176, 0.04)',
        opacity: 0.98,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 26,
        lineHeight: '41.6px',
        [theme.breakpoints.down('sm')]: {
            marginRight: 16,
        },
    },
    backWithTitleContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    arrowBack: {
        cursor: 'pointer',
    },
}));
const HomeView = ({ className, conversationStatus, features, handleNewRoomStatus, isRateConversationOpen, selectedTranscriptId, setRateConversationOpen, setSelectedTranscriptId, style, transcripts, }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();
    const displayDev = useSelector(selectDisplayDev);
    const feedbackMetrics = useAppSelector(selectFeedbackMetrics);
    const avaId = useAppSelector(selectAvaId);
    const firebaseAuthUID = useAppSelector(selectFirebaseUser).uid;
    const backends = useAppSelector(selectBackends);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const getHeadingText = () => {
        if (location.pathname.includes('/web/conversations'))
            return t('sidebar.conversations');
        if (location.pathname.includes('/web/account-settings'))
            return t('sidebar.accountSettings');
        if (location.pathname.includes('/web/scribe-dashboard'))
            return t('sidebar.scribeDashboard');
        if (location.pathname.includes('/workspace'))
            return t('sidebar.workspace');
        return '';
    };
    const onCloseSelectedTranscript = () => {
        handleNewRoomStatus({});
        setSelectedTranscriptId('');
        if (isRateConversationOpen) {
            setRateConversationOpen(false);
            trackConversationRating({
                rate: 0,
                status: conversationStatus,
                feedbackMetrics,
                avaId,
                firebaseAuthUID,
                dispatch,
            });
            clearConversationStatus();
        }
    };
    const selectedTranscript = transcripts.find((transcript) => transcript.roomId === selectedTranscriptId);
    const backend = getBadge(backends);
    const viewingSelectedTranscript = selectedTranscript && location.pathname.includes('/web/conversations');
    if (pathname.includes('/download'))
        return null;
    return (React.createElement(AppBar, { className: classnames(classes.appBar, className), position: "fixed", color: "inherit", style: Object.assign({ WebKitAppRegion: 'no-drag' }, style) },
        React.createElement(Toolbar, null,
            React.createElement(Typography, { className: classes.title, variant: "h3", style: { flexGrow: 1 } },
                React.createElement(Box, { className: classes.backWithTitleContainer, "data-qa": "backArrowButton", onClick: onCloseSelectedTranscript, style: {
                        cursor: viewingSelectedTranscript ? 'pointer' : 'default',
                    } },
                    viewingSelectedTranscript && React.createElement(ArrowBackIosIcon, { className: classes.arrowBack }),
                    getHeadingText()),
                displayDev && (React.createElement(Hidden, { smDown: true },
                    React.createElement(BackendBadge, { style: {
                            marginLeft: 12,
                            backgroundColor: backend.backgroundColor,
                            color: 'white',
                            fontSize: 14,
                            lineHeight: '24px',
                        } },
                        backend.name,
                        features.ok)))),
            React.createElement(ProfileMenu, null))));
};
const mapStateToProps = ({ rateConversation: { conversationStatus, isOpen }, savedTranscript: { transcripts, selectedId }, userProfile: { features }, }) => ({
    conversationStatus,
    features,
    isRateConversationOpen: isOpen,
    selectedTranscriptId: selectedId,
    transcripts,
});
const mapDispatchToProps = {
    clearConversationStatus,
    handleNewRoomStatus,
    setRateConversationOpen,
    setSelectedTranscriptId,
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
