import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontSize: '1.2rem',
        color: theme.palette.mode === 'light' ? theme.palette.ava.text.grey : theme.palette.ava.text.dark2,
        marginTop: 16,
    },
}));
const PageSubtitle = ({ children }) => {
    const classes = useStyles();
    return (React.createElement(Typography, { className: classes.subtitle, variant: "body1" }, children));
};
export default PageSubtitle;
