var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { trackMicPermission } from '../utils/segment';
const useMicPermission = () => {
    const [micPermissionStatus, setMicPermissionStatus] = useState('');
    const [trackMicPermissionPrompt, setTrackMicPermissionPrompt] = useState(false);
    useEffect(() => {
        let permissionResult;
        // Note that some browsers, most notably Safari and Firefox, still don't
        // fully support permissions API (and may never support it due to privacy concerns).
        // So this microphone check is more or less just informational, and is later used for
        // sending tracking information to Segment
        const { permissions } = window.navigator;
        if (permissions) {
            (() => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    permissionResult = yield permissions.query({ name: 'microphone' });
                    // 'onchange' fires when user grants/denies access,
                    // only works reliably in Chrome and derivatives.
                    permissionResult.onchange = () => {
                        setMicPermissionStatus(permissionResult.state);
                    };
                    setMicPermissionStatus(permissionResult.state);
                }
                catch (err) {
                    // Ignore any error, as permissions API is still draft/experimental API:
                    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/permissions
                }
            }))();
        }
        return () => {
            if (permissionResult) {
                // clear the callback on component unmount.
                permissionResult.onchange = null;
            }
        };
    }, []);
    useEffect(() => {
        if (trackMicPermissionPrompt) {
            trackMicPermission(micPermissionStatus);
            setTrackMicPermissionPrompt(false);
        }
        if (micPermissionStatus === 'prompt') {
            // track user's choice
            setTrackMicPermissionPrompt(true);
        }
    }, [micPermissionStatus]);
    return { micPermissionStatus, trackMicPermissionPrompt };
};
export default useMicPermission;
