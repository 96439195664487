import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
    link: {
        marginRight: 40,
        fontSize: 20,
        color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.white,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover, &:focus': {
            color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.white,
            textDecoration: 'none',
        },
        paddingBottom: 6,
        borderBottom: '6px solid transparent',
    },
    active: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.blue1 : theme.palette.ava.icon.dark1,
        cursor: 'default',
    },
    border: {
        borderBottom: `4px solid ${theme.palette.mode === 'light' ? theme.palette.ava.blue1 : theme.palette.ava.icon.dark1}`,
    },
    disabled: {
        pointerEvents: 'none',
        cursor: 'default',
    },
}));
const WorkspaceLink = ({ link }) => {
    const { pathname } = useLocation();
    const classes = useStyles();
    const active = pathname.includes(link.to);
    return (React.createElement(Link, { to: link.to, className: classNames(classes.link, {
            [classes.active]: active,
            [classes.border]: active,
            [classes.disabled]: link === null || link === void 0 ? void 0 : link.disabled,
        }) },
        React.createElement("span", null, link.title)));
};
export default WorkspaceLink;
