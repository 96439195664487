import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import RaisedContainer from '../../components/Containers/RaisedContainer';
const useStyles = makeStyles(() => ({
    container: {
        paddingTop: 24,
        paddingRight: 40,
        paddingBottom: 24,
        paddingLeft: 40,
        fontSize: 18,
    },
}));
const SectionContainer = ({ children, className }) => {
    const classes = useStyles();
    return React.createElement(RaisedContainer, { className: classnames(classes.container, className) }, children);
};
export default SectionContainer;
