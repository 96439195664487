import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { selectFeatures, selectUpdateFeaturesLoading } from '../../selectors/userProfile';
import { updateFeatures } from '../../store/slices/userProfile';
import { useAppSelector } from '../../store/store';
import AntSwitch from './AntSwitch';
const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        display: 'flex',
    },
    loading: {
        position: 'absolute',
        left: 60,
        color: theme.palette.ava.blue2,
    },
}));
const SaveTranscriptsSwitch = () => {
    const classes = useStyles();
    const features = useAppSelector(selectFeatures);
    const dispatch = useDispatch();
    const updateUserFeaturesLoading = useAppSelector(selectUpdateFeaturesLoading);
    const hasSaveTranscript = features.saveTranscript;
    const onClick = () => {
        dispatch(updateFeatures({ saveTranscript: !hasSaveTranscript }));
    };
    return (React.createElement("span", { className: classes.container },
        React.createElement(AntSwitch, { color: "primary", onClick: onClick, checked: updateUserFeaturesLoading ? !hasSaveTranscript : hasSaveTranscript }),
        updateUserFeaturesLoading && React.createElement(CircularProgress, { className: classes.loading, style: { width: 24, height: 24 } })));
};
export default SaveTranscriptsSwitch;
