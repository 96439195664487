import { Fade } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import ConnectToMeetingsCard from '../../components/Cards/ConnectToMeetingsCard/ConnectToMeetingsCard';
import { selectIsConnectToMeetingOpen } from '../../selectors/ui';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import ConnectToMeetingsButton from './ConnectToMeetingsButton';
const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: '45%',
        },
        minWidth: 'max-content',
    },
}));
function ConnectToMeetingsContainer({ hideText }) {
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const isConnectToMeetingsOpen = useAppSelector(selectIsConnectToMeetingOpen);
    const shouldShow = !electronCaptionMode && isConnectToMeetingsOpen;
    const classes = useStyles();
    return (React.createElement("div", { className: classes.container }, !isConnectToMeetingsOpen ? (React.createElement("div", null,
        React.createElement(ConnectToMeetingsButton, { open: isConnectToMeetingsOpen, hideText: hideText }))) : (React.createElement(Fade, { in: shouldShow, timeout: 500, easing: 'ease-in-out', unmountOnExit: true },
        React.createElement("div", { style: { height: '100%' } },
            React.createElement(ConnectToMeetingsCard, null))))));
}
export default ConnectToMeetingsContainer;
