import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 24,
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark4,
        boxShadow: theme.palette.mode === 'light'
            ? '0px 4px 24px rgba(112, 144, 176, 0.25)'
            : '0px 4px 4px rgba(112, 144, 176, 0.04)',
        borderRadius: 20,
    },
}));
export default useStyles;
