var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getScribeBackendEndpoint, httpRequestWithToken } from '../../utils/http';
const getProfile = ({ user }) => __awaiter(void 0, void 0, void 0, function* () {
    let url = `${getScribeBackendEndpoint()}/api/v1/users/me`;
    const firebaseAuthUID = user ? user.firebaseAuthUID || user.id : null;
    if (firebaseAuthUID) {
        url = `${url}?firebaseAuthUID=${firebaseAuthUID}`;
    }
    return httpRequestWithToken({
        url,
        method: 'GET',
    });
});
const saveReport = ({ report, firebaseAuthUID }) => __awaiter(void 0, void 0, void 0, function* () {
    return httpRequestWithToken({
        url: `${getScribeBackendEndpoint()}/api/v1/reports?firebaseAuthUID=${firebaseAuthUID}`,
        method: 'POST',
        payload: report,
    });
});
const getReportTags = () => __awaiter(void 0, void 0, void 0, function* () {
    return httpRequestWithToken({
        url: `${getScribeBackendEndpoint()}/api/v1/reports`,
        method: 'GET',
    });
});
export const users = {
    getProfile,
};
export const reports = {
    getReportTags,
    saveReport,
};
