var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import WorkIcon from '@mui/icons-material/Work';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AvaLogoImg from '../../../assets/images/logo.png';
import { selectIsSideBarOpen } from '../../selectors/ui';
import { selectFeatures } from '../../selectors/userProfile';
import { selectDisplayDev } from '../../selectors/v1Session';
import { toggleSideBar } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getIsMac } from '../../utils/electron';
import { show } from '../../utils/intercom';
import ContactSupportIcon from '../Icons/ContactSupport';
import ScribeIcon from '../Icons/Scribe';
import AccountSettingsIcon from '../Icons/Sidebar/AccountSettings';
import TranscriptIcon from '../Icons/Sidebar/Transcript';
import JoinConversationButton from '../JoinConversation/Button';
import ServerSettings from './ServerSettings';
import SidebarItem from './SidebarItem';
import StartCaptionsButton from './StartCaptionsButton';
const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        transform: 'translateX(0) !important',
        transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        border: '1px solid transparent', // for some reason if I remove this contactSupport gets pushed down on autofocus
    },
    sidebarClosedContent: {
        justifyContent: 'center !important',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 76,
        paddingLeft: (sidebarOpen) => (sidebarOpen ? 24 : 0),
        paddingRight: (sidebarOpen) => (sidebarOpen ? 16 : 0),
    },
    logoContainer: {
        maxWidth: 100,
    },
    logo: {
        height: '100%',
        width: '100%',
        cursor: 'pointer',
    },
    menuContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    options: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            width: '0 !important',
        },
    },
    conversation: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 25,
    },
    divider: {
        marginTop: 24,
        marginRight: 20,
        marginBottom: 24,
        marginLeft: 20,
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.text.dark1,
    },
    list: {
        flex: 1,
    },
    link: {
        color: 'currentColor',
        '&:hover': {
            color: 'currentColor',
            textDecoration: 'none',
        },
    },
    disabled: {
        cursor: 'default',
    },
    footer: {
        minHeight: 120,
    },
}));
const HomeView = ({ className }) => {
    const { t } = useTranslation();
    const sidebarOpen = useAppSelector(selectIsSideBarOpen);
    const classes = useStyles({ sidebarOpen });
    const theme = useTheme();
    const features = useAppSelector(selectFeatures);
    const showWorkspace = useAppSelector(selectDisplayDev);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isMac = getIsMac();
    let headerTop = 0;
    if (window.isElectron) {
        headerTop = isMac ? 28 : 32;
    }
    const getSidebarWidth = () => {
        if (sidebarOpen)
            return 272;
        return 90;
    };
    const scribeDashboardActive = pathname.includes('/web/scribe-dashboard');
    const conversationsActive = pathname.includes('/web/conversations');
    const settingsActive = pathname.includes('/account/settings');
    const workspaceActive = pathname.includes('/workspace');
    const onAvaLogoClick = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (!pathname.includes('/web/transcript')) {
            navigate('/web/transcript');
        }
    });
    if (pathname.includes('/download'))
        return null;
    return (React.createElement(Drawer, { className: classnames('invisible-scrollbar', className), classes: {
            paper: classes.drawerPaper,
        }, style: {
            width: getSidebarWidth(),
        }, PaperProps: {
            style: {
                top: window.isElectron ? headerTop : 0,
                height: window.isElectron ? 'calc(100% - 10px)' : '100%',
                width: getSidebarWidth(),
                visibility: 'visible',
                background: theme.palette.mode === 'light'
                    ? 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.6)'
                    : theme.palette.ava.dark4,
                borderRight: `2px solid ${theme.palette.mode === 'light' ? theme.palette.ava.offBlue1 : theme.palette.ava.dark1}`,
            },
        }, open: sidebarOpen, anchor: "left", variant: "persistent" },
        React.createElement("div", { className: classes.container },
            React.createElement("section", { className: classnames(classes.header, {
                    [classes.sidebarClosedContent]: !sidebarOpen,
                }) },
                sidebarOpen && (React.createElement("div", { className: classes.logoContainer },
                    React.createElement("img", { className: classes.logo, src: AvaLogoImg, alt: "ava logo", onClick: onAvaLogoClick }))),
                React.createElement("div", { className: classes.menuContainer },
                    sidebarOpen && (React.createElement(ArrowBackIosIcon, { style: {
                            position: 'absolute',
                            left: -8,
                            fontSize: 16,
                            cursor: 'pointer',
                            color: theme.palette.text.primary,
                        }, onClick: () => dispatch(toggleSideBar()) })),
                    React.createElement(MenuOutlinedIcon, { style: {
                            fontSize: 34,
                            cursor: 'pointer',
                            color: theme.palette.text.primary,
                        }, onClick: () => dispatch(toggleSideBar()) }))),
            React.createElement("div", { className: classes.options },
                React.createElement("section", { className: classes.conversation },
                    React.createElement(StartCaptionsButton, null),
                    React.createElement(JoinConversationButton, null)),
                React.createElement(Divider, { className: classes.divider }),
                React.createElement(List, { className: classes.list, component: "nav", disablePadding: true },
                    features.scribe && (React.createElement(Link, { to: "/web/scribe-dashboard", className: classnames(classes.link, {
                            [classes.disabled]: scribeDashboardActive,
                        }) },
                        React.createElement(SidebarItem, { active: scribeDashboardActive, icon: React.createElement(ScribeIcon, { active: scribeDashboardActive, color: scribeDashboardActive ? 'white' : '' }), text: t('sidebar.scribeDashboard'), to: "/web/scribe-dashboard", disableOnClick: true }))),
                    React.createElement(Link, { to: "/web/conversations", className: classnames(classes.link, {
                            [classes.disabled]: conversationsActive,
                        }) },
                        React.createElement(SidebarItem, { active: conversationsActive, icon: React.createElement(TranscriptIcon, { active: conversationsActive, color: conversationsActive ? 'white' : '' }), text: t('sidebar.conversations'), to: "/web/conversations", disableOnClick: true })),
                    React.createElement(Link, { to: "/web/account-settings", className: classnames(classes.link, {
                            [classes.disabled]: settingsActive,
                        }) },
                        React.createElement(SidebarItem, { active: settingsActive, icon: React.createElement(AccountSettingsIcon, { active: settingsActive, color: settingsActive ? 'white' : '' }), text: t('sidebar.accountSettings'), disableOnClick: true })),
                    showWorkspace && (React.createElement(Link, { to: "/workspace", className: classnames(classes.link, {
                            [classes.disabled]: workspaceActive,
                        }) },
                        React.createElement(SidebarItem, { active: workspaceActive, icon: React.createElement(WorkIcon, { style: {
                                    fill: workspaceActive ? 'white' : 'black',
                                } }), text: t('sidebar.workspace'), disableOnClick: true }))),
                    React.createElement(ServerSettings, null)),
                React.createElement(Divider, { className: classes.divider }),
                React.createElement(List, { className: classes.footer, disablePadding: true },
                    React.createElement(SidebarItem, { icon: React.createElement(ContactSupportIcon, null), text: t('sidebar.contactSupport'), onClick: () => show() }))))));
};
const mapStateToProps = ({ account: { user } }) => ({
    user,
});
export default connect(mapStateToProps)(HomeView);
