import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BoostScreen from '../Icons/BoostScreen';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    scrollPaper: {
        background: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.1)',
    },
    dialogPaper: {
        justifyContent: 'space-between',
        padding: '20px 40px',
        background: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.darkBg2,
        borderRadius: 20,
        height: 590,
        maxHeight: '90vh',
        maxWidth: 800,
        [theme.breakpoints.down('md')]: {
            height: '94%',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: 0,
            margin: 0,
            padding: '0 24px',
            height: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    heading: {
        color: theme.palette.ava.blue2,
        fontSize: 34,
        fontWeight: 700,
        lineHeight: '37px',
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
            lineHeight: '30px',
        },
    },
    iconContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    body: {
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.ava.blue5 : theme.palette.ava.icon.darkBg,
        borderRadius: 20,
        padding: '24px 30px',
    },
    title: {
        fontSize: 22,
        color: theme.palette.mode === 'light' ? theme.palette.ava.blue1 : theme.palette.ava.blue4,
        marginTop: 0,
        marginBottom: 16,
    },
    text: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark5 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '27px',
        fontWeight: 300,
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: '20px',
        },
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    upgradeButton: {
        margin: '10px 50px 8px',
        padding: '15px 0',
        background: theme.palette.ava.blue2,
        color: theme.palette.ava.white,
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '26px',
        borderRadius: 100,
        width: '100%',
        maxWidth: 450,
        boxShadow: '0px 4px 24px rgba(112, 144, 176, 0.25)',
        '&:hover': {
            backgroundColor: theme.palette.ava.blue1,
        },
        [theme.breakpoints.down('lg')]: {
            margin: '0 40px 8px',
        },
        [theme.breakpoints.down('md')]: {
            margin: 0,
            fontSize: 18,
            lineHeight: '20px',
        },
    },
    notNowButton: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.blue2 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '27px',
        fontWeight: 400,
        width: '100%',
        maxWidth: 450,
        '&:hover': {
            backgroundColor: 'initial',
            color: theme.palette.mode === 'light' ? theme.palette.ava.blue1 : theme.palette.ava.blue4,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 16,
            lineHeight: '20px',
        },
    },
}));
const BoostUpgradePopup = ({ open, onCancel, onConfirm }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (React.createElement(Dialog, { className: classes.root, classes: { scrollPaper: classes.scrollPaper, paper: classes.dialogPaper }, open: open, onClose: onCancel },
        React.createElement("h3", { className: classes.heading }, t('boost.upgradeDialog.heading')),
        React.createElement("div", { className: classes.iconContainer },
            React.createElement(BoostScreen, null)),
        React.createElement("div", { className: classes.body },
            React.createElement("h4", { className: classes.title }, t('boost.upgradeDialog.title')),
            React.createElement("div", { className: classes.text }, t('boost.upgradeDialog.text'))),
        React.createElement("div", { className: classes.buttonContainer },
            React.createElement(Button, { className: classes.upgradeButton, onClick: onConfirm }, t('boost.upgradeDialog.confirm')),
            React.createElement(Button, { className: classes.notNowButton, onClick: onCancel }, t('boost.upgradeDialog.cancel')))));
};
export default BoostUpgradePopup;
