import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MobileScreenShareOutlinedIcon from '@mui/icons-material/MobileScreenShareOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import { Avatar, Divider, Grid, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LoginApple } from '../../../../components/LoginApple';
import { LoginEmail } from '../../../../components/LoginEmail';
import { LoginFacebook } from '../../../../components/LoginFacebook';
import { LoginGoogle } from '../../../../components/LoginGoogle';
import { LoginPhone } from '../../../../components/LoginPhone';
import { getPhraseOrEmptyString } from '../../../../utils/i18n';
const useStyles = makeStyles((theme) => ({
    args: {
        alignSelf: 'baseline',
        fontSize: 16,
        marginTop: theme.spacing(2),
        minHeight: theme.spacing(12),
        overflow: 'hidden',
        textAlign: 'left',
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(0, 0, 0, 2),
        },
    },
    asterix: {
        color: 'rgb(60, 60, 60)',
        fontSize: 13,
    },
    changeOrgLink: {
        color: theme.palette.text.secondary,
        cursor: 'pointer',
        margin: theme.spacing(2, 4, 0, 0),
    },
    icons: {
        backgroundColor: theme.palette.ava.blue,
    },
    linkTerms: {
        color: theme.palette.text.secondary,
    },
    providerButtonsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    root: {
        '&>*': {
            marginTop: theme.spacing(3),
        },
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(2),
    },
    secure: {
        marginLeft: theme.spacing(1),
    },
    secureWrapper: {
        alignItems: 'center',
        color: green[400],
        display: 'flex',
        marginTop: theme.spacing(1),
    },
    subtitle: {
        color: '#4a4a4a',
        fontSize: '20px',
    },
    terms: {
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '1.06',
    },
    titleBlue: {
        color: theme.palette.ava.blue,
    },
}));
function Explanation(props) {
    const classes = useStyles();
    const { handleLoginFacebook, handleLoginGoogle, handleLoginApple, loginEmailLink, loginPhoneLink } = props;
    const { t, i18n } = useTranslation();
    const orgType = window.localStorage.getItem('account_orgType') || 'hr';
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, null,
            React.createElement(Link, { to: { pathname: '/account/start', search: '?orgType=' } },
                React.createElement(Typography, { align: "right", className: classes.changeOrgLink }, t('account.login.change-organization-cta', {
                    organization: t(`account.login.organization-type-with-proname.${orgType}`),
                })))),
        React.createElement(Grid, { className: classes.root, container: true, direction: "row", alignItems: "center", justifyContent: "center" },
            React.createElement(Grid, { item: true, xs: 10, md: 7 },
                React.createElement(Typography, { className: classes.title },
                    React.createElement(Trans, { i18nKey: `account.login.signup-title.${orgType}`, values: { organization: t(`account.login.organization-type.${orgType}`) } },
                        React.createElement("span", { className: classes.titleBlue }, "organization")))),
            React.createElement(Grid, { item: true, xs: 10, md: 7 },
                React.createElement(Typography, { className: classes.subtitle }, t(`account.login.signup-subtitle.${orgType}`))),
            React.createElement(Grid, { item: true, xs: 10, md: 7 },
                React.createElement(Divider, null)),
            React.createElement(Grid, { item: true, xs: 10, md: 7 },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, xs: 12, md: 6, className: classes.args },
                        React.createElement(Grid, { container: true, direction: "row", justifyContent: "flex-start", alignItems: "center" },
                            React.createElement(Grid, { item: true, lg: 2, xs: 4 },
                                React.createElement(Avatar, { className: classes.icons },
                                    React.createElement(MobileScreenShareOutlinedIcon, null))),
                            React.createElement(Grid, { item: true, md: 8, xs: 8 }, t(`account.login.signup-argument.${orgType}.first`)))),
                    React.createElement(Grid, { item: true, xs: 12, md: 6, className: classes.args },
                        React.createElement(Grid, { container: true, direction: "row", justifyContent: "flex-start", alignItems: "center" },
                            React.createElement(Grid, { item: true, lg: 2, xs: 4 },
                                React.createElement(Avatar, { className: classes.icons },
                                    React.createElement(KeyboardOutlinedIcon, null))),
                            React.createElement(Grid, { item: true, md: 8, xs: 8 }, t(`account.login.signup-argument.${orgType}.second`)))),
                    React.createElement(Grid, { item: true, xs: 12, md: 6, className: classes.args },
                        React.createElement(Grid, { container: true, direction: "row", justifyContent: "flex-start", alignItems: "center" },
                            React.createElement(Grid, { item: true, lg: 2, xs: 4 },
                                React.createElement(Avatar, { className: classes.icons },
                                    React.createElement(SubtitlesOutlinedIcon, null))),
                            React.createElement(Grid, { item: true, md: 8, xs: 8 }, t(`account.login.signup-argument.${orgType}.third`)))),
                    React.createElement(Grid, { item: true, xs: 12, md: 6, className: classes.args },
                        React.createElement(Grid, { container: true, direction: "row", justifyContent: "flex-start", alignItems: "center" },
                            React.createElement(Grid, { item: true, lg: 2, xs: 4 },
                                React.createElement(Avatar, { className: classes.icons },
                                    React.createElement(PaletteOutlinedIcon, null))),
                            React.createElement(Grid, { item: true, md: 8, xs: 8 }, t(`account.login.signup-argument.${orgType}.fourth`)))))),
            React.createElement(Grid, { item: true, xs: 10, md: 7 },
                React.createElement(Divider, null),
                React.createElement(Typography, { variant: "subtitle1", className: classes.asterix }, i18n.language === 'fr' ? '' : getPhraseOrEmptyString(`account.login.asterix.${orgType}`))),
            React.createElement(Grid, { item: true, xs: 10, md: 7 },
                React.createElement(Grid, { container: true, direction: "column", alignItems: "center", justifyContent: "center" },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "body1", color: "textSecondary", className: classes.terms },
                            React.createElement(Trans, { i18nKey: "account.login.signup-terms", components: [
                                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: t('account.dashboard.dashboard-footer-link.terms'), className: classes.linkTerms }, "terms"),
                                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: t('account.dashboard.dashboard-footer-link.privacy'), className: classes.linkTerms }, "privacy"),
                                ] }))),
                    React.createElement(Grid, { item: true },
                        React.createElement("div", { className: classes.providerButtonsContainer },
                            React.createElement(LoginFacebook, { login: handleLoginFacebook }),
                            React.createElement(LoginGoogle, { login: handleLoginGoogle }),
                            React.createElement(LoginApple, { login: handleLoginApple }),
                            React.createElement(Typography, { variant: "body2", color: "textSecondary", align: "center" }, t('account.login.signin-or')),
                            React.createElement(LoginEmail, { to: loginEmailLink }),
                            React.createElement(LoginPhone, { to: loginPhoneLink }))),
                    React.createElement(Grid, { item: true },
                        React.createElement("span", { className: classes.secureWrapper },
                            React.createElement(LockOutlinedIcon, null),
                            React.createElement(Typography, { variant: "overline", className: classes.secure }, t('account.login.signup-secure-sentence')))))))));
}
export default Explanation;
