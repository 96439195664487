import { getAvaBackendEndpoint } from './http';
let worker = undefined;
try {
    worker = new Worker(new URL('../sw.js', import.meta.url));
}
catch (_a) { }
export const prepareOfflineTracking = (idToken) => {
    if (!worker)
        return;
    const avaBackendEndpoint = getAvaBackendEndpoint();
    const authentifiedHeaders = {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Basic ${btoa(`firebase-mobile:${idToken}`)}`,
    };
    worker.postMessage({
        type: 'prepareOfflineTracking',
        avaBackendEndpoint,
        authentifiedHeaders,
    });
};
export const updateConvoMetricsThroughWorker = (avaId, firebaseAuthUID, metricsToUpdate) => {
    if (!worker)
        throw new Error('no worker');
    worker.postMessage({
        type: 'updateConvoMetrics',
        avaId,
        firebaseAuthUID,
        metricsToUpdate,
    });
};
