import AddLinkIcon from '@mui/icons-material/AddLink';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import AvaLogoSphere from '../../../assets/images/ava_logo_sphere.png';
import { selectUniqueParticipants } from '../../selectors/legacy-conversation';
import { useAppSelector } from '../../store/store';
import { ApplessParticipantAvatar } from './ApplessParticipantAvatar';
export function ApplessParticipantListBadge() {
    const participants = useAppSelector(selectUniqueParticipants);
    const [participantModalOpen, setParticipantModalOpen] = useState(false);
    const theme = useTheme();
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: () => {
                setParticipantModalOpen(true);
            }, sx: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: 48,
                backgroundColor: 'white',
                margin: 1,
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderRadius: '48px',
                paddingLeft: 1,
                paddingRight: 2,
            } },
            participants.slice(0, 3).map((participant, index) => (React.createElement(Box, { key: participant.avaId, sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: '-8px',
                    zIndex: 100 - index,
                } },
                React.createElement(ApplessParticipantAvatar, { borderColor: 'white', speaker: participant })))),
            React.createElement(Typography, { sx: {
                    marginLeft: 2,
                } }, participants.length)),
        React.createElement(Dialog, { open: participantModalOpen, onClose: () => setParticipantModalOpen(false) },
            React.createElement(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minWidth: '300px',
                    paddingBottom: 3,
                    paddingX: 2,
                } },
                React.createElement(DialogTitle, { sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'start',
                        justifyContent: 'center',
                    } },
                    React.createElement(Typography, { sx: {
                            fontSize: 20,
                            fontWeight: 600,
                            alignSelf: 'center',
                        } }, "Participants")),
                React.createElement(IconButton, { onClick: () => setParticipantModalOpen(false), sx: {
                        flexGrow: 1,
                        position: 'absolute',
                        top: 8,
                        right: 0,
                    } },
                    React.createElement(CloseIcon, null)),
                participants.map((p) => (React.createElement(Box, { key: p.avaId, sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'start',
                        width: '100%',
                    } },
                    React.createElement(ApplessParticipantAvatar, { speaker: p }),
                    React.createElement(Box, { sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'center',
                            marginLeft: 2,
                        } },
                        React.createElement(Typography, null, p.userName),
                        React.createElement(Typography, { sx: {
                                color: theme.palette.grey[500],
                            } },
                            React.createElement("img", { src: AvaLogoSphere, style: { height: 12, marginRight: 4 }, alt: "Ava logo" }),
                            p.avaName))))),
                React.createElement(Button, { fullWidth: true, variant: 'contained', sx: {
                        backgroundColor: theme.palette.ava.gradientBlue,
                        borderRadius: '12px',
                        marginTop: 2,
                    } },
                    React.createElement(AddLinkIcon, { sx: { marginRight: 2 } }),
                    React.createElement(Typography, { sx: {
                            fontWeight: 700,
                        } }, "Copy invitation link"))))));
}
