import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { remoteConfig } from './firebase';
import { setupDesktopIntegration } from './services/desktopIntegration';
import { setFirebaseRemoteConfig } from './store/slices/auth';
import { store } from './store/store';
import { initAnalytics } from './utils/segment';
if (window.location.origin.includes('https://app-stage.ava.me')) {
    LogRocket.init('xyqwjx/ava-scribe');
    setupLogRocketReact(LogRocket);
}
const isProd = window.location.href.indexOf('app.avascribe.com') > -1 ||
    window.location.href.indexOf('app.ava.me') > -1 ||
    window.location.href.indexOf('web.ava.me') > -1;
const isLocal = window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('127.0.0.1') > -1;
// See https://docs.sentry.io/clients/javascript/config/ for details.
Sentry.init({
    dsn: 'https://598382256bd44dc7b175357bac4bc464@sentry.io/1303073',
    integrations: [new Integrations.BrowserTracing()],
    environment: isProd ? 'production' : 'playground',
    release: process.env.HASH_COMMIT,
    enabled: !isLocal,
    ignoreErrors: ['connection appears to be offline'],
});
initAnalytics();
remoteConfig.fetchAndActivate().then(() => {
    store.dispatch(setFirebaseRemoteConfig(remoteConfig.getAll()));
});
setupDesktopIntegration();
const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(React.createElement(Provider, { store: store },
        React.createElement(App, null)));
}
