import ClosedCaptionIcon from '@mui/icons-material/ClosedCaption';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { selectElectronCaptionMode, selectFullScreen } from '../../selectors/ui';
import { setElectronCaptionMode } from '../../store/slices/uiState';
import { useAppSelector } from '../../store/store';
import { openNewWindowAndCloseOnFocus } from '../../utils/domUtils';
import DesktopAppCaptionsModal from '../scribe/DesktopAppCaptionsModal';
const CCButton = ({ isDesktop }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [showDesktopAppCaptionPopup, setShowDesktopAppCaptionPopup] = useState(false);
    const openAvaCallback = useCallback(() => {
        setShowDesktopAppCaptionPopup(true);
        openNewWindowAndCloseOnFocus();
    }, [setShowDesktopAppCaptionPopup]);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const fullScreen = useAppSelector(selectFullScreen);
    const dispatch = useDispatch();
    if (isDesktop) {
        return (React.createElement(React.Fragment, null, !electronCaptionMode && !fullScreen && (React.createElement(Fab, { size: "small", style: {
                width: 56,
                height: 56,
                backgroundColor: theme.palette.ava.navy,
                color: theme.palette.ava.white,
            }, onClick: () => dispatch(setElectronCaptionMode(true)) },
            React.createElement(ClosedCaptionIcon, { style: { fontSize: 36 } })))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Fab, { size: "small", style: {
                width: 56,
                height: 56,
                backgroundColor: theme.palette.ava.navy,
                color: theme.palette.ava.white,
            }, "data-qa": "ccDesktopAppButton", onClick: openAvaCallback },
            React.createElement(ClosedCaptionIcon, { style: { fontSize: 36 } })),
        React.createElement("div", { style: { display: 'none' } }, showDesktopAppCaptionPopup && (React.createElement(DesktopAppCaptionsModal, { onClose: () => setShowDesktopAppCaptionPopup(false), t: t })))));
};
export default CCButton;
