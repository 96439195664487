import { connect } from 'react-redux';
import { highlightTranscript, insertUpdateMutation, scribeDeselectTranscriptWord, scribeJumpToBlocEnd, scribeJumpToBlocStart, scribeJumpToNextBloc, scribeJumpToNextHightlightedBloc, scribeJumpToPreviousBloc, scribeSelectTranscriptWord, scribeUpdateInputValue, scribeWordEditSetStarted, } from '../../actions';
import ScribeWordInput from './ScribeWordInput';
const mapStateToProps = (state) => {
    const { scribeConversation: { ui: { selectedWordInputValue, selectedTranscriptIndex, selectedTranscriptCaretPosition, selectedWordIndex }, }, } = state;
    return {
        selectedWordInputValue,
        selectedWordIndex,
        selectedTranscriptCaretPosition,
        selectedTranscriptIndex,
    };
};
export default connect(mapStateToProps, {
    highlightTranscript,
    insertUpdateMutation,
    scribeDeselectTranscriptWord,
    scribeJumpToBlocEnd,
    scribeJumpToBlocStart,
    scribeJumpToNextBloc,
    scribeJumpToNextHightlightedBloc,
    scribeJumpToPreviousBloc,
    scribeSelectTranscriptWord,
    scribeUpdateInputValue,
    scribeWordEditSetStarted,
})(ScribeWordInput);
