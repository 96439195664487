import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles({
    singleValue: {
        fontSize: 16,
    },
});
const JoinConversationSingleValue = ({ data: { user }, innerProps }) => {
    const classes = useStyles();
    return (React.createElement(Typography, Object.assign({ className: classes.singleValue }, innerProps), (user === null || user === void 0 ? void 0 : user.avaName) || null));
};
export default JoinConversationSingleValue;
