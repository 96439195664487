/* eslint no-fallthrough: 0 */
/* eslint no-case-declarations: 0 */
/* eslint no-underscore-dangle: 0 */
export const getSelectionRange = ({ selectedIndex, selectionLength, }) => {
    const selectionStartIndex = selectionLength < 0 ? selectedIndex + selectionLength : selectedIndex;
    const selectionEndIndex = selectionLength > 0 ? selectedIndex + selectionLength : selectedIndex + 1;
    return {
        selectionStartIndex,
        selectionEndIndex, // Word at this index is not selected
    };
};
export const getWordCharacterIndex = (words, wordIndex, selectionLength = 0) => {
    const { selectionStartIndex, selectionEndIndex } = getSelectionRange({
        selectedIndex: wordIndex,
        selectionLength,
    });
    const currentWordText = words.slice(selectionStartIndex, selectionEndIndex).join(' ');
    const text = words.join(' ');
    const selectedWordCharacterTranscriptIndex = wordIndex === 0 ? 0 : words.slice(0, selectionStartIndex).join(' ').length + 1;
    const followingWords = words.slice(selectionEndIndex);
    const indexEnd = words.join(' ').length - followingWords.join(' ').length;
    const { length } = currentWordText;
    return {
        startCharacterIndex: selectedWordCharacterTranscriptIndex,
        endCharacterIndex: selectedWordCharacterTranscriptIndex + length,
        length,
        currentWordText,
        withSpace: {
            startCharacterIndexWithSpace: selectedWordCharacterTranscriptIndex,
            endCharacterIndexWithSpace: indexEnd,
            lengthWithSpace: text.slice(selectedWordCharacterTranscriptIndex, indexEnd).length,
        },
    };
};
export const computeTextFormLastMutation = (previousText, mutation, indexWord = 0) => {
    var _a;
    const { type, data, } = mutation;
    let { startCharacterIndex } = getWordCharacterIndex(previousText.split(' '), indexWord, 1);
    let result = previousText;
    if (type === 'insert') {
        result = previousText.slice(0, data.index) + data.text + previousText.slice(data.index);
        if (data.index < startCharacterIndex) {
            startCharacterIndex += data.text.length;
        }
    }
    else if (type === 'delete') {
        result = previousText.slice(0, data.index) + previousText.slice(data.index + ((_a = data.length) !== null && _a !== void 0 ? _a : 0));
        if (data.index < startCharacterIndex) {
            startCharacterIndex -= data.length || 0;
        }
    }
    else {
        result = previousText;
    }
    return {
        text: result,
        words: result.split(' '),
        indexWord: result.slice(0, startCharacterIndex).split(' ').length - 1,
    };
};
export const avaLogoUrl = 'https://d33wubrfki0l68.cloudfront.net/5fdf912f53d109419cc8cfb808f4a0060de580b2/989fd/assets/images/ava-logo.png';
export const endPunctuation = ['.', ',', '?', '!', ':', '...'];
export const shouldCapitalize = (punc) => {
    return ['.', '?', '!'].includes(punc);
};
export const removeEndPunctuation = (s, key) => {
    if (endPunctuation.includes(s.slice(s.length - 3, s.length))) {
        return s.slice(0, s.length - 3);
    }
    if (endPunctuation.includes(s[s.length - 1])) {
        if (key === '.' && s[s.length - 1] === '.') {
            return s;
        }
        return s.slice(0, s.length - 1);
    }
    return s;
};
export const capitalizeWord = (s) => {
    return s[0].toUpperCase() + s.slice(1, s.length);
};
export const unCapitalizeWord = (s, lang) => {
    if (lang === 'en' && s.toLowerCase() === 'i') {
        return 'I';
    }
    return s[0].toLowerCase() + s.slice(1, s.length);
};
export const toggleCapitalizeWord = (s) => {
    if (!s.length)
        return s;
    if (s[0].toLowerCase() === s[0]) {
        return capitalizeWord(s);
    }
    return unCapitalizeWord(s);
};
const getTwoLetterCode = (language) => {
    return language.split('-')[0];
};
export const getTextLang = (transcript, lang) => {
    let lang_ = lang;
    if (lang === '~' && transcript.speechLang) {
        lang_ = getTwoLetterCode(transcript.speechLang);
    }
    return lang_;
};
export const getText = (transcript, lang) => {
    if (!transcript || !transcript.texts || !transcript.speechLang)
        return '';
    const lang_ = getTextLang(transcript, lang);
    return transcript.texts[lang_] || '';
};
export const getTranscriptWordCount = (transcript) => {
    const text = getText(transcript, getTwoLetterCode(transcript.speechLang));
    return text.split(' ').length;
};
export const getLang = (orLang, globalLang) => {
    return globalLang === '~' ? getTwoLetterCode(orLang) : globalLang;
};
export const mutate = (transcript, indexWord, mutation, asr) => {
    var _a, _b;
    let newTranscript = structuredClone(transcript); // make a deep copy to not get redux-mutation related errors.
    if (mutation.parent === 'lucy') {
        newTranscript = {
            id: transcript.id,
            timestampMs: transcript.timestampMs,
            isFinal: false,
            index: 0,
            author: 'Callee',
            mutationsQueuedByHumanMutation: [],
            mutations: [],
            // $FlowFixMe
            speechLang: (_b = (_a = mutation.data) === null || _a === void 0 ? void 0 : _a.lang) !== null && _b !== void 0 ? _b : '',
            texts: {},
            cursors: [],
            t0: transcript.t0,
            trackName: transcript.trackName || '',
        };
    }
    let newIndexWord = indexWord;
    switch (mutation.type) {
        case 'delete':
        case 'insert':
            const { text, indexWord: newIndexW } = computeTextFormLastMutation(getText(newTranscript, mutation.data.lang), mutation, indexWord);
            newIndexWord = newIndexW;
            newTranscript = Object.assign(Object.assign({}, newTranscript), { texts: Object.assign(Object.assign({}, newTranscript.texts), { [mutation.data.lang]: text }) });
            newTranscript.indexes = newTranscript.indexes || {};
            const key = mutation.data.lang;
            newTranscript.indexes[key] = newTranscript.indexes[key] || 0;
            // $FlowFixMe
            const lastIndex = newTranscript.indexes[key];
            if (asr) {
                if (mutation.type === 'insert' && mutation.data.index < lastIndex) {
                    newTranscript.indexes[key] += mutation.data.text.length;
                }
                if (mutation.type === 'delete' && mutation.data.index < lastIndex) {
                    newTranscript.indexes[key] -= mutation.data.length;
                }
            }
            else {
                if (mutation.type === 'insert' && mutation.data.index <= lastIndex) {
                    newTranscript.indexes[key] += mutation.data.text.length;
                }
                if (mutation.type === 'delete' && mutation.data.index < lastIndex) {
                    newTranscript.indexes[key] -= mutation.data.length;
                }
                if (mutation.type === 'insert' && mutation.data.index > lastIndex) {
                    newTranscript.indexes[key] = mutation.data.index + mutation.data.text.length;
                }
            }
        case 'cursor':
            break;
        case 'updateField':
            if (mutation.data.field === 'speakerId') {
                // eslint-disable-next-line no-param-reassign
                mutation.data.field = 'author';
            }
            newTranscript = Object.assign({}, newTranscript);
            newTranscript[mutation.data.field] = mutation.data.value;
            break;
        case 'changeSpeaker':
            newTranscript = Object.assign(Object.assign({}, newTranscript), { author: mutation.data.speakerId });
            break;
        default:
            throw new Error(`Unknown mutation type ${mutation.type}`);
    }
    return {
        transcript: newTranscript,
        indexWord: newIndexWord,
    };
};
export function commonSubstring(array) {
    const A = array.concat().sort();
    const a1 = A[0];
    const a2 = A[A.length - 1];
    const L = a1.length;
    let i = 0;
    while (i < L && a1.charAt(i) === a2.charAt(i))
        i += 1;
    return a1.substring(0, i);
}
