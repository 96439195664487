import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { selectFirebaseUser } from '../selectors/auth';
import { selectIsInConversation } from '../selectors/conversation';
import { selectElectronCaptionMode, selectFullScreen, selectIsSideBarOpen, selectLoading } from '../selectors/ui';
import { selectAvaId, selectFeatures } from '../selectors/userProfile';
import { useAppSelector } from '../store/store';
import { boot, unreadCountChange } from '../utils/intercom';
import ElectronLayout from './ElectronLayout';
const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.default,
        display: (props) => (props.fullScreen ? 'inherit' : 'grid'),
        gridTemplateColumns: `
      [sidebar-start] minmax(260px, max-content) [sidebar-end]
      repeat(12, [col-start] 1fr [col-end])
    `,
        gridTemplateRows: `
      [appBar-start] max-content [appBar-end
      header-start] minmax(67px, max-content) [header-end
      main-start] 1fr [main-end]
    `,
        height: 'calc(var(--vh, 1vh) * 100)',
        width: '100vw',
    },
    columnSpacing: {
        columnGap: 10,
    },
    electronRoot: {
        gridTemplateRows: `
      [appBar-start] max-content [appBar-end
      header-start] minmax(99px, max-content) [header-end
      main-start] 1fr [main-end]
    `,
    },
    rootSidebarClosed: {
        gridTemplateColumns: `
      [sidebar-start] 0 [sidebar-end]
      repeat(12, [col-start] 1fr [col-end])
    `,
    },
    rootSidebarClosedAuthenticated: {
        gridTemplateColumns: `
      [sidebar-start] max-content [sidebar-end]
      repeat(12, [col-start] 1fr [col-end])
    `,
    },
    appBar: {
        gridColumn: 'sidebar-start / col-end 12',
        gridRow: 'appBar-start / appBar-end',
    },
    header: {
        gridColumn: 'col-start / col-end 12',
        gridRow: 'header-start / header-end',
    },
    sidebar: {
        gridColumn: 'sidebar-start / sidebar-end',
        gridRow: 'header-start / main-end',
    },
    main: {
        gridColumn: 'col-start / col-end 12',
        gridRow: 'main-start / main-end',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: 'auto',
    },
}));
const Default = ({ ccMode, children, status }) => {
    const theme = useTheme();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const sidebar = useAppSelector(selectIsSideBarOpen);
    const sessionLoading = useAppSelector(selectLoading);
    const features = useAppSelector(selectFeatures);
    const avaId = useAppSelector(selectAvaId);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const isInConversation = useAppSelector(selectIsInConversation);
    const fullScreen = useAppSelector(selectFullScreen);
    const classes = useStyles({ fullScreen });
    const [unreadIntercomMessages, setUnreadIntercomMessages] = useState(0);
    useEffect(() => {
        boot({
            app_id: 'eko73i1t',
            created_at: new Date(),
            user_id: avaId,
            email: firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.email,
            horizontal_padding: 80,
        });
        // As the app loads - hide intercom
        document.documentElement.style.setProperty('--intercom-display', `none`);
        // unreadCountChange can only run successfully if it is called after `boot`
        // successfully loads intercom. However there is no way to check when that happens.
        // So we give Intercom generous 5 seconds to load.
        setTimeout(() => 
        // There is no way to stop listening on the intercom events
        unreadCountChange(setUnreadIntercomMessages), 5000);
    }, []);
    useEffect(() => {
        // Programatically hiding and showing intercom is very hard. 'shutdown' and 'boot'
        // seem to work, but only if 'shutdown' is called after 'boot' finishes initializing
        // which is impossible to determine programatically. hide_default_launcher seems
        // to be just plainly ignored. So we are relying on CSS classes and variables
        // to forcefully hide it.
        // Always hide intercom when CCMode is activated.
        if (electronCaptionMode) {
            document.documentElement.style.setProperty('--intercom-display', `none`);
            return;
        }
        // If not in CCMode, and there is a message that the user has not seen -
        // show intercom and do not hide it afterwards, until we go to CC mode again.
        if (unreadIntercomMessages !== 0) {
            document.documentElement.style.setProperty('--intercom-display', `block`);
        }
    }, [electronCaptionMode, unreadIntercomMessages]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classnames({ [classes.root]: !electronCaptionMode }, { [classes.columnSpacing]: (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) || isInConversation }, {
                [!(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) ? classes.rootSidebarClosedAuthenticated : classes.rootSidebarClosed]: !sidebar && !electronCaptionMode,
            }, { [classes.electronRoot]: (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) && window.isElectron && !electronCaptionMode }) },
            window.isElectron && !electronCaptionMode && React.createElement(ElectronLayout, { className: classes.appBar }),
            React.createElement(Header, { className: classnames({
                    [classes.header]: !electronCaptionMode,
                }) }),
            !electronCaptionMode && (React.createElement(Sidebar, { className: classnames({
                    [classes.sidebar]: !electronCaptionMode,
                }), sidebarShown: sidebar, allowSaving: !!status.confidential &&
                    status.confidential.allowTranscriptsSaving &&
                    !!features &&
                    !features['forbid-conversation-saving'] })),
            React.createElement("main", { id: "main-content" // Needed for lazy load transcripts
                , className: classnames({
                    [classes.main]: !electronCaptionMode,
                }), style: {
                    backgroundColor: electronCaptionMode ? 'inherit' : theme.palette.background.default,
                } }, children))));
};
const mapStateToProps = ({ ccMode: { ccMode }, scribeConversation: { status } }) => ({
    ccMode,
    status,
});
export default connect(mapStateToProps)(Default);
