import { setRecording } from '../store/slices/audioRecorder';
import { setRecallAIHost, setRecallAIService, setRecallAIStatus } from '../store/slices/recallAI';
import { setConnectToMeetingsOpen } from '../store/slices/uiState';
import * as segment from './segment';
import { sendConversationModeUpdateMessage, sendWsMessage } from './ws-v1';
export class RecallAIManager {
    constructor(ws, dispatch) {
        this.v1RecallAIStatus = 'ready';
        this.ws = ws;
        this.ws.addEventListener('message', (event) => {
            const message = JSON.parse(event.data);
            this.handleMessage(message);
        });
        this.dispatch = dispatch;
        this.errorOccured = false;
        this.connected = false;
        this.closed = false;
        this.handleMessage = this.handleMessage.bind(this);
        this.serviceProvider = '';
        this.host = undefined;
        this.link = undefined;
        this.currentUserRequested = false;
        this.message = '';
    }
    createBot(speechLang, meetingUrl, currentUserRequested) {
        segment.track('Web - RecallAI - Bot Requested');
        this.dispatch(setRecallAIStatus({ status: 'LOADING' }));
        this.closed = false;
        this.errorOccured = false;
        this.currentUserRequested = currentUserRequested;
        sendWsMessage(this.ws, { type: 'request-ava-bot', speechLang, meetingUrl });
    }
    handleMessage(response) {
        if (!this.closed) {
            if (response.avaBot && !this.errorOccured) {
                const { statusCode, statusSubCode, message, meetingPlatform, requestor } = response.avaBot;
                this.v1RecallAIStatus = statusCode;
                switch (statusCode) {
                    case 'joining_call':
                        // The bot has acknowledged the request to join the call, and is in the process of connecting.
                        // this will always be false for anyone besides the requestor and dispatching these actions will sync
                        // the flow for all other users
                        if (!this.currentUserRequested) {
                            this.dispatch(setConnectToMeetingsOpen(true));
                            this.dispatch(setRecallAIStatus({ status: 'LOADING' }));
                        }
                        this.setServiceProvider(meetingPlatform);
                        this.host = requestor;
                        this.dispatch(setRecallAIHost(requestor));
                        break;
                    case 'in_waiting_room':
                        // The bot is in the "waiting room" of the meeting.
                        if (!this.currentUserRequested) {
                            this.dispatch(setConnectToMeetingsOpen(true));
                        }
                        this.dispatch(setRecallAIStatus({ status: 'WAITING_FOR_HOST' }));
                        setTimeout(() => {
                            this.dispatch(setRecallAIStatus({ status: 'CONNECTING_TO_AUDIO' }));
                        }, 5000);
                        break;
                    case 'in_call_not_recording':
                        // The bot has joined the meeting, however is not recording yet.
                        // This could be because the bot is still setting up, does not have recording permissions, or the recording was paused.
                        break;
                    case 'in_call_recording':
                        // The bot is in the meeting, and is currently recording the audio and video.
                        // this status keeps getting sent while we're connected, so we have to "ignore" it when we're captioning
                        if (!this.connected) {
                            segment.track('Web - RecallAI - Bot Joined');
                            if (!this.currentUserRequested) {
                                this.dispatch(setConnectToMeetingsOpen(true));
                            }
                            if (!this.serviceProvider)
                                this.setServiceProvider(meetingPlatform);
                            this.dispatch(setRecallAIStatus({ status: 'CONNECTED' }));
                            this.dispatch(setRecording(false));
                            sendConversationModeUpdateMessage(this.ws, 'public-muted');
                            this.connected = true;
                            setTimeout(() => {
                                this.dispatch(setRecallAIStatus({ status: 'CAPTIONING' }));
                            }, 1500);
                        }
                        break;
                    case 'call_ended':
                        // The bot has left the call, and the real-time transcription is complete.
                        // we only care about this status when there's a subcode
                        if (statusSubCode && statusSubCode !== 'bot_received_leave_call') {
                            this.errorOccured = true;
                            this.connected = false;
                            sendConversationModeUpdateMessage(this.ws, 'public');
                            this.dispatch(setRecallAIStatus({
                                status: 'ERROR',
                                errorMessage: `connectToOnlineMeetings.errors.${statusSubCode}`,
                            }));
                            setTimeout(() => {
                                this.dispatch(setConnectToMeetingsOpen(false));
                                this.dispatch(setRecallAIStatus({ status: 'NOT_CONNECTED' }));
                            }, 1500);
                        }
                        else {
                            this.message = statusSubCode || '';
                        }
                        break;
                    case 'done':
                        // The video is uploaded and available for download.
                        // if we invoked destroy bot then:
                        if (this.message.length) {
                            this.dispatch(setRecallAIStatus({
                                status: 'ERROR',
                                errorMessage: `connectToOnlineMeetings.errors.${statusSubCode}`,
                            }));
                            sendConversationModeUpdateMessage(this.ws, 'public');
                            this.reset();
                        }
                        break;
                    case 'fatal':
                        // The bot has encountered an error that prevented it from joining the call. The data.status.message field may contain a description of the error.
                        this.errorOccured = true;
                        this.connected = false;
                        this.link = message ? message.split(':').slice(1).join(':').trim() : undefined;
                        let errorMessage = message
                            ? 'connectToOnlineMeetings.errors.ava_bot_requested_already'
                            : 'connectToOnlineMeetings.failed';
                        if (statusSubCode) {
                            errorMessage = `connectToOnlineMeetings.errors.${statusSubCode}`;
                        }
                        this.dispatch(setRecallAIStatus({
                            status: 'ERROR',
                            errorMessage,
                        }));
                        sendConversationModeUpdateMessage(this.ws, 'public');
                        if (errorMessage === 'connectToOnlineMeetings.failed') {
                            setTimeout(() => {
                                this.dispatch(setRecallAIStatus({ status: 'NOT_CONNECTED' }));
                            }, 2000);
                        }
                        else {
                            setTimeout(() => {
                                this.dispatch(setConnectToMeetingsOpen(false));
                                this.dispatch(setRecallAIStatus({ status: 'NOT_CONNECTED' }));
                            }, 2000);
                        }
                        break;
                }
            }
        }
    }
    prepareDisconnect() {
        this.dispatch(setRecallAIStatus({ status: 'DISCONNECT' }));
    }
    destroyBot() {
        sendWsMessage(this.ws, {
            type: 'end-ava-bot',
        });
    }
    reset(avaId) {
        // when someone else leaves call besides the avaBot host
        if (this.connected && avaId && this.host === avaId) {
            sendWsMessage(this.ws, {
                type: 'end-ava-bot',
            });
        }
        this.dispatch(setRecallAIStatus({ status: 'NOT_CONNECTED' }));
        this.dispatch(setConnectToMeetingsOpen(false));
        this.errorOccured = false;
        this.connected = false;
        this.closed = false;
        this.v1RecallAIStatus = 'ready';
        this.host = undefined;
    }
    mobileClick() {
        this.dispatch(setRecallAIStatus({ status: 'DISCONNECT' }));
    }
    handleChangeLang(speechLang) {
        sendWsMessage(this.ws, {
            type: 'update-ava-bot-speech-lang',
            speechLang,
        });
    }
    setServiceProvider(provider) {
        this.serviceProvider = provider;
        this.dispatch(setRecallAIService(this.serviceProvider));
    }
}
let singletonRecallAIManager;
let oldWs;
export const getSingletonRecallAIManager = (ws, dispatch) => {
    if (!oldWs)
        oldWs = ws;
    if (!singletonRecallAIManager || oldWs !== ws) {
        singletonRecallAIManager = new RecallAIManager(ws, dispatch);
    }
    return singletonRecallAIManager;
};
