import Pause from '@mui/icons-material/Pause';
import VolumeOff from '@mui/icons-material/VolumeOff';
import VolumeUp from '@mui/icons-material/VolumeUp';
import Fab from '@mui/material/Fab';
import React, { useEffect, useState } from 'react';
import { selectIsInConversation } from '../../selectors/conversation';
import { useAppSelector } from '../../store/store';
import { singletonTrackPlayer } from '../../utils/audio';
const AudioChunkBuffer = () => {
    const isInConversation = useAppSelector(selectIsInConversation);
    const [secondsBehind, setSecondsBehind] = useState(0);
    const [state, setState] = useState(undefined);
    const [gain, setGain] = useState(1);
    const [delay, setDelay] = useState(0);
    const [paused, setPaused] = useState(false);
    useEffect(() => {
        // TODO: Would make sense to use useSyncExternalStore
        return singletonTrackPlayer.addStateChangeListener((params) => {
            setState((state) => params.state || state);
            setGain((gain) => params.gain || gain);
            setPaused(!!params.paused);
            setSecondsBehind((secondsBehind) => Math.round((params.currentDelay || secondsBehind) * 10) / 10);
            setDelay((delay) => params.currentDelay || delay);
        });
    }, []);
    let secondsBehindS = `${secondsBehind}`;
    if (!secondsBehindS.includes('.')) {
        secondsBehindS = `${secondsBehindS}.0`;
    }
    if (!isInConversation) {
        return null;
    }
    const customGain = Math.pow((gain - 1), 2) > 0.0001;
    return (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'row',
        } },
        state === 'suspended' && (React.createElement(Fab, { style: { marginLeft: 'auto' }, onClick: () => singletonTrackPlayer.play() },
            React.createElement(VolumeOff, null))),
        (state === 'running' || state === 'closed') && delay && (React.createElement("div", { style: {
                padding: 2,
                paddingRight: 5,
                paddingLeft: 5,
                marginRight: 10,
                marginLeft: 10,
                display: 'flex',
                flexDirection: 'row',
            } },
            React.createElement("div", { style: {
                    borderBottomLeftRadius: 7,
                    margin: 'auto',
                    display: 'flex',
                    placeContent: 'center',
                    flexDirection: 'row',
                    borderTopLeftRadius: 7,
                    width: 60,
                    backgroundColor: paused ? 'red' : 'darkgreen',
                } },
                paused && React.createElement(Pause, { style: { fontSize: 16, marginTop: 3 } }),
                !paused && React.createElement(VolumeUp, { style: { fontSize: 16, marginTop: 3 } }),
                React.createElement("div", null, secondsBehindS)),
            React.createElement("div", { style: {
                    whiteSpace: 'nowrap',
                    borderTopRightRadius: 7,
                    borderBottomRightRadius: 7,
                    backgroundColor: 'darkgreen',
                    minWidth: 7,
                    width: 35,
                    margin: 'auto',
                    placeContent: 'center',
                    display: 'flex',
                } }, customGain && React.createElement(React.Fragment, null,
                Math.round(gain * 100),
                "%"))))));
};
export default AudioChunkBuffer;
