import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Member from './Member';
const useStyles = makeStyles((theme) => ({
    select: {
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
    },
}));
const MembersListRow = ({ avaName, avatar, columnClass, email, license, name, role, scribeLimit, }) => {
    const classes = useStyles();
    const captionQuality = license === 'Free' ? '10% errors' : '5% errors';
    const Column = ({ children }) => React.createElement("div", { className: columnClass }, children);
    return (React.createElement(React.Fragment, null,
        React.createElement(Column, null,
            React.createElement(Member, { avaName: avaName, avatar: avatar, name: name })),
        React.createElement(Column, null,
            React.createElement(Typography, null,
                React.createElement(Select, { IconComponent: KeyboardArrowDownIcon, variant: "standard", className: classes.select, disableUnderline: true, value: role },
                    React.createElement(MenuItem, { value: "Admin" }, "Admin"),
                    React.createElement(MenuItem, { value: "User" }, "User")))),
        React.createElement(Column, null,
            React.createElement(Typography, null, email)),
        React.createElement(Column, null,
            React.createElement(Typography, null, license)),
        React.createElement(Column, null,
            React.createElement(Typography, null, captionQuality)),
        React.createElement(Column, null,
            React.createElement(Typography, null, scribeLimit !== null && scribeLimit !== void 0 ? scribeLimit : '-'))));
};
export default MembersListRow;
