import { useTheme } from '@mui/material/styles';
import React from 'react';
const AccountSettings = ({ active, color }) => {
    const theme = useTheme();
    let fill = theme.palette.mode === 'light' ? 'black' : 'white';
    if (color) {
        fill = color;
    }
    if (active) {
        return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("path", { d: "M15.723 9.75334L14.2128 8.60334C14.2319 8.426 14.2484 8.21869 14.2484 7.99934C14.2484 7.78 14.2326 7.57269 14.2128 7.39534L15.7244 6.24469C16.0051 6.02869 16.0818 5.64534 15.9031 5.32603L14.3326 2.68C14.1648 2.38134 13.7972 2.228 13.4241 2.362L11.6421 3.05866C11.3004 2.81866 10.9424 2.616 10.572 2.454L10.3009 0.613344C10.2564 0.264 9.94421 0 9.57522 0H6.42602C6.05701 0 5.74553 0.264 5.70169 0.608L5.42991 2.45534C5.07117 2.61269 4.71861 2.81269 4.36192 3.06L2.57508 2.36134C2.24028 2.23534 1.83978 2.37666 1.67342 2.674L0.100886 5.32334C-0.084633 5.62869 -0.00796721 6.02534 0.278896 6.24669L1.78914 7.39669C1.76517 7.62134 1.75355 7.81669 1.75355 8.00003C1.75355 8.18338 1.7652 8.37869 1.78914 8.60403L0.277516 9.75469C-0.00318562 9.97134 -0.0791775 10.3547 0.0995061 10.6733L1.67002 13.3193C1.83776 13.6173 2.20196 13.772 2.57848 13.6373L4.36054 12.9407C4.70148 13.18 5.05952 13.3827 5.42991 13.5453L5.70102 15.3853C5.7455 15.736 6.05701 16 6.4267 16H9.5759C9.94491 16 10.2571 15.736 10.3009 15.392L10.5727 13.5453C10.9314 13.3873 11.2833 13.188 11.6407 12.94L13.4275 13.6387C13.5138 13.6713 13.6035 13.688 13.6959 13.688C13.9615 13.688 14.2059 13.5467 14.3291 13.3267L15.9065 10.6667C16.0818 10.3547 16.0051 9.97134 15.723 9.75334ZM8.00061 10.6667C6.49037 10.6667 5.26217 9.47066 5.26217 8C5.26217 6.52934 6.49037 5.33334 8.00061 5.33334C9.51085 5.33334 10.739 6.52934 10.739 8C10.739 9.47066 9.51085 10.6667 8.00061 10.6667Z", fill: "white" }),
            React.createElement("path", { d: "M23.8269 20.0958L22.883 19.3771C22.895 19.2663 22.9052 19.1367 22.9052 18.9996C22.9052 18.8625 22.8954 18.7329 22.883 18.6221L23.8278 17.9029C24.0032 17.7679 24.0511 17.5283 23.9394 17.3288L22.9579 15.675C22.853 15.4883 22.6233 15.3925 22.3901 15.4762L21.2763 15.9117C21.0628 15.7617 20.839 15.635 20.6075 15.5338L20.4381 14.3833C20.4102 14.165 20.2151 14 19.9845 14H18.0163C17.7856 14 17.591 14.165 17.5636 14.38L17.3937 15.5346C17.1695 15.6329 16.9491 15.7579 16.7262 15.9125L15.6094 15.4758C15.4002 15.3971 15.1499 15.4854 15.0459 15.6712L14.0631 17.3271C13.9471 17.5179 13.995 17.7658 14.1743 17.9042L15.1182 18.6229C15.1032 18.7633 15.096 18.8854 15.096 19C15.096 19.1146 15.1032 19.2367 15.1182 19.3775L14.1734 20.0967C13.998 20.2321 13.9505 20.4717 14.0622 20.6708L15.0438 22.3246C15.1486 22.5108 15.3762 22.6075 15.6116 22.5233L16.7253 22.0879C16.9384 22.2375 17.1622 22.3642 17.3937 22.4658L17.5631 23.6158C17.5909 23.835 17.7856 24 18.0167 24H19.9849C20.2156 24 20.4107 23.835 20.4381 23.62L20.6079 22.4658C20.8321 22.3671 21.0521 22.2425 21.2754 22.0875L22.3922 22.5242C22.4461 22.5446 22.5022 22.555 22.5599 22.555C22.7259 22.555 22.8787 22.4667 22.9557 22.3292L23.9416 20.6667C24.0511 20.4717 24.0032 20.2321 23.8269 20.0958ZM19.0004 20.6667C18.0565 20.6667 17.2889 19.9192 17.2889 19C17.2889 18.0808 18.0565 17.3333 19.0004 17.3333C19.9443 17.3333 20.7119 18.0808 20.7119 19C20.7119 19.9192 19.9443 20.6667 19.0004 20.6667Z", fill: "white" })));
    }
    return (React.createElement("svg", { width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_628:1326)" },
            React.createElement("path", { d: "M23.2818 15.13L21.0758 13.405C21.1038 13.139 21.1278 12.828 21.1278 12.499C21.1278 12.17 21.1048 11.859 21.0758 11.593L23.2838 9.86703C23.6938 9.54303 23.8058 8.96802 23.5448 8.48905L21.2508 4.52C21.0058 4.07202 20.4688 3.842 19.9238 4.043L17.3208 5.08798C16.8218 4.72798 16.2988 4.424 15.7578 4.181L15.3618 1.42002C15.2968 0.896 14.8408 0.5 14.3018 0.5H9.70181C9.16279 0.5 8.70782 0.896 8.64379 1.412L8.24681 4.18302C7.72279 4.41903 7.20782 4.71903 6.68681 5.09L4.07681 4.04202C3.58776 3.85302 3.00276 4.06498 2.75976 4.511L0.462792 8.48502C0.191808 8.94303 0.303792 9.53802 0.722808 9.87003L2.92879 11.595C2.89378 11.932 2.87681 12.225 2.87681 12.5C2.87681 12.7751 2.89382 13.068 2.92879 13.406L0.720792 15.132C0.310777 15.457 0.199777 16.032 0.460776 16.51L2.75479 20.479C2.99981 20.926 3.53179 21.158 4.08178 20.956L6.68479 19.911C7.18279 20.27 7.70578 20.574 8.24681 20.818L8.64281 23.578C8.70778 24.104 9.16279 24.5 9.70279 24.5H14.3028C14.8418 24.5 15.2978 24.104 15.3618 23.588L15.7588 20.818C16.2828 20.581 16.7968 20.282 17.3188 19.91L19.9288 20.958C20.0548 21.007 20.1858 21.032 20.3208 21.032C20.7088 21.032 21.0658 20.82 21.2458 20.49L23.5498 16.5C23.8058 16.032 23.6938 15.457 23.2818 15.13ZM12.0018 16.5C9.79579 16.5 8.00179 14.706 8.00179 12.5C8.00179 10.294 9.79579 8.50002 12.0018 8.50002C14.2078 8.50002 16.0018 10.294 16.0018 12.5C16.0018 14.706 14.2078 16.5 12.0018 16.5Z", fill: fill })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_628:1326" },
                React.createElement("rect", { width: "24", height: "24", fill: "white", transform: "translate(0 0.5)" })))));
};
export default AccountSettings;
