/* eslint-disable no-shadow */
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { withStyles } from '@mui/styles';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import Toggle from 'react-toggled';
import { clearScribeConversationState } from '../../actions';
import { EmptyIcon, styles } from '../../modules/scribe/SidebarHelpers';
import { selectBackends, selectDisplayDev } from '../../selectors/v1Session';
import { useAppSelector } from '../../store/store';
import { updateBackendAva } from '../../utils/http';
const ServerSettings = React.memo(function ServerSettingsComponent({ classes, t, }) {
    const displayDev = useSelector(selectDisplayDev);
    const backends = useAppSelector(selectBackends);
    if (!displayDev) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Divider, null),
        React.createElement(Toggle, null, ({ on, getTogglerProps }) => (React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'column',
            } },
            React.createElement(ListItem, Object.assign({ button: true }, getTogglerProps()),
                React.createElement(ListItemIcon, null,
                    React.createElement(CloudOutlinedIcon, null)),
                React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: t('sidebar.serverSettings') }),
                React.createElement(ListItemIcon, { classes: { root: classes.expandIcon } }, on ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null))),
            React.createElement(Collapse, { in: on },
                React.createElement(List, null, Object.keys(backends).map((backendId) => {
                    const backendColor = backends[backendId].color;
                    return (React.createElement(ListItem, { key: backendId, button: true, style: {
                            display: localStorage.getItem('avaBackendUrl') === backends[backendId].url ? 'none' : undefined,
                        } },
                        React.createElement(EmptyIcon, null),
                        React.createElement(ListItemText, { classes: { root: classes.listItemText }, onClick: () => updateBackendAva(backendId, backends), primary: backends[backendId].name ? backends[backendId].name : capitalize(backendId), style: {
                                backgroundColor: backendColor,
                                color: 'white',
                                fontSize: 14,
                            } })));
                }))))))));
});
const mapStateToProps = ({}) => ({});
export default connect(mapStateToProps, {
    clearScribeConversationState,
})(withTranslation()(withStyles(styles, { withTheme: true })(ServerSettings)));
