import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import classnames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectFontSize } from '../../../selectors/ui';
import { selectAvaId } from '../../../selectors/userProfile';
import { useAppSelector } from '../../../store/store';
import useStyles from './KeyboardInput.styles';
const KeyboardInput = ({ onCloseKeyboard, scribeCreateTranscript, scribeDeselectTranscriptWord }) => {
    const fontSize = useAppSelector(selectFontSize);
    const classes = useStyles({ fontSize });
    const { t } = useTranslation();
    const avaId = useAppSelector(selectAvaId);
    const [message, setMessage] = useState('');
    const sendMessage = () => {
        scribeDeselectTranscriptWord();
        scribeCreateTranscript(undefined, message, avaId);
        scribeDeselectTranscriptWord();
        setMessage('');
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement("section", { className: classes.inputContainer },
            React.createElement("button", { className: classes.closeKeyboardInputButton, onClick: onCloseKeyboard },
                React.createElement(KeyboardArrowUpIcon, { className: classes.keyboardArrowUpIcon })),
            React.createElement("div", { className: classes.messageContainer },
                React.createElement("textarea", { className: classes.input, rows: "1", placeholder: t('conversation.typeTranscriptHere'), value: message, onChange: (event) => setMessage(event.target.value), onKeyDown: (event) => {
                        if (['Enter'].includes(event.key)) {
                            // User is pressing shift + enter, allow the default action of
                            // adding another line and don't send a message.
                            if (!event.shiftKey) {
                                event.preventDefault();
                                sendMessage();
                            }
                        }
                    }, spellCheck: false, autoFocus: true })),
            React.createElement(Tooltip, { classes: { tooltip: classes.tooltip, tooltipPlacementTop: classes.sendTooltipPlacementTop }, title: t('ccMode.keyboard.tooltip.send'), placement: "top", disableInteractive: true },
                React.createElement("div", { className: classnames(classes.sendContainer, { [classes.visible]: !!message }) },
                    React.createElement(IconButton, { className: classes.sendButton, onClick: sendMessage, size: "large" },
                        React.createElement(SendIcon, { className: classes.sendIcon })))))));
};
export default KeyboardInput;
