import { connect } from 'react-redux';
import { createNewConversation } from '../../../actions';
import { setCCMode, setShowBubbleAboveCCModeDebounced } from '../../../store/slices/ccMode';
import { setRate, setRateConversationOpen, setRateHover } from '../../../store/slices/rateConversation';
import RatingView from './RatingView';
const mapStateToProps = ({ rateConversation: { conversationStatus, isOpen, rate, rateHover } }) => ({
    conversationStatus,
    isRatingOpen: isOpen,
    rate,
    rateHover,
});
const mapDispatchToProps = {
    createNewConversation,
    setCCMode,
    setRate,
    setRateConversationOpen,
    setRateHover,
    setShowBubbleAboveCCMode: setShowBubbleAboveCCModeDebounced,
};
export default connect(mapStateToProps, mapDispatchToProps)(RatingView);
