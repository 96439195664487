import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { selectFirebaseUser } from '../../selectors/auth';
import { useAppSelector } from '../../store/store';
export function ApplessParticipantAvatar({ speaker, borderColor }) {
    var _a, _b, _c, _d, _e, _f;
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const theme = useTheme();
    if (!speaker) {
        return null;
    }
    const avatarUrl = speaker.userAvatar || speaker.photoUrl || (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.photoURL);
    const speakerBackgroundColor = ((_b = (_a = speaker.theme) === null || _a === void 0 ? void 0 : _a.light_theme) === null || _b === void 0 ? void 0 : _b.background_color) || theme.palette.success.light;
    const speakerTextColor = ((_d = (_c = speaker.theme) === null || _c === void 0 ? void 0 : _c.light_theme) === null || _d === void 0 ? void 0 : _d.text_color) || 'white';
    const speakerColor = ((_f = (_e = speaker.theme) === null || _e === void 0 ? void 0 : _e.light_theme) === null || _f === void 0 ? void 0 : _f.speaker_color) || theme.palette.success.dark;
    return (React.createElement(Box, { sx: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '30px',
            width: '30px',
            borderRadius: '16px',
            border: '2px solid',
            borderColor: borderColor || speakerBackgroundColor,
            backgroundColor: speakerBackgroundColor,
            color: speakerColor,
            overflow: 'hidden',
        } },
        avatarUrl && React.createElement("img", { src: avatarUrl, style: { height: 30 }, alt: "Avatar" }),
        !avatarUrl && React.createElement("p", null, speaker.userName[0].toUpperCase())));
}
