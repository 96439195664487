// @flow
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { WorkspaceUIContext } from './context/WorkspaceUIContext';
import Members from './Members';
import Sidebar from './Sidebar';
import WorkspaceLink from './WorkspaceLink';
const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
        padding: 40,
    },
    navContainer: {
        marginBottom: 20,
    },
}));
const Workspace = () => {
    const [sidebarView, setSidebarView] = useState();
    const classes = useStyles();
    const { t } = useTranslation();
    const handleSetSidebarView = (view) => setSidebarView(view);
    const links = [
        {
            title: t('workspace.nav.members'),
            to: '/workspace/members',
        },
        {
            disabled: true,
            title: t('workspace.nav.reporting'),
            to: '/workspace/reporting',
        },
        {
            disabled: true,
            title: t('workspace.nav.orgVocabulary'),
            to: '/workspace/org-vocabulary',
        },
        {
            disabled: true,
            title: t('workspace.nav.payment'),
            to: '/workspace/payment',
        },
        {
            disabled: true,
            title: t('workspace.nav.org'),
            to: '/workspace/organization',
        },
    ];
    const Wrapper = () => (React.createElement(WorkspaceUIContext.Provider, { value: {
            setSidebarView: handleSetSidebarView,
            sidebarView,
        } },
        React.createElement("div", { className: classes.container },
            React.createElement("div", null,
                React.createElement("div", { className: classes.navContainer }, links.map((link) => (React.createElement(WorkspaceLink, { key: link.to, link: link })))),
                React.createElement(Outlet, null))),
        React.createElement(Sidebar, null)));
    return (React.createElement(Routes, null,
        React.createElement(Route, { path: "*", element: React.createElement(Wrapper, null) },
            React.createElement(Route, { path: "members", element: React.createElement(Members, null) }),
            React.createElement(Route, { index: true, path: "*", element: React.createElement(Navigate, { to: "/workspace/members" }) }))));
};
export default Workspace;
