import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { withTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { analytics } from '../firebase';
function SnackbarWithCountdown(props) {
    const { classes, countdown, countdownBegin, message, theme, additionalActionMessage, additionalActionUrl, appSubmittedTypeform, } = props;
    const [timeLeft, setTimeLeft] = useState(countdown);
    const [closed, setClosed] = useState(true);
    const [formattedMins, setFormattedMins] = useState('0');
    const [formattedSecs, setFormattedSecs] = useState('00');
    useEffect(() => {
        // If the countdownBegin changed, we un-close the snackbar.
        if (countdownBegin)
            setClosed(false);
    }, [countdownBegin]);
    useEffect(() => {
        const changeTimeLeft = () => {
            const timeElapsed = Date.now() - countdownBegin;
            const currentTimeLeft = Math.floor(Math.max(0, props.countdown - timeElapsed / 1000));
            setTimeLeft(currentTimeLeft);
            setFormattedMins(Math.floor(currentTimeLeft / 60).toString());
            const currentSecLeft = currentTimeLeft % 60;
            // 11 -> '11', but '6' -> '06'. JS does not have nice format strings :(
            setFormattedSecs((currentSecLeft < 10 ? '0' : '') + currentSecLeft.toString());
        };
        changeTimeLeft();
        // We want it to update at lest every 1s, to have a consistent 1s
        // countdown. However, setInterval guarantees it will be executed after
        // at least the specified time happens. So it's better to execute a little
        // more often, to catch every second.
        const interval = setInterval(changeTimeLeft, 900);
        return () => clearInterval(interval);
    });
    return (React.createElement("div", null,
        React.createElement(Snackbar, { classes: classes, anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, open: !closed && timeLeft > 0 },
            React.createElement(SnackbarContent, { "aria-describedby": "client-snackbar", message: 
                // eslint-disable-next-line
                React.createElement("span", { id: "client-snackbar" }, message.replace('$min', formattedMins).replace('$sec', formattedSecs)), style: {
                    backgroundColor: theme.palette.ava.accent,
                }, action: [
                    React.createElement(Button, { key: "additional", href: additionalActionUrl, onClick: () => {
                            setClosed(true);
                            analytics.logEvent('app_submitted_typeform', Object.assign({}, appSubmittedTypeform));
                        }, target: "_blank", rel: "noreferrer noopener", variant: "outlined", style: {
                            borderColor: 'white',
                            borderRadius: 20,
                            color: 'white',
                            fontSize: '0.9em',
                            textTransform: 'none',
                        } }, additionalActionMessage),
                    React.createElement(IconButton, { key: "close", "aria-label": "Close", color: "inherit", onClick: () => setClosed(true), size: "large" },
                        React.createElement(CloseIcon, null)),
                ] }))));
}
export default withTheme(withTranslation()(SnackbarWithCountdown));
