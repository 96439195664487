import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import RaisedContainer from '../../components/Containers/RaisedContainer';
import { selectIsSideBarOpen } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import { getIsMac } from '../../utils/electron';
const getNavbarTopPosition = () => {
    let position = 110;
    if (window.isElectron) {
        position = getIsMac() ? position + 28 : position + 32;
    }
    return position;
};
const getNavbarWidthReduction = (sidebarOpen) => {
    let reduction = sidebarOpen ? 352 : 170;
    if (window.isElectron && !getIsMac()) {
        reduction -= 16;
    }
    return `${reduction}px`;
};
const useStyles = makeStyles((theme) => ({
    offsetLayer: {
        height: 90,
    },
    topLayer: {
        position: 'fixed',
        top: 76,
        left: 0,
        height: 98,
        width: '100%',
        background: theme.palette.background.default,
        zIndex: 30,
    },
    navigation: {
        position: 'fixed',
        top: getNavbarTopPosition(),
        zIndex: 40,
        display: 'flex',
        alignItems: 'flex-end',
        paddingRight: 40,
        paddingLeft: 40,
        borderRadius: '20px 20px 0px 0px',
        height: 64,
        width: (props) => `calc(100% - ${getNavbarWidthReduction(props.sidebarOpen)})`,
    },
    item: {
        marginRight: 40,
        fontSize: 22,
        color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.white,
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover, &:focus': {
            color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.white,
            textDecoration: 'none',
        },
        paddingBottom: 6,
        borderBottom: '6px solid transparent',
    },
    active: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.blue1 : theme.palette.ava.icon.dark1,
        cursor: 'default',
    },
    border: {
        borderBottom: `6px solid ${theme.palette.mode === 'light' ? theme.palette.ava.blue1 : theme.palette.ava.icon.dark1}`,
    },
}));
const Navigation = () => {
    const sidebarOpen = useAppSelector(selectIsSideBarOpen);
    const classes = useStyles({ sidebarOpen });
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const myProfileActive = pathname.includes('/web/account-settings/my-profile') || pathname === '/web/account-settings';
    const generalActive = pathname.includes('/web/account-settings/general');
    if (!myProfileActive && !generalActive) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classes.offsetLayer }),
        React.createElement("div", { className: classes.topLayer }),
        React.createElement(RaisedContainer, { className: classes.navigation },
            React.createElement(Link, { className: classnames(classes.item, {
                    [classes.active]: myProfileActive,
                    [classes.border]: myProfileActive,
                }), to: "/web/account-settings/my-profile" },
                React.createElement("span", { className: classnames({ [classes.active]: myProfileActive }) }, t('profileMenu.myProfile'))),
            React.createElement(Link, { className: classnames(classes.item, {
                    [classes.active]: generalActive,
                    [classes.border]: generalActive,
                }), to: "/web/account-settings/general" },
                React.createElement("span", { className: classnames({ [classes.active]: generalActive }) }, t('accountSettings.navigation.general'))))));
};
export default Navigation;
