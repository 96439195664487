import { createSelector } from '@reduxjs/toolkit';
const selectRecallAI = (state) => state.recallAI;
const selectUserProfile = (state) => state.userProfile;
export const selectRecallAIStatus = createSelector([selectRecallAI], (recallAI) => recallAI.recallAIStatus);
export const selectRecallAIError = createSelector([selectRecallAI], (recallAI) => recallAI.errorMessage);
export const selectRecallAIService = createSelector([selectRecallAI], (recallAI) => recallAI.service);
export const selectRecallAIManager = createSelector([selectRecallAI], (recallAI) => recallAI.recallAIManager);
// to make sure we're not disabling the button while not connected
export const selectIsRecallAIHost = createSelector([selectRecallAI, selectUserProfile], (recallAI, userProfile) => {
    var _a;
    return recallAI.recallAIStatus !== 'CAPTIONING' ||
        (recallAI.recallAIStatus === 'CAPTIONING' && recallAI.host === ((_a = userProfile.parse) === null || _a === void 0 ? void 0 : _a.avaId));
});
