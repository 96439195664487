import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles({
    valueContainer: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        maxWidth: 170,
        height: 50,
        marginLeft: ({ landing }) => (landing ? 148 : 15),
        overflow: 'hidden',
    },
});
const ValueContainer = ({ children, selectProps }) => {
    const classes = useStyles({
        landing: selectProps.landing,
    });
    return React.createElement("div", { className: classes.valueContainer }, children);
};
export default ValueContainer;
