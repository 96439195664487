import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark4,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        boxShadow: theme.palette.mode === 'light'
            ? '0px 0px 20px rgba(112, 144, 176, 0.25)'
            : '0px 0px 20px rgba(112, 144, 176, 0.04)',
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
    },
}));
const RaisedContainer = ({ className, children }) => {
    const classes = useStyles();
    return React.createElement("div", { className: classnames(classes.container, className) }, children);
};
export default RaisedContainer;
