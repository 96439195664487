import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '../../../components/Icons/ArrowRightIcon';
import BoostUpgradePopup from '../../../components/Popups/BoostUpgradePopup';
import { analytics } from '../../../firebase';
import { selectOrganization, selectSubscription } from '../../../selectors/userProfile';
import LocalStorage, { STORAGE_KEYS } from '../../../services/localStorage';
import { useAppSelector } from '../../../store/store';
import { getLanguageCode } from '../../../utils/i18n';
import { getPlanType, isBoostEnabled } from '../../../utils/status';
const useStyles = makeStyles((theme) => ({
    item: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
        marginBottom: 20,
        maxWidth: 650,
        cursor: 'pointer',
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        '&:hover, &:focus': {
            color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
            textDecoration: 'none',
        },
    },
}));
const getTypeformUrl = (organization, subscription) => {
    const lang = getLanguageCode();
    const typeformId = lang === 'fr' ? 'R8L5rC' : 'q2n11h';
    const med = window.isElectron ? 'cc' : 'webapp';
    const mkt = (organization === null || organization === void 0 ? void 0 : organization.type) || 'ind';
    const url = `https://ava-me.typeform.com/to/${typeformId}?lang=${lang}&chn=inb&src=boost&med=${med}&mkt=${mkt}#userid=${LocalStorage.get(STORAGE_KEYS.AVA_ID) || ''}`;
    analytics.logEvent('app_submitted_typeform', {
        channel: 'inbound',
        source: 'boost',
        market: mkt === 'ind' ? 'individual' : mkt,
        organization_name: organization === null || organization === void 0 ? void 0 : organization.name,
        plan: getPlanType(subscription),
    });
    return url;
};
const BoostSettings = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const organization = useAppSelector(selectOrganization);
    const subscription = useAppSelector(selectSubscription);
    const clickUpgrade = () => {
        setDialogOpen(false);
        window.open(getTypeformUrl(organization, subscription), '_blank');
    };
    const boostEnabled = isBoostEnabled(subscription);
    return (React.createElement(React.Fragment, null,
        boostEnabled ? (React.createElement(Link, { className: classes.item, to: "/web/account-settings/boost" },
            React.createElement("span", null, t('accountSettings.general.vocabularyBoost')),
            React.createElement(ArrowRightIcon, null))) : (React.createElement("div", { className: classes.item, onClick: () => setDialogOpen(true) },
            React.createElement("span", null, t('accountSettings.general.vocabularyBoost')),
            React.createElement(ArrowRightIcon, null))),
        dialogOpen && (React.createElement(BoostUpgradePopup, { open: dialogOpen, onCancel: () => setDialogOpen(false), onConfirm: clickUpgrade }))));
};
export default BoostSettings;
