import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    wrapper: {
        left: '0',
        height: '60px',
        width: '100%',
        zIndex: 999999,
        backgroundColor: '#dadce0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > span': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '31px',
            margin: '0 16px',
            color: '#000',
        },
        '& > button': {
            borderRadius: '50px',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '26px',
            padding: '8px 24px',
            border: `1px solid ${theme.palette.ava.blue}`,
            color: theme.palette.ava.blue,
        },
    },
}));
function Status() {
    return null;
    /*
    TODO: Figure out how to check offline status without relying on onLine.
    const { t } = useTranslation();
    const isInConversation = useAppSelector(selectIsInConversation);
    const classes = useStyles();
  
    const handleReconnect = () => {
      window.location.reload();
    };
  
    return (
      <div className={classes.wrapper}>
        <NoWiFi />
        <span>{t<string>('network.currentlyOffline')}</span>
        {!window.isElectron && <Button onClick={handleReconnect}>{t<string>('network.tryToReconnect')}</Button>}
      </div>
    );
     */
}
export default Status;
