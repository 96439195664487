import { ClickAwayListener, DialogTitle } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Input from '@mui/material/InputBase';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AvatarLogo from '../../components/Icons/AvatarLogo';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
import { sendAssignVoicingSpearkerName } from '../../utils/ws-v1';
const SoloDiaNameModalContext = React.createContext({
    speaker: null,
    isInternalAudio: false,
    onSelect: () => ({}),
});
export const useSoloDiaSpeakerNameModalContext = () => {
    const context = React.useContext(SoloDiaNameModalContext);
    if (!context) {
        throw new Error('SoloDiaNameModalProvider is not in the component tree.');
    }
    return context;
};
export const SoloDiaNameModalProvider = ({ children }) => {
    const [state, setState] = React.useState({
        speaker: null,
        isInternalAudio: false,
    });
    function onSelect(speaker, isInternalAudio) {
        setState({ speaker, isInternalAudio });
    }
    return React.createElement(SoloDiaNameModalContext.Provider, { value: Object.assign(Object.assign({}, state), { onSelect }) }, children);
};
const useStyles = makeStyles((theme) => ({
    speakerNameWrapper: {
        border: '1px solid',
        borderColor: theme.palette.ava.blue2,
        borderRadius: '500px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: (props) => (props.electronCaptionMode ? 0 : undefined),
    },
    speakerNameInput: {
        paddingLeft: '20px',
    },
    speakerNameButton: {
        backgroundColor: theme.palette.ava.blue2,
        color: 'white',
        padding: (props) => (props.electronCaptionMode ? 2.5 : undefined),
        borderRadius: '500px',
        '&:disabled': {
            color: 'white',
            opacity: 0.2,
        },
        '&:hover': {
            background: theme.palette.ava.blue4,
        },
    },
    // container for solo dia names UI
    soloDiaParticipantContainer: {
        padding: 6,
        borderRadius: 15,
        maxWidth: 450,
        background: theme.palette.ccMode.backgroundGradient,
        marginLeft: 25,
    },
}));
const SoloDiaSpeakerNameModal = () => {
    const { t } = useTranslation();
    const { speaker, onSelect } = useSoloDiaSpeakerNameModalContext();
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const classes = useStyles({ electronCaptionMode });
    const [speakerName, setSpeakerName] = React.useState('');
    const [ws] = useV1Socket();
    function onClose() {
        onSelect(null, false);
    }
    function assignSpeakerName() {
        if (speaker) {
            sendAssignVoicingSpearkerName(ws, speaker.avaId, speakerName);
            onClose();
        }
    }
    if (!speaker) {
        return null;
    }
    if (electronCaptionMode) {
        return (React.createElement(ClickAwayListener, { onClickAway: onClose },
            React.createElement("div", { className: classes.soloDiaParticipantContainer },
                React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                    React.createElement("div", { style: { marginRight: '10px' } },
                        speaker && speaker.userAvatar && (React.createElement(Avatar, { key: "avatar", src: speaker.userAvatar, alt: "speaker_photo", style: {
                                width: 32,
                                height: 32,
                            } })),
                        speaker && !speaker.userAvatar && React.createElement(AvatarLogo, { color: speaker.theme.color, width: 32, height: 32 })),
                    React.createElement("div", { className: classes.speakerNameWrapper },
                        React.createElement(Input, { placeholder: t('transcripts.suggestSpeakerPlaceholder'), fullWidth: true, autoFocus: true, className: classes.speakerNameInput, value: speakerName, onChange: (e) => setSpeakerName(e.currentTarget.value) }),
                        React.createElement(Button, { disabled: speakerName.length === 0, className: classes.speakerNameButton, onClick: assignSpeakerName }, t('transcripts.suggestSpeakerButtonText')))))));
    }
    return (React.createElement(Dialog, { onClose: onClose, open: true, maxWidth: "xs", fullWidth: true },
        React.createElement(DialogTitle, null, t('transcripts.suggestedSpeakers')),
        React.createElement(DialogContent, null,
            React.createElement("div", { style: { display: 'flex', alignItems: 'center' } },
                React.createElement("div", { style: { marginRight: '10px' } },
                    speaker && speaker.userAvatar && (React.createElement(Avatar, { key: "avatar", src: speaker.userAvatar, alt: "speaker_photo", style: {
                            width: 32,
                            height: 32,
                        } })),
                    speaker && !speaker.userAvatar && React.createElement(AvatarLogo, { color: speaker.theme.color, width: 32, height: 32 })),
                React.createElement("div", { className: classes.speakerNameWrapper },
                    React.createElement(Input, { placeholder: t('transcripts.suggestSpeakerPlaceholder'), fullWidth: true, autoFocus: true, className: classes.speakerNameInput, value: speakerName, onChange: (e) => setSpeakerName(e.currentTarget.value) }),
                    React.createElement(Button, { disabled: speakerName.length === 0, className: classes.speakerNameButton, onClick: assignSpeakerName, color: "primary" }, t('transcripts.suggestSpeakerButtonText')))))));
};
export default SoloDiaSpeakerNameModal;
