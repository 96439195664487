import { createSelector } from '@reduxjs/toolkit';
const selectUi = (state) => state.ui;
export const selectCorrectionMode = createSelector([selectUi], (ui) => ui.correctionMode);
export const selectElectronCaptionMode = createSelector([selectUi], (ui) => ui.electronCaptionMode);
export const selectFullScreen = createSelector([selectUi], (ui) => ui.fullScreen);
export const selectDisplayShortcuts = createSelector([selectUi], (ui) => ui.displayShortcuts);
export const selectInsertSpeakerNameMode = createSelector([selectUi], (ui) => ui.insertSpeakerNameMode);
export const selectMetaDown = createSelector([selectUi], (ui) => ui.metaDown);
export const selectIsInviteCardOpen = createSelector([selectUi], (ui) => ui.isInviteCardOpen);
export const selectIsSideBarOpen = createSelector([selectUi], (ui) => ui.sidebar);
export const selectLoading = createSelector([selectUi], (ui) => ui.loading !== undefined && ui.loading);
export const selectFontSize = createSelector([selectUi], (ui) => ui.fontSize);
export const selectIsIntercomOpen = createSelector([selectUi], (ui) => ui.intercomOpen);
export const selectElectronAppLoaded = createSelector([selectUi], (ui) => ui.electronAppLoaded !== undefined && ui.electronAppLoaded);
export const selectIsWordBeingEdited = createSelector([selectUi], (ui) => ui.wordBeingEdited);
export const selectKeyboardInputShown = createSelector([selectUi], (ui) => ui.keyboardInputShown);
export const selectIsConnectToMeetingOpen = createSelector([selectUi], (ui) => ui.isConnectToMeetingsOpen);
