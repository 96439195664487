import ListItem from '@mui/material/ListItem';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { selectIsSideBarOpen } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
const useStyles = makeStyles((theme) => ({
    item: {
        padding: '12px 28px',
        '&:hover': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.ava.navy5 : theme.palette.ava.grey5,
        },
    },
    active: {
        backgroundColor: theme.palette.ava.navy,
        color: theme.palette.ava.white,
        '&:hover': {
            cursor: 'default',
            backgroundColor: theme.palette.ava.navy,
        },
    },
    text: {
        marginLeft: 16,
        fontSize: 18,
    },
    disabled: { opacity: '1 !important' },
}));
const SidebarItem = ({ active, className, disableOnClick, icon, text, onClick }) => {
    const classes = useStyles();
    const sidebarOpen = useAppSelector(selectIsSideBarOpen);
    const [textVisible, setTextVisible] = useState(true);
    useEffect(() => {
        if (sidebarOpen) {
            setTimeout(() => setTextVisible(true), 200);
        }
        else {
            setTextVisible(false);
        }
    }, [sidebarOpen]);
    return (React.createElement(ListItem, { className: classnames(classes.item, className, {
            [classes.active]: active,
        }), classes: { disabled: classes.disabled }, role: "button", button: true, disabled: active, onClick: disableOnClick ? null : onClick },
        icon,
        textVisible && React.createElement("span", { className: classes.text }, text)));
};
export default SidebarItem;
