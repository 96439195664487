// This is the modal that opens for scribes to add or remove words from the boost list
import { DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import React, { useState } from 'react';
import useBoostManager from '../../hooks/useBoostManager';
import { setIsBoosting } from '../../store/slices/boost';
import { useAppDispatch } from '../../store/store';
const BoostModal = ({ open, fullScreen, boostList }) => {
    const [boost, setBoost] = useState('');
    const dispatch = useAppDispatch();
    const boostManager = useBoostManager();
    if (!boostManager)
        return null;
    const onCreate = (word) => {
        boostManager.handleScribeAddOrRemoveBoostWords('add', word);
    };
    const onDelete = (word) => {
        boostManager.handleScribeAddOrRemoveBoostWords('remove', word);
    };
    return (React.createElement(Dialog, { open: open, fullScreen: fullScreen },
        React.createElement(DialogContent, null,
            React.createElement(DialogTitle, { variant: "h5", style: { alignSelf: 'center' } }, "Boost")),
        React.createElement("div", null,
            React.createElement("div", { className: "row inputs" },
                React.createElement("div", { className: "col-lg-7" },
                    React.createElement("input", { type: "text", name: "band", placeholder: "boost", onChange: (e) => setBoost(e.target.value), value: boost, className: "form-control", size: 20 })),
                React.createElement("div", { className: "col-md-3" },
                    React.createElement("button", { type: "button", style: { width: '100%' }, className: "btn btn-success", onClick: () => {
                            onCreate(boost);
                        } }, "Add"))),
            React.createElement("table", { className: "table" },
                React.createElement("tbody", null, boostList.map((data) => {
                    return (React.createElement("tr", null,
                        React.createElement("td", { style: { width: '30%' } }, data),
                        React.createElement("td", { style: { width: '10%' } },
                            React.createElement("button", { type: "button", className: "btn btn-danger", onClick: () => onDelete(data) }, "Delete"))));
                })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { type: "submit", onClick: () => {
                    dispatch(setIsBoosting(false));
                }, color: "secondary" }, "Quit"))));
};
export default BoostModal;
