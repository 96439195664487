import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    list: {
        paddingTop: 0,
        paddingBottom: 0,
        color: theme.palette.ava.dark1,
    },
}));
const DropdownMenu = ({ id, children, anchorEl, anchorOrigin, transformOrigin, paperStyle, width, onClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const open = Boolean(anchorEl);
    return (React.createElement(Menu, { id: id, anchorEl: anchorEl, open: open, onClose: onClose, onClick: onClose, classes: {
            list: classes.list,
        }, PaperProps: {
            style: Object.assign({ width, background: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark2, boxShadow: theme.palette.mode === 'light'
                    ? '0px 4px 24px rgba(112, 144, 176, 0.25)'
                    : '0px 4px 4px rgba(112, 144, 176, 0.04)', borderRadius: 8 }, paperStyle),
        }, anchorOrigin: Object.assign({ vertical: 'bottom', horizontal: 'left' }, anchorOrigin), transformOrigin: Object.assign({ vertical: 'top', horizontal: 'left' }, transformOrigin) }, children));
};
export default DropdownMenu;
