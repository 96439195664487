var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import SettingsVoiceOutlinedIcon from '@mui/icons-material/SettingsVoiceOutlined';
import { ListItemIcon } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { withTheme } from '@mui/styles';
import { withSnackbar } from 'notistack';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AvaMic from '../../components/Icons/AvaMic';
import { selectAvailableMics, selectMicIdSelected } from '../../selectors/audioRecorder';
import LocalStorage, { STORAGE_KEYS } from '../../services/localStorage';
import { setMicId } from '../../store/slices/audioRecorder';
import { setOnlyInternalAudio } from '../../store/slices/audioRecorder';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { isInternalAudio, ONLY_INTERNAL_AUDIO_MIC_ID } from '../../utils/internalAudio';
import { getSnack } from '../../utils/snackbar';
const MicSelector = ({ enqueueSnackbar, closeSnackbar }) => {
    const { t } = useTranslation();
    const availableMics = useAppSelector(selectAvailableMics);
    const micIdSelected = useAppSelector(selectMicIdSelected);
    const dispatch = useAppDispatch();
    const previousMicSelection = useRef();
    const handleChange = (event) => __awaiter(void 0, void 0, void 0, function* () {
        const newMicSelection = availableMics.find((mic) => mic.value === event.target.value);
        LocalStorage.set(STORAGE_KEYS.SELECTED_MIC_ID, event.target.value);
        const dispatchRes = yield dispatch(setMicId({ micId: event.target.value, oldMic: previousMicSelection.current })).unwrap();
        previousMicSelection.current = newMicSelection;
        if (dispatchRes.notify) {
            getSnack({ enqueueSnackbar, closeSnackbar })(t('macInternalAudio.enabling'));
        }
        dispatch(setOnlyInternalAudio(event.target.value.includes(ONLY_INTERNAL_AUDIO_MIC_ID)));
    });
    const prevAvailableMics = useRef(availableMics);
    useEffect(() => {
        if (availableMics.length - prevAvailableMics.current.length === 1) {
            let newMicValue;
            availableMics.forEach((newMic) => {
                let micFound = false;
                prevAvailableMics.current.forEach((prevMic) => {
                    if (prevMic.value === newMic.value) {
                        micFound = true;
                    }
                });
                if (!micFound && !isInternalAudio(newMic)) {
                    newMicValue = newMic.value;
                }
            });
            const currentMic = prevAvailableMics.current.find((m) => m.value === micIdSelected);
            if (isInternalAudio(currentMic)) {
                // We do nothing as we want to stay on "Ava Mic"
            }
            else if (newMicValue) {
                dispatch(setMicId({ micId: newMicValue }));
            }
        }
        prevAvailableMics.current = availableMics;
    }, [availableMics, setMicId, micIdSelected]);
    if (!micIdSelected) {
        return React.createElement("div", null);
    }
    const selectedMic = availableMics.find((mic) => mic.value === micIdSelected);
    if (!selectedMic || !selectedMic.label) {
        return React.createElement("div", null);
    }
    return (React.createElement("div", null,
        React.createElement(ListItem, { key: "mic_selected", role: undefined, dense: true },
            React.createElement(ListItemIcon, null,
                React.createElement(SettingsVoiceOutlinedIcon, null)),
            React.createElement(Select, { variant: "standard", value: micIdSelected, onChange: handleChange, style: { overflow: 'hidden', width: '100%' }, disabled: selectedMic && availableMics.length < 2 }, availableMics.map((x) => (React.createElement(MenuItem, { key: x.value, value: x.value },
                React.createElement("span", { style: { display: 'flex', alignItems: 'center' } },
                    isInternalAudio(x) && React.createElement(AvaMic, { style: { marginRight: 10 } }),
                    React.createElement("span", null, isInternalAudio(x) ? 'Ava Mic' : x.label)))))))));
};
export default withSnackbar(withTheme(MicSelector));
