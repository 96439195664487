var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { selectFirebaseLoginInProgress } from '../selectors/combined';
import { useAppSelector } from '../store/store';
const useStyles = makeStyles(() => ({
    signinButton: {
        '&:hover': {
            opacity: 0.8,
            textDecoration: 'none',
        },
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 24,
        display: 'flex',
        fontSize: 16,
        fontWeight: 600,
        height: 48,
        marginTop: 16,
        width: 270,
        overflow: 'hidden',
        padding: 2,
        paddingRight: 28,
        position: 'relative',
        textAlign: 'left',
        textTransform: 'none',
    },
    link: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
    disabledSigninButton: {
        opacity: 0.8,
        cursor: 'default',
    },
    signinLogoWrap: {
        alignItems: 'center',
        display: 'flex',
        flexShrink: 0,
        justifyContent: 'center',
        height: 48,
        marginLeft: 8,
        overflow: 'hidden',
        width: 48,
    },
    signinTextWrap: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: 8,
        width: '100%',
        lineHeight: 1.2,
    },
    buttonProgress: {
        left: '50%',
        marginLeft: -12,
        marginTop: -12,
        position: 'absolute',
        top: '50%',
    },
}));
export const LoginButton = (props) => {
    const loading = useAppSelector(selectFirebaseLoginInProgress);
    const classes = useStyles();
    const { icon, login, text, to, style } = props;
    const MaybeLink = (_a) => {
        var { children } = _a, linkProps = __rest(_a, ["children"]);
        return to ? (React.createElement(Link, Object.assign({ to: to }, linkProps), children)) : (children);
    };
    return (React.createElement(MaybeLink, { className: classes.link },
        React.createElement(Button, { className: `${classes.signinButton} ${loading ? classes.disabledSigninButton : ''}`, disabled: loading, "aria-label": "signup", onClick: login, style: style },
            React.createElement("div", { className: `${classes.signinLogoWrap}` }, icon),
            React.createElement("span", { className: classes.signinTextWrap }, text),
            loading && React.createElement(CircularProgress, { color: "secondary", size: 24, className: classes.buttonProgress }))));
};
