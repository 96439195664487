import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import FilledButton from '../Buttons/FilledButton';
import OutlinedButton from '../Buttons/OutlinedButton';
import ValidatedMessage from '../ValidatedMessage';
const useStyles = makeStyles((theme) => ({
    loading: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.blue2 : theme.palette.ava.white,
    },
    options: {
        '& > :not(:last-child)': {
            marginRight: 10,
        },
    },
}));
const CancelSaveControl = ({ buttonClassName, cancelText, error, errorComponent, loading, onCancelClick, onSaveClick, resetSuccess, resetValue, saveDisabled, saveText, showControls, success, successMessage, }) => {
    const classes = useStyles();
    useEffect(() => {
        if (success) {
            resetValue();
            const timeout = setTimeout(resetSuccess, 1500);
            return () => {
                clearTimeout(timeout);
                resetSuccess();
            };
        }
    }, [success]);
    if (loading) {
        return React.createElement(CircularProgress, { className: classes.loading, size: 20 });
    }
    if (success) {
        return React.createElement(ValidatedMessage, { message: successMessage });
    }
    if (error && errorComponent)
        return errorComponent;
    return (showControls && (React.createElement("div", { className: classes.options },
        React.createElement(OutlinedButton, { className: buttonClassName, onClick: onCancelClick }, cancelText),
        React.createElement(FilledButton, { className: buttonClassName, color: "blue", onClick: onSaveClick, disabled: saveDisabled }, saveText))));
};
export default CancelSaveControl;
