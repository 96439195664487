import keycodes from './keycodes';
export const handleCancelSaveEvent = ({ event, saveCondition, onSave, onCancel }) => {
    const key = keycodes(event);
    if (key.RETURN && (saveCondition === undefined || saveCondition)) {
        onSave();
    }
    else if (key.ESC) {
        onCancel();
    }
};
