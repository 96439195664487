import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginButton } from './LoginButton';
export const LoginEmail = (props) => {
    const { to } = props;
    const { t } = useTranslation();
    return (React.createElement(LoginButton, { to: to, icon: React.createElement(EmailOutlinedIcon, null), text: t('signInWithEmail'), style: {
            backgroundColor: '#77B8EC',
            color: 'white',
        } }));
};
