var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import * as emailValidator from 'email-validator';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { authInstance } from '../../firebase';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectFirebaseLoginInProgress } from '../../selectors/combined';
import { useAppSelector } from '../../store/store';
import { firebaseFunctionsEndpoint } from '../../utils/http';
import { sendEmailQueryMessage } from '../../utils/ws-v1';
import ScribeLoginEmailVerification from './ScribeLoginEmailVerification';
import { checkForSSOProvider, initiateSSOLogin } from './ScribeLoginSSO';
const styles = () => {
    return {
        forgotDisabled: {
            color: 'grey',
            display: 'inline-block',
            cursor: 'not-allowed',
        },
        forgotEnabled: {
            color: '#77B8EC',
            cursor: 'pointer',
            display: 'inline-block',
            textDecoration: 'underline',
        },
        form: {
            marginTop: 4,
            width: '100%', // Fix IE 11 issue.
        },
        paper: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: 420,
        },
        submit: {
            boxShadow: 'none',
            color: 'white',
            fontWeight: 600,
            margin: '12px 0 8px',
        },
        title: {
            marginTop: 32,
            fontWeight: 700,
        },
        inputLabel: {
            fontWeight: 400,
        },
    };
};
const ScribeLoginEmail = (props) => {
    const { checkProvidersForPasswordReset, classes, t } = props;
    const [authError, setAuthError] = useState('');
    const [email, setEmail] = useState('');
    const [emailToShow, setEmailToShow] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);
    const [verificationEmailSent, setVerificationEmailSent] = useState(false);
    const [waitForEmailVerification, setWaitForEmailVerification] = useState(false);
    const [showPasswordField, setShowPasswordField] = useState(false);
    const firebaseLoginInProgress = useAppSelector(selectFirebaseLoginInProgress);
    const [ws] = useV1Socket();
    const sendVerificationEmailIfNeeded = () => __awaiter(void 0, void 0, void 0, function* () {
        const user = authInstance.currentUser;
        if (!verificationEmailSent && user) {
            setLoading(true);
            yield fetch(`${firebaseFunctionsEndpoint}/widgets/sendEmailVerification?uid=${user.uid}`, { method: 'POST' });
            setLoading(false);
            setVerificationEmailSent(true);
            setWaitForEmailVerification(true);
        }
    });
    const reset = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(false);
        yield authInstance.signOut();
        setAuthError('');
        setEmail('');
        setLoading(false);
        setPassword('');
        setVerificationEmailSent(false);
        setWaitForEmailVerification(false);
        setShowPasswordField(false);
    });
    const resetPassword = () => __awaiter(void 0, void 0, void 0, function* () {
        if (validateEmail(email)) {
            const hasOtherProviders = yield checkProvidersForPasswordReset(email);
            if (!hasOtherProviders) {
                setLoading(true);
                try {
                    yield authInstance.sendPasswordResetEmail(email);
                }
                finally {
                    setResetPasswordEmailSent(true);
                    setLoading(false);
                }
            }
        }
    });
    const validateEmail = (email) => {
        return emailValidator.validate(email);
    };
    const validatePassword = (password) => {
        return password && password.length >= 6;
    };
    const disabled = () => {
        return !validateEmail(email) || (showPasswordField && !validatePassword(password));
    };
    const handleEmailChange = (email) => {
        setEmailToShow(email);
        setEmail(email.toLowerCase());
        if (validateEmail(email)) {
            if (ws) {
                sendEmailQueryMessage(ws, email);
            }
        }
    };
    const tryLoginWithSSO = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        const provider = yield checkForSSOProvider(email);
        if (provider) {
            yield initiateSSOLogin(provider);
        }
        else {
            setShowPasswordField(true);
        }
    });
    const handleLoginCustomMail = (event) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        event.preventDefault();
        setLoading(true);
        try {
            yield authInstance.setPersistence('local');
            const response = yield authInstance.signInWithEmailAndPassword(email, password);
            if (!((_a = response === null || response === void 0 ? void 0 : response.user) === null || _a === void 0 ? void 0 : _a.emailVerified)) {
                setLoading(false);
                setVerificationEmailSent(true);
                setWaitForEmailVerification(true);
            }
        }
        catch (err) {
            switch (err.code) {
                case 'auth/user-not-found':
                    yield authInstance.createUserWithEmailAndPassword(email, password);
                    yield sendVerificationEmailIfNeeded();
                    break;
                case 'auth/wrong-password':
                    setAuthError('wrongPassword');
                    setLoading(false);
                    break;
                default:
                    setLoading(false);
                    Sentry.captureException(err);
            }
        }
    });
    const showLoading = loading || firebaseLoginInProgress;
    if (waitForEmailVerification) {
        return React.createElement(ScribeLoginEmailVerification, { email: email, loading: showLoading, reset: () => reset() });
    }
    if (resetPasswordEmailSent) {
        return (React.createElement(Typography, { variant: "body1", align: "center", "data-qa": "password_reset_confirmation" }, t('signin-messages.resetLinkSent', { email })));
    }
    return (React.createElement("div", null,
        React.createElement("div", { className: classes.paper },
            React.createElement(Typography, { variant: "h4", className: classes.title, gutterBottom: true }, t('signInSignUp')),
            React.createElement("form", { className: classes.form, onSubmit: (event) => (showPasswordField ? handleLoginCustomMail(event) : tryLoginWithSSO(event)), noValidate: true },
                React.createElement(TextField, { variant: "outlined", margin: "normal", color: "primary", required: true, fullWidth: true, id: "email", "data-qa": "userEmail", label: t('account.login.email'), name: "email", autoComplete: "email", autoFocus: !showPasswordField, value: emailToShow || '', onChange: (event) => handleEmailChange(event.target.value), error: !!authError }),
                showPasswordField && (React.createElement(TextField, { variant: "outlined", margin: "normal", required: true, fullWidth: true, autoFocus: showPasswordField, name: "password", label: t('account.login.password'), type: "password", id: "password", "data-qa": "password", disabled: !validateEmail(email), autoComplete: "current-password", value: password || '', onChange: (event) => setPassword(event.target.value), helperText: authError && t(`signin-messages.${authError}`), error: !!authError })),
                React.createElement(Button, { type: "submit", fullWidth: true, variant: "contained", color: "primary", className: classes.submit, disabled: disabled() || showLoading, "data-qa": "getStartedButton" },
                    showLoading && React.createElement(CircularProgress, { color: "secondary", size: "1.6em" }),
                    !showLoading && t('getStarted')),
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, xs: true, style: { textAlign: 'right' } },
                        React.createElement(Typography, { onClick: () => resetPassword(), variant: "body2", className: classes[validateEmail(email) ? 'forgotEnabled' : 'forgotDisabled'], "data-qa": "forgotPasswordLink" }, t('signin-messages.forgotPassword'))))))));
};
export default withTranslation()(withStyles(styles)(ScribeLoginEmail));
