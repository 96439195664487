import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React from 'react';
import CloseIcon from '../Icons/Close';
import DropdownMenu from './DropdownMenu';
const useStyles = makeStyles((theme) => ({
    heading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        height: 22,
        color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.text.dark2,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        boxSizing: 'content-box',
    },
    prompt: {
        fontSize: 'inherit',
    },
    closeIcon: {
        position: 'absolute',
        top: -3,
        right: -16,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    divider: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.text.dark2,
    },
}));
const DropdownSelection = ({ children, id, anchorEl, prompt, width, onClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (React.createElement(DropdownMenu, { id: id, paperStyle: { marginTop: 8 }, anchorEl: anchorEl, width: width, onClose: onClose },
        React.createElement("div", { className: classes.heading },
            React.createElement("h4", { className: classes.prompt }, prompt),
            React.createElement(IconButton, { className: classes.closeIcon, onClick: onClose, size: "large" },
                React.createElement(CloseIcon, { color: theme.palette.mode === 'light' ? theme.palette.ava.grey5 : theme.palette.ava.text.dark2, width: 40, height: 40 }))),
        React.createElement(Divider, { className: classes.divider }),
        children));
};
export default DropdownSelection;
