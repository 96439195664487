import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { LoginButton } from './LoginButton';
export const LoginPhone = (props) => {
    const { to } = props;
    const { t } = useTranslation();
    return (React.createElement(LoginButton, { to: to, icon: React.createElement(PhoneOutlinedIcon, null), text: t('signInWithPhone'), style: {
            backgroundColor: '#64C25E',
            color: 'white',
        } }));
};
