import { makeStyles } from '@mui/styles';
import React from 'react';
import Rating from 'react-rating';
import RatingStarIcon from '../Icons/RatingStar';
const useStyles = makeStyles(() => ({
    star: {
        marginRight: 8,
    },
}));
const RatingSelector = ({ initialRating, onChange, onHover, readonly, rating }) => {
    const classes = useStyles();
    let starColor = '';
    if (!rating) {
        starColor = '#150ACEF';
    }
    else if (rating <= 2) {
        starColor = '#DE423E';
    }
    else if (rating !== 3) {
        starColor = '#05BF6F';
    }
    else {
        starColor = '#ED8822';
    }
    return (React.createElement(Rating, { emptySymbol: React.createElement("div", { className: classes.star },
            React.createElement(RatingStarIcon, null)), fullSymbol: React.createElement("div", { className: classes.star },
            React.createElement(RatingStarIcon, { fill: starColor })), readonly: readonly, initialRating: initialRating, onChange: onChange, onHover: onHover }));
};
export default RatingSelector;
