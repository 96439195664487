import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    reconnectBanner: {
        position: 'fixed',
    },
    volumeController: {
        opacity: (props) => (props.conversationContainerHovered ? 1 : 0),
        transition: 'opacity 200ms',
    },
    zoomBroadcastWarningDialog: {
        maxWidth: 500,
        margin: '0 auto',
    },
    zoomBroadcastWarningIconWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 120,
        height: 120,
        margin: '12px auto',
        background: theme.palette.ava.blue,
        borderRadius: '50%',
    },
    zoomBroadcastWarningIconText: {
        fontSize: 72,
        color: theme.palette.background.light,
        marginRight: 5,
    },
    zoomBroadcastWarningTitle: {
        fontWeight: 700,
    },
    zoomBroadcastWarningDialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    zoomBroadcastWarningButton: {
        margin: '0 auto 8px',
        padding: '0.4em 1.6em',
        background: theme.palette.ava.blue,
        color: theme.palette.background.light,
        fontSize: '1.2em',
        fontWeight: 700,
        textTransform: 'none',
        '&:hover': {
            background: theme.palette.ava.blue,
            opacity: 0.9,
        },
    },
    zoomBroadcastWarningCancel: {
        marginRight: 8,
        marginBottom: '12px',
        padding: '0.4em 1.6em',
        color: theme.palette.ava.blue,
        fontSize: '1.2em',
        fontWeight: 700,
        textTransform: 'none',
        '&:hover': {
            background: 'none',
            textDecoration: 'underline',
        },
    },
}));
export default useStyles;
