var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { listen } from '@tauri-apps/api/event';
import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchScribeSettings } from '../../actions';
import { login as accountLogin } from '../../actions/account/session';
import { authInstance } from '../../firebase';
import { selectAccountUser } from '../../selectors/account';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectLoggedOut } from '../../selectors/combined';
import { selectV1Token } from '../../selectors/v1Session';
import { setRecording } from '../../store/slices/audioRecorder';
import { handleFirebaseUser, initiateAnonymousFirebaseLogin, initiateFirebaseLoginWithToken, loadIDToken, markFirebaseAuthTriggered, } from '../../store/slices/auth';
import { setShowQuickSetup } from '../../store/slices/quickSetup';
import { setLoading } from '../../store/slices/uiState';
import { fetchUserProfile } from '../../store/slices/userProfile';
import { createV1Socket, fetchAsyncBackends } from '../../store/slices/v1Session';
import { useAppSelector } from '../../store/store';
import AccountManager from '../../utils/classes/AccountManager';
import Branch from '../../utils/classes/Branch';
import ParseUser from '../../utils/classes/ParseUser';
import { clearSearchValue, getSearchValue } from '../../utils/http';
export const LoginListener = () => {
    // The purpose of this component is to listen to all the authentication
    // and login related events and dispatch relevant actions. This could be done
    // as part of a redux-saga or an async-thunk, but using a component lets
    // us easily listen (using useEffect) to particular fields and respond
    // appropriately. Plus it gives us a way to hook into the Firebase auth
    // process.
    // Current flow for regular login:
    // TODO
    // Current flow for join-by-link (anonymous, appless, qr code):
    // * The flow is triggered by setting `ava_request` in local storage.
    // * After firebase confirms that the user is logged out, isLoggedOut will be
    //   set to true. This will trigger an effect in this file to initiate
    //   anonymous login.
    // * After firebase anonymous login is complete, another effect will be
    //   triggered in this file to initiate the v1Socket creation (same effect
    //   as regular login).
    // * v1Socket creation happens in v1Session.ts. It will perform the necessary
    //   userProfile fetch, and create the socket. Afterwards it will ask the
    //   newly created joinConverstionManager whether any room should be joined.
    // * joinConversationManager will read `ava_request` from local storage and
    //   join the room if it exists.
    // * The conversation will be started and the user will be redirected to the
    //   conversation page by an effect in ScribeWelcome.
    const dispatch = useDispatch();
    const accountUser = useAppSelector(selectAccountUser);
    const avaUser = useAppSelector((state) => state.userProfile.avaUser);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const v1Token = useAppSelector(selectV1Token);
    const isLoggedOut = useAppSelector(selectLoggedOut);
    const location = useLocation();
    const navigate = useNavigate();
    const branch = useRef();
    const branchId = localStorage.getItem('ava_branch_id') || '';
    const initializeBranch = () => __awaiter(void 0, void 0, void 0, function* () {
        branch.current = new Branch(branchId);
        const branchInit = branch.current.initializeBranch();
        if (branchId)
            yield branchInit;
        if (branch.current.hasData()) {
            branch.current.savePropertiesToLocalStorageIfExists();
            if (branch.current.hasAudio()) {
                dispatch(setRecording(true));
            }
        }
    });
    useEffect(() => {
        if (isLoggedOut && (v1Token || branchId || localStorage.getItem('ava_request'))) {
            dispatch(setLoading(true));
            initiateAnonymousFirebaseLogin(dispatch);
        }
    }, [isLoggedOut]);
    useEffect(() => {
        dispatch(fetchAsyncBackends());
        initializeBranch();
        const accountManager = new AccountManager();
        accountManager.setAccountDetailsInLocalStorage();
        const firebaseCustomToken = getSearchValue(window, 'firebaseCustomToken');
        if (firebaseCustomToken) {
            clearSearchValue(window, 'firebaseCustomToken');
            const newUser = getSearchValue(window, 'newUser');
            if (window.isElectron && newUser) {
                dispatch(setShowQuickSetup(true));
            }
            initiateFirebaseLoginWithToken(dispatch, firebaseCustomToken);
        }
        let unlisten;
        const inner = () => __awaiter(void 0, void 0, void 0, function* () {
            if (!window.__TAURI__)
                return;
            unlisten = yield listen('login-token', (event) => {
                const loginToken = event.payload.loginToken;
                initiateFirebaseLoginWithToken(dispatch, loginToken);
            });
        });
        inner();
        const firebaseCancel = authInstance.onAuthStateChanged((user) => {
            dispatch(markFirebaseAuthTriggered());
            if (user && firebaseUser && user.uid === firebaseUser.uid && user.emailVerified && (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.emailVerified))
                return;
            handleFirebaseUser(dispatch, user);
        });
        return () => {
            firebaseCancel();
            // TODO: This will fail unpredictably if inner does not finish executing
            // before this gets unmounted.
            if (unlisten)
                unlisten();
        };
    }, []);
    useEffect(() => {
        if (!firebaseUser)
            return;
        dispatch(fetchUserProfile());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebaseUser]);
    useEffect(() => {
        if (!firebaseUser)
            return;
        if (!location.pathname.includes('/account/')) {
            // Only issue accountLogin action for /account paths
            return;
        }
        if (firebaseUser.email === accountUser.email)
            return;
        const inner = () => __awaiter(void 0, void 0, void 0, function* () {
            const idToken = yield dispatch(loadIDToken()).unwrap();
            dispatch(accountLogin({
                avatar: firebaseUser.photoURL || undefined,
                email: firebaseUser.email || '',
                id: firebaseUser.uid,
                name: firebaseUser.displayName || '',
            }, idToken || ''));
        });
        inner();
    }, [firebaseUser]);
    useEffect(() => {
        if (!firebaseUser || !avaUser)
            return;
        fetchScribeSettings()(dispatch, window.store.getState);
        dispatch(setLoading(true));
        const inner = () => __awaiter(void 0, void 0, void 0, function* () {
            const parseUser = new ParseUser(avaUser);
            parseUser.setParseIdToLocalStorage();
            parseUser.setAvaIdToLocalStorage();
            dispatch(createV1Socket());
            if (firebaseUser.isAnonymous) {
                // If the user is anonymous then there's two options:
                // * we treat them as logged out
                // * we send them directly to someone else's conversation.
                // That's handled in localStorage by ava_request
                const avaRequest = localStorage.getItem('ava_request');
                if (!avaRequest) {
                    dispatch(setLoading(false));
                }
                return;
            }
            const isNew = firebaseUser.metadata.lastSignInTime === firebaseUser.metadata.creationTime;
            const justSignedIn = firebaseUser.metadata.lastSignInTime
                ? Date.parse(firebaseUser.metadata.lastSignInTime) + 60 * 1000 > Date.now()
                : false;
            if (isNew) {
                parseUser.trackSignUp();
            }
            else if (justSignedIn) {
                parseUser.trackSignIn();
            }
            const redirect = getSearchValue(window, 'redirect');
            if (redirect) {
                navigate({ pathname: redirect, search: '' });
            }
        });
        inner();
    }, [avaUser && avaUser.uid]);
    return React.createElement(React.Fragment, null);
};
