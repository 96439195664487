import { connect } from 'react-redux';
import { scribeSelectTranscriptWord } from '../../actions';
import ScribeTranscript from './ScribeTranscript';
const emptyArray = [];
const mapStateToProps = (state, { id }) => {
    const { scribeConversation: { speakers, ui: { selectedTranscriptIndex, selectedWordIndex }, status, }, userProfile: { features, avaUser: { parse: { avaId }, }, }, ui: { wordBeingEdited }, } = state;
    const { texts, highlighted, speechLang, cursors, isFinal, mutationsQueuedByHumanMutation, indexes, author, authorShow, trackName, } = state.scribeConversation.transcripts[id] || {};
    const cursorsSize = (cursors || []).map((cursor) => cursor.index).filter((i) => i >= 0).length;
    // The current logged in user
    const currentUser = speakers[avaId];
    // Who the transcript is related
    const currentSpeaker = speakers[author];
    let speaker = currentUser;
    // When a scribe/user splits a transcript the transcript.author is briefly 'Callee'
    // before it is corrected. We need to handle this brief moment so it doesn't crash the app.
    if (currentSpeaker && !currentSpeaker.isSoloDiaSpeakerCurrentUser) {
        // If is Solo-Dia and is marked as myself = true then use currentUser speaker object.
        speaker = currentSpeaker;
    }
    return {
        author,
        authorShow,
        cursors,
        highlighted,
        id,
        isFinal,
        isSelected: selectedTranscriptIndex === id,
        mutationsQueuedByHumanMutation: (mutationsQueuedByHumanMutation === null || mutationsQueuedByHumanMutation === void 0 ? void 0 : mutationsQueuedByHumanMutation.length)
            ? mutationsQueuedByHumanMutation
            : emptyArray,
        participantsHash: status.participants && cursorsSize ? status.participants : undefined,
        selectedWordIndex: selectedTranscriptIndex === id ? selectedWordIndex : 0,
        speaker,
        singleSpeaker: Object.keys(speakers).length === 1,
        speechLang,
        texts,
        trackName,
        userScribeFlag: features.scribe || false,
        wordBeingEdited: selectedTranscriptIndex === id ? wordBeingEdited : false,
        indexes,
    };
};
export default connect(mapStateToProps, { scribeSelectTranscriptWord })(ScribeTranscript);
