import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import MagnifyingGlassIcon from '../../../assets/icons/magnifying-glass.svg';
import { AVA_DOMAIN } from '../../constants';
import useJoinConversationManager from '../../hooks/useJoinConversationManager';
import { selectJoinConversationError } from '../../selectors/conversation';
import { selectElectronCaptionMode } from '../../selectors/ui';
import LocalStorage, { STORAGE_KEYS } from '../../services/localStorage';
import { addNotification } from '../../store/slices/ccMode';
import { joinConversationAnonymously } from '../../store/slices/conversation';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { formatAvaName } from '../../utils/search-ava-room';
import { getSnack } from '../../utils/snackbar';
import UserAvatar from '../Avatars/UserAvatar';
import JoinConversationInput from './Input';
import JoinConversationMenu from './Menu';
import JoinConversationOption from './Option';
import JoinConversationPlaceholder from './Placeholder';
import JoinConversationSingleValue from './SingleValue';
import JoinConversationValueContainer from './ValueContainer';
const adaptAvaName = (avaName) => {
    if (!avaName) {
        return '';
    }
    return avaName.replace(/[^A-Z0-9]/gi, '').toLowerCase();
};
const useStyles = makeStyles((theme) => ({
    reactSelectInput: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 0,
        height: '50px',
        minWidth: 192,
        fontSize: 18,
        color: theme.palette.ava.blue2,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
    },
    top: {
        flexShrink: 0,
        display: 'flex',
        marginBottom: 4,
        height: 32,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        overflow: 'hidden',
    },
    avaLink: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 6,
        paddingRight: 6,
        paddingBottom: 6,
        paddingLeft: 12,
        width: 110,
        backgroundColor: theme.palette.ava.blue5,
        color: theme.palette.ava.dark1,
        fontSize: 16,
        lineHeight: '20px',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 6,
        paddingBottom: 6,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        border: `1px solid ${theme.palette.ava.grey6}`,
        overflow: 'hidden',
    },
    inputContainerFocused: {
        border: `1px solid ${theme.palette.ava.blue2}`,
    },
    iconContainer: {
        height: 20,
        width: 40,
    },
    icon: {
        height: '100%',
        width: '100%',
    },
    input: {
        paddingRight: 12,
        height: '100%',
        width: '100%',
        background: 'transparent',
        border: 'none',
        outline: 'none',
        fontSize: 16,
        color: theme.palette.ava.blue2,
        caretColor: theme.palette.ava.blue2,
        '&::placeholder': {
            color: theme.palette.ava.grey6,
        },
        '&:focus::placeholder': {
            color: theme.palette.ava.grey5,
        },
    },
    bottom: {
        marginBottom: 20,
        width: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    card: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: 4,
        paddingRight: 8,
        paddingBottom: 4,
        paddingLeft: 8,
        height: 32,
        width: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        backgroundBlendMode: 'overlay',
        border: `1px solid ${theme.palette.ava.blue2}`,
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.ava.navy2,
        },
        '&:first-child': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
        },
        '&:last-child': {
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
        },
    },
    avatar: {
        marginRight: 8,
    },
    avaName: {
        fontSize: 14,
        lineHeight: '18px',
    },
}));
const NewJoinConversation = ({ autoFocus, joinAsScribe, header, inviteCard, landing }) => {
    const { t } = useTranslation();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const classes = useStyles({ landing });
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const components = {
        Control: JoinConversationInput,
        Menu: JoinConversationMenu,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        NoOptionsMessage: () => null,
        Option: JoinConversationOption,
        Placeholder: JoinConversationPlaceholder,
        ClearIndicator: () => null,
        SingleValue: JoinConversationSingleValue,
        ValueContainer: JoinConversationValueContainer,
    };
    const [inputValue, setInputValue] = useState('');
    const [fullValue, setFullValue] = useState(null);
    const [inputFocused, setInputFocused] = useState(false);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const joinConversationError = useAppSelector(selectJoinConversationError);
    const joinConversationManager = useJoinConversationManager();
    const dispatch = useAppDispatch();
    const handleInputChange = (avaNameInput) => {
        const avaName = adaptAvaName(avaNameInput);
        setInputValue(avaName);
        joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.handleUserQuery(avaName);
    };
    // this is just to show the user's input as a suggestion
    // since we're not connected to the websocket in the header component
    const headerCustomOption = inputValue.length
        ? [
            {
                value: `&${inputValue}`,
                label: `&${inputValue}`,
                user: { avaName: inputValue, userName: '' },
            },
        ]
        : [];
    const formattedSuggestions = (joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.getFormattedSuggestions(inputValue, fullValue || undefined)) || [];
    useEffect(() => {
        const localStorageCache = LocalStorage.get(STORAGE_KEYS.CACHE, {});
        joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.updateCache(Array.from(Object.values(localStorageCache)));
        let request = LocalStorage.get(STORAGE_KEYS.AVA_REQUEST);
        if (request) {
            request = `&${request}`;
            setFullValue({
                value: request,
                label: request,
                user: { avaName: request.slice(1), userName: '' },
            });
            setInputValue('');
        }
    }, []);
    useEffect(() => {
        if (joinConversationError) {
            setInputValue('');
            setFullValue(null);
            const errorMessage = t(`snackbar.request.${joinConversationError || 'unknownError'}`);
            if (electronCaptionMode) {
                dispatch(addNotification({
                    text: errorMessage,
                    timestamp: Date.now(),
                    type: 'default',
                }));
            }
            else {
                getSnack({ enqueueSnackbar, closeSnackbar })(errorMessage, {
                    variant: 'error',
                });
            }
        }
    }, [joinConversationError]);
    return (React.createElement(React.Fragment, null, inviteCard ? (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classes.top },
            React.createElement("span", { className: classes.avaLink },
                "web.",
                AVA_DOMAIN,
                "/"),
            React.createElement("div", { className: classnames(classes.inputContainer, {
                    [classes.inputContainerFocused]: inputFocused,
                }) },
                React.createElement("div", { className: classes.iconContainer },
                    React.createElement("img", { className: classes.icon, src: MagnifyingGlassIcon, alt: "" })),
                React.createElement("input", { className: classes.input, value: inputValue, "data-qa": "enterRoomNameField", placeholder: t('ccMode.inviteCard.enterRoomName'), onChange: (event) => {
                        const formattedAvaName = formatAvaName(event.target.value);
                        setInputValue(formattedAvaName);
                        if (formattedAvaName) {
                            joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.handleUserQuery(formattedAvaName);
                        }
                    }, onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                            joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.joinRoomByAvaName(formattedSuggestions[0].user.avaName, location, navigate, joinAsScribe);
                        }
                    }, onFocus: () => setInputFocused(true), onBlur: () => setInputFocused(false), autoFocus: true }))),
        React.createElement("div", { className: classes.bottom }, formattedSuggestions.map((user) => (React.createElement("div", { className: classes.card, key: user.user.avaName, onClick: () => joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.handleJoinAvaRoom(user.user.avaName) },
            React.createElement("div", { className: classes.avatar },
                React.createElement(UserAvatar, { height: 24, width: 24, user: user.user })),
            React.createElement("span", { className: classes.avaName }, user.user.avaName))))))) : (React.createElement("div", { className: classes.root },
        React.createElement(Select, { className: classes.reactSelectInput, styles: {
                input: (base) => (Object.assign(Object.assign({}, base), { color: theme.palette.ava.blue2, '& input': {
                        font: 'inherit',
                    } })),
            }, inputValue: inputValue, value: fullValue, onInputChange: (reason) => {
                handleInputChange(reason);
            }, onChange: (option) => {
                if (option) {
                    setFullValue(option);
                    setInputValue('');
                    joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.updateLocalStorageCache(option);
                    header
                        ? dispatch(joinConversationAnonymously(option.value.slice(1)))
                        : joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.joinRoomByAvaName(option.value.slice(1), location, navigate, joinAsScribe);
                }
            }, onBlur: () => {
                setInputValue('');
                setFullValue(null);
            }, onKeyDown: (event) => {
                if (['Enter'].includes(event.key)) {
                    if (!header && formattedSuggestions.length === 0)
                        return;
                    header
                        ? dispatch(joinConversationAnonymously(event.target.value))
                        : joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.joinRoomByAvaName(formattedSuggestions[0].user.avaName, location, navigate, joinAsScribe);
                    event.preventDefault(); // DONT REMOVE OR IT WONT CLEAR THE INPUT
                    setInputValue('');
                    setFullValue(null);
                }
            }, escapeClearsValue: true, joinAsScribe: joinAsScribe, header: header, landing: landing, autoFocus: autoFocus, options: header ? headerCustomOption : formattedSuggestions, components: components, isClearable: true, placeholder: joinAsScribe
                ? 'Join as Scribe...'
                : autoFocus
                    ? t('joinConversation.enterAvaUsername')
                    : t('sidebar.buttons.joinAConversation') })))));
};
export default NewJoinConversation;
