var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectTranscripts } from '../../selectors/legacy-conversation';
import { selectAvaId, selectFeatures } from '../../selectors/userProfile';
import { updateFeatures } from '../../store/slices/userProfile';
import { useAppSelector } from '../../store/store';
import { sendSaveTranscriptUpdateMessage } from '../../utils/ws-v1';
import SaveTranscriptDialog1 from './SaveTranscriptDialog1';
import SaveTranscriptDialog2 from './SaveTranscriptDialog2';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    scrollPaper: {
        background: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.1)',
    },
    dialog1Paper: {
        justifyContent: 'space-around',
        padding: '0 48px',
        background: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.darkBg2,
        borderRadius: 20,
        height: '64%',
        maxHeight: 565,
        [theme.breakpoints.down('md')]: {
            height: '94%',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: 0,
            margin: 0,
            padding: '0 24px',
            height: '100%',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    dialog2Paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        padding: '10px 20px',
        minHeight: 250,
        maxWidth: 464,
        background: theme.palette.mode === 'light' ? theme.palette.ava.white : theme.palette.ava.dark2,
        borderRadius: 20,
        [theme.breakpoints.down('sm')]: {
            margin: 10,
            padding: 10,
        },
    },
}));
const SaveTranscriptsPopup = ({ callback }) => {
    const classes = useStyles();
    const transcripts = useSelector(selectTranscripts);
    const dispatch = useDispatch();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const features = useAppSelector(selectFeatures);
    const avaId = useAppSelector(selectAvaId);
    const [socket] = useV1Socket();
    const [isOpen, setIsOpen] = useState(true);
    const [dialogNumber, setDialogNumber] = useState(1);
    const [changingUserFeatures, setChangingUserFeatures] = useState(false);
    const isSaveTranscript = features.saveTranscript;
    const isForbidConversationSaving = !!features['forbid-conversation-saving'];
    useLayoutEffect(() => {
        if (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) {
            setIsOpen(false);
            callback({ saveClicked: false });
            return;
        }
        const shouldCallCallback = isSaveTranscript || isForbidConversationSaving;
        if (shouldCallCallback || !Object.keys(transcripts).length) {
            setIsOpen(false);
            callback({ saveClicked: false });
        }
    }, []);
    const onSaveClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        setChangingUserFeatures(true);
        yield dispatch(updateFeatures({ saveTranscript: true }));
        if (socket) {
            sendSaveTranscriptUpdateMessage(socket, true);
        }
        yield callback({ saveClicked: true });
        setChangingUserFeatures(false);
    });
    const onNotNowClicked = () => __awaiter(void 0, void 0, void 0, function* () {
        if (dialogNumber === 2) {
            yield callback({ saveClicked: false });
        }
        else {
            setDialogNumber(2);
        }
    });
    return (React.createElement(Dialog, { className: classes.root, classes: {
            scrollPaper: classes.scrollPaper,
            paper: dialogNumber === 1 ? classes.dialog1Paper : classes.dialog2Paper,
        }, open: isOpen, onClose: () => true }, dialogNumber === 1 ? (React.createElement(SaveTranscriptDialog1, { disabled: changingUserFeatures, onSaveClicked: onSaveClicked, onNotNowClicked: onNotNowClicked })) : (React.createElement(SaveTranscriptDialog2, { disabled: changingUserFeatures, onSaveClicked: onSaveClicked, onContinueClicked: onNotNowClicked }))));
};
export default SaveTranscriptsPopup;
