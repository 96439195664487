import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import React, { lazy, Suspense, useEffect, useRef } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { onkeydown, onkeyup } from '../actions/shortcut';
import Loading from '../components/Loading';
import Default from '../Layouts/Default';
import { LoginListener } from '../modules/login/LoginListener';
import ScribeLoginProfiling from '../modules/scribe/ScribeLoginProfilingContainer';
import ScribeWelcomeContainer from '../modules/scribe/ScribeWelcomeContainer';
import { logoutAndClean } from '../utils/user';
import ConversationPage from '../views/conversation/ConversationPage';
const DownloadAppComponent = lazy(() => import(/* webpackChunkName: "appDownload" */ '../components/DownloadAppComponent'));
const BranchRedirectScreen = lazy(() => import(/* webpackChunkName: "branch" */ '../components/BranchRedirectScreen'));
const AppDownloadScreen = lazy(() => import(/* webpackChunkName: "appDownload" */ '../components/AppDownloadScreen'));
const DesktopLoginSuccess = lazy(() => import(/* webpackChunkName: "login" */ '../components/DesktopLoginSuccess'));
const ScribeDashboardContainer = lazy(() => import(/* webpackChunkName: "scribe" */ '../modules/scribe/ScribeDashboard'));
const ScribeConversationsContainer = lazy(() => import(/* webpackChunkName: "scribe" */ '../modules/scribe/ScribeConversationsContainer'));
const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ '../modules/account/pages/dashboard'));
const LicenceBuy = lazy(() => import(/* webpackChunkName: "dashboard" */ '../modules/account/pages/license/buy'));
const Payment = lazy(() => import(/* webpackChunkName: "dasboard" */ '../modules/account/pages/license/payment'));
const PaymentSucceed = lazy(() => import(/* webpackChunkName: "dasboard" */ '../modules/account/pages/payment-succeed'));
const OrganizationCreate = lazy(() => import(/* webpackChunkName: "dasboard" */ '../modules/account/pages/organization/create'));
const AccountStart = lazy(() => import(/* webpackChunkName: "login" */ '../modules/account/pages/start'));
import { makeStyles } from '@mui/styles';
import { AppWrapper } from '../App';
import Workspace from '../components/Workspace';
import Boost from '../modules/account-settings/boost/Boost';
import General from '../modules/account-settings/General';
import MyProfile from '../modules/account-settings/MyProfile';
import Navigation from '../modules/account-settings/Navigation';
import { ApplessConversationPage } from '../modules/appless/ApplessConversationPage';
import { selectAccountUser } from '../selectors/account';
import { selectMetaDown } from '../selectors/ui';
import { selectAvaId } from '../selectors/userProfile';
import { useAppDispatch, useAppSelector } from '../store/store';
import PrivateRouteWrapper from './PrivateRouteWrapper';
const browserHistory = createBrowserHistory();
Sentry.init({
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
        }),
    ],
    tracesSampleRate: 1.0,
    ignoreErrors: ['connection appears to be offline'],
});
const LogoutComponent = () => {
    logoutAndClean();
    return React.createElement(Navigate, { to: "/web/login", replace: true });
};
const AppRoutes = () => {
    const dispatch = useAppDispatch();
    const metaDown = useAppSelector(selectMetaDown);
    const metaDownRef = useRef(metaDown);
    const avaId = useAppSelector(selectAvaId);
    const accountUser = useAppSelector(selectAccountUser);
    useEffect(() => {
        metaDownRef.current = metaDown;
    }, [metaDown]);
    useEffect(() => {
        window.document.onkeydown = (ev) => onkeydown(ev, dispatch, metaDownRef);
        window.document.onkeyup = (ev) => onkeyup(ev, dispatch);
        return () => {
            window.document.onkeydown = null;
            window.document.onkeyup = null;
        };
    }, []);
    const webAppAuthenticate = () => Boolean(avaId);
    const accountAuthenticate = () => Boolean(accountUser === null || accountUser === void 0 ? void 0 : accountUser.id);
    const isAnonymous = localStorage.getItem('isAnonymous');
    return (React.createElement("div", { id: "gesture" },
        React.createElement(BrowserRouter, null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/", element: React.createElement(RouterWrapper, null) },
                    React.createElement(Route, { index: true, element: React.createElement(Navigate, { to: "/web", replace: true }) }),
                    React.createElement(Route, { path: "branch/:branchUrlId", element: React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
                            React.createElement(BranchRedirectScreen, null)) }),
                    React.createElement(Route, { path: "download", element: React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
                            React.createElement(AppDownloadScreen, null)) }),
                    React.createElement(Route, { path: "mobile" },
                        React.createElement(Route, { path: "transcript", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                                React.createElement(ApplessConversationPage, null)) })),
                    React.createElement(Route, { path: "workspace/*", element: React.createElement(Workspace, null) }),
                    React.createElement(Route, { path: "account" },
                        React.createElement(Route, { index: true, element: React.createElement(Navigate, { to: "/account/dashboard", replace: true }) }),
                        React.createElement(Route, { path: "start", element: React.createElement(AccountStart, null) }),
                        React.createElement(Route, { path: "login/profile", element: React.createElement(ScribeLoginProfiling, null) }),
                        React.createElement(Route, { path: "login/*", element: React.createElement(ScribeWelcomeContainer, { product: "account" }) }),
                        React.createElement(Route, { path: "payment-succeed", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: accountAuthenticate, redirectPath: "/account/dashboard" },
                                React.createElement(PaymentSucceed, null)) }),
                        React.createElement(Route, { path: "organization/create", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: accountAuthenticate, redirectPath: "/account/dashboard" },
                                React.createElement(OrganizationCreate, null)) }),
                        React.createElement(Route, { path: "dashboard", element: React.createElement(Dashboard, null) }),
                        React.createElement(Route, { path: "license/buy", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: accountAuthenticate, redirectPath: "/account/dashboard" },
                                React.createElement(LicenceBuy, null)) }),
                        React.createElement(Route, { path: "license/payment", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: accountAuthenticate, redirectPath: "/account/dashboard" },
                                React.createElement(Payment, null)) }),
                        React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/account/dashboard", replace: true }) })),
                    React.createElement(Route, { path: "web" },
                        React.createElement(Route, { index: true, element: React.createElement(Navigate, { to: "/web/login" }) }),
                        React.createElement(Route, { path: "login/profile/*", element: React.createElement(ScribeLoginProfiling, null) }),
                        React.createElement(Route, { path: "login/desktop", element: React.createElement(DesktopLoginSuccess, null) }),
                        React.createElement(Route, { element: React.createElement(LogoutComponent, null), path: "logout" }),
                        React.createElement(Route, { path: "scribe-dashboard", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                                React.createElement(ScribeDashboardContainer, null)) }),
                        React.createElement(Route, { path: "conversations", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                                React.createElement(ScribeConversationsContainer, null)) }),
                        React.createElement(Route, { path: "transcript", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                                React.createElement(ConversationPage, null)) }),
                        React.createElement(Route, { path: "account-settings", element: React.createElement(AccountSettingsWrapper, null) },
                            React.createElement(Route, { index: true, element: React.createElement(Navigate, { to: "my-profile" }) }),
                            React.createElement(Route, { path: "my-profile", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                                    React.createElement(MyProfile, null)) }),
                            React.createElement(Route, { path: "general", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                                    React.createElement(General, null)) }),
                            React.createElement(Route, { path: "boost", element: React.createElement(PrivateRouteWrapper, { authenticationMethod: webAppAuthenticate, redirectPath: "/web" },
                                    React.createElement(Boost, null)) })),
                        React.createElement(Route, { path: "login/*", element: React.createElement(ScribeWelcomeContainer, { product: "web" }) })),
                    React.createElement(Route, { path: "download", element: React.createElement(DownloadAppComponent, null) }),
                    React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/web/login", replace: true }) }))))));
};
const useStyles = makeStyles(() => ({
    container: {
        position: 'relative',
        paddingLeft: 40,
        paddingRight: 40,
        opacity: 0.98,
    },
    content: {
        paddingTop: 40,
        '& > :not(:last-child)': {
            position: 'relative',
            zIndex: 20,
            marginBottom: 16,
        },
    },
}));
const AccountSettingsWrapper = () => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.container },
        React.createElement(Navigation, null),
        React.createElement("div", { className: classes.content },
            React.createElement(Outlet, null))));
};
const RouterWrapper = () => {
    const navigate = useNavigate();
    window.navigate = navigate;
    return (React.createElement(React.Fragment, null,
        React.createElement(LoginListener, null),
        React.createElement(AppWrapper, null,
            React.createElement(Default, null,
                React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
                    React.createElement(Outlet, null))))));
};
export default AppRoutes;
