import React, { useEffect, useRef } from 'react';
/* USAGE
  This component is intended to be used as a wrapper around components that you want to
  observe with IntersectionObserver with the ability to hide the children and render a
  plain div with the same dimensions in its place.
*/
const ObservableWrapperWithHideableChildren = ({ children, dataId, dimensions, hidden, observe, unobserve, }) => {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            observe(ref.current);
        }
        return () => {
            unobserve(ref.current);
        };
    }, [observe, ref.current, unobserve]);
    // we don't want to render children if the element is hidden, however if we do not have dimensions we need to render them to keep the correct sizing
    const shouldRenderChildren = !hidden || !dimensions;
    const style = hidden && dimensions ? dimensions : {};
    return (React.createElement("div", { "data-dataid": dataId, ref: ref, style: style }, shouldRenderChildren && children));
};
export default ObservableWrapperWithHideableChildren;
