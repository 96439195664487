import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Button, DialogContent, DialogContentText } from '@mui/material';
import Dialog from '@mui/material/Dialog/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { selectSncfConsentDialogShown } from '../../selectors/avaTranslate';
import { setRecording } from '../../store/slices/audioRecorder';
import { setSncfConsentDialogShown, setSncfConsentGiven } from '../../store/slices/avaTranslate';
import { useAppDispatch, useAppSelector } from '../../store/store';
import RoundedButton from '../Buttons/RoundedButton';
export const SNCFConsentButton = () => {
    const theme = useTheme();
    const dialogOpen = useAppSelector(selectSncfConsentDialogShown);
    const dispatch = useAppDispatch();
    return (React.createElement(React.Fragment, null,
        React.createElement(RoundedButton, { style: {
                color: theme.palette.mode === 'dark' ? 'white' : theme.palette.ava.grey4,
            }, color: "white", onClick: () => {
                dispatch(setSncfConsentDialogShown(true));
            }, disableRipple: true },
            React.createElement(CheckBoxIcon, { fontSize: 'large' }),
            React.createElement("div", { id: "axeptio-target" })),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => {
                dispatch(setSncfConsentDialogShown(false));
            } },
            React.createElement(DialogTitle, null, "Translate your voice"),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null, "To better inform you, we would like to translate what you say. Your voice data will not be recorded. Tap consent to proceed."),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { size: 'large', sx: {
                            color: theme.palette.mode === 'dark' ? 'white' : theme.palette.ava.grey4,
                        }, onClick: () => {
                            dispatch(setSncfConsentGiven(false));
                            dispatch(setRecording(false));
                            dispatch(setSncfConsentDialogShown(false));
                        } }, "Not now"),
                    React.createElement(Button, { size: 'large', onClick: () => {
                            dispatch(setSncfConsentGiven(true));
                            dispatch(setRecording(true));
                            dispatch(setSncfConsentDialogShown(false));
                        }, variant: 'contained' }, "Consent"))))));
};
