import platform from 'platform';
import { setDisplayShortcuts, setMeta } from '../store/slices/uiState';
const isMac = platform.os.family === 'OS X';
const isMetaOrCtrl = (ev) => {
    return (!isMac && ev.ctrlKey) || (isMac && ev.key === 'Meta');
};
export const onkeydown = (ev, dispatch, metaDownRef) => {
    const id = Date.now();
    window.onblur = () => dispatch(setMeta(0));
    if (isMetaOrCtrl(ev)) {
        dispatch(setMeta(id));
    }
    setTimeout(() => {
        if (metaDownRef.current === id) {
            dispatch(setDisplayShortcuts(true));
        }
    }, 300);
};
export const onkeyup = (ev, dispatch) => {
    if (isMetaOrCtrl(ev)) {
        dispatch(setMeta(0));
    }
};
