import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import ExpandIcon from '../../../assets/icons/expand.svg';
const useStyles = makeStyles((theme) => ({
    root: {
        background: 'transparent',
        border: 'none',
        outline: 'none',
        userSelect: 'none',
        '&:hover': {
            background: theme.palette.ava.grey5,
        },
    },
}));
const ExpandButton = ({ className, onClick }) => {
    const classes = useStyles(0);
    return (React.createElement("button", { className: classnames(classes.root, className), onClick: onClick },
        React.createElement("img", { src: ExpandIcon, alt: "Expand" })));
};
export default ExpandButton;
