var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { analytics } from '../firebase';
import { getConversationCloseReason, getConversationTimeInSeconds, getMetricsToUpdate, getRatingMetricsToUpdate, isFirstConvo, isHost, isScribeRequester, updateConversationMetrics, } from '../services/conversation-metrics';
import { fetchUserProfile } from '../store/slices/userProfile';
import * as segment from './segment';
import { updateConvoMetricsThroughWorker } from './sw';
export const getBlocs = ({ speakers, transcripts }) => {
    const conversationBlocs = [];
    Object.keys(transcripts).forEach((transcriptId) => {
        const currentTranscript = transcripts[transcriptId];
        const bloc = {};
        bloc.asr = {
            final: currentTranscript['asr.final'],
            timeReceivedMs: currentTranscript['asr.timeReceivedMs'],
        };
        bloc.blocId = currentTranscript.id;
        bloc.environment = 'production';
        bloc.final = currentTranscript.final;
        bloc.id = currentTranscript.id;
        bloc.lastHumanIndexes = currentTranscript.indexes;
        bloc.speaker = (speakers[currentTranscript.author] || {}).avaName;
        bloc.speakerId = currentTranscript.author;
        bloc.speechLang = currentTranscript.speechLang;
        bloc.text = currentTranscript.texts;
        bloc.usePaidASR = currentTranscript.usePaidASR;
        conversationBlocs.push(bloc);
    });
    return conversationBlocs;
};
export const getIsAllowedAccess = ({ status, features }) => {
    let isAllowedAccess = status.confidential.allowTranscriptsSaving;
    const hasSaveTranscript = features.saveTranscript;
    const hasForbidConversationSaving = features['forbid-conversation-saving'];
    const { id: roomId, host } = status;
    if (hasForbidConversationSaving || !hasSaveTranscript) {
        isAllowedAccess = false;
    }
    else if (roomId.indexOf(host.avaId) !== 0) {
        if (host.flags['forbid-conversation-sharing']) {
            isAllowedAccess = false;
        }
        else {
            isAllowedAccess = isAllowedAccess || host.flags.saveTranscript;
        }
    }
    return isAllowedAccess;
};
export const getSpeakers = ({ speakers }) => {
    const speakersArr = [];
    Object.keys(speakers).forEach((speakerId) => {
        const currentSpeaker = speakers[speakerId];
        const speaker = {};
        speaker.avaId = speakerId;
        speaker.avaName = currentSpeaker.avaName;
        speaker.theme = currentSpeaker.theme;
        speaker.userName = currentSpeaker.userName;
        speaker.userAvatar = currentSpeaker.userAvatar;
        speakersArr.push(speaker);
    });
    return speakersArr;
};
export const updateMetricsAndRefetchProfile = (metricsToUpdate, avaId, firebaseAuthUID, dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    // We want to let the service worker do the long
    // running queries, especially if we're closing the window!
    try {
        updateConvoMetricsThroughWorker(avaId, firebaseAuthUID, metricsToUpdate);
    }
    catch (_a) {
        // If the user is closing the window, this will not work
        // as it's a promise, long running query
        yield updateConversationMetrics({ metricsToUpdate, avaId, firebaseAuthUID });
    }
    dispatch(fetchUserProfile());
});
export const trackHadConversation = ({ allMicsSelected, conversationStatus, conversationBroadcastedToZoom, endedByCurrentUser, endedDueToConvoRestrictedSession, isTranscriptSaved, inTwilioCalls, transcriptStats, avaId, accountType, feedbackMetrics, firebaseAuthUID, dispatch, }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!avaId)
        return;
    const { conversationMode, host, id: convoChannel, scribe, speakers, startTimestampMs } = conversationStatus;
    const { wordsTypedYou, wordsTypedGroup, wordsCapturedYou, wordsCapturedGroup } = transcriptStats;
    const conversationTimeInSeconds = getConversationTimeInSeconds(startTimestampMs);
    const currentMetrics = feedbackMetrics;
    const closeReason = getConversationCloseReason({
        currentUserAvaId: avaId || '',
        endedByCurrentUser,
        endedDueToConvoRestrictedSession,
        host,
    });
    const metricsToUpdate = getMetricsToUpdate({
        conversationTimeInSeconds,
        isSoloConvo: (speakers === null || speakers === void 0 ? void 0 : speakers.length) === 1,
        startTimestampMs: startTimestampMs,
        transcriptSaved: isTranscriptSaved,
        transcriptStats,
    });
    if (isFirstConvo(currentMetrics)) {
        segment.identifyWithId(avaId || '', { First_convo_at: new Date() });
    }
    segment.track('Had Conversation', {
        'Convo Time': conversationTimeInSeconds,
        'Convo Channel': convoChannel,
        IsFirstConvo: isFirstConvo(currentMetrics),
        IsHost: isHost({ host, avaId: avaId || '' }),
        Scribe: !!scribe,
        IsTempUser: accountType === 'Temporary',
        IsScribeRequester: isScribeRequester({ scribe, avaId: avaId || '' }),
        'Close Reason': closeReason,
        'Conversation Mode': conversationMode,
        'Participant Count': speakers.length,
        'Participant List': speakers.map((speaker) => `${speaker.userName} / ${speaker.avaId} / ${speaker.avaName}`),
        IsBroadcasted: conversationBroadcastedToZoom,
        IsDialIn: inTwilioCalls,
        TranscriptSaved: isTranscriptSaved,
        'Words Typed You': wordsTypedYou,
        'Words Typed Group': wordsTypedGroup,
        'Words Captured You': wordsCapturedYou,
        'Words Captured Group': wordsCapturedGroup,
        microphone_selected: allMicsSelected,
    });
    analytics.logEvent('app_had_conversation', {
        words_captured_group: wordsCapturedGroup,
        words_captured_you: wordsCapturedYou,
        participant_count: speakers.length,
        convo_scribed: !!scribe,
        is_scribe_requester: isScribeRequester({ scribe, avaId: avaId || '' }),
        is_host: isHost({ host, avaId: avaId || '' }),
        convo_time: conversationTimeInSeconds,
    });
    updateMetricsAndRefetchProfile(metricsToUpdate, avaId, firebaseAuthUID, dispatch);
});
export const trackConversationRating = ({ rate, status, feedbackMetrics, avaId, firebaseAuthUID, dispatch, }) => __awaiter(void 0, void 0, void 0, function* () {
    const { id: convoChannel } = status;
    const currentMetrics = feedbackMetrics;
    const metricsToUpdate = getRatingMetricsToUpdate({ rate });
    segment.track('Rated', {
        convo_channel: convoChannel,
        rating: rate || 0,
        is_first_5_star: rate && rate === 5 && !(currentMetrics === null || currentMetrics === void 0 ? void 0 : currentMetrics.Rated_5),
    });
    updateMetricsAndRefetchProfile(metricsToUpdate, avaId, firebaseAuthUID, dispatch);
});
