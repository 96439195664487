var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authInstance } from '../../firebase';
import { selectJoinConversationManager } from '../../selectors/conversation';
import { selectAvaId, selectFeatures, selectSubscription } from '../../selectors/userProfile';
import { getOS } from '../../utils';
import { clearSearchValue } from '../../utils/http';
import { getsingletonJoinConversationManager } from '../../utils/joinConversation';
import { isUnlimitedPaidASR } from '../../utils/status';
import { setOnlyInternalAudio } from './audioRecorder';
import { setUrlToOpenAfterStartingConvo } from './quickSetup';
import { setLoading } from './uiState';
import { setV1Token } from './v1Session';
const initialState = {
    editedWords: [],
    curseFilter: parseInt(localStorage.getItem('ava-curse-filter') || '2', 10),
    joiningStatus: 'none',
    selectedCaptions: 'free',
    isInConversation: false,
    conversationEnded: false,
    inTwilioCalls: false,
    createConversationWhenReady: false,
};
export const conversationSlice = createSlice({
    name: 'conversation',
    initialState,
    reducers: {
        addEditedWord(state, { payload }) {
            state.editedWords.push(payload);
        },
        setCurseFilter(state, { payload }) {
            window.localStorage.setItem('ava-curse-filter', payload.toString());
            state.curseFilter = payload;
        },
        setJoiningStatus(state, { payload }) {
            state.joiningStatus = payload;
        },
        setIsInConversation(state, { payload }) {
            state.isInConversation = payload;
        },
        setConversationEnded(state, { payload }) {
            state.conversationEnded = payload;
        },
        setSelectedCaptions(state, { payload }) {
            state.selectedCaptions = payload;
        },
        updateBroadcastToZoomToken(state, { payload }) {
            state.broadcastToZoomToken = payload;
        },
        setCreateConversationWhenReady(state, { payload }) {
            state.createConversationWhenReady = payload;
        },
        setJoinConversationError(state, { payload }) {
            state.joinConversationError = payload;
        },
        setWakeLockSentinel(state, { payload }) {
            state.conversationWakeLock = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('ROOM_STATUS_UPDATE', (state, action) => {
            var _a;
            state.inTwilioCalls = action.status.twilioCalls && Object.keys(action.status.twilioCalls).length > 0;
            // backend will send usePaidASR in audioStreams if using premium captions
            if (action.status.audioStreams && ((_a = action.status.audioStreams[0]) === null || _a === void 0 ? void 0 : _a.usePaidASR)) {
                state.backendSelectedCaptions = 'premium';
            }
        });
        builder.addCase(createJoinConversationManager.fulfilled, (state, { payload }) => {
            if (payload)
                state.joinConversationManager = payload;
        });
    },
});
export const conversationReducer = conversationSlice.reducer;
export const { updateBroadcastToZoomToken, setSelectedCaptions, addEditedWord, setCurseFilter, setJoiningStatus, setCreateConversationWhenReady, } = conversationSlice.actions;
export const { setWakeLockSentinel, setIsInConversation, setConversationEnded, setJoinConversationError } = conversationSlice.actions;
export const updateConversationEnded = createAsyncThunk('conversation/updateConversationEnded', (conversationEnded, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const dispatch = thunkAPI.dispatch;
    dispatch(setConversationEnded(conversationEnded));
    if (conversationEnded) {
        clearSearchValue(window, 'endpoint');
        dispatch(setV1Token(undefined));
    }
}));
export const resetSelectedCaptions = createAsyncThunk('conversation/resetSelectedCaptions', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const state = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch;
    const asrTime = Math.floor(((_a = state.userProfile) === null || _a === void 0 ? void 0 : _a.paidASRCreditTime) / 1000);
    const subscription = selectSubscription(state);
    const features = selectFeatures(state);
    const unlimitedPaidASR = isUnlimitedPaidASR(subscription, features);
    const canSelectPremium = unlimitedPaidASR || asrTime > 0;
    dispatch(setSelectedCaptions(canSelectPremium ? 'premium' : 'free'));
}));
export const updateIsInConversation = createAsyncThunk('conversation/updateIsInConversation', (isInConversation, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const state = thunkAPI.getState();
    const dispatch = thunkAPI.dispatch;
    if (isInConversation === state.conversation.isInConversation) {
        return thunkAPI.rejectWithValue(false);
    }
    if (window.electronIPC) {
        window.electronIPC.sendSetIsInConversation(isInConversation);
    }
    dispatch(setIsInConversation(isInConversation));
    if (isInConversation) {
        dispatch(resetSelectedCaptions());
        if (window.navigator.wakeLock) {
            const wakeLockSentinel = yield window.navigator.wakeLock.request('screen');
            dispatch(setWakeLockSentinel(wakeLockSentinel));
        }
    }
    if (!isInConversation) {
        if (state.conversation.conversationWakeLock) {
            yield state.conversation.conversationWakeLock.release();
        }
        dispatch(setWakeLockSentinel(undefined));
    }
    // If electron app and hearing profile is "Deaf non-voicing" - enable only internal audio
    // FIXME: when TS is introduced, this value should be in enum not hard coded
    if (isInConversation && window.isElectron && ((_b = state.userProfile.profile) === null || _b === void 0 ? void 0 : _b.hearingProfile) === 4) {
        dispatch(setOnlyInternalAudio(true));
    }
    if (window.isElectron && getOS() === 'Mac') {
        const micId = state.audioRecorder.micIdSelected;
        const mic = micId && state.audioRecorder.availableMics.find((m) => m.value === micId);
        const isInternal = mic && mic.label.startsWith('Ava Computer Audio');
        if (isInternal) {
            if (isInConversation) {
                window.electronIPC.sendActivateMacInternalAudio();
            }
            else {
                window.electronIPC.sendDeactivateMacInternalAudio();
            }
        }
    }
    if (state.quickSetup.urlToOpenAfterStartingConvo && isInConversation) {
        const url = state.quickSetup.urlToOpenAfterStartingConvo;
        setTimeout(() => {
            if (window.desktopIntegration) {
                window.desktopIntegration.openExternalURL(url);
            }
        }, 500);
        dispatch(setUrlToOpenAfterStartingConvo(undefined));
    }
}));
export const createJoinConversationManager = createAsyncThunk('conversation/createJoinConversationManager', (_, thunkAPI) => {
    var _a, _b, _c;
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const { v1Socket } = state.v1Session;
    const avaName = ((_a = state.userProfile.parse) === null || _a === void 0 ? void 0 : _a.avaName) || '';
    const firebaseUser = ((_b = state.auth.firebaseUser) === null || _b === void 0 ? void 0 : _b.uid) || '';
    const avaId = ((_c = state.userProfile.parse) === null || _c === void 0 ? void 0 : _c.avaId) || '';
    if (!v1Socket)
        return;
    return getsingletonJoinConversationManager(v1Socket, dispatch, avaName, firebaseUser, avaId);
});
export const joinConversationAnonymously = createAsyncThunk('conversation/joinConversationAnonymously', (avaName, { dispatch, getState }) => __awaiter(void 0, void 0, void 0, function* () {
    if (avaName.length < 3)
        return;
    const state = getState();
    dispatch(setLoading(true));
    const avaId = selectAvaId(state);
    const joinConversationManager = selectJoinConversationManager(state);
    if (!avaId) {
        localStorage.setItem('ava_request', avaName);
        authInstance.signInAnonymously();
        // ava_request will be picked up during v1 Socket creation.
    }
    else {
        // user is already logged in, so the v1 Socket already exists
        joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.handleJoinAvaRoom(avaName);
    }
}));
