import Close from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import React from 'react';
const { Fragment } = React;
export const getSnack = (p) => (text, options, button) => {
    return p.enqueueSnackbar(text, Object.assign(Object.assign({}, options), { action: (key) => (React.createElement(Fragment, null,
            button && (React.createElement(Button, { href: button.url, target: "_blank", rel: "noreferrer noopener", variant: "outlined", style: {
                    borderColor: options.color || 'white',
                    borderRadius: 20,
                    color: options.color || 'white',
                    fontSize: '0.9em',
                    textTransform: 'none',
                } }, button.text)),
            React.createElement(Button, { onClick: () => {
                    p.closeSnackbar(key);
                }, style: {
                    color: 'inherit',
                } },
                React.createElement(Close, null)))) }));
};
