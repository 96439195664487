import { Avatar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Logo from '../../../../assets/images/ava_logo_sphere.png';
const useStyles = makeStyles((theme) => ({
    memberContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        marginRight: 8,
    },
    name: {
        fontSize: 18,
        marginBottom: 4,
    },
    logo: {
        height: 16,
        width: 16,
        marginRight: 4,
    },
    avaNameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    avaName: {
        color: theme.palette.ava.grey5,
        fontSize: 14,
    },
}));
const Member = ({ avaName, avatar, name }) => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.memberContainer },
        React.createElement(Avatar, { className: classes.avatar, src: avatar }, avatar ? undefined : name.charAt(0)),
        React.createElement("div", null,
            React.createElement(Typography, { className: classes.name }, name),
            React.createElement("div", { className: classes.avaNameContainer },
                React.createElement("img", { className: classes.logo, src: Logo }),
                React.createElement(Typography, { className: classes.avaName }, avaName)))));
};
export default Member;
