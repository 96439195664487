import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AppleIcon from '../../assets/icons/apple.svg';
import { LoginButton } from './LoginButton';
const useStyles = makeStyles(() => ({
    signinLogo: {
        width: 24,
        height: 24,
    },
}));
export const LoginApple = (props) => {
    const classes = useStyles();
    const { login } = props;
    const { t } = useTranslation();
    return (React.createElement(LoginButton, { login: login, icon: React.createElement("img", { className: classes.signinLogo, src: AppleIcon, alt: "Apple logo" }), text: t('signInWithApple'), style: {
            backgroundColor: 'black',
            color: 'white',
        } }));
};
