import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import ConnectToMeetingsCard from '../../components/Cards/ConnectToMeetingsCard/ConnectToMeetingsCard';
import { selectIsConnectToMeetingOpen } from '../../selectors/ui';
import { setConnectToMeetingsOpen } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import ConnectToMeetingsButton from './ConnectToMeetingsButton';
const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            maxWidth: '45%',
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
function ConnectToMeetingsMobileContainer({ hideText }) {
    const isConnectToMeetingsOpen = useAppSelector(selectIsConnectToMeetingOpen);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const handleClose = (_event, reason) => {
        if (reason === 'backdropClick') {
            dispatch(setConnectToMeetingsOpen(false));
        }
    };
    return (React.createElement("div", { className: classes.container }, !isConnectToMeetingsOpen ? (React.createElement("div", null,
        React.createElement(ConnectToMeetingsButton, { hideText: hideText, open: isConnectToMeetingsOpen, tabletOrMobile: true }))) : (React.createElement(Modal, { open: isConnectToMeetingsOpen, className: classes.modal, onClose: handleClose },
        React.createElement("div", null,
            React.createElement(ConnectToMeetingsCard, { tabletOrMobile: true }))))));
}
export default ConnectToMeetingsMobileContainer;
