var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Typography from '@mui/material/Typography';
import { withTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Phone from '../../../assets/icons/phone.png';
import { clearScribeConversationState } from '../../actions';
import RoundedButton from '../../components/Buttons/RoundedButton';
import MicIcon from '../../components/Icons/Mic';
import JoinConversationButton from '../../components/JoinConversation/Button';
import PhraseMarkdownText from '../../components/PhraseMarkdownText';
import { selectFirebaseUser } from '../../selectors/auth';
import { useAppSelector } from '../../store/store';
import { logoutAndClean } from '../../utils/user';
import ConferenceCallPopupContainer from '../scribe/ConferenceCallPopupContainer';
import ConversationButton from './ConversationButton';
const useStyles = makeStyles((theme) => ({
    workLogo: {
        height: '100%',
        marginTop: -28,
        marginBottom: 2,
        [theme.breakpoints.up('xl')]: {
            height: 'unset !important',
            width: '100%',
        },
        [theme.breakpoints.down('xl')]: {
            height: 400,
        },
        [theme.breakpoints.down('lg')]: {
            height: 350,
        },
    },
    title: {
        fontSize: 40,
        fontWeight: 400,
        marginBottom: 20,
        [theme.breakpoints.down('lg')]: {
            fontSize: 34,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 28,
        },
    },
    conversationOptions: {
        width: '100%',
        maxWidth: 537,
        '& > *': {
            marginBottom: 20,
        },
    },
    icon: {
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    startCaptionsIcon: {
        top: 5,
        left: 20,
    },
    text: {
        fontSize: 20,
        lineHeight: '26px',
    },
    conferenceCallButton: {
        backgroundColor: theme.palette.ava.scribe2,
        color: theme.palette.ava.white,
        '&:hover': {
            backgroundColor: theme.palette.ava.scribe1,
        },
    },
    phoneIcon: {
        top: 18,
        left: 28,
    },
    signInLink: {
        alignItems: 'center',
        background: theme.palette.ava.blue,
        borderRadius: 100,
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto 16px',
        textAlign: 'center',
        width: '100%',
        whiteSpace: 'nowrap',
        '&:hover': {
            textDecoration: 'none',
            color: 'white',
        },
    },
    signInButton: {
        width: '100%',
        height: 56,
        fontSize: 20,
    },
    link: {
        fontWeight: 600,
    },
}));
const Landing = ({ host, twilio, onHost, theme }) => {
    const classes = useStyles();
    const [twilioPopup, setTwilioPopup] = useState(false);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    return (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        } },
        twilioPopup && React.createElement(ConferenceCallPopupContainer, { on: twilioPopup, setOff: () => setTwilioPopup(false) }),
        React.createElement("img", { src: theme.palette.mode === 'dark' ? '/work_dark.svg' : '/work.svg', alt: "ava work logo", className: classes.workLogo }),
        React.createElement(Typography, { variant: "h4", align: "center", className: classes.title },
            React.createElement(PhraseMarkdownText, { phraseKey: "joinAConversation" })),
        React.createElement("div", { className: classes.conversationOptions },
            (!firebaseUser || (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous)) && (React.createElement(RoundedButton, { className: classes.signInButton, color: "blue", disableRipple: true, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    yield logoutAndClean();
                    clearScribeConversationState();
                    window.history.pushState({}, document.title, '/web');
                    window.location.reload();
                }) },
                React.createElement(PhraseMarkdownText, { phraseKey: "signUpForCaptions" }))),
            host && (React.createElement(ConversationButton, { color: "blue", onClick: onHost },
                React.createElement("div", { className: classnames(classes.icon, classes.startCaptionsIcon) },
                    React.createElement(MicIcon, { width: 36, height: 44 })),
                React.createElement(PhraseMarkdownText, { className: classes.text, phraseKey: "sidebar.buttons.startCaptions" }))),
            twilio && (React.createElement(ConversationButton, { className: classes.conferenceCallButton, onClick: () => setTwilioPopup(true) },
                React.createElement("img", { className: classnames(classes.icon, classes.phoneIcon), src: Phone, alt: "phone", height: "20" }),
                React.createElement(PhraseMarkdownText, { className: classes.text, phraseKey: "conversation.call.joinShort" }))),
            React.createElement(JoinConversationButton, { landing: true }))));
};
const mapStateToProps = ({}) => ({});
const mapDispatchToProps = {
    clearScribeConversationState,
};
export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Landing));
