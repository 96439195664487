import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '../../assets/images/fb_login.png';
import { LoginButton } from './LoginButton';
const useStyles = makeStyles(() => ({
    signinLogo: {
        width: 24,
        height: 24,
        transform: 'translate(-2px, -2px)',
    },
}));
export const LoginFacebook = (props) => {
    const classes = useStyles();
    const { login } = props;
    const { t } = useTranslation();
    return (React.createElement(LoginButton, { login: login, icon: React.createElement("img", { className: classes.signinLogo, src: FacebookIcon, alt: "Facebook logo" }), text: t('signInWithFacebook'), style: {
            backgroundColor: '#1876f2',
            color: 'white',
            whiteSpace: 'nowrap',
        } }));
};
