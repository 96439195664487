import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import KeyboardImage from '../../../assets/images/keyboard.png';
import { selectKeyboardInputShown } from '../../selectors/ui';
import { useAppSelector } from '../../store/store';
const KeyboardButton = ({ onClick }) => {
    const theme = useTheme();
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    return (React.createElement(Fab, { style: { backgroundColor: keyboardInputShown ? theme.palette.ava.blue : theme.palette.ava.grey }, "data-qa": "openKeyboardTtsButton", onClick: onClick },
        React.createElement("img", { src: KeyboardImage, alt: "reply by keyboard", height: "20" })));
};
export default KeyboardButton;
