// @flow
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import RoundedButton from '../../Buttons/RoundedButton';
import { WorkspaceDivider, WorkspaceRaisedContainer } from '../components';
import useWorkspaceUIContext from '../hooks/useWorkspaceUIContext';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    blueText: {
        color: theme.palette.ava.blue1,
    },
    proLicensesContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    header: {
        fontWeight: 600,
        lineHeight: '26px',
    },
    variantMapping: {
        h4: 'h4',
        h6: 'h6',
    },
    button: {
        fontSize: '18px',
        lineHeight: '24px',
        padding: '8px 24px',
    },
}));
const ScribeHoursAndProLicenses = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { setSidebarView } = useWorkspaceUIContext();
    // TODO get these from backend
    const scribeHours = 0;
    const proLicensesCount = 0;
    const openLicensesSidebar = () => {
        setSidebarView('AllocateLicenses');
    };
    return (React.createElement(WorkspaceRaisedContainer, { className: classes.container },
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h6", classes: { h6: classes.header } }, t('workspace.members.scribeTime')),
            React.createElement(WorkspaceDivider, null),
            React.createElement(Typography, { className: classes.blueText, classes: { h4: classes.header }, variant: "h4" }, `${scribeHours} ${t('workspace.members.hours')}`)),
        React.createElement("div", null,
            React.createElement(Typography, { variant: "h6", classes: { h6: classes.header } }, t('workspace.members.proLicenses')),
            React.createElement(WorkspaceDivider, null),
            React.createElement("div", { className: classes.proLicensesContainer },
                React.createElement(Typography, { className: classes.blueText, style: { marginRight: 172 }, variant: "h4" }, proLicensesCount),
                React.createElement("div", null,
                    React.createElement(RoundedButton, { color: "blue", onClick: openLicensesSidebar, className: classes.button, style: { marginRight: 8 } }, t('workspace.members.buy')),
                    React.createElement(RoundedButton, { className: classes.button, color: "white" }, t('workspace.members.startTrial')))))));
};
export default ScribeHoursAndProLicenses;
