var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { withStyles, withTheme } from '@mui/styles';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import ZingTouch from 'zingtouch';
import { createNewConversation } from '../../../actions';
import Loading from '../../../components/Loading';
import RateConversation from '../../../components/Rating/RateConversation';
import SnackbarWithCountdown from '../../../components/SnackbarWithCountdown';
import useBoostManager from '../../../hooks/useBoostManager';
import useJoinConversationManager from '../../../hooks/useJoinConversationManager';
import usePrevious from '../../../hooks/usePrevious';
import useRecallAIManager from '../../../hooks/useRecallAIManager';
import { useV1Socket } from '../../../hooks/useV1Socket';
import ConversationButtons from '../../../modules/conversation/ConversationButtons';
import KeyboardInput from '../../../modules/conversation/KeyboardInput';
import Landing from '../../../modules/landing/Landing';
import BoostModal from '../../../modules/scribe/BoostModal';
import EditProfileModal from '../../../modules/scribe/EditProfileModal';
import ScribeKeypressHandlerContainer from '../../../modules/scribe/ScribeKeypressHandlerContainer';
import SetupAudio from '../../../modules/scribe/SetupAudioContainer';
import { ZoomBroadcastCaptionsPopup } from '../../../modules/scribe/ZoomBroadcastCaptionsPopup';
import { selectIsRecording, selectMicrophoneAccess, selectNeedInternalAudioAccess, } from '../../../selectors/audioRecorder';
import { selectAllMicsSelected } from '../../../selectors/audioRecorder';
import { selectFirebaseUser } from '../../../selectors/auth';
import { selectBoostWords, selectIsBoosting } from '../../../selectors/boost';
import { selectConversationMuted, selectQuickSetupShown, selectShowKeyboardInput } from '../../../selectors/combined';
import { selectBroadcastToZoomToken, selectConversationEnded, selectEditedWords, selectInTwilioCalls, selectIsInConversation, } from '../../../selectors/conversation';
import { selectLang, selectSpeakersMap, selectTranscripts } from '../../../selectors/legacy-conversation';
import { selectIsRateConversationOpen } from '../../../selectors/rate-conversation';
import { selectRecallAIStatus } from '../../../selectors/recallAI';
import { selectIsScribing } from '../../../selectors/scribe';
import { selectElectronCaptionMode, selectFullScreen, selectKeyboardInputShown, selectLoading, } from '../../../selectors/ui';
import { selectAccountType, selectAvaId, selectFeatures, selectFeedbackMetrics, selectIsUserTemporary, selectOrganization, selectSubscription, selectUserName, } from '../../../selectors/userProfile';
import { selectConferenceCallRequested } from '../../../selectors/v1Session';
import { calls } from '../../../services/api/ava';
import LocalStorage, { STORAGE_KEYS } from '../../../services/localStorage';
import { setMicId, setRecording } from '../../../store/slices/audioRecorder';
import { setAvailableMics } from '../../../store/slices/audioRecorder';
import { updateBroadcastToZoomToken } from '../../../store/slices/conversation';
import { setHostName, setRateConversationOpen } from '../../../store/slices/rateConversation';
import { addNewTranscript, setSelectedTranscriptId } from '../../../store/slices/savedTranscript';
import { setConnectToMeetingsOpen, setElectronCaptionMode as updateElectronCaptionMode, toggleSideBar, updateKeyboardInputShown, } from '../../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { getOS } from '../../../utils';
import * as Conversation from '../../../utils/conversation';
import { getText } from '../../../utils/scribeUtils';
import { getPlanType } from '../../../utils/status';
import { isBoostEnabled } from '../../../utils/status';
import { getDefaultRoomId, sendResetRoomIdMessage, sendRoomStateUpdateMessage } from '../../../utils/ws-v1';
import CCPage from '../../cc-mode/CCPage';
import ConversationView from '../ConversationView/ConversationView';
import styles from './ConversationPage.styles';
const branchSdk = require('branch-sdk');
const ScribeConversation = (props) => {
    var _a, _b;
    const { addNewTranscript, // eslint-disable-line no-shadow
    classes, createNewConversation, getTranscriptStats, haveTranscripts, i18n, lang, scribeTrainingRequested, setCCMode, setConversationStatus, setHostName, setRateConversationOpen, setSelectedTranscriptId, speakers, speechLang, status, t, theme, transcripts, } = props;
    const silenceTimeout = useRef();
    const [appSubmittedTypeform, setAppSubmittedTypeform] = useState({});
    const [askingUserName, setAskingUserName] = useState(false);
    const [avaNameRequested, setAvaNameRequested] = useState(undefined);
    const [broadcastToZoomWarningShown, setBroadcastToZoomWarningShown] = useState(false);
    const [connectionByUsernameRequested, setConnectionByUsernameRequested] = useState(false);
    const [conversationBroadcastedToZoom, setConversationBroadcastedToZoom] = useState(false);
    const [conversationContainerHovered, setConversationContainerHovered] = useState(false);
    const [editedWordsState, setEditedWordsState] = useState([]);
    const [endedByCurrentUser, setEndedByCurrentUser] = useState(false);
    const [endedDueToConvoRestrictedSession, setEndedDueToConvoRestrictedSession] = useState(false);
    const [newTranscriptDraft, setNewTranscriptDraft] = useState('');
    const [redirectLoading, setRedirectLoading] = useState(false);
    const [roomEndingWarningBegin, setRoomEndingWarningBegin] = useState(0);
    const [roomEndingWarningCountdown, setRoomEndingWarningCountdown] = useState(0);
    const [roomEndingWarningProlongUrl, setRoomEndingWarningProlongUrl] = useState('');
    const [showBoostModal, setShowBoostModal] = useState(false);
    const [showSaveTranscriptsModal, setShowSaveTranscriptsModal] = useState(false);
    const [transcriptSaveClicked, setTranscriptSaveClicked] = useState();
    const [ttsBestVoice, setTtsBestVoice] = useState(undefined);
    const [ttsVoices, setTtsVoices] = useState([]);
    const [zoomExportPopup, setZoomExportPopup] = useState();
    const features = useAppSelector(selectFeatures);
    const avaId = useAppSelector(selectAvaId);
    const organization = useAppSelector(selectOrganization);
    const userName = useAppSelector(selectUserName);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const feedbackMetrics = useAppSelector(selectFeedbackMetrics);
    const accountType = useAppSelector(selectAccountType);
    const subscription = useAppSelector(selectSubscription);
    const firebaseAuthUID = (_a = useAppSelector(selectFirebaseUser)) === null || _a === void 0 ? void 0 : _a.uid;
    const fullScreen = useAppSelector(selectFullScreen);
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const loading = useAppSelector(selectLoading);
    const allMicsSelected = useAppSelector(selectAllMicsSelected);
    const microphoneAccess = useAppSelector(selectMicrophoneAccess);
    const needInternalAudioAccess = useAppSelector(selectNeedInternalAudioAccess);
    const recording = useAppSelector(selectIsRecording);
    const conferenceCallRequested = useAppSelector(selectConferenceCallRequested);
    const scribing = useAppSelector(selectIsScribing);
    const editedWords = useAppSelector(selectEditedWords);
    const isInConversation = useAppSelector(selectIsInConversation);
    const conversationEnded = useAppSelector(selectConversationEnded);
    const inTwilioCalls = useAppSelector(selectInTwilioCalls);
    const keyboardInputShown = useAppSelector(selectKeyboardInputShown);
    const muted = useAppSelector(selectConversationMuted);
    const isBoosting = useAppSelector(selectIsBoosting);
    const boostWords = useAppSelector(selectBoostWords);
    const broadcastToZoomToken = useAppSelector(selectBroadcastToZoomToken);
    const isRateConversationOpen = useAppSelector(selectIsRateConversationOpen);
    const showKeyboardButton = useAppSelector(selectShowKeyboardInput);
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const quickSetupShown = useAppSelector(selectQuickSetupShown);
    const tempUser = useAppSelector(selectIsUserTemporary);
    const dispatch = useAppDispatch();
    const [ws, websocketStatus] = useV1Socket();
    const recallAIManager = useRecallAIManager();
    const navigate = useNavigate();
    const joinConversationManager = useJoinConversationManager();
    const boostManager = useBoostManager();
    useEffect(() => {
        const containerElement = window.document.getElementById('gesture');
        const activeRegion = ZingTouch.Region(containerElement, false, false);
        activeRegion.bind(containerElement, 'swipe', (event) => {
            const { currentDirection } = event.detail.data[0];
            if (currentDirection < 20 || currentDirection > 340) {
                dispatch(toggleSideBar(true));
            }
            else if (currentDirection > 160 && currentDirection < 200) {
                dispatch(toggleSideBar(false));
            }
        });
        if (window.speechSynthesis) {
            const populateVoiceList = () => {
                const newVoices = window.speechSynthesis.getVoices();
                if (newVoices) {
                    setTtsVoices(newVoices);
                }
            };
            // FF, Chrome and Safari have the voice list prepared at different
            // moments and implement different parts of the API. MDN shows this way
            // of updating the voice list as working for all the browsers in 03/2021.
            populateVoiceList();
            if (window.speechSynthesis.onvoiceschanged !== undefined) {
                window.speechSynthesis.onvoiceschanged = populateVoiceList;
            }
        }
        if (window.electronIPC) {
            window.electronIPC.onEndConversation(requestConversationEnd);
        }
        boostManager === null || boostManager === void 0 ? void 0 : boostManager.handleLoadBoostWords();
    }, []);
    useEffect(() => {
        if (isInConversation) {
            // Hide the journey banner
            branchSdk.closeJourney();
        }
        else {
            // Show the journey banner
            branchSdk.track('pageview');
        }
    }, [isInConversation]);
    useEffect(() => {
        findBestTTSVoice();
    }, [speechLang, ttsVoices]);
    useEffect(() => {
        var _a;
        if (((_a = status.state) === null || _a === void 0 ? void 0 : _a.value) === 'ended' && status.reason === 'room-ended-due-to-convo-restricted-session') {
            setEndedDueToConvoRestrictedSession(true);
            requestConversationEnd();
        }
    }, [(_b = status === null || status === void 0 ? void 0 : status.state) === null || _b === void 0 ? void 0 : _b.value]);
    useEffect(() => {
        if (conversationEnded) {
            setEndedDueToConvoRestrictedSession(false);
            setRoomEndingWarningBegin(0);
            setRoomEndingWarningCountdown(0);
            setRoomEndingWarningProlongUrl('');
            setAppSubmittedTypeform({});
        }
    }, [conversationEnded]);
    useEffect(() => {
        if (status.reason === 'convo-restricted-session-warning') {
            const params = {
                lang: i18n.language,
                src: 'timeout',
                med: window.isElectron ? 'cc' : 'webapp',
                mkt: '',
            };
            switch (subscription === null || subscription === void 0 ? void 0 : subscription.ongoingSubscription) {
                case 'Campus':
                    params.mkt = 'edu';
                    break;
                case 'Pro':
                    params.mkt = 'hr';
                    break;
                case 'Premium':
                case 'Free':
                    params.mkt = 'ind';
                    break;
                default:
                    break;
            }
            let url = `https://ava-me.typeform.com/to/${i18n.language === 'fr' ? 'R8L5rC' : 'q2n11h'}${Object.keys(params).reduce((acc, curr) => `${acc}${curr}=${params[curr]}&`, '?')}`;
            url = url.substring(0, url.length - 1);
            url += `#userid=${LocalStorage.get(STORAGE_KEYS.AVA_ID) || ''}`;
            setRoomEndingWarningBegin(Date.now());
            setRoomEndingWarningCountdown(status.restrictions.convoWarningCountdownTimerMs / 1000 || 300);
            setRoomEndingWarningProlongUrl(url);
            setAppSubmittedTypeform({
                channel: 'inbound',
                source: 'timeout',
                market: params.mkt === 'ind' ? 'individual' : params.mkt,
                organization_name: organization === null || organization === void 0 ? void 0 : organization.name,
                plan: getPlanType(subscription),
            });
        }
    }, [status === null || status === void 0 ? void 0 : status.reason]);
    useEffect(() => {
        // If either the conversation just started (isInConversation), or its type
        // changed, we potentially need to start recording. `muted` depends on
        // conversationMode.
        if (isInConversation &&
            !conferenceCallRequested &&
            !muted &&
            (!scribing || scribeTrainingRequested) &&
            !inTwilioCalls) {
            dispatch(setRecording(true));
        }
    }, [muted, isInConversation]);
    useEffect(() => {
        if (inTwilioCalls) {
            dispatch(setRecording(false));
        }
    }, [inTwilioCalls]);
    const countParticipants = (participants) => uniqBy(participants || [], 'avaId').length;
    const participantCount = countParticipants(status.participants);
    useEffect(() => {
        // WARNING: Muting timer logic is tricky and error-prone, so be careful.
        // If we are not recording anymore - stop the muting timer, so that it does
        // not spill over into the next potential recording session.
        if (!recording) {
            stopMutingTimer();
        }
        if (recording &&
            !inTwilioCalls &&
            !conferenceCallRequested &&
            // Sometimes the status.participants list will be empty when we start
            // recording. But if there is more than one participant - the timer
            // will be reset after the list updates.
            participantCount <= 1 &&
            ((subscription === null || subscription === void 0 ? void 0 : subscription.ongoingSubscription) === 'Free' || (subscription === null || subscription === void 0 ? void 0 : subscription.ongoingSubscriptionSubtype) === 'Lite')) {
            // If we are starting to record, but it's not a twilio call and there is
            // only one user -> silence the mic after 100 seconds.
            startMutingTimer();
        }
    }, [recording]);
    useEffect(() => {
        // For some reason backend's participants list contains duplicates.
        // If there is more than one user - never mute the mic.
        if (participantCount > 1) {
            stopMutingTimer();
        }
    }, [participantCount]);
    useEffect(() => {
        // Restart the muting timer if there is a transcript mutation.
        if (!subscription ||
            subscription.ongoingSubscription === 'Free' ||
            subscription.ongoingSubscriptionSubtype === 'Lite') {
            startMutingTimer(true);
        }
    }, [transcripts]);
    const zoomConference = !!Object.values((status === null || status === void 0 ? void 0 : status.twilioCalls) || {}).find((call) => call.conferenceType === 'zoom');
    useEffect(() => {
        if (inTwilioCalls && zoomConference && zoomExportPopup !== status.id && zoomExportPopup !== 'display') {
            setZoomExportPopup('display');
        }
    }, [inTwilioCalls, zoomConference, zoomExportPopup]);
    const handleEditProfilModalClose = ({ updated }) => {
        setAskingUserName(!updated);
    };
    const handleTranscriptInputVisibilityChange = () => {
        dispatch(updateKeyboardInputShown(!keyboardInputShown));
    };
    const onNewTranscriptChange = (text) => {
        setNewTranscriptDraft(text);
    };
    const findBestTTSVoice = () => {
        let voice;
        if (window.speechSynthesis && ttsVoices.length) {
            let candidateVoices = ttsVoices.filter((v) => v.lang === speechLang);
            if (!candidateVoices.length) {
                const pattern = /-|_/;
                const shortLang = speechLang.split(pattern)[0];
                candidateVoices = ttsVoices.filter((v) => v.lang.split(pattern)[0] === shortLang);
            }
            if (candidateVoices.length === 1)
                [voice] = candidateVoices;
            if (candidateVoices.length > 1) {
                // TODO: Select the best candidate based on user's gender.
                // "Male", "Female", "David", "Zira" are good indicators for gender
                // of the voice for English voices.
                [voice] = candidateVoices;
            }
        }
        setTtsBestVoice(voice);
    };
    const getHostName = () => {
        // When a conversation ends, the status may become empty. To keep the previous
        // conversation, the data needs to be passed in.
        if (!status || !status.id || !speakers)
            return '';
        const hostId = status.id.split('_')[0];
        const host = speakers[hostId] || {};
        const hostName = host.userName || hostId;
        return hostName;
    };
    useEffect(() => {
        if (conversationEnded) {
            requestConversationEnd();
        }
    }, [conversationEnded]);
    const prevIsInConversation = usePrevious(isInConversation);
    useEffect(() => {
        var _a;
        if (isInConversation) {
            if (userName === 'web guest' && recallAIStatus !== 'CAPTIONING') {
                setAskingUserName(true);
                setAvaNameRequested((_a = status === null || status === void 0 ? void 0 : status.host) === null || _a === void 0 ? void 0 : _a.userName);
            }
            // TODO: This particular if might not be needed, but it was here.
            if (!conversationEnded && !endedByCurrentUser) {
                setTranscriptSaveClicked(undefined);
                const screenWidth = Math.max((document.documentElement && document.documentElement.clientWidth) || 0, window.innerWidth || 0);
                if (screenWidth > 768 && !window.isElectron && !electronCaptionMode) {
                    dispatch(toggleSideBar(true));
                }
                if (window.isElectron) {
                    dispatch(updateElectronCaptionMode(true));
                    setCCMode('conversation');
                }
            }
            setEndedByCurrentUser(false);
        }
        if (!isInConversation && prevIsInConversation) {
            recallAIManager === null || recallAIManager === void 0 ? void 0 : recallAIManager.reset(avaId);
            joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.reset();
            setConnectionByUsernameRequested(false);
            setShowBoostModal(false);
            setShowSaveTranscriptsModal(false);
            dispatch(setConnectToMeetingsOpen(false));
            setRoomEndingWarningBegin(0);
            setBroadcastToZoomWarningShown(false);
            // When conversation ends, reset the mic selected and available mics.
            // They will get set again when user enters a new convo. They are needed
            // here because having this code in AudioRecorder will result in the
            // microphone staying off when switching between CC mode and expanded view.
            dispatch(setMicId({ micId: '' }));
            dispatch(setAvailableMics([]));
            const timestamp = Date.now();
            const isSaveTranscript = features.saveTranscript;
            const isForbidConversationSaving = features['forbid-conversation-saving'];
            const transcriptStats = getTranscriptStats();
            setConversationStatus(status);
            Conversation.trackHadConversation({
                allMicsSelected,
                conversationStatus: status,
                conversationBroadcastedToZoom,
                endedByCurrentUser,
                endedDueToConvoRestrictedSession,
                inTwilioCalls,
                isTranscriptSaved: isSaveTranscript && !isForbidConversationSaving,
                transcriptStats,
                avaId,
                feedbackMetrics,
                accountType,
                firebaseAuthUID,
                dispatch,
            });
            if (window.isElectron) {
                if (getOS() === 'Mac') {
                    window.electronIPC.sendDeactivateMacInternalAudio();
                }
                if (electronCaptionMode) {
                    setCCMode(haveTranscripts ? 'rating' : 'home');
                }
            }
            if (haveTranscripts) {
                setRateConversationOpen(true);
            }
            setRedirectLoading(false);
            if (transcriptSaveClicked || (!electronCaptionMode && isSaveTranscript && !isForbidConversationSaving)) {
                setSelectedTranscriptId(status.id);
                if (Object.keys(transcripts).length) {
                    const blocs = Conversation.getBlocs({ speakers, transcripts });
                    const isAllowedAccess = Conversation.getIsAllowedAccess({ status, features });
                    const convoSpeakers = Object.values(speakers);
                    const convoTranscripts = Conversation.getBlocs({ speakers, transcripts }).length > 0;
                    const newTranscript = {
                        blocs,
                        conversationMode: status.conversationMode,
                        endTimestampMs: timestamp,
                        env: 'production',
                        hasTranscripts: Object.keys(transcripts).length > 0,
                        isAllowedAccess,
                        langs: status.langs,
                        links: status.links,
                        roomId: status.id,
                        speakers: convoSpeakers,
                        startTimestampMs: status.startTimestampMs,
                        t0: status.startTimestampMs,
                        t1: timestamp,
                        title: status.title,
                        custom_title: status.title,
                        transcripts: convoTranscripts,
                    };
                    addNewTranscript(newTranscript);
                }
                navigate('/web/conversations');
            }
        }
    }, [isInConversation]);
    const handleOnEndConversationClick = () => {
        setEndedByCurrentUser(true);
        requestConversationEnd();
    };
    // This is entry point to the conversation ending logic. It's triggered
    // by user actions - either in this component or in the desktop app.
    const requestConversationEnd = () => __awaiter(void 0, void 0, void 0, function* () {
        // Let's mark if that conversation was broadcasted
        setConversationBroadcastedToZoom(!!broadcastToZoomToken);
        dispatch(updateBroadcastToZoomToken(''));
        setHostName(getHostName());
        if (scribeTrainingRequested || electronCaptionMode) {
            stopConversation();
            return;
        }
        // TODO: It would be nice to have a component that can manage all the popups
        // and then have a finished callback to know when everything is done.
        // This way, we are free to quickly and easily reorder pop ups.
        const boostEnabled = isBoostEnabled(subscription);
        const editedWords = checkEditedWords();
        if (boostEnabled && editedWords) {
            setEditedWordsState(editedWords);
            setShowBoostModal(true);
        }
        else {
            setShowSaveTranscriptsModal(true);
        }
    });
    // Callback when boost modal finishes. This modal might be shown or potentially
    // the save transcript modal might be shown instead (look at requestConversationEnd).
    const handleBoostPopup = () => {
        setShowBoostModal(false);
        setEditedWordsState([]);
        setShowSaveTranscriptsModal(true);
    };
    // Callback when save transcript popup finishes.
    // Since this is the last popup, it will handle leaving the conversation.
    // NOTE: If the app decides not to show the transcript popup at all, then
    // this callback will still be called with `saveClicked: bool`.
    const handleSaveTranscriptPopup = ({ saveClicked }) => __awaiter(void 0, void 0, void 0, function* () {
        setTranscriptSaveClicked(saveClicked);
        setShowSaveTranscriptsModal(false);
        setRedirectLoading(true);
        stopConversation();
    });
    // This stops the conversation in the backend.
    const stopConversation = () => __awaiter(void 0, void 0, void 0, function* () {
        if (status.host && status.host.avaId === avaId) {
            Object.keys(status.twilioCalls || {}).forEach((sid) => {
                calls.terminateCall({ avaId, uid: firebaseUser.uid, sid });
            });
            if (ws)
                sendRoomStateUpdateMessage(ws, { value: 'ended' });
        }
        if (ws)
            sendResetRoomIdMessage(ws, getDefaultRoomId(firebaseUser.uid));
    });
    const onHost = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!location.pathname.includes('/web/transcript')) {
            navigate('/web/transcript');
        }
        yield createNewConversation();
    });
    // Returns true is the timer was previously started.
    const stopMutingTimer = () => {
        const timeoutSet = silenceTimeout.current !== undefined;
        if (timeoutSet) {
            clearTimeout(silenceTimeout.current);
            silenceTimeout.current = undefined;
        }
        return timeoutSet;
    };
    // If onlyRestart is set to true, will only start the timer if it was already
    // running. If onlyRestart is unset it will always start the timer anew.
    const startMutingTimer = (onlyRestart = false) => {
        const timeoutSet = stopMutingTimer();
        if (onlyRestart && !timeoutSet)
            return;
        silenceTimeout.current = setTimeout(() => {
            dispatch(setRecording(false));
        }, 100 * 1000);
    };
    const checkEditedWords = () => {
        const boostWordsLowercase = boostWords.map((w) => w.toLowerCase());
        const stripPunctuation = (value) => {
            return value ? value.replace(/[_.,!?]/g, '').trim() : '';
        };
        const words = editedWords
            .map((word) => (Object.assign(Object.assign({}, word), { text: stripPunctuation(word.text) })))
            .filter((w) => !!w.text &&
            transcripts[w.transcriptionId] &&
            getText(transcripts[w.transcriptionId], lang).includes(w.text) &&
            !boostWordsLowercase.includes(w.text.toLowerCase()))
            .map((w) => w.text);
        return words.length ? uniqBy(words, (w) => w.toLowerCase()) : null;
    };
    if (window.isElectron && electronCaptionMode) {
        return (React.createElement(CCPage, { onEndConversation: handleOnEndConversationClick, roomEndingWarningCountdown: roomEndingWarningCountdown, roomEndingWarningBegin: roomEndingWarningBegin, roomEndingWarningProlongUrl: roomEndingWarningProlongUrl, appSubmittedTypeform: appSubmittedTypeform }));
    }
    if (loading) {
        return React.createElement(Loading, null, t('joiningConversation'));
    }
    if (redirectLoading) {
        return React.createElement(Loading, null);
    }
    if (quickSetupShown) {
        return React.createElement(SetupAudio, { t: t, microphoneAccess: microphoneAccess, needInternalAudioAccess: needInternalAudioAccess });
    }
    return (React.createElement("div", { style: { height: '100%' } },
        React.createElement("audio", { id: "player", controls: true, muted: true, style: { display: 'none' } }),
        !window.isElectron && zoomExportPopup === 'display' && (React.createElement(ZoomBroadcastCaptionsPopup, { on: zoomExportPopup === 'display', setOff: () => {
                setZoomExportPopup(status.id);
            } })),
        React.createElement("div", { className: `conversation-container ${classes.container}`, onMouseEnter: () => setConversationContainerHovered(true), onMouseLeave: () => setConversationContainerHovered(false), style: {
                overflowX: 'hidden',
                position: 'relative',
                // If we are in caption mode, we want Electron to manage the
                // background, since we want it to be transparent.
                backgroundColor: theme.palette.background.default,
                backgroundImage: null,
                height: `100%`,
                fontFamily: theme.typography.fontFamily,
                width: '100%',
            } },
            askingUserName && (React.createElement(EditProfileModal, { open: askingUserName, close: (e) => handleEditProfilModalClose(e), title: t('profilModal.enterYourName'), subtitle: `${t('profilModal.weNeedToAnnouceYouTo')} &${avaNameRequested || ''}`, defaultUserName: "", action: t(`profilModal.continue`) })),
            React.createElement(BoostModal, { fullScreen: fullScreen, open: isBoosting, boostList: (status === null || status === void 0 ? void 0 : status.boost) || [] }),
            showKeyboardButton && (React.createElement(React.Fragment, null,
                React.createElement(KeyboardInput, { value: newTranscriptDraft, onChange: onNewTranscriptChange, ttsBestVoice: ttsBestVoice }))),
            React.createElement("span", null, websocketStatus === 'online' && (!muted || (recallAIStatus === 'CAPTIONING' && !tempUser)) && (
            // eslint-disable-next-line
            React.createElement(ScribeKeypressHandlerContainer, null))),
            React.createElement(SnackbarWithCountdown, { message: t('snackbar.convoRestrictedSessionWarning'), countdown: roomEndingWarningCountdown, countdownBegin: roomEndingWarningBegin, additionalActionMessage: t('snackbar.convoRestrictedSessionProlong'), additionalActionUrl: roomEndingWarningProlongUrl, appSubmittedTypeform: appSubmittedTypeform }),
            !loading && !isInConversation && !connectionByUsernameRequested && (React.createElement(Landing, { host: features['web-host'], onHost: onHost, twilio: features.twilio })),
            (!status.participants || isInConversation) && (React.createElement(ConversationView, { conversationContainerHovered: conversationContainerHovered, editedWords: editedWordsState, handleBoostPopup: handleBoostPopup, handleSaveTranscriptPopup: handleSaveTranscriptPopup, showBoostModal: showBoostModal, showSaveTranscriptsModal: showSaveTranscriptsModal })),
            React.createElement(ConversationButtons, { onKeyboardButtonClick: handleTranscriptInputVisibilityChange, onEndConversationClick: handleOnEndConversationClick })),
        !isInConversation && isRateConversationOpen && (React.createElement(RateConversation, { conversationBroadcastedToZoom: conversationBroadcastedToZoom, endedByCurrentUser: endedByCurrentUser }))));
};
const mapStateToProps = createStructuredSelector({
    speakers: selectSpeakersMap,
    transcripts: selectTranscripts,
    lang: selectLang,
});
const mapDispatchToProps = {
    setRateConversationOpen,
    setSelectedTranscriptId,
    createNewConversation,
    addNewTranscript,
    setHostName,
};
export default withTranslation()(withTheme(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ScribeConversation))));
