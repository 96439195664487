export const attemptSpeakWithVoice = ({ message, recording, startRecord, stopRecord, voice, }) => {
    if (!window.speechSynthesis || !voice)
        return;
    const utterThis = new SpeechSynthesisUtterance(message);
    // 1 is the default, ranges from 0.1 to 10. 1 sounds a bit too fast.
    utterThis.rate = 0.7;
    utterThis.voice = voice;
    if (recording) {
        stopRecord();
        utterThis.onend = startRecord;
        utterThis.onerror = startRecord;
    }
    window.speechSynthesis.speak(utterThis);
};
