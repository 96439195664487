import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        width: 48,
        height: 25,
        padding: 0,
        borderRadius: 100,
        display: 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 20,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            padding: 2,
            '&.Mui-checked': {
                transform: 'translateX(24px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.ava.blue2,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 20,
            height: 20,
            borderRadius: 100,
            transition: theme.transitions.create(['width'], {
                duration: 150,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    },
}));
const AntSwitch = (props) => {
    const classes = useStyles();
    return React.createElement(Switch, Object.assign({ classes: { root: classes.root } }, props));
};
export default AntSwitch;
