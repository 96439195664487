// On Windows we just add a new mic with id "internal"
export const INTERNAL_AUDIO_MIC_ID = 'internal';
export const ONLY_INTERNAL_AUDIO_MIC_ID = 'only_internal';
// On mac we have a custom driver called Ava Computer Audio
export const isInternalAudio = (mic) => {
    if (mic) {
        const { label, value, deviceId } = mic;
        return ((label && label.startsWith('Ava Computer Audio')) ||
            value === INTERNAL_AUDIO_MIC_ID ||
            deviceId === INTERNAL_AUDIO_MIC_ID);
    }
    return false;
};
// On mac we have a custom driver called Ava Computer Audio
export const isOnlyInternalAudio = (mic) => {
    if (mic) {
        const { label, value, deviceId } = mic;
        return ((label && label.startsWith('Internal Audio')) ||
            value === ONLY_INTERNAL_AUDIO_MIC_ID ||
            deviceId === ONLY_INTERNAL_AUDIO_MIC_ID);
    }
    return false;
};
