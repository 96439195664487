import * as React from 'react';
function SvgComponent(props) {
    return (React.createElement("svg", Object.assign({ width: 24, height: 20, fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 19.636, rx: 4, fill: "#000" }),
        React.createElement("g", { clipPath: "url(#prefix__clip0)", fill: "#50ACED" },
            React.createElement("path", { d: "M6.545 9.056c0-.779-.008-1.477.633-1.477h1.748l2.913-2.123v8.494l-2.913-2.123H7.178c-.641 0-.633-.542-.633-1.657V9.056zM13.862 7.523l-.967 1.041a2.16 2.16 0 010 2.397l.967 1.041c1.163-1.244 1.163-3.123 0-4.479z" }),
            React.createElement("path", { d: "M15.767 5.454l-.939 1.005c1.596 1.86 1.596 4.657 0 6.617l.939 1.004c2.246-2.397 2.252-6.13 0-8.626z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "prefix__clip0" },
                React.createElement("path", { fill: "#fff", transform: "translate(6.545 4.364)", d: "M0 0h10.909v10.909H0z" })))));
}
export default SvgComponent;
