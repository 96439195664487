import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        borderRadius: 999,
        backgroundColor: theme.palette.ava.blue5,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 16,
        paddingRight: 12,
        marginRight: 12,
        marginBottom: 12,
        color: theme.palette.ava.dark1,
    },
    button: {
        backgroundColor: 'transparent',
        border: 0,
        outline: 0,
        height: 24,
        paddingLeft: 4,
        paddingRight: 4,
        marginLeft: 12,
    },
    icon: {
        height: 16,
        width: 16,
    },
    text: {
        fontSize: '1.125rem',
    },
}));
const BoostWord = ({ word, deleteWord }) => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.container },
        React.createElement(Typography, { variant: "body1", className: classes.text }, word),
        React.createElement("button", { className: classes.button, onClick: deleteWord },
            React.createElement(CancelOutlinedIcon, { className: classes.icon }))));
};
export default BoostWord;
