import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
const useStyles = makeStyles({
    button: {
        WebkitAppearance: 'none',
        width: 46,
        height: '100%',
        padding: 0,
        background: 'transparent',
        border: 'none',
        fontSize: 12,
        cursor: 'default',
        '&:focus': {
            outline: 'none',
        },
    },
    light: {
        '&:hover': {
            background: 'rgba(23,23,23,0.1)',
        },
        '&:active': {
            background: 'rgba(23,23,23,0.2)',
        },
        '&>svg>g': {
            fill: '#171717',
        },
    },
    dark: {
        color: '#FFFFFF',
        '&:hover': {
            background: 'rgba(255,255,255,0.1)',
        },
        '&:active': {
            background: 'rgba(255,255,255,0.2)',
        },
    },
    close: {
        '&:hover': {
            background: '#E81123',
            '&>svg>g': {
                fill: '#FFFFFF',
            },
        },
        '&:active': {
            background: '#F1707A',
            '&>svg>g': {
                fill: '#171717',
            },
        },
    },
});
const WindowsTitlebarButton = ({ id, children, close, onClick }) => {
    const classes = useStyles();
    const theme = useTheme();
    return (React.createElement("button", { id: id, type: "button", className: classnames(classes.button, theme.palette.mode === 'light' ? classes.light : classes.dark, {
            [classes.close]: close,
        }), onClick: onClick }, children));
};
export default WindowsTitlebarButton;
