import { makeStyles } from '@mui/styles';
// ts is going to have me over-type everything or red squiggly mess
//@ts-ignore
const useStyles = makeStyles((theme) => ({
    panel: {
        zIndex: 500,
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        borderRadius: 8,
        padding: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: (props) => props.electronCaptionMode && '#000E34',
        minWidth: (props) => props.electronCaptionMode && 'max-content',
    },
    modal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 16px',
        gap: '20px',
        isolation: 'isolate',
        background: '#FFFFFF',
        boxShadow: '0px 4px 8px rgba(112, 144, 176, 0.2)',
        borderRadius: '12px',
    },
    button: {
        fontWeight: 600,
        transition: 'opacity 0.3s ease-in-out',
        flex: 30,
        flexBasis: '30%',
        borderRadius: 8,
        padding: '10px 16px 10px 16px',
    },
    connect: {
        '&:disabled': {
            opacity: 0.5,
            boxShadow: 'none',
            color: 'white',
        },
        '&:hover': {
            cursor: (props) => props.loading && 'wait',
        },
        minWidth: 'fit-content',
        fontSize: 18,
        color: (props) => (props.loading ? theme.palette.ava.grey5 : 'white'),
        backgroundColor: (props) => props.loading && 'transparent !important',
        marginLeft: 20,
        marginRight: (props) => (props.electronCaptionMode ? 0 : 6),
        height: 40,
        boxShadow: (props) => props.loading || props.electronCaptionMode || theme.palette.mode === 'dark' ? 'none' : '4px 2px 12px',
    },
    disconnect: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
        '&:hover': {
            backgroundColor: theme.palette.ava.red2,
        },
        color: 'white',
        backgroundColor: theme.palette.ava.red3,
        margin: '0 25px',
        boxShadow: theme.palette.mode === 'light' ? '4px 2px 12px rgba(112, 144, 176, 0.25)' : 'none',
        borderRadius: 8,
        border: 'none',
    },
    connected: {
        '&:hover': {
            background: 'transparent',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            minWidth: 'max-content',
        },
        background: 'transparent',
        color: theme.palette.ava.scribe2,
        boxShadow: 'none',
        border: 'none',
        gap: '8px',
        marginLeft: 8,
        minWidth: 'fit-content',
    },
    captioning: {
        boxShadow: 'none',
        gap: '8px',
        background: (props) => (theme.palette.mode === 'light' || props.electronCaptionMode ? 'transparent' : 'white'),
        color: (props) => (props.electronCaptionMode ? 'white' : theme.palette.ava.deepBlue),
        borderRadius: 40,
        border: `1px solid ${theme.palette.ava.grey7}`,
        width: (props) => props.expanded && props.recallAIBotConnected && '40%',
        margin: (props) => props.electronCaptionMode && 0,
        '&:hover': {
            background: (props) => !props.electronCaptionMode && theme.palette.ava.navy5,
        },
        '&:disabled': {
            color: (props) => theme.palette.mode === 'dark' || !props.electronCaptionMode ? theme.palette.ava.deepBlue : 'white',
        },
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        [theme.breakpoints.down('sm')]: {
            minWidth: 'max-content',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
            minWidth: 225,
            color: (props) => !props.electronCaptionMode && theme.palette.ava.grey4,
        },
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        borderRadius: 6,
        zIndex: 9999,
        paddingLeft: 8,
        flexBasis: '70%',
        flex: 70,
        minWidth: 265,
        maxWidth: 285,
        height: 40,
        border: (props) => (props.errorMessage ? '1px solid red' : '1px solid #50ACED'),
        fontSize: 16,
        color: theme.palette.mode !== 'light' ? theme.palette.text.dark : theme.palette.text.light,
    },
    inputError: {
        borderColor: 'red',
        outline: 'red',
    },
    inputErrorMessage: {
        '&:disabled': {
            opacity: 1,
            boxShadow: 'none',
            color: 'red',
        },
        background: 'transparent',
        color: 'red !important',
        flexBasis: 'fit-content',
    },
    recallError: {
        '&:disabled': {
            color: 'red',
        },
        '&:hover': {
            background: 'transparent',
        },
        color: 'red',
        background: 'transparent',
        fontSize: '16px',
        boxShadow: 'none',
        flexBasis: 'auto',
        maxWidth: 160,
    },
    spinnerContainer: {
        marginLeft: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    spinner: {
        color: '#ccc',
    },
    text: {
        color: theme.palette.ava.grey3,
    },
}));
export default useStyles;
