var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { emit, listen } from '@tauri-apps/api/event';
import config from '../../../electron/package.json';
import { createNewConversation, startConversationReadClient } from '../../actions/conversation';
import { analytics } from '../../firebase';
import { selectJoinConversationManager } from '../../selectors/conversation';
import { conversations, users as avaUsers } from '../../services/api/ava';
import { getBrowserName, getBrowserVersion, getOS, getOSVersion } from '../../utils';
import { clearSearchValue, getSearchValue, setSearchValue } from '../../utils/http';
import * as segment from '../../utils/segment';
import { getSingletonWebRTCManager, recreateSingletonWebRTCManager } from '../../utils/webrtc';
import { getDefaultRoomId, isDefaultRoomId, respondPingMessage, sendPing, sendWebRTCTrackMetadataMessage, sendWsMessage, } from '../../utils/ws-v1';
import { setAudioParamsSent } from './audioRecorder';
import { createAvaTranslateManager } from './avaTranslate';
import { createBoostManager } from './boost';
import { createJoinConversationManager, setCreateConversationWhenReady } from './conversation';
import { createRecallAIManager } from './recallAI';
import { setLoading } from './uiState';
import { fetchUserProfile } from './userProfile';
const WS_URL_TTL_MS = 25000;
const PING_INTERVAL = 5000;
const PING_TIMEOUT = 3000;
const SOCKET_STATUS_INTERVAL = 1000;
const RECONNECTOR_CHECK_INTERVAL = 1000;
const RECONNECTOR_COOLDOWN_MS = 5000;
const INITIAL_STATE = {
    conferenceCallRequested: false,
    v1Socket: undefined,
    v1WebsocketStatus: 'uninitialized',
    v1URLTimestamp: 0,
    v1WebSocketURL: undefined,
    v1ReconnectionURL: undefined,
    v1Token: getSearchValue(window, 'token', undefined),
    backends: {
        production: {
            name: 'production',
            url: 'https://backend.ava.me',
            color: 'seagreen',
        },
        stage: {
            name: 'stage',
            url: 'https://api-stage.ava.me',
            color: 'steelblue',
        },
        local: {
            name: 'local',
            url: 'http://localhost:3000',
            color: 'saddlebrown',
        },
    },
    displayDev: window.location.hostname === 'localhost',
};
const v1SessionSlice = createSlice({
    name: 'v1SessionSlice',
    initialState: INITIAL_STATE,
    reducers: {
        closeV1Connection(state) {
            // Maybe set websocket status to 'closed'?
            Object.assign(state, INITIAL_STATE);
        },
        setConferenceCallRequested(state, { payload }) {
            state.conferenceCallRequested = payload;
        },
        setV1WebsocketStatus(state, { payload }) {
            state.v1WebsocketStatus = payload;
        },
        setV1WebsocketURL(state, { payload }) {
            state.v1WebSocketURL = payload;
            state.v1URLTimestamp = Date.now();
        },
        setV1ReconnectionURL(state, { payload }) {
            state.v1ReconnectionURL = payload;
        },
        setV1Socket(state, { payload }) {
            state.v1Socket = payload;
        },
        setV1Token(state, { payload }) {
            if (payload) {
                setSearchValue(window, 'token', payload);
            }
            else {
                clearSearchValue(window, 'token');
            }
            state.v1Token = payload;
        },
        enableDevMode(state) {
            state.displayDev = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAsyncBackends.fulfilled, (state, action) => {
            Object.assign(state.backends, Object.fromEntries(action.payload.map((backend) => [
                backend.name,
                Object.assign(Object.assign({}, backend), { color: `#${backend.color}` }),
            ])));
        })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
            var _a, _b;
            state.displayDev = state.displayDev || ((_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.features['dev-mode']);
        });
    },
});
export const { setV1ReconnectionURL, closeV1Connection, setConferenceCallRequested, setV1WebsocketStatus, setV1WebsocketURL, enableDevMode, setV1Token, } = v1SessionSlice.actions;
const { setV1Socket } = v1SessionSlice.actions;
export const v1SessionReducer = v1SessionSlice.reducer;
const createNewSocket = (url) => new Promise((resolve, reject) => {
    const ws = new WebSocket(url);
    ws.addEventListener('open', () => {
        resolve(ws);
    }, { once: true });
    ws.addEventListener('error', (error) => {
        reject(new Error('Websocket error', { cause: error }));
    }, { once: true });
    ws.addEventListener('close', () => {
        reject(new Error('Websocket error', { cause: 'socket closed' }));
    }, { once: true });
});
export const fetchAsyncBackends = createAsyncThunk('v1Session/fetchAsyncBackends', () => __awaiter(void 0, void 0, void 0, function* () {
    const { endpoints } = yield (yield fetch('https://5ebpkaja6a.execute-api.us-west-2.amazonaws.com/default/get-endpoints')).json();
    return endpoints;
}));
export const createV1Socket = createAsyncThunk('v1Session/createV1Socket', (_, { getState, dispatch }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    prepareReconnector(getState, dispatch);
    const state = getState();
    const uid = (_a = state.auth.firebaseUser) === null || _a === void 0 ? void 0 : _a.uid;
    const avaId = (_b = state.userProfile.parse) === null || _b === void 0 ? void 0 : _b.avaId;
    const v1Token = state.v1Session.v1Token;
    const oldWs = state.v1Session.v1Socket;
    if (oldWs && oldWs.readyState === WebSocket.OPEN) {
        oldWs.close();
    }
    const scribing = state.scribe.scribing;
    const status = state.scribeConversation.status;
    // Migrated code used this, but now that we have types, we know this is never defined?
    // const branch = state.scribeSession.branch;
    if (!uid || !avaId) {
        throw new Error('User not logged in');
    }
    const userName = (_c = state.userProfile.parse) === null || _c === void 0 ? void 0 : _c.userName;
    let url = state.v1Session.v1ReconnectionURL;
    let ws;
    try {
        if (url) {
            dispatch(setV1WebsocketStatus('reconnecting'));
        }
        else {
            dispatch(setV1WebsocketStatus('pending'));
            if (Date.now() - state.v1Session.v1URLTimestamp < WS_URL_TTL_MS) {
                url = state.v1Session.v1WebSocketURL;
            }
        }
        if (!url) {
            ({ wsUrl: url } = (yield dispatch(fetchUserProfile()).unwrap()) || {});
        }
        if (!url) {
            throw new Error('Failed to get wsUrl');
        }
        // Consuming the Websocket URL after it has been used. They are one-time
        // use only.
        dispatch(setV1WebsocketURL(''));
        dispatch(setV1ReconnectionURL(''));
        ws = yield createNewSocket(url);
        dispatch(setV1Socket(ws));
        dispatch(setAudioParamsSent(false));
        yield prepareV1Socket(ws, uid, scribing, dispatch);
    }
    catch (e) {
        Sentry.captureException(e, {
            tags: {
                category: 'v1Socket',
            },
        });
        dispatch(setV1WebsocketStatus('disconnected'));
        return undefined;
    }
    startConversationReadClient(ws, dispatch, getState);
    const roomId = yield getRoomIdFromTokenOrStatus(v1Token, status);
    yield sendConnectionParams(ws, avaId, roomId || getDefaultRoomId(uid), scribing, userName || '');
    const newState = getState();
    const joinConversationManager = selectJoinConversationManager(newState);
    if (joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.shouldJoinOnInit()) {
        console.log('joining conversation on init');
        yield (joinConversationManager === null || joinConversationManager === void 0 ? void 0 : joinConversationManager.joinConversationOnInit());
    }
    else if (newState.conversation.createConversationWhenReady) {
        console.log('creating new conversation');
        yield createNewConversation()(dispatch, getState);
    }
    else if (!roomId) {
        dispatch(setLoading(false));
    }
    dispatch(setV1WebsocketStatus('online'));
    yield dispatch(setCreateConversationWhenReady(false));
    return ws;
}));
let lastReconnectAttempt = 0;
const prepareReconnector = (getState, dispatch) => {
    const reconnectInterval = setInterval(() => {
        const state = getState();
        const status = state.v1Session.v1WebsocketStatus;
        if (status === 'disconnected' || status === 'closed' || status === 'offline') {
            if (lastReconnectAttempt === 0 || Date.now() - lastReconnectAttempt > RECONNECTOR_COOLDOWN_MS) {
                lastReconnectAttempt = Date.now();
                clearInterval(reconnectInterval);
                dispatch(createV1Socket());
            }
            else {
                console.log('Reconnect attempt too soon');
            }
        }
    }, RECONNECTOR_CHECK_INTERVAL);
};
const prepareV1Socket = (ws, uid, scribe, dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    ws.addEventListener('error', () => {
        Sentry.captureMessage('Websocket error', {
            tags: {
                category: 'v1Socket',
            },
        });
    });
    ws.addEventListener('close', () => {
        Sentry.captureMessage('Websocket close', {
            tags: {
                category: 'v1Socket',
            },
        });
    });
    const wsCloseInterval = setInterval(() => {
        // Listening to 'close' event is not enough, because it is not fired
        // until the closing handshake comes back from the other side, which will
        // not happen if the connection is lost.
        if (ws.readyState === ws.CLOSED || ws.readyState === ws.CLOSING) {
            dispatch(setV1WebsocketStatus('disconnected'));
            dispatch(setV1Token(undefined));
            clearInterval(wsCloseInterval);
        }
    }, SOCKET_STATUS_INTERVAL);
    window.onunload = () => {
        ws.send(JSON.stringify({
            type: 'connection-params-update',
            roomId: getDefaultRoomId(uid),
        }));
    };
    // Awaiting connection-succeeded message
    yield new Promise((resolve, reject) => {
        ws.addEventListener('message', (message) => {
            const data = JSON.parse(message.data);
            if (data.type !== 'connection-succeeded') {
                Sentry.captureMessage('invalid V1 first message, expected connection-succeeeded', {
                    extra: {
                        message: data,
                    },
                    tags: {
                        category: 'v1Socket',
                    },
                });
                reject(new Error('invalid V1 first message, expected connection-succeeeded'));
            }
            dispatch(setV1ReconnectionURL(data.reconnectionWsUrl));
            dispatch({
                type: 'CONNECTION_SUCCEEDED',
                status: data.hash,
            });
            if (window.electronIPC) {
                window.electronIPC.sendDoneLoading();
            }
            resolve(true);
        }, { once: true });
    });
    window.__TAURI__ ? prepareWebRTCForTauri(ws) : prepareWebRTC(ws, dispatch);
    dispatch(createRecallAIManager({ ws, dispatch }));
    dispatch(createJoinConversationManager());
    dispatch(createBoostManager());
    dispatch(createAvaTranslateManager());
    if (scribe)
        prepareScribe(ws);
    preparePing(ws, dispatch);
    if (window.electronIPC) {
        window.electronIPC.sendSetIsLoggedIn(!!uid);
    }
});
const prepareScribe = (ws) => {
    ws.addEventListener('message', (message) => {
        var _a, _b;
        const data = JSON.parse(message.data);
        if (data.type === 'room-status' && data.id && !isDefaultRoomId(data.id)) {
            sendWsMessage(ws, {
                type: 'connection-params-update',
                roomId: data.id,
                downAudio: {
                    speakerId: (_a = data.audioStreams[0]) === null || _a === void 0 ? void 0 : _a.avaId,
                    trackId: (_b = data.audioStreams[0]) === null || _b === void 0 ? void 0 : _b.trackId,
                },
            });
        }
    });
};
const prepareWebRTCForTauri = (ws) => {
    ws.addEventListener('message', (message) => {
        const data = JSON.parse(message.data);
        if (data.type === 'webrtc') {
            emit('webrtc-w2t', data);
        }
    });
    emit('webrtc-new-ws', {});
    listen('webrtc-t2w', (event) => {
        var _a, _b, _c;
        sendWsMessage(ws, {
            type: 'webrtc',
            offer: ((_a = event.payload) === null || _a === void 0 ? void 0 : _a.offer) || undefined,
            answer: ((_b = event.payload) === null || _b === void 0 ? void 0 : _b.answer) || undefined,
            iceCandidate: ((_c = event.payload) === null || _c === void 0 ? void 0 : _c.iceCandidate) || undefined,
        });
    });
    listen('webrtc-metadata', (event) => {
        sendWebRTCTrackMetadataMessage(ws, {
            streamId: event.payload.streamId,
            name: event.payload.name,
            isInternal: event.payload.isInternal,
        });
    });
};
const prepareWebRTC = (ws, dispatch) => {
    var _a;
    // Lifetime of the RTC connection is tied to the WS lifetime. It will be freed
    // when the socket reconnects, or when the whole app closes. We do not close
    // the webRTC connection when the socket closes, so that the audio has chance to
    // flow even when the socket is unstable.
    recreateSingletonWebRTCManager(dispatch);
    ws.addEventListener('message', (message) => {
        var _a;
        const data = JSON.parse(message.data);
        if (data.type === 'webrtc') {
            // WebRTCManager can handle messages even before it is initialized.
            (_a = getSingletonWebRTCManager()) === null || _a === void 0 ? void 0 : _a.handleV1Message(data);
        }
    });
    (_a = getSingletonWebRTCManager()) === null || _a === void 0 ? void 0 : _a.initialize((message) => {
        sendWsMessage(ws, Object.assign({ type: 'webrtc' }, message));
    });
};
const preparePing = (ws, dispatch) => {
    const ping = setInterval(() => {
        sendPing(ws);
        // Closing the WebSocket after 3 seconds of no response.
        const wsCloseTimeout = setTimeout(() => {
            Sentry.captureMessage('Websocket ping timeout', {
                tags: {
                    category: 'v1Socket',
                },
            });
            ws.close();
            clearPing();
        }, PING_TIMEOUT);
        ws.addEventListener('message', (message) => {
            const data = JSON.parse(message.data);
            if (data.type === 'pong') {
                clearTimeout(wsCloseTimeout);
            }
        });
    }, PING_INTERVAL);
    ws.addEventListener('message', (message) => {
        const data = JSON.parse(message.data);
        if (data.type === 'PING') {
            respondPingMessage(ws, data);
        }
    });
    const clearPing = () => {
        clearInterval(ping);
    };
    window.onunload = clearPing;
    ws.addEventListener('close', clearPing);
};
const getRoomIdFromTokenOrStatus = (token, status) => __awaiter(void 0, void 0, void 0, function* () {
    let roomId = status ? status.id : undefined;
    if (token && !roomId) {
        if (sessionStorage.getItem(token)) {
            roomId = sessionStorage.getItem(token);
        }
        else {
            try {
                const res = yield conversations.getRoomId({ token });
                roomId = res.data.roomId;
                sessionStorage.setItem(token, roomId);
            }
            catch (err) {
                Sentry.captureException(err);
            }
        }
    }
    return roomId;
});
const sendConnectionParams = (ws, avaId, roomId, scribe, userName) => {
    const type = !window.isElectron ? 'web' : 'desktop';
    const appVersion = config.version;
    const system = getOS();
    const operationSystem = getOSVersion() || 'Unknown';
    const browserName = getBrowserName();
    const browserVersion = getBrowserVersion() || 'Unknown';
    const client = {
        type,
        appVersion,
        system,
        os: operationSystem,
        device: 'Unknown',
        browserName,
        browserVersion,
    };
    return ws.send(JSON.stringify({
        type: 'connection-params',
        speakerId: avaId,
        roomId: roomId,
        username: userName,
        client,
        downAudio: scribe
            ? {
                host: true,
            }
            : undefined,
        conversationMode: 'public',
    }));
};
const getRoomIdFromBranch = (branch, avaId, uid) => __awaiter(void 0, void 0, void 0, function* () {
    // TODO: I think this is never triggered, but maybe it is?
    if (branch && (branch.roomId || branch.convoChannel)) {
        // This is an invite, let's track it
        analytics.logEvent('app_downloaded', {
            referrer_ava_id: branch.avaName,
            invite_channel: branch['~channel'],
            invite_campaign: branch['~campaign'],
        });
        segment.track('Invites Triggered', {
            'Invite Link': branch['~referring_link'],
            'Inviter Ava Name': branch.avaName,
        });
        segment.identify({
            'Invite Channel': branch['~channel'],
            'Invite Campaign': branch['~campaign'],
        });
        const metricsToUpdate = {
            'Total Invites Triggered Count': 1,
        };
        yield avaUsers.updateInviteMetrics({
            avaId,
            firebaseAuthUID: uid,
            inviteMetrics: metricsToUpdate,
        });
        return branch.roomId || branch.convoChannel;
    }
    return undefined;
});
