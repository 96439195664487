import { TextField, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RoundedButton from '../../components/Buttons/RoundedButton';
import AppleLogo from '../../components/Icons/AppleLogo';
import FacebookLogo from '../../components/Icons/FacebookLogo';
import GoogleLogo from '../../components/Icons/GoogleLogo';
import PhoneRinging from '../../components/Icons/PhoneRinging';
import SSOLogo from '../../components/Icons/SSOLogo';
import WorkEmailIcon from '../../components/Icons/WorkEmail';
import PhraseMarkdownText from '../../components/PhraseMarkdownText';
import { selectConfigEnableSSO } from '../../selectors/auth';
import { selectFirebaseLoginInProgress } from '../../selectors/combined';
import { selectDisplayDev } from '../../selectors/v1Session';
import { initiateFirebaseLoginWithToken } from '../../store/slices/auth';
import { useAppSelector } from '../../store/store';
import { getOS, SNCF } from '../../utils';
import { isMobileiOS, isSafari } from '../../utils';
const useStyles = makeStyles((theme) => ({
    image: {
        marginTop: -40,
        height: 'calc(30vw - 10px)',
        maxHeight: 540,
        minHeight: 380,
        width: '100vw',
        background: theme.palette.mode === 'dark' ? `url(/work_dark.svg)` : `url(/work.svg)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    title: {
        marginBottom: 14,
        color: theme.palette.ava.blue1,
        fontSize: 40,
        fontWeight: 400,
        lineHeight: '52px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 34,
        },
    },
    buttons: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1200,
        marginBottom: 30,
        '& > :not(:last-child)': {
            marginBottom: 12,
        },
        '&.Mui-disabled': {
            cursor: 'not-allowed !important',
        },
    },
    text: {
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        fontSize: 18,
        lineHeight: '23.4px',
    },
    button: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: 344,
        height: 56,
        paddingTop: 0,
        paddingRight: 16,
        paddingBottom: 0,
        paddingLeft: 20,
        [theme.breakpoints.down('lg')]: {
            maxWidth: 320,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 344,
        },
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        maxWidth: 344,
        '&:hover, &:focus': {
            textDecoration: 'none',
            cursor: (props) => (props.safari || props.MobileiOS) && 'not-allowed',
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: 320,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 344,
        },
    },
    buttonText: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginLeft: -24,
        fontSize: 18,
        lineHeight: '23.4px',
        [theme.breakpoints.between('md', 'lg')]: {
            margin: 0,
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: -16,
        },
    },
    apple: {
        [theme.breakpoints.between('md', 'lg')]: {
            margin: -24,
        },
    },
    textWhite: {
        color: theme.palette.ava.white,
    },
    textBlue: {
        color: theme.palette.ava.blue2,
    },
    other: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            '& > :not(:last-child)': {
                marginBottom: 24,
            },
        },
    },
    secondary: {
        [theme.breakpoints.up('md')]: {
            margin: '0 10px',
        },
        '&:hover': {
            color: theme.palette.ava.dark1,
        },
    },
    icon: {
        background: 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    loading: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    customTokenInput: {
        width: '75%',
        '& textarea': { fontFamily: 'monospace', color: theme.palette.ava.grey },
    },
    loginTokenButton: {
        color: theme.palette.ava.blue2,
        background: 'transparent',
        border: 'none',
        outline: 'none',
    },
}));
const Login = ({ loginWithApple, loginWithGoogle, loginWithFacebook, productPath }) => {
    const theme = useTheme();
    const displayDev = useSelector(selectDisplayDev);
    const [emailButtonHovered, setEmailButtonHovered] = useState(false);
    const [customTokenInputShown, setCustomTokenInputShown] = useState(false);
    const [customToken, setCustomToken] = useState('');
    const [MobileiOS, setMobileiOS] = useState(false);
    const [safari, setIsSafari] = useState(false);
    const classes = useStyles({ safari, MobileiOS });
    const dispatch = useDispatch();
    const loading = useAppSelector(selectFirebaseLoginInProgress);
    const enableSSO = useAppSelector(selectConfigEnableSSO);
    const { t } = useTranslation();
    useEffect(() => {
        setMobileiOS(isMobileiOS());
        setIsSafari(isSafari());
    }, []);
    return (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            overflow: 'hidden',
        } },
        React.createElement("div", { className: classes.image }),
        React.createElement(Typography, { variant: "h4", align: "center", className: classes.title }, window.isElectron ? (React.createElement(PhraseMarkdownText, { phraseKey: "landing.welcomeToAva" })) : (React.createElement(PhraseMarkdownText, { phraseKey: "signInSignUp" }))),
        React.createElement("div", { className: classes.buttons }, window.isElectron ? (React.createElement(React.Fragment, null,
            !customTokenInputShown && (React.createElement(RoundedButton, { className: classnames(classes.button, classes.text, classes.textWhite), style: { justifyContent: 'center' }, color: "blue", onClick: () => {
                    if (window.desktopIntegration) {
                        const url = new URL(window.location.href);
                        url.searchParams.append('auth', 'desktop');
                        if (window.__TAURI__) {
                            url.searchParams.append('tauri', 'true');
                        }
                        window.desktopIntegration.openExternalURL(url.href);
                    }
                } },
                React.createElement(PhraseMarkdownText, { phraseKey: "desktopLogin.signInWithBrowser" }))),
            ((window.isElectron && getOS() === 'Linux') || displayDev) && (React.createElement(React.Fragment, null,
                !customTokenInputShown && (React.createElement("button", { className: classes.loginTokenButton, onClick: () => setCustomTokenInputShown(true) }, "I have login token")),
                customTokenInputShown && (React.createElement(React.Fragment, null,
                    React.createElement(TextField, { variant: "outlined", className: classes.customTokenInput, multiline: true, placeholder: "Paste your login token here", onChange: (event) => setCustomToken(event.target.value) }),
                    React.createElement(RoundedButton, { className: classnames(classes.button, classes.text, classes.textWhite), style: { justifyContent: 'center' }, color: "blue", disabled: customToken === '', onClick: () => initiateFirebaseLoginWithToken(dispatch, customToken) }, "Log in with token"))))))) : (React.createElement(React.Fragment, null,
            loading && React.createElement(CircularProgress, { className: classes.loading, color: "secondary", size: 40 }),
            !SNCF && (React.createElement(RoundedButton, { className: classnames(classes.button, classes.text, classes.textWhite), color: "blue", onClick: loginWithGoogle, disabled: loading, "data-qa": "googleLoginButton" },
                React.createElement("div", { className: classes.icon },
                    React.createElement(GoogleLogo, null)),
                React.createElement(PhraseMarkdownText, { className: classes.buttonText, phraseKey: "signInWithGoogle" }))),
            React.createElement(Link, { className: classes.link, to: loading ? {} : `/${productPath}/login/email${window.location.search}`, disabled: loading, "data-qa": "emailLoginButton" },
                React.createElement(RoundedButton, { className: classnames(classes.button, classes.text, classes.textBlue), onMouseEnter: () => setEmailButtonHovered(true), onMouseLeave: () => setEmailButtonHovered(false), disabled: loading },
                    React.createElement("div", { className: classes.icon },
                        React.createElement(WorkEmailIcon, { color: emailButtonHovered || theme.palette.mode === 'dark' ? 'white' : 'black' })),
                    React.createElement(PhraseMarkdownText, { className: classes.buttonText, phraseKey: "signInWithEmail" }))),
            !SNCF && (React.createElement(React.Fragment, null,
                React.createElement(PhraseMarkdownText, { className: classes.text, phraseKey: "landing.or" }),
                React.createElement("div", { className: classes.other },
                    React.createElement(RoundedButton, { className: classnames(classes.button, classes.text, classes.secondary), onClick: loginWithFacebook, disabled: loading, "aria-label": "signup", "data-qa": "facebookLoginButton" },
                        React.createElement("div", { className: classes.icon },
                            React.createElement(FacebookLogo, null)),
                        React.createElement(PhraseMarkdownText, { className: classes.buttonText, phraseKey: "signInWithFacebook" })),
                    React.createElement(RoundedButton, { className: classnames(classes.button, classes.text, classes.secondary), onClick: loginWithApple, disabled: loading, "data-qa": "appleLoginButton" },
                        React.createElement("div", { className: classes.icon },
                            React.createElement(AppleLogo, { color: theme.palette.mode === 'light' ? 'black' : 'white' })),
                        React.createElement(PhraseMarkdownText, { className: classnames(classes.buttonText, classes.apple), phraseKey: "signInWithApple" })),
                    React.createElement(Link, { className: classes.link, to: loading ? {} : `/${productPath}/login/phone${window.location.search}`, disabled: loading, "data-qa": "phoneLoginButton" },
                        React.createElement(RoundedButton, { className: classnames(classes.button, classes.text, classes.secondary), disabled: loading },
                            React.createElement("div", { className: classes.icon },
                                React.createElement(PhoneRinging, null)),
                            React.createElement(PhraseMarkdownText, { className: classes.buttonText, phraseKey: "signInWithPhone" }))),
                    enableSSO && (React.createElement(Tooltip, { title: (safari || MobileiOS) && t('SSOToolTip'), disableInteractive: true, enterDelay: 300, leaveDelay: 200 },
                        React.createElement(Link, { className: classes.link, to: loading || safari || MobileiOS ? {} : `/${productPath}/login/sso${window.location.search}`, disabled: loading || safari || MobileiOS, "data-qa": "ssoLoginButton" },
                            React.createElement(RoundedButton, { className: classnames(classes.button, classes.text, classes.secondary), disabled: loading || safari || MobileiOS },
                                React.createElement("div", { className: classes.icon, style: { alignSelf: 'center' } },
                                    React.createElement(SSOLogo, null)),
                                React.createElement(PhraseMarkdownText, { className: classes.buttonText, phraseKey: "continueWithSSO" })))))))))))));
};
export default Login;
