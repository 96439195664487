import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    buttons: {
        display: 'flex',
        height: 32,
        background: theme.palette.ccMode.backgroundGradient,
        boxShadow: '0px 0.561661px 3.36997px rgba(112, 144, 176, 0.25)',
        borderRadius: 8,
        overflow: 'hidden',
        visibility: 'hidden',
        '& > *': {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 0,
        },
    },
    buttonsVisible: {
        visibility: 'visible',
    },
}));
export default useStyles;
