var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Button, CardActions, CircularProgress, Fab, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, TextField, Typography, } from '@mui/material';
import Card from '@mui/material/Card';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import addOrg from '../../../assets/icons/add_org.svg';
import eduOrg from '../../../assets/icons/edu.svg';
import eventOrg from '../../../assets/icons/event.svg';
import hrOrg from '../../../assets/icons/hr.svg';
import DeafEmoji from '../../../assets/images/deafEmoji.png';
import EarEmoji from '../../../assets/images/earEmoji.png';
import Event from '../../../assets/images/event.svg';
import HohEmoji from '../../../assets/images/hohEmoji.png';
import School from '../../../assets/images/school.svg';
import SomeoneSpeaking from '../../../assets/images/someoneSpeaking.png';
import LoginBackground from '../../../assets/images/swipeBackground.svg';
import Video from '../../../assets/images/video.svg';
import Work from '../../../assets/images/work-laptop.svg';
import { createNewConversation } from '../../actions';
import Loading from '../../components/Loading';
import OpenOrDownloadAvaAppComponent from '../../components/OpenOrDownloadAvaAppComponent';
import OrganizationsSearchResults from '../../components/OrganizationSearchResults';
import PhraseMarkdownText from '../../components/PhraseMarkdownText';
import { analytics } from '../../firebase';
import { selectFirebaseUser } from '../../selectors/auth';
import * as avaApi from '../../services/api/ava';
import * as saasApi from '../../services/api/saas';
import { triggerOrgTrial } from '../../services/api/subscription';
import LocalStorage, { STORAGE_KEYS } from '../../services/localStorage';
import { updateHearingProfile, updateUserName } from '../../store/slices/userProfile';
import { useAppSelector } from '../../store/store';
import { getOS } from '../../utils';
import * as segment from '../../utils/segment';
const useStyles = makeStyles((theme) => ({
    outerWrap: {
        margin: '0 auto',
        maxWidth: 600,
        width: '100%',
    },
    title: {
        fontWeight: 700,
    },
    orgTypeOptionsWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: theme.spacing(4),
        width: '100%',
    },
    orgTypeButton: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            textDecoration: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        [theme.breakpoints.down('md')]: {
            '& h6': {
                fontSize: '1rem',
            },
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            marginBottom: theme.spacing(2),
            width: `calc(50% - ${theme.spacing(1)})`,
        },
        background: 'transparent',
        border: `2px solid ${theme.palette.border}`,
        borderRadius: 12,
        flexGrow: 0,
        marginBottom: theme.spacing(4),
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
        WebkitAppearance: 'none',
        width: `calc(50% - ${theme.spacing(2)})`,
    },
    orgTypeIllustration: {
        width: '100%',
    },
    orgInfoOptionsWrap: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '0 auto',
        marginTop: theme.spacing(2),
        maxWidth: 420,
        width: '100%',
    },
    formControl: {
        marginBottom: theme.spacing(2),
    },
    formLabel: {
        fontSize: '1em',
        marginBottom: 8,
        zIndex: 1,
    },
    helperText: {
        marginLeft: 14,
    },
    checkboxHelperText: {
        lineHeight: 3,
    },
    orgTypeLabel: {
        textTransform: 'capitalize',
    },
    noDecorationLink: {
        '&:hover': {
            textDecoration: 'none',
        },
    },
    button: {
        boxShadow: 'none',
        fontWeight: 700,
        marginTop: theme.spacing(2),
    },
    centerText: {
        textAlign: 'center',
    },
    fab: {
        backgroundColor: 'white',
        textTransform: 'none',
        fontSize: 18,
        color: '#102867',
        fontWeight: 400,
        height: 94,
        textAlign: 'left',
        display: 'flex',
        marginBottom: theme.spacing(4),
    },
    fabBold: {
        fontWeight: 800,
    },
    fabContainer: {
        display: 'flex',
        maxWidth: 270,
        margin: 'auto',
        flexDirection: 'column',
        paddingTop: theme.spacing(4),
    },
    fabContainerVoicingProfile: {
        display: 'flex',
        maxWidth: 300,
        margin: 'auto',
    },
    fabMargin: {
        marginRight: 17,
        marginLeft: 17,
        height: 55,
    },
    orgaSearchProgress: {
        position: 'absolute',
        width: 20,
        height: 20,
        right: 17,
        top: 17,
        color: theme.palette.ava.blue,
    },
    searchFieldWithPadding: {
        paddingLeft: 30,
    },
    searchResultTypePicto: {
        position: 'absolute',
        left: 9,
        top: 18,
        width: 25,
    },
    loginImageContainer: {
        width: '100vw',
        marginTop: 40,
        [theme.breakpoints.only('xs')]: {
            height: 250,
            paddingTop: 60,
        },
        [theme.breakpoints.only('sm')]: {
            height: 300,
            paddingTop: 70,
        },
        [theme.breakpoints.up('md')]: {
            height: 400,
            paddingTop: 100,
        },
        bottom: 70,
        backgroundImage: `url(${LoginBackground})`,
        backgroundSize: '109vw 130%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    illustration: {
        height: '100%',
    },
    blueTitle: {
        color: '#0679B4',
        fontWeight: 'normal',
        marginTop: -13,
    },
    blueTitleSmall: {
        color: '#0679B4',
        fontWeight: 'normal',
        fontSize: 25,
    },
    maybeLater: {
        color: '#828282',
        textDecoration: 'underline',
        border: 'none',
        backgroundColor: 'transparent',
        outline: 'none',
    },
    bigButton: {
        padding: '12px 35px',
        borderRadius: 27,
        marginBottom: 10,
        fontWeight: 'bold',
    },
}));
const Wrapper = () => {
    const classes = useStyles();
    return (React.createElement("div", { className: classes.outerWrap },
        React.createElement(Outlet, null)));
};
const ScribeLoginProfiling = () => {
    const classes = useStyles();
    const { t, i18n: { language }, } = useTranslation();
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const dispatch = useDispatch();
    const currentOrganizationSearch = useRef(null);
    const [userName, setUserName] = useState('');
    const [orgType, setOrgType] = useState('hr');
    const [orgName, setOrgName] = useState(undefined);
    const [isAdmin, setIsAdmin] = useState('');
    const [userNameRequiredError, setUserNameRequiredError] = useState(false);
    const [userNameResError, setUserNameResError] = useState(false);
    const [isAdminRequiredError, setIsAdminRequiredError] = useState(false);
    const [orgNameRequiredError, setOrgNameRequiredError] = useState(false);
    const [orgId, setOrgId] = useState(undefined);
    const [inputOrgName, setInputOrgName] = useState('');
    const [loading, setLoading] = useState(false);
    const [hearingProfile, setHearingProfile] = useState(undefined);
    const [organizationsSearchResults, setOrganizationsSearchResults] = useState(null);
    const [currentlySearchingOrganizations, setCurrentlySearchingOrganizations] = useState(false);
    const [openOrDownloadAvaApp, setOpenOrDownloadAvaApp] = useState(false);
    const [orgInputFocused, setOrgInputFocused] = useState(false);
    const [resError, setResError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const setUserNameFun = (e) => {
        setUserName(e.target.value);
        setUserNameRequiredError(false);
    };
    const disabledUsername = () => {
        return !userName;
    };
    const submitUserName = (e) => {
        e.preventDefault();
        if (!userName) {
            setUserNameRequiredError(true);
            return;
        }
        // We are issuing the request, but in case it fails, we do not stop the login
        // flow to make it faster. In case it fails - the user's default name will
        // be well formatted part before @ of their email, which is not bad.
        dispatch(updateUserName(userName)).then(() => {
            segment.track('Updated Name', { Name: userName });
            segment.identify({ name: userName });
        });
        const locationArray = location.pathname.split('/');
        if (locationArray[locationArray.length - 1] === 'user-name-subscribed') {
            // In the time of writing this means that that the user already
            // has the hearing-profile setup.
            if (!window.isElectron) {
                navigate(`/${locationArray[1]}/login/profile/app-bridge`);
            }
            else if (location.pathname.split('/')[1] === 'web') {
                createConverstionAndRedirectToTranscript();
            }
        }
        else if (locationArray[1] === 'web') {
            // This is the path taken when the user logs in for the first time.
            navigate('/web/login/profile/hearing-profile');
        }
    };
    const createConverstionAndRedirectToTranscript = () => __awaiter(void 0, void 0, void 0, function* () {
        yield createNewConversation()(dispatch, window.store.getState);
        navigate('/web/transcript/');
    });
    const patchProfile = (profile) => {
        dispatch(updateHearingProfile(profile));
    };
    const setHearingProfileFun = (hearingProfile) => {
        segment.track('Hearing Profiled', {
            'Hearing Profile': hearingProfile,
        });
        segment.identify({
            'Hearing Profile': hearingProfile,
        });
        setHearingProfile(hearingProfile);
        const locationArray = location.pathname.split('/');
        if (hearingProfile === 'hearing') {
            navigate(`/${locationArray[1]}/login/profile/org-type`);
            patchProfile(2);
        }
        else {
            navigate(`/${locationArray[1]}/login/profile/voicing-profile`);
        }
    };
    const setVoicingProfile = (voicingProfile) => {
        segment.track('Voice Checked', {
            Voice: voicingProfile,
        });
        segment.identify({
            Voice: voicingProfile,
        });
        const locationArray = location.pathname.split('/');
        navigate(`/${locationArray[1]}/login/profile/org-type`);
        if (hearingProfile === 'hard-of-hearing') {
            if (voicingProfile === 'not voicing') {
                patchProfile(3);
            }
            else {
                patchProfile(1);
            }
        }
        else if (hearingProfile === 'deaf') {
            if (voicingProfile === 'not voicing') {
                patchProfile(4);
            }
            else {
                patchProfile(0);
            }
        }
    };
    const setOrgTypeFun = (orgType) => {
        if (orgType === 'video') {
            segment.track('Role Profiled', { Role: 'user' });
            segment.identify({ Role: 'user' });
            if (!window.isElectron) {
                const locationArray = location.pathname.split('/');
                navigate(`/${locationArray[1]}/login/profile/app-bridge`);
            }
            else if (location.pathname.split('/')[1] === 'web') {
                createConverstionAndRedirectToTranscript();
            }
        }
        else {
            setOrgType(orgType);
        }
        const market = orgType === 'video' ? 'ind' : orgType;
        segment.track('Market Profiled', { Market: market });
        segment.identify({ Market: market });
    };
    const setIsAdminFun = (e) => {
        setIsAdmin(e.target.value);
        setIsAdminRequiredError(false);
        setResError('');
    };
    const fetchOrganizationsByOrgName = (orgName) => __awaiter(void 0, void 0, void 0, function* () {
        if (!currentlySearchingOrganizations)
            return;
        if (currentOrganizationSearch.current !== orgName) {
            // Because there is some delay, we may get answer from server
            // for something else than what is currently typed. We ignore it
            return;
        }
        const response = yield avaApi.organizations.findOrganizations({
            firebaseAuthUID: firebaseUser.uid,
            orgName,
            orgType,
        });
        const { result } = response.data;
        const { resultFromParse, resultFromGoogle } = result || {};
        // We keep 3 results, parse first
        const organizations = (resultFromParse || []).slice(0, 3).map((r) => ({
            name: r.name,
            id: r.objectId,
        }));
        if (organizations.length < 3) {
            const googleOrgs = ((resultFromGoogle === null || resultFromGoogle === void 0 ? void 0 : resultFromGoogle.results) || []).slice(0, 3 - organizations.length).map((r) => ({
                name: r.name,
                address: r.formatted_address,
                id: r.place_id,
            }));
            organizations.push(...googleOrgs);
        }
        setOrganizationsSearchResults(organizations);
        setCurrentlySearchingOrganizations(false);
    });
    const findOrganizationsByOrgName = _.debounce(fetchOrganizationsByOrgName, 500);
    const setOrgNameFun = (e) => {
        setOrgName(undefined);
        setOrgId(undefined);
        setInputOrgName(e.target.value);
        setOrgNameRequiredError(false);
        setResError('');
        if (e.target.value) {
            setCurrentlySearchingOrganizations(true);
            currentOrganizationSearch.current = e.target.value;
            findOrganizationsByOrgName(e.target.value);
        }
        else {
            currentOrganizationSearch.current = null;
            setCurrentlySearchingOrganizations(false);
            setOrganizationsSearchResults(null);
        }
    };
    const submit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        const userRole = isAdmin === 'yes' ? 'admin' : 'user';
        if (!isAdmin || !orgName) {
            setIsAdminRequiredError(!isAdmin);
            setOrgNameRequiredError(!orgName);
            return;
        }
        segment.track('Organization Profiled', { 'Organization Name': orgName });
        segment.track('Role Profiled', { Role: userRole });
        segment.identify({ Role: userRole });
        setResError('');
        setIsAdminRequiredError(false);
        setOrgNameRequiredError(false);
        setLoading(true);
        let orgRes;
        try {
            orgRes = yield saasApi.organizations.createOrganization({
                type: orgType,
                name: orgName,
                role: userRole,
                placeId: orgId,
            });
            const orgResData = orgRes.data;
            if (!orgResData.organization) {
                if (orgResData.error &&
                    orgResData.error.error &&
                    orgResData.error.error.content &&
                    orgResData.error.error.content.errorCode === 'adminAlreadyExists') {
                    setResError('adminAlreadyExists');
                }
                else {
                    setResError('unexpected');
                }
                return;
            }
            yield avaApi.users.updateRole({
                avaId: window.localStorage.getItem('avaId') || '',
                firebaseAuthUID: firebaseUser.uid,
                role: userRole,
            });
        }
        catch (e) {
            setResError('unexpected');
            return;
        }
        finally {
            setLoading(false);
        }
        const avaId = window.localStorage.getItem('avaId');
        // We trigger Org trial, but we ignore its effects to shorten the time
        // it takes for the users to onboard. In case this fails it is still not
        // actionable for the users, and should not block them from accessing the
        // app.
        triggerOrgTrial(avaId || '', firebaseUser.uid).catch((e) => {
            // This backend call fails most of the time, but its failure is
            // inconsequential to the users so we ignore it.
            console.log(`failed to trigger org trial: ${e}`);
        });
        const params = Object.assign(Object.assign({ chn: 'inb', src: 'premium', med: window.isElectron ? 'cc' : 'webapp', mkt: orgType, avaid: avaId, name: userName, orgname: orgName, role: userRole, utm_campaign: window.utm_parameters && window.utm_parameters.name }, (firebaseUser.email && { email: firebaseUser.email })), (firebaseUser.phoneNumber && { phone: firebaseUser.phoneNumber }));
        let baseUrl;
        switch (language) {
            case 'fr':
                baseUrl = 'https://ava-me.typeform.com/to/R8L5rC';
                break;
            case 'en':
            default:
                baseUrl = 'https://ava-me.typeform.com/to/q2n11h';
                break;
        }
        const url = `${baseUrl}${Object.keys(params).reduce((acc, cur) => `${acc}&${cur}=${params[cur]}`, '?')}#userid=${LocalStorage.get(STORAGE_KEYS.AVA_ID) || ''}`;
        if (isAdmin === 'yes') {
            analytics.logEvent('app_submitted_typeform', {
                channel: 'inbound',
                source: 'onboarding',
                market: orgType,
                plan: 'Free',
                organization_name: orgName,
            });
        }
        if (window.desktopIntegration && isAdmin === 'yes') {
            window.desktopIntegration.openExternalURL(url);
            window.location.href = '/';
        }
        else if (!window.isElectron && isAdmin === 'yes') {
            window.location.href = url;
        }
        else if (window.isElectron && isAdmin !== 'yes') {
            createConverstionAndRedirectToTranscript();
        }
        else if (!window.isElectron && isAdmin !== 'yes') {
            const locationArray = location.pathname.split('/');
            navigate(`/${locationArray[1]}/login/profile/app-bridge`);
        }
    });
    const openOrDownloadAvaAppFun = () => {
        // Since it's based on rendering a component,
        // we toggle the visibility alltogether
        setOpenOrDownloadAvaApp(false);
        setTimeout(() => {
            setOpenOrDownloadAvaApp(true);
        }, 0);
    };
    let organizationSearchResultPicto;
    switch (orgType) {
        case 'edu':
            organizationSearchResultPicto = eduOrg;
            break;
        case 'event':
            organizationSearchResultPicto = eventOrg;
            break;
        default:
            organizationSearchResultPicto = hrOrg;
            break;
    }
    if (loading) {
        return React.createElement(Loading, null);
    }
    return (React.createElement(Routes, null,
        React.createElement(Route, { element: React.createElement(Wrapper, null) },
            React.createElement(Route, { path: "user-name", element: React.createElement("div", null,
                    React.createElement("form", { className: classes.orgInfoOptionsWrap },
                        React.createElement(Typography, { variant: "h4", align: "center", className: classes.title, gutterBottom: true }, t('profiling.userName.heading')),
                        React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t(`profiling.userName.body`)),
                        React.createElement(FormControl, { variant: "standard", className: classes.formControl, component: "fieldset", margin: "normal" },
                            React.createElement(TextField, { required: true, fullWidth: true, onChange: (e) => setUserNameFun(e), label: t('profiling.userName.label'), variant: "outlined", "data-qa": "enterUserName", error: userNameRequiredError }),
                            userNameRequiredError && (React.createElement(FormHelperText, { error: true, className: classes.helperText }, t('profiling.userName.required')))),
                        React.createElement(Button, { type: "submit", "data-qa": "continueButtonNamePage", variant: "contained", color: "primary", className: classes.button, onClick: submitUserName, disabled: disabledUsername() || !!loading },
                            loading && React.createElement(CircularProgress, { size: 20, color: "secondary" }),
                            !loading && t('profiling.userName.continue')),
                        userNameResError && (React.createElement(FormHelperText, { error: true, className: classes.centerText }, t(`profiling.userName.resError`))))) }),
            React.createElement(Route, { path: "hearing-profile", element: React.createElement("div", null,
                    React.createElement(Typography, { variant: "h4", align: "center", className: classes.title, gutterBottom: true }, t('profiling.hearingProfile.heading')),
                    React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t('profiling.hearingProfile.body')),
                    React.createElement("div", { className: classes.fabContainer },
                        React.createElement(Fab, { variant: "extended", className: classes.fab, onClick: () => setHearingProfileFun('deaf') },
                            React.createElement("div", { style: { flexGrow: 1, marginRight: 12 } },
                                React.createElement(PhraseMarkdownText, { phraseKey: "profiling.hearingProfile.deaf" })),
                            React.createElement("div", null,
                                React.createElement("img", { src: DeafEmoji, alt: "deaf" }))),
                        React.createElement(Fab, { variant: "extended", className: classes.fab, onClick: () => setHearingProfileFun('hard-of-hearing') },
                            React.createElement("div", { style: { flexGrow: 1 } },
                                React.createElement(PhraseMarkdownText, { phraseKey: "profiling.hearingProfile.hoh" })),
                            React.createElement("div", null,
                                React.createElement("img", { src: HohEmoji, alt: "hard of hearing" }))),
                        React.createElement(Fab, { variant: "extended", className: classes.fab, onClick: () => setHearingProfileFun('hearing') },
                            React.createElement("div", { style: { flexGrow: 1, marginRight: 12 } },
                                React.createElement(PhraseMarkdownText, { phraseKey: "profiling.hearingProfile.hearing" })),
                            React.createElement("div", null,
                                React.createElement("img", { src: EarEmoji, alt: "ear" }))))) }),
            React.createElement(Route, { path: "voicing-profile", element: React.createElement("div", null,
                    React.createElement(Typography, { variant: "h4", align: "center", className: classes.title, gutterBottom: true }, t('profiling.voicingProfile.heading')),
                    React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t('profiling.voicingProfile.body')),
                    React.createElement("img", { src: SomeoneSpeaking, alt: "someone speaking", style: { maxWidth: 300, margin: 'auto', display: 'block' } }),
                    React.createElement("div", { className: classes.fabContainerVoicingProfile },
                        React.createElement(Fab, { variant: "extended", className: classNames([classes.fab, classes.fabMargin]), onClick: () => setVoicingProfile('voicing') },
                            React.createElement("span", { className: classes.fabBold }, t('profiling.voicingProfile.yes'))),
                        React.createElement(Fab, { variant: "extended", className: classNames([classes.fab, classes.fabMargin]), onClick: () => setVoicingProfile('not voicing') },
                            React.createElement("span", { className: classes.fabBold }, t('profiling.voicingProfile.notAlways'))))) }),
            React.createElement(Route, { path: "org-type", element: React.createElement("div", null,
                    React.createElement(Typography, { variant: "h4", align: "center", className: classes.title, gutterBottom: true }, t('profiling.orgType.heading')),
                    React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t('profiling.orgType.body')),
                    React.createElement("div", { className: classes.orgTypeOptionsWrap },
                        React.createElement(Link, { to: "../org-info", type: "button", className: classes.orgTypeButton, onClick: () => setOrgTypeFun('hr'), style: { color: 'rgb(75, 75, 75' } },
                            React.createElement(Typography, { variant: "h6", align: "center", className: classes.title, gutterBottom: true }, t('profiling.orgType.work')),
                            React.createElement("img", { src: Work, alt: "Laptop with Ava captioning on screen", className: classes.orgTypeIllustration })),
                        React.createElement(Link, { to: "../org-info", type: "button", className: classes.orgTypeButton, onClick: () => setOrgTypeFun('edu'), style: { color: 'rgb(75, 75, 75' } },
                            React.createElement(Typography, { variant: "h6", align: "center", className: classes.title, gutterBottom: true }, t('profiling.orgType.school')),
                            React.createElement("img", { src: School, alt: "Laptop with Ava captioning on screen", className: classes.orgTypeIllustration })),
                        React.createElement(Link, { to: "../org-info", type: "button", className: classes.orgTypeButton, onClick: () => setOrgTypeFun('event'), style: { color: 'rgb(75, 75, 75' } },
                            React.createElement(Typography, { variant: "h6", align: "center", className: classes.title, gutterBottom: true }, t('profiling.orgType.event')),
                            React.createElement("img", { src: Event, alt: "Laptop with Ava captioning on screen", className: classes.orgTypeIllustration })),
                        React.createElement("button", { type: "button", className: classes.orgTypeButton, onClick: () => setOrgTypeFun('video'), style: { color: 'rgb(75, 75, 75' } },
                            React.createElement(Typography, { variant: "h6", align: "center", className: classes.title, gutterBottom: true }, t('profiling.orgType.video')),
                            React.createElement("img", { src: Video, alt: "Laptop with Ava captioning on screen", className: classes.orgTypeIllustration })))) }),
            React.createElement(Route, { path: "org-info", element: React.createElement("div", null,
                    React.createElement("form", { className: classes.orgInfoOptionsWrap },
                        React.createElement(Typography, { variant: "h4", align: "center", className: classes.title, gutterBottom: true }, t(`profiling.orgInfo.heading.${orgType}`)),
                        React.createElement(Typography, { variant: "body1", align: "center", gutterBottom: true }, t(`profiling.orgInfo.body`)),
                        React.createElement(FormControl, { variant: "standard", className: classes.formControl, component: "fieldset", margin: "normal" },
                            React.createElement("div", { style: { position: 'relative' } },
                                React.createElement(TextField, { required: true, fullWidth: true, value: orgName || inputOrgName, onChange: (e) => setOrgNameFun(e), label: t(`profiling.orgInfo.orgName.label.${orgType}`), variant: "outlined", error: resError === 'adminAlreadyExists', InputProps: {
                                        className: orgName && classes.searchFieldWithPadding,
                                    }, onFocus: () => {
                                        setOrgInputFocused(true);
                                    }, onBlur: () => {
                                        // Blur after timeout
                                        // so time to select value
                                        setTimeout(() => {
                                            setOrgInputFocused(false);
                                            setOrgName(orgName || inputOrgName);
                                        }, 150);
                                    } }),
                                orgName && (React.createElement("img", { className: classes.searchResultTypePicto, alt: "search result picto", src: orgId ? organizationSearchResultPicto : addOrg })),
                                currentlySearchingOrganizations && (React.createElement(CircularProgress, { size: 20, className: classes.orgaSearchProgress })),
                                orgInputFocused && inputOrgName && organizationsSearchResults && (React.createElement(OrganizationsSearchResults, { searchResults: organizationsSearchResults, query: inputOrgName, onSelectResult: (result) => {
                                        setOrgName(result.name);
                                        setOrgId(result.id);
                                    }, orgType: orgType }))),
                            orgNameRequiredError && (React.createElement(FormHelperText, { error: true, className: classes.helperText }, t('profiling.orgInfo.required'))),
                            resError === 'adminAlreadyExists' && (React.createElement(FormHelperText, { error: true, className: classes.helperText }, t('profiling.orgInfo.res.adminAlreadyExists')))),
                        React.createElement(FormControl, { variant: "standard", component: "fieldset", margin: "none", required: true },
                            React.createElement(FormLabel, { component: "legend", className: classes.formLabel }, t(`profiling.orgInfo.isAdmin.${orgType}`)),
                            React.createElement(RadioGroup, { value: isAdmin, onChange: (e) => setIsAdminFun(e), row: true },
                                React.createElement(FormControlLabel, { value: "yes", control: React.createElement(Radio, null), label: t('profiling.orgInfo.isAdmin.yes') }),
                                React.createElement(FormControlLabel, { value: "no", control: React.createElement(Radio, null), label: t('profiling.orgInfo.isAdmin.no') }),
                                isAdminRequiredError && (React.createElement(FormHelperText, { error: true, className: classes.checkboxHelperText }, t('profiling.orgInfo.required'))))),
                        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", className: classes.button, onClick: (e) => submit(e), disabled: !orgName },
                            loading && React.createElement(CircularProgress, { size: 20, color: "secondary" }),
                            !loading && t('profiling.orgInfo.continue')),
                        resError === 'newTrialError' && (React.createElement(FormHelperText, { error: true, className: classes.centerText }, t(`profiling.orgInfo.res.newTrialError`))),
                        resError === 'unexpected' && (React.createElement(FormHelperText, { error: true, className: classes.centerText }, t(`profiling.orgInfo.res.unexpectedError`))))) }),
            React.createElement(Route, { path: "app-bridge", element: React.createElement(React.Fragment, null,
                    React.createElement(Card, { style: {
                            boxShadow: 'none',
                            marginBottom: '20px',
                            textAlign: 'center',
                        } },
                        React.createElement(CardActions, null,
                            React.createElement("div", { style: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '0 auto 12px',
                                } },
                                React.createElement(Typography, { variant: "h4", className: classes.blueTitle, align: "center", gutterBottom: true }, t('profiling.appBridge.captionsForAnyVideo')),
                                React.createElement(Typography, { variant: "h4", className: classes.blueTitleSmall, align: "center", gutterBottom: true }, t('profiling.appBridge.frontAndCenter'))))),
                    React.createElement("div", { style: { textAlign: 'center', marginTop: '50%', position: 'relative' } },
                        React.createElement("div", { className: classes.loginImageContainer },
                            React.createElement("img", { src: language === 'fr' ? '/newLoginBridgeFr.svg' : '/newLoginBridge.svg', alt: "Laptop with Ava captioning on screen", className: classes.illustration })),
                        React.createElement(Button, { type: "submit", variant: "contained", color: "primary", className: classes.bigButton, onClick: () => openOrDownloadAvaAppFun(), style: { marginTop: 15 } }, t('profiling.appBridge.downloadAvaDesktop', { OS: getOS() })),
                        React.createElement(Typography, { variant: "subtitle1", align: "center" },
                            React.createElement("button", { className: classes.maybeLater, type: "button", onClick: () => {
                                    if (location.pathname.split('/')[1] === 'web') {
                                        createConverstionAndRedirectToTranscript();
                                    }
                                } }, t('profiling.appBridge.maybeLater')))),
                    openOrDownloadAvaApp && React.createElement(OpenOrDownloadAvaAppComponent, null)) }),
            React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/web/login/profile/user-name" }) }))));
};
export default ScribeLoginProfiling;
