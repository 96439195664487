import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
const useStyles = makeStyles(() => ({
    root: {
        '&:after': {
            position: 'absolute',
            overflow: 'hidden',
            display: 'inline-block',
            verticalAlign: 'bottom',
            content: "'\\2026'",
            width: 0,
            WebkitAnimation: '$ellipsis steps(4, end) 1600ms infinite',
            animation: '$ellipsis steps(4, end) 1600ms infinite',
        },
    },
    '@keyframes ellipsis, @-webkit-keyframes-ellipsis': {
        to: {
            width: 28,
        },
    },
}));
const LoadingText = ({ children, className }) => {
    const classes = useStyles();
    return React.createElement("span", { className: classnames(classes.root, className) }, children);
};
export default LoadingText;
