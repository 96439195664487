import './DebugWindow.css'; // import the CSS file for styling
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDisplayDev } from '../../selectors/v1Session';
function DebugWindow() {
    const [open, setOpen] = useState(false);
    const [isDragging, setIsDragging] = useState(false); // state to track dragging
    const [isResizing, setIsResizing] = useState(false); // state to track resizing
    const [posX, setPosX] = useState(0); // state to track horizontal position
    const [posY, setPosY] = useState(0); // state to track vertical position
    const [width, setWidth] = useState(500); // state to track width
    const [height, setHeight] = useState(600); // state to track height
    const [path, setPath] = useState('');
    const [pathValue, setPathValue] = useState(null);
    const state = useSelector((x) => x);
    const displayDev = useSelector(selectDisplayDev);
    function displayPathValue() {
        try {
            const value = path ? eval(`state.${path}`) : state;
            const valueString = JSON.stringify(value, null, 2);
            setPathValue(valueString);
        }
        catch (error) {
            console.error(error);
            setPathValue(`Error: ${error.message}`);
        }
    }
    function clearPathValue() {
        setPath('');
        setPathValue(null);
    }
    // effect to add resize listener on mount and remove on unmount
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });
    // function to handle resize event, updates size
    const handleResize = () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const maxWidth = windowWidth - posX - 20;
        const maxHeight = windowHeight - posY - 20;
        setWidth(Math.min(width, maxWidth));
        setHeight(Math.min(height, maxHeight));
    };
    // function to handle mouse down event on title bar or resizer, starts dragging or resizing
    const handleMouseDown = (e) => {
        if (e.target.className === 'debug-title') {
            setIsDragging(true);
        }
        else {
            setIsResizing(true);
        }
        setPosX(e.clientX);
        setPosY(e.clientY);
    };
    // function to handle mouse move event when dragging or resizing, updates position or size
    const handleMouseMove = (e) => {
        if (isDragging) {
            const deltaX = e.clientX - posX;
            const deltaY = e.clientY - posY;
            setPosX(e.clientX);
            setPosY(e.clientY);
            document.getElementById('debug-window').style.left =
                document.getElementById('debug-window').offsetLeft + deltaX + 'px';
            document.getElementById('debug-window').style.top =
                document.getElementById('debug-window').offsetTop + deltaY + 'px';
        }
        else if (isResizing) {
            const deltaX = e.clientX - posX;
            const deltaY = e.clientY - posY;
            setPosX(e.clientX);
            setPosY(e.clientY);
            setWidth(width + deltaX);
            setHeight(height + deltaY);
        }
    };
    // function to handle mouse up event when dragging or resizing stops
    const handleMouseUp = () => {
        setIsDragging(false);
        setIsResizing(false);
    };
    // function to handle click event on close button, hides the window
    const handleClose = () => {
        setOpen(false);
    };
    function clearLocalStorage() {
        localStorage.clear();
    }
    if (!open) {
        if (!displayDev) {
            return null;
        }
        return (React.createElement("button", { style: { background: 'black', color: 'green', marginRight: 20 }, onClick: () => setOpen(true) }, "Open Debug Console"));
    }
    return (React.createElement("div", { id: "debug-window", className: "debug-window", style: { width: `${width}px`, height: `${height}px` }, onMouseMove: handleMouseMove, onMouseUp: handleMouseUp },
        React.createElement("div", { className: "debug-title", onMouseDown: handleMouseDown },
            React.createElement("span", { className: "debug-title-text" }, "Debug"),
            React.createElement("button", { className: "debug-close", onClick: handleClose }, "X")),
        React.createElement("div", { className: "debug-resizer", onMouseDown: handleMouseDown }),
        React.createElement("div", { className: "debug-content" },
            React.createElement("div", { className: "debug-placeholder" },
                React.createElement("button", { onClick: clearLocalStorage, className: "debug-button" }, "Clear Local Storage")),
            React.createElement("div", { className: "debug-placeholder" },
                React.createElement("div", null,
                    React.createElement("label", { htmlFor: "pathInput", style: { display: 'block', marginTop: '10px' } }, "Enter state subpath:"),
                    React.createElement("input", { type: "text", id: "pathInput", value: path, onChange: (e) => setPath(e.target.value), style: { padding: '5px', borderRadius: '3px', border: '1px solid #ccc' } }),
                    React.createElement("button", { onClick: displayPathValue, style: {
                            backgroundColor: 'black',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '3px',
                            border: 'none',
                            cursor: 'pointer',
                            marginLeft: '10px',
                        } }, "Show JSON")),
                pathValue && (React.createElement("div", { style: { padding: '10px', borderRadius: '5px', marginTop: '10px' } },
                    React.createElement("pre", null, pathValue),
                    React.createElement("button", { onClick: clearPathValue, style: {
                            backgroundColor: 'black',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '3px',
                            border: 'none',
                            cursor: 'pointer',
                            marginTop: '10px',
                        } }, "Hide JSON")))),
            React.createElement("div", { className: "debug-placeholder" }, "Debug Function 3"))));
}
export default DebugWindow;
