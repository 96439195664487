import classnames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import CloseButton from '../../../components/CCMode/CloseButton';
import ExpandButton from '../../../components/CCMode/ExpandButton';
import StartConversationButton from '../../../components/CCMode/StartConversationButton';
import { setElectronCaptionMode } from '../../../store/slices/uiState';
import useStyles from './HomeView.styles';
const HomeView = ({ createNewConversation, setCCMode, setShowBubbleAboveCCMode, showBubbleAboveCCMode }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    return (React.createElement("div", { className: classes.root },
        React.createElement("div", { className: classnames(classes.buttons, {
                [classes.buttonsVisible]: showBubbleAboveCCMode,
            }) },
            React.createElement(ExpandButton, { onClick: () => dispatch(setElectronCaptionMode(false)) }),
            React.createElement(CloseButton, { onClick: () => window.electronIPC.sendCCMode('close') })),
        React.createElement(StartConversationButton, { onClick: () => {
                setCCMode('conversation');
                createNewConversation();
            }, onMouseEnter: () => setShowBubbleAboveCCMode(true) })));
};
export default HomeView;
