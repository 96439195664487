// @flow
import RemoveIcon from '@mui/icons-material/Remove';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CircularIconWrapper } from '../components';
import Member from '../Members/Member';
const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: 16,
        display: 'flex',
        justifyContent: 'space-between',
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    license: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: 16,
    },
    trialContainer: {
        backgroundColor: theme.palette.ava.blue2,
        borderRadius: 6,
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        padding: '0 4px',
        marginLeft: 4,
    },
}));
const MemberWithAssignedLicense = ({ avaName, avatar, license, name, onRemoveLicense, }) => {
    const classes = useStyles();
    const isTrial = license.includes('Trial');
    const handleRemove = () => {
        onRemoveLicense(avaName);
    };
    return (React.createElement("div", { className: classes.container },
        React.createElement(Member, { avaName: avaName, avatar: avatar, name: name }),
        React.createElement("div", { className: classes.rightContainer },
            React.createElement("div", { className: classes.license },
                React.createElement(Typography, null, isTrial ? license.split(' ')[0] : license),
                isTrial && (React.createElement("div", { className: classes.trialContainer },
                    React.createElement(Typography, null, "Trial")))),
            React.createElement(CircularIconWrapper, { onClick: handleRemove },
                React.createElement(RemoveIcon, null)))));
};
export default MemberWithAssignedLicense;
