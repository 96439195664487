import { useEffect } from 'react';
const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target) && callback) {
            callback();
        }
    };
    useEffect(() => {
        if (callback) {
            document.addEventListener('click', handleClick);
            return () => {
                document.removeEventListener('click', handleClick);
            };
        }
    }, [callback]);
};
export default useOutsideClick;
