var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import 'react-phone-input-2/lib/material.css';
import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import firebase, { authInstance } from '../../firebase';
const styles = (theme) => {
    return {
        form: {
            marginTop: 4,
            width: '100%', // Fix IE 11 issue.
        },
        message: {
            margin: '12px 0 16px',
        },
        paper: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 32,
            maxWidth: 420,
        },
        phoneInput: {
            '&:focus': {
                borderColor: `${theme.palette.ava.blue} !important`,
                boxShadow: `0 0 0 1px ${theme.palette.ava.blue} !important`,
            },
            fontFamily: 'inherit !important',
            width: '100% !important',
            height: 56,
        },
        codeInput: {
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '& input[type=number]': {
                '-moz-appearance': 'texfield',
            },
        },
        phoneInputWrap: {
            fontFamily: 'inherit',
            width: '100%',
        },
        submit: {
            boxShadow: 'none',
            color: 'white',
            margin: '12px 0 8px',
        },
        title: {
            fontWeight: 700,
        },
    };
};
const ScribeLoginPhone = (props) => {
    const { classes, t } = props;
    const [code, setCode] = useState('');
    const [invalidCode, setInvalidCode] = useState(false);
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
    const [message, setMessage] = useState('inputPhone');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(0);
    useEffect(() => {
        authInstance.useDeviceLanguage();
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            size: 'invisible',
        });
    }, []);
    const sendVerificationCode = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        const appVerifier = window.recaptchaVerifier;
        setLoading(true);
        // we received a bunch of spam auth attempts from indonesian numbers, so disallow them
        const isIndonesia = phone.startsWith('62');
        if (isIndonesia) {
            setInvalidPhoneNumber(true);
            setLoading(false);
            setMessage('invalidPhoneNumber');
            return;
        }
        try {
            const confirmationResult = yield authInstance.signInWithPhoneNumber(`+${phone}`, appVerifier);
            window.confirmationResult = confirmationResult;
            setInvalidPhoneNumber(false);
            setLoading(false);
            setMessage('inputCode');
            setStep(1);
        }
        catch (err) {
            setLoading(false);
            Sentry.captureException(err);
            if (err.code === 'auth/invalid-phone-number') {
                setInvalidPhoneNumber(true);
                setMessage('invalidPhoneNumber');
            }
        }
    });
    const confirmVerificationCode = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        setLoading(true);
        window.confirmationResult
            .confirm(code)
            .then((result) => __awaiter(void 0, void 0, void 0, function* () {
            const { user } = result;
            yield authInstance.setPersistence('local');
            if (user) {
                setLoading(true);
            }
        }))
            .catch((e) => {
            if (e.code === 'auth/invalid-verification-code') {
                setInvalidCode(true);
                setLoading(false);
                setMessage('invalidCode');
                Sentry.captureException(e);
            }
            else {
                setLoading(false);
                Sentry.captureException(e);
            }
        });
    });
    return (React.createElement("div", null,
        React.createElement("div", { className: classes.paper },
            React.createElement(Typography, { variant: "h4", className: classes.title, gutterBottom: true }, t('signIn')),
            React.createElement(Typography, { variant: "body1", className: classes.message, align: "center", color: invalidPhoneNumber || invalidCode ? 'error' : 'initial' }, t(`signin-messages.${message}`)),
            React.createElement("form", { className: classes.form, noValidate: true },
                step === 0 && (React.createElement(PhoneInput, { containerClass: classes.phoneInputWrap, country: "us", defaultErrorMessage: "Invalid phone number", excludeCountries: ['id'], inputClass: classes.phoneInput, isValid: !invalidPhoneNumber, onChange: (phoneNumber) => setPhone(phoneNumber), value: phone })),
                step === 1 && (React.createElement(TextField, { variant: "outlined", className: classes.codeInput, margin: "none", required: true, fullWidth: true, name: "code", label: "Code", type: "number", id: "code", "data-qa": "code", autoFocus: true, autoComplete: "none", value: code || '', onChange: (event) => setCode(event.target.value), error: invalidCode })),
                step === 0 && (React.createElement(Button, { fullWidth: true, variant: "contained", color: "primary", className: classes.submit, id: "sign-in-button", "data-qa": "continueButton", disabled: loading, onClick: (event) => sendVerificationCode(event) },
                    loading && React.createElement(CircularProgress, { color: "secondary", size: "1.6em" }),
                    !loading && t('continue'))),
                step === 1 && (React.createElement(Button, { type: "submit", "data-qa": "signInButton", fullWidth: true, variant: "contained", color: "primary", className: classes.submit, onClick: (event) => confirmVerificationCode(event), disabled: code.length !== 6 || loading },
                    loading && React.createElement(CircularProgress, { color: "secondary", size: "1.6em" }),
                    !loading && t('signIn')))))));
};
export default withTranslation()(withStyles(styles)(ScribeLoginPhone));
