import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTheme, withStyles, withTheme } from '@mui/styles';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation, withTranslation } from 'react-i18next';
import Toggle from 'react-toggled';
import AsrQueueingMessageLoader from '../../components/AsrQueueingMessageLoader';
import AvatarLogo from '../../components/Icons/AvatarLogo';
import Pencil from '../../components/Icons/Pencil';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectAutoTranslateActive } from '../../selectors/avaTranslate';
import { selectConversationMuted, selectFurthestObservedByScribe } from '../../selectors/combined';
import { selectRecallAIStatus } from '../../selectors/recallAI';
import { selectIsScribing } from '../../selectors/scribe';
import { selectCorrectionMode, selectElectronCaptionMode, selectFontSize } from '../../selectors/ui';
import { selectAvaId, selectIsUserTemporary, selectUserName } from '../../selectors/userProfile';
import { ScribeCorrectionMode } from '../../store/slices/uiState';
import { useAppSelector } from '../../store/store';
import { canShowFlagEmoji } from '../../utils/platform';
import { getText } from '../../utils/scribeUtils';
import { sendAssignVoicingSpearkerMyself } from '../../utils/ws-v1';
import EditProfileModal from './EditProfileModal';
import ScribeTranscriptWordContainer from './ScribeTranscriptWord';
import ScribeWordInputContainer from './ScribeWordInputContainer';
import { useSoloDiaSpeakerNameModalContext } from './SoloDiaSpeakerNameModal';
const styles = (theme) => ({
    loadingText: {
        height: '1em',
        fontSize: '32px',
        lineHeight: '32px',
    },
    itsMeButton: {
        color: theme.palette.ava.blue2,
        background: 'transparent',
        border: `1px solid ${theme.palette.ava.blue2}`,
        borderRadius: '50px',
        marginLeft: '10px',
        fontSize: '1rem',
        lineHeight: '1.5',
        padding: '.0em .75em',
        '&:hover': {
            background: theme.palette.ava.transparent.blueHover[theme.palette.mode],
        },
    },
    itsNotMeButton: {
        color: theme.palette.mode === 'dark' ? theme.palette.grey7 : theme.palette.ava.grey3,
        background: 'transparent',
        border: `1px solid`,
        borderColor: theme.palette.ava.grey7,
        borderRadius: '50px',
        marginLeft: '10px',
        fontSize: '1rem',
        lineHeight: '1.5',
        padding: '.0em .75em',
        '&:hover': {
            background: theme.palette.ava.transparent.grey6,
        },
    },
    itsNotMeEditButton: {
        background: 'transparent',
        height: '20px',
        width: '20px',
        marginLeft: '10px',
        padding: 0,
    },
});
const isTextEqualExceptSelectedWord = (props1, props2, selectedWordIndex) => {
    const t1 = getText(
    // $FlowFixMe
    { texts: props1.texts, speechLang: props1.speechLang }, props1.lang);
    const t2 = getText(
    // $FlowFixMe
    { texts: props2.texts, speechLang: props2.speechLang }, props2.lang);
    const t1Words = t1.split(' ');
    const t2Words = t2.split(' ');
    if (t1Words.length !== t2Words.length) {
        return false;
    }
    if (t1Words.slice(0, selectedWordIndex).join(' ') !== t2Words.slice(0, selectedWordIndex).join(' ') ||
        t1Words.slice(selectedWordIndex + 1, t2Words.length).join(' ') !==
            t2Words.slice(selectedWordIndex + 1, t2Words.length).join(' ')) {
        return false;
    }
    return true;
};
const ScribeTranscript = React.memo((props) => {
    const { author, authorShow, classes, cursors, dividerHeight, hideFirstInternal, highlighted, id, isSelected, lang, participantsHash: participants, scribeSelectTranscriptWord, selectedWordIndex, singleSpeaker, speaker, speechLang, texts, trackName, theme, userScribeFlag, } = props;
    let { indexes: human } = props;
    const { t } = useTranslation();
    const userAvaId = useAppSelector(selectAvaId);
    const userName = useAppSelector(selectUserName);
    const [ws, websocketStatus] = useV1Socket();
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const fontSize = useAppSelector(selectFontSize);
    const scribing = useAppSelector(selectIsScribing);
    const muted = useAppSelector(selectConversationMuted);
    const recallAIStatus = useAppSelector(selectRecallAIStatus);
    const furthestObservedByScribe = useAppSelector(selectFurthestObservedByScribe);
    const scribeCorrectionMode = useAppSelector(selectCorrectionMode);
    const autoTranslateActive = useAppSelector(selectAutoTranslateActive);
    const tempUser = useAppSelector(selectIsUserTemporary);
    human = human || {};
    const participantsHash = participants ? participants.map((p) => p.hash) : undefined;
    let charIndex = 0;
    const text = getText({ texts, speechLang }, lang);
    let transcriptWords = text.split(' ');
    if ((muted || !isSelected) && !text) {
        return null;
    }
    if (furthestObservedByScribe &&
        scribeCorrectionMode === ScribeCorrectionMode.Accurate &&
        !scribing &&
        furthestObservedByScribe.tid === id) {
        transcriptWords = transcriptWords.slice(0, furthestObservedByScribe.index);
    }
    const indexes = {};
    if (cursors && participantsHash) {
        cursors.forEach((cursor) => {
            if (participantsHash.includes(cursor.mutatorHash)) {
                if (!indexes[cursor.index]) {
                    indexes[cursor.index] = [];
                }
                indexes[cursor.index].push(cursor.mutatorHash);
            }
        });
    }
    const allowEdit = websocketStatus === 'online' && (!muted || (recallAIStatus === 'CAPTIONING' && !tempUser));
    const lastHuman = human[(speechLang || lang).split('-')[0]];
    const isInternalAudio = Boolean(speaker && speaker.avaId === userAvaId && trackName && trackName.startsWith('Ava Computer Audio'));
    let color = theme.palette.error.light;
    if (speaker && speaker.theme) {
        const { theme: speakerTheme } = speaker;
        color = speakerTheme[`${theme.palette.mode}_theme`].text_color;
    }
    if (speaker && (speaker.avaId === userAvaId || speaker.avaId === userAvaId)) {
        color =
            theme.palette.mode === 'light'
                ? theme.palette.ava.navyGroup.convoText
                : theme.palette.ava.navyGroup.convoTextDark;
        if (isInternalAudio) {
            color = theme.palette.ava.green;
        }
    }
    if (theme.palette.mode === 'dark' && color === '#000000') {
        color = '#FFFFFF';
    }
    let authorName = author;
    if (speaker) {
        if (speaker.userName) {
            authorName = speaker.userName;
        }
        if (!speaker.myself && speaker.possiblyMyself) {
            authorName = t('transcripts.possiblyYou'); // 'You (?)';
        }
        const translatedSpeaker = t('transcripts.speakerDefault'); // Speaker
        if (authorName === 'speaker_0') {
            authorName = t('transcripts.you');
        }
        else if (authorName.includes('speaker_') || authorName.startsWith('username ')) {
            authorName = authorName.replace('username ', '').replace('speaker_', `${translatedSpeaker} `);
        }
        if (speaker.avaId === userAvaId || speaker.avaId === userAvaId) {
            authorName = t('transcripts.you');
            if (userName === 'web guest') {
                authorName += t('transcripts.tapToSetYourName');
            }
            if (isInternalAudio) {
                authorName = t('transcripts.internalAudioAuthorName');
            }
        }
    }
    let showAuthor = authorShow;
    if (hideFirstInternal && isInternalAudio) {
        // We show "Speakers" as first author name
        // only if it's the only author in the convo
        showAuthor = false;
    }
    function assignSpeakerMyself() {
        sendAssignVoicingSpearkerMyself(ws, author);
    }
    const recallAIEditable = recallAIStatus === 'CAPTIONING' || (speaker && !speaker.avaId.includes('speaker'));
    return (React.createElement("div", { style: {
            marginTop: dividerHeight,
            position: 'relative',
            lineHeight: `${fontSize}px`,
            fontSize: `${fontSize}px`,
            color: electronCaptionMode || (singleSpeaker && theme.palette.mode === 'dark') ? theme.palette.ava.white : color,
            userSelect: electronCaptionMode ? 'none' : undefined,
            MozUserSelect: electronCaptionMode ? 'none' : undefined,
            KhtmlUserSelect: electronCaptionMode ? 'none' : undefined,
            WebkitUserSelect: electronCaptionMode ? 'none' : undefined,
            OUserSelect: electronCaptionMode ? 'none' : undefined,
            marginLeft: 'initial',
        } },
        React.createElement(Grid, { container: true, alignItems: "center", wrap: "nowrap", style: {
                marginTop: showAuthor && speaker && speaker.userAvatar !== '' && !isInternalAudio ? `${fontSize / 5}` : '0',
            } },
            React.createElement(Hidden, { smDown: true },
                React.createElement(Grid, { item: true, xs: "auto", style: {
                        flexBasis: 0,
                        marginRight: !electronCaptionMode ? '5px' : 'initial',
                    } },
                    !electronCaptionMode && showAuthor && speaker && speaker.userAvatar && !isInternalAudio && (React.createElement(Avatar, { key: "avatar", src: speaker.userAvatar, alt: "speaker_photo", style: {
                            width: fontSize * 2,
                            height: fontSize * 2,
                        } })),
                    !electronCaptionMode && showAuthor && speaker && !speaker.userAvatar && !isInternalAudio && (React.createElement(AvatarLogo, { color: color, width: fontSize * 2, height: fontSize * 2 })))),
            React.createElement(Grid, { item: true, xs: "auto", css: { lineHeight: 1 } }, showAuthor && (React.createElement(Toggle, null, ({ on, getTogglerProps, setOff }) => (React.createElement(React.Fragment, null,
                React.createElement(Typography, Object.assign({ variant: "h5", style: {
                        color,
                        fontWeight: 'bold',
                        fontSize,
                        lineHeight: `${fontSize}px`,
                        outline: 'none',
                        display: 'inline-block',
                        cursor: speaker && speaker.avaId === userAvaId && !isInternalAudio && 'pointer',
                    } }, getTogglerProps()),
                    React.createElement("strong", { key: "name" },
                        authorName,
                        autoTranslateActive && (React.createElement(ReactCountryFlag, { style: {
                                marginLeft: '6px',
                                marginTop: '-4px',
                                height: !canShowFlagEmoji() ? 32 : undefined,
                                width: !canShowFlagEmoji() ? 32 : undefined,
                            }, svg: !canShowFlagEmoji(), countryCode: speechLang.split('-')[1] })))),
                speaker && speaker.isSoloDiaSpeaker && speaker.avaId === speaker.userName && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { className: classes.itsMeButton, onClick: assignSpeakerMyself }, t('transcripts.itsMeButton')),
                    React.createElement(NotMeButton, { speaker: speaker, userAvaId: userAvaId, isInternalAudio: isInternalAudio, electronCaptionMode: electronCaptionMode }))),
                speaker && speaker.isSoloDiaSpeaker && speaker.avaId !== speaker.userName && recallAIEditable && (React.createElement(EditNotMeButton, { speaker: speaker, userAvaId: userAvaId, isInternalAudio: isInternalAudio })),
                speaker && speaker.avaId && speaker.avaId === userAvaId && !isInternalAudio ? (React.createElement(EditProfileModal, { open: on, close: setOff })) : (React.createElement("br", null)))))))),
        React.createElement("div", { style: {
                height: electronCaptionMode ? Math.floor(fontSize * 0.6) : Math.floor(fontSize * 0.8),
            } }),
        React.createElement(Grid, { container: true, wrap: "nowrap" },
            React.createElement(Grid, { item: true, xs: 12 },
                (!muted || text) &&
                    transcriptWords.map((wordText, i) => {
                        const wordComp = i === selectedWordIndex && allowEdit && isSelected ? (
                        // eslint-disable-next-line
                        React.createElement(ScribeWordInputContainer, { key: `${i}-${wordText}` })) : (React.createElement(ScribeTranscriptWordContainer, { word: wordText, cursor: userScribeFlag && indexes[i], participants: participants, transcriptId: id, wordIndex: i, highlighted: highlighted, allowEdit: allowEdit, scribe: scribing, unlocked: charIndex + wordText.length > lastHuman, scribeSelectTranscriptWord: scribeSelectTranscriptWord, 
                            // eslint-disable-next-line
                            key: `${i}-${wordText}` }));
                        charIndex += wordText.length + 1;
                        return wordComp;
                    }),
                React.createElement(AsrQueueingMessageLoader, { transcriptId: id })))));
}, (prevProps, nextProps) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { selectedWordIndex, speaker } = prevProps;
    const updatedKeys = [];
    Array.from(Object.keys(nextProps)).forEach((k) => {
        const { [k]: value } = prevProps;
        if (nextProps[k] !== value) {
            updatedKeys.push(k);
        }
    });
    if (updatedKeys.length === 1 && updatedKeys.includes('speaker')) {
        // This is ugly, but `speaker` is recreated often and in a complex way,
        // and this trick makes it not re-render every time room-status-update is sent.
        if (speaker &&
            nextProps.speaker &&
            speaker.avaId === nextProps.speaker.avaId &&
            speaker.userName === nextProps.speaker.userName &&
            speaker.userAvatar === nextProps.speaker.userAvatar &&
            ((_b = (_a = speaker.theme) === null || _a === void 0 ? void 0 : _a.dark_theme) === null || _b === void 0 ? void 0 : _b.text_color) === ((_d = (_c = nextProps.speaker.theme) === null || _c === void 0 ? void 0 : _c.dark_theme) === null || _d === void 0 ? void 0 : _d.text_color) &&
            ((_f = (_e = speaker.theme) === null || _e === void 0 ? void 0 : _e.light_theme) === null || _f === void 0 ? void 0 : _f.text_color) === ((_h = (_g = nextProps.speaker.theme) === null || _g === void 0 ? void 0 : _g.light_theme) === null || _h === void 0 ? void 0 : _h.text_color))
            return true;
    }
    if (updatedKeys.includes('texts') &&
        isTextEqualExceptSelectedWord(nextProps, prevProps, selectedWordIndex) &&
        nextProps.wordBeingEdited) {
        // eslint-disable-next-line
        for (let k of ['wordBeingEdited', 'mutationsQueuedByHumanMutation', 'cursors']) {
            if (updatedKeys.includes(k) && updatedKeys.length === 2) {
                return true;
            }
        }
        if (updatedKeys.length === 1) {
            return true;
        }
    }
    if (updatedKeys.length === 1 &&
        (updatedKeys.includes('wordBeingEdited') || updatedKeys.includes('mutationsQueuedByHumanMutation'))) {
        return true;
    }
    return false;
});
const NotMeButton = ({ speaker, isInternalAudio }) => {
    const { t } = useTranslation();
    const { onSelect } = useSoloDiaSpeakerNameModalContext();
    const classes = makeStyles(styles)();
    function handleNotMeClick() {
        onSelect(speaker, isInternalAudio);
    }
    return (React.createElement(Button, { className: classes.itsNotMeButton, onClick: handleNotMeClick }, t('transcripts.notMeButton')));
};
const EditNotMeButton = ({ speaker, isInternalAudio, userAvaId, }) => {
    const classes = makeStyles(styles)();
    const { onSelect } = useSoloDiaSpeakerNameModalContext();
    const theme = useTheme();
    const color = React.useMemo(() => {
        let color = theme.palette.error.light;
        if (speaker && speaker.theme) {
            const { theme: speakerTheme } = speaker;
            color = speakerTheme[`${theme.palette.mode}_theme`].text_color;
        }
        if (speaker && (speaker.avaId === userAvaId || speaker.avaId === userAvaId)) {
            color =
                theme.palette.mode === 'light'
                    ? theme.palette.ava.navyGroup.convoText
                    : theme.palette.ava.navyGroup.convoTextDark;
            if (isInternalAudio) {
                color = theme.palette.ava.green;
            }
        }
        if (theme.palette.mode === 'dark' && color === '#000000') {
            color = '#FFFFFF';
        }
        return color;
    }, [speaker, theme, userAvaId, isInternalAudio]);
    function handleNotMeClick() {
        onSelect(speaker, isInternalAudio);
    }
    return (React.createElement(IconButton, { className: classes.itsNotMeEditButton, onClick: handleNotMeClick, size: "large" },
        React.createElement(Pencil, { fill: color })));
};
export default withTranslation()(withStyles(styles)(withTheme(ScribeTranscript)));
