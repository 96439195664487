import * as React from 'react';
import { GoogleIcon } from 'react-google-button/lib/icons';
import { useTranslation } from 'react-i18next';
import { LoginButton } from './LoginButton';
export const LoginGoogle = (props) => {
    const { login } = props;
    const { t } = useTranslation();
    return (React.createElement(LoginButton, { login: login, icon: React.createElement(GoogleIcon, null), text: t('signInWithGoogle'), style: {
            backgroundColor: 'white',
            border: 'solid 1px #dddddd',
        } }));
};
