/* eslint-disable no-shadow */
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { withStyles } from '@mui/styles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Toggle from 'react-toggled';
import ScribeAccurate from '../../../assets/icons/scribe-accurate.svg';
import ScribeComfortOption from '../../../assets/icons/scribe-comfort-option.svg';
import ScribeFast from '../../../assets/icons/scribe-fast.svg';
import { styles } from '../../modules/scribe/SidebarHelpers';
import { selectCorrectionMode } from '../../selectors/ui';
import { ScribeCorrectionMode, ScribeCorrectionModeDefault } from '../../store/slices/uiState';
import { setScribeCorrectionMode } from '../../store/slices/uiState';
import { useAppDispatch } from '../../store/store';
import { useAppSelector } from '../../store/store';
import { hasScribe, pendingScribe } from '../../utils/status';
function CaptionsSettings({ classes, status, t, theme }) {
    const scribeCorrectionMode = useAppSelector(selectCorrectionMode);
    const dispatch = useAppDispatch();
    const shownCorrectionMode = scribeCorrectionMode !== ScribeCorrectionMode.Unset ? scribeCorrectionMode : ScribeCorrectionModeDefault;
    if (!hasScribe(status) && !pendingScribe(status))
        return null;
    const handleChange = (newCorrectionMode) => {
        dispatch(setScribeCorrectionMode(newCorrectionMode));
    };
    return (React.createElement(Toggle, { key: "0_toggle_array", defaultOff: true }, ({ on, getTogglerProps }) => [
        React.createElement(ListItem, Object.assign({ button: true }, getTogglerProps(), { key: "toggle_head", classes: {
                focused: classes.root,
            } }),
            React.createElement(ListItemIcon, null,
                React.createElement("img", { src: ScribeComfortOption, alt: "scribe_comfort_option" })),
            React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: t('editorSettings.correctionMode.configName') }),
            React.createElement(ListItemIcon, { classes: { root: classes.expandIcon } }, on ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null))),
        React.createElement(Collapse, { in: on, key: "toggle_collapse" },
            React.createElement(List, { style: { paddingLeft: 10 } },
                React.createElement(ListItem, null,
                    React.createElement(RadioGroup, { "aria-label": "scribe-comfort-option-radio", name: "mode", value: shownCorrectionMode },
                        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                            React.createElement("div", { style: { display: 'flex', alignItems: 'center', cursor: 'pointer' }, onClick: () => handleChange(ScribeCorrectionMode.Fast) },
                                React.createElement(Radio, { size: "small", style: { color: theme.palette.ava.blue }, value: ScribeCorrectionMode.Fast }),
                                React.createElement(ListItemIcon, { style: { minWidth: 24 } },
                                    React.createElement("img", { src: ScribeFast, alt: "Scribe Comfort Option Fast" })),
                                React.createElement(ListItemText, null, t('editorSettings.correctionMode.fastMode'))),
                            React.createElement(ListItemText, { style: { marginLeft: 10, marginTop: -10 }, secondary: t('editorSettings.correctionMode.fastModeDesc') })),
                        React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                            React.createElement("div", { style: { display: 'flex', alignItems: 'center', cursor: 'pointer' }, onClick: () => handleChange(ScribeCorrectionMode.Accurate) },
                                React.createElement(Radio, { size: "small", style: { color: theme.palette.ava.blue }, value: ScribeCorrectionMode.Accurate }),
                                React.createElement(ListItemIcon, { style: { minWidth: 24 } },
                                    React.createElement("img", { src: ScribeAccurate, alt: "Scribe Comfort Option Accurate" })),
                                React.createElement(ListItemText, null, t('editorSettings.correctionMode.accurateMode'))),
                            React.createElement(ListItemText, { style: { marginLeft: 10, marginTop: -10 }, secondary: t('editorSettings.correctionMode.accurateModeDesc') })))))),
    ]));
}
const mapStateToProps = ({ scribeConversation: { status } }) => ({
    status,
});
export default connect(mapStateToProps)(withTranslation()(withStyles(styles, { withTheme: true })(CaptionsSettings)));
