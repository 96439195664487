/* eslint-disable no-shadow */
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { withStyles } from '@mui/styles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Toggle from 'react-toggled';
import ScribeFontSizeInput from '../../modules/scribe/ScribeFontSizeInput';
import ScribeSetCurseFilter from '../../modules/scribe/ScribeSetCurseFilter';
import ScribeSetThemeColorContainer from '../../modules/scribe/ScribeSetThemeColorContainer';
import { styles } from '../../modules/scribe/SidebarHelpers';
import ZoomBroadcastCaptionMenuItem from '../../modules/scribe/ZoomBroadcastCaptionMenuItem';
import { selectIsInConversation } from '../../selectors/conversation';
import { selectFullScreen } from '../../selectors/ui';
import { selectFeatures } from '../../selectors/userProfile';
import { toggleSideBar } from '../../store/slices/uiState';
import { setFullScreen } from '../../store/slices/uiState';
import { useAppDispatch } from '../../store/store';
import { useAppSelector } from '../../store/store';
function CaptionsSettings({ classes, t }) {
    const features = useAppSelector(selectFeatures);
    const fullScreen = useAppSelector(selectFullScreen);
    const isInConversation = useAppSelector(selectIsInConversation);
    const dispatch = useAppDispatch();
    const handleUpdateFullScreen = (e) => {
        e.preventDefault();
        if (window.navigator.share && document.body.fullScreen) {
            document.body.requestFullscreen();
        }
        dispatch(setFullScreen(!fullScreen));
        dispatch(toggleSideBar());
    };
    return (React.createElement(Toggle, { key: "0_toggle_array", defaultOn: true }, ({ on, getTogglerProps }) => [
        React.createElement(ListItem, Object.assign({ button: true }, getTogglerProps(), { key: "toggle_head", classes: {
                focused: classes.root,
            } }),
            React.createElement(ListItemIcon, null,
                React.createElement(SubtitlesOutlinedIcon, null)),
            React.createElement(ListItemText, { classes: { root: classes.listItemText }, "data-qa": "captionSettingsButton", primary: t('sidebar.captionSettings') }),
            React.createElement(ListItemIcon, { classes: { root: classes.expandIcon } }, on ? React.createElement(ExpandLessOutlinedIcon, null) : React.createElement(ExpandMoreOutlinedIcon, null))),
        React.createElement(Collapse, { in: on, key: "toggle_collapse" },
            isInConversation && React.createElement(ScribeFontSizeInput, null),
            isInConversation && React.createElement(ScribeSetCurseFilter, null),
            React.createElement(ScribeSetThemeColorContainer, null),
            React.createElement(ListItem, { onClick: handleUpdateFullScreen, style: {
                    paddingLeft: 32,
                }, role: "button", button: true },
                React.createElement(ListItemIcon, null,
                    React.createElement(FullscreenOutlinedIcon, null)),
                React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: t('theme.fullScreen') })),
            isInConversation && features['zoom-caption'] && React.createElement(ZoomBroadcastCaptionMenuItem, null)),
    ]));
}
export default withTranslation()(withStyles(styles, { withTheme: true })(CaptionsSettings));
