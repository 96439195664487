import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
const useStyles = makeStyles((theme) => ({
    item: {
        paddingTop: 9,
        paddingRight: 12,
        paddingBottom: 9,
        paddingLeft: 9,
        fontSize: 18,
        color: theme.palette.mode === 'light' ? theme.palette.ava.dark1 : theme.palette.ava.white,
        opacity: '1 !important',
        '&:hover': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.ava.navy5 : theme.palette.ava.grey5,
        },
    },
    active: {
        backgroundColor: theme.palette.ava.blue2,
        color: theme.palette.ava.white,
    },
}));
const DropdownItem = ({ active, children, className, onClick }) => {
    const classes = useStyles();
    return (React.createElement(MenuItem, { className: classnames(classes.item, className, { [classes.active]: active }), onClick: onClick, disabled: active }, children));
};
export default DropdownItem;
