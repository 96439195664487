var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getsingletonBoostManager } from '../../utils/boost';
const INITIAL_STATE = {
    boostWords: [],
    isBoosting: false,
    addBoostWordsLoading: false,
    addBoostWordsSuccess: false,
    deleteBoostWordLoading: false,
    boostModifyError: false,
};
const boostSlice = createSlice({
    name: 'boostSlice',
    initialState: INITIAL_STATE,
    reducers: {
        setIsBoosting(state, { payload }) {
            state.isBoosting = payload;
        },
        setBoostWords: (state, { payload }) => {
            const words = payload ? payload.split(',').map((w) => w.trim()) : [];
            state.boostWords = words;
        },
        resetBoostWordsStatus: (state) => {
            state.addBoostWordsLoading = false;
            state.addBoostWordsLoading = false;
            state.addBoostWordsSuccess = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(addBoostWordsRequest.pending, (state) => {
            state.addBoostWordsLoading = true;
        });
        builder.addCase(addBoostWordsRequest.fulfilled, (state) => {
            state.addBoostWordsLoading = false;
            state.addBoostWordsSuccess = true;
        });
        builder.addCase(addBoostWordsRequest.rejected, (state) => {
            state.addBoostWordsLoading = false;
            state.boostModifyError = true;
        });
        builder.addCase(deleteBoostWordRequest.pending, (state) => {
            state.deleteBoostWordLoading = true;
        });
        builder.addCase(deleteBoostWordRequest.fulfilled, (state) => {
            state.deleteBoostWordLoading = false;
        });
        builder.addCase(deleteBoostWordRequest.rejected, (state) => {
            state.deleteBoostWordLoading = false;
            state.boostModifyError = true;
        });
        builder.addCase(createBoostManager.fulfilled, (state, { payload }) => {
            if (payload)
                state.boostManager = payload;
        });
    },
});
export const boostReducer = boostSlice.reducer;
export const { setIsBoosting, setBoostWords, resetBoostWordsStatus } = boostSlice.actions;
export const addBoostWordsRequest = createAsyncThunk('boostSlice/addBoostWords', (words, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { boostManager } = state.boost;
    if (!boostManager)
        throw new Error('BoostManager does not exist');
    boostManager.handleUserAddOrRemoveBoostWords('add', words.join(','));
    return { words }; // we need to return something to ensure thunk is fulfilled
}));
export const deleteBoostWordRequest = createAsyncThunk('boostSlice/deleteBoostWord', (word, { getState }) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { boostManager } = state.boost;
    if (!boostManager)
        throw new Error('BoostManager does not exist');
    boostManager.handleUserAddOrRemoveBoostWords('remove', word);
    return { word }; // we need to return something to ensure thunk is fulfilled
}));
export const createBoostManager = createAsyncThunk('boostSlice/createBoostManager', (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const state = thunkAPI.getState();
    const { v1Socket } = state.v1Session;
    const { subscription } = state.userProfile;
    if (!v1Socket)
        return;
    return getsingletonBoostManager(v1Socket, dispatch, subscription);
});
