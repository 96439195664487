var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-shadow */
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { withStyles } from '@mui/styles';
import * as Sentry from '@sentry/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clearScribeConversationState } from '../../actions';
import { styles } from '../../modules/scribe/SidebarHelpers';
import { selectFirebaseUser } from '../../selectors/auth';
import { useAppSelector } from '../../store/store';
import { logoutAndClean } from '../../utils/user';
function LogOut({ classes, clearScribeConversationState, t, }) {
    const firebaseUser = useAppSelector(selectFirebaseUser);
    return (React.createElement(ListItem, { button: true, onClick: () => __awaiter(this, void 0, void 0, function* () {
            yield logoutAndClean();
            clearScribeConversationState();
            Sentry.configureScope((scope) => {
                scope.setUser(null);
            });
            window.history.pushState({}, document.title, (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) ? '/web/login' : '/web');
            window.location.reload();
        }) },
        React.createElement(ListItemIcon, null,
            React.createElement(ExitToAppOutlinedIcon, null)),
        React.createElement(ListItemText, { classes: { root: classes.listItemText }, primary: (firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) ? t('sidebar.signIn') : t('sidebar.signOut') })));
}
export default connect(() => ({}), {
    clearScribeConversationState,
})(withTranslation()(withStyles(styles, { withTheme: true })(LogOut)));
