import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';
import { getBackendConfig } from '../../utils/backends';
export const getBadge = (backends, backendUrl_) => {
    const backendUrl = backendUrl_ || localStorage.getItem('avaBackendUrl') || getBackendConfig('captionerUrl');
    const backend = Object.values(backends).find((backend) => backend.url === backendUrl || backend.name === backendUrl);
    return {
        name: (backend === null || backend === void 0 ? void 0 : backend.name) || 'backend-not-found',
        backgroundColor: (backend === null || backend === void 0 ? void 0 : backend.color) || 'saddlebrown',
    };
};
const useStyles = makeStyles(() => ({
    wrapper: {
        marginLeft: '3px',
        marginRight: '3px',
        paddingLeft: '6px',
        WebkitAppRegion: 'no-drag',
        borderRadius: '3px',
        paddingRight: '6px',
        paddingTop: '1px',
        paddingBottom: '2px',
    },
}));
export const BackendBadge = ({ children, className, style }) => {
    const classes = useStyles();
    return (React.createElement("div", { className: classnames(classes.wrapper, className), style: style }, children));
};
