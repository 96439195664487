import { useTheme } from '@mui/material/styles';
import React from 'react';
const Scribe = ({ active, color }) => {
    const theme = useTheme();
    let fill = theme.palette.mode === 'light' ? 'black' : 'white';
    if (color) {
        fill = color;
    }
    if (active) {
        return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("g", { clipPath: "url(#clip0_3485_40364)" },
                React.createElement("path", { d: "M10.5561 10.1627C10.3462 10.1035 9.5809 9.80403 9.16946 9.40358C9.15713 9.39663 9.14476 9.38618 9.13657 9.37574C9.13657 9.37574 9.13243 9.37226 9.12833 9.36877C8.77447 9.18421 7.92277 8.65494 7.41669 7.6103C7.25623 7.59636 7.1122 7.57198 6.98877 7.53369L6.73779 11.4615C7.61005 11.0402 8.78269 10.7059 10.2557 10.4726L10.7618 10.3925L11.0704 10.974V10.2567C11.0663 10.2532 11.058 10.2532 11.0539 10.2497C10.9675 10.2358 10.8852 10.2184 10.8071 10.1975C10.7206 10.194 10.6342 10.1836 10.5561 10.1627Z", fill: "white" }),
                React.createElement("path", { d: "M15.555 7.54824C15.2423 8.28645 14.477 9.69321 12.9629 10.1668V10.9746L13.2715 10.3931L13.7776 10.4732C15.2464 10.7065 16.4232 11.0373 17.2955 11.4621L17.0363 7.52734C16.5425 7.66662 15.8924 7.64921 15.555 7.54824Z", fill: "white" }),
                React.createElement("path", { d: "M6.98626 6.94725C7.14673 7.08086 7.54725 7.10726 7.85678 7.10041C8.36264 8.48635 9.56281 8.98154 9.56281 8.98154C9.77156 9.26466 10.4927 9.59718 10.7409 9.66394C10.9012 9.70704 11.1269 9.66876 11.2156 9.63247C11.3814 9.56473 11.5043 9.44131 11.5587 9.29368C11.6101 9.15333 11.593 9.00331 11.5129 8.87262C11.43 8.73954 11.2899 8.64517 11.124 8.6016C10.8838 8.54111 10.209 8.49029 9.82875 8.57257C8.87736 8.18438 8.5338 7.26509 8.39084 6.96744H8.37557C8.45307 6.8706 8.46033 6.74633 8.45395 6.64885C8.44159 6.46928 8.4148 6.06763 8.38976 5.68923C11.4147 5.2949 13.094 2.89824 13.094 2.89824C13.6285 4.46126 15.3458 5.15449 15.6681 5.27246C15.6386 5.7209 15.5936 6.39916 15.5764 6.64885C15.5676 6.78313 15.5808 6.96963 15.7747 7.06288C15.8276 7.09645 16.7752 7.17105 17.0441 6.94725C17.2468 6.77567 17.3614 6.57427 17.379 6.31688C17.379 6.31688 17.4408 5.41047 17.4363 5.29112C17.4279 4.89572 17.2251 4.6267 16.8465 4.48273L16.8051 3.83822C16.6641 1.68599 14.5661 0 12.0142 0C9.46665 0 7.36429 1.68599 7.22768 3.83822L7.18664 4.4814C6.80605 4.62505 6.60244 4.89467 6.59397 5.29112C6.58958 5.41047 6.65129 6.31688 6.65129 6.31688C6.66891 6.57427 6.7835 6.77567 6.98626 6.94725Z", fill: "white" }),
                React.createElement("path", { d: "M20.3156 22.5359H18.0187C17.9389 22.2296 17.6172 22 17.2312 22H6.76878C6.38278 22 6.06106 22.2296 5.98124 22.5359H3.68444C3.30643 22.5359 3 22.7953 3 23.1152C3 23.4351 3.30643 23.6944 3.68444 23.6944H20.3156C20.6936 23.6944 21 23.4351 21 23.1152C21 22.7953 20.6936 22.5359 20.3156 22.5359Z", fill: "white" }),
                React.createElement("path", { d: "M6.70175 15.0923C6.0577 15.0923 5.5569 15.5675 5.63138 16.1097L6.40837 21.8017C6.40837 22.2808 6.86676 22.6678 7.4306 22.6678H16.5688C17.1326 22.6678 17.591 22.2808 17.591 21.8017L18.368 16.1097C18.4424 15.5675 17.9416 15.0923 17.2976 15.0923H6.70175Z", fill: "white" }),
                React.createElement("path", { d: "M4.73099 21.6982H4.73101C5.18418 21.6982 5.53617 21.3639 5.48423 20.9829L4.83075 16.1884C4.76818 15.7374 4.93774 15.2829 5.29506 14.9429C5.65238 14.6047 6.16514 14.4099 6.70211 14.4099H17.2985C17.8354 14.4099 18.3502 14.6047 18.7075 14.9429C19.0648 15.2829 19.2324 15.7374 19.1718 16.1884L18.5166 20.9827C18.4645 21.3638 18.8165 21.6982 19.2698 21.6982H19.2716C19.6676 21.6982 19.9973 21.4405 20.0282 21.1064C20.2682 18.5176 20.1731 15.7749 19.62 14.6593C19.2203 13.8495 18.6349 13.1439 17.8859 12.5887C17.2153 11.9815 16.0714 11.5816 14.9709 11.3216C14.225 11.1453 13.4394 11.4613 13.1207 12.0587L12.5827 13.0671C12.4696 13.2772 12.2355 13.3831 12.0013 13.3831C11.7671 13.3831 11.5329 13.2772 11.4199 13.0671L10.8819 12.0587C10.5632 11.4613 9.77763 11.1453 9.03172 11.3216C7.93122 11.5816 6.78728 11.9814 6.11669 12.5887C5.36774 13.1439 4.7823 13.8495 4.38259 14.6593C3.82946 15.7749 3.73435 18.5176 3.97433 21.1064C4.00531 21.4405 4.33498 21.6982 4.73099 21.6982Z", fill: "white" }),
                React.createElement("circle", { cx: "12", cy: "19", r: "2.875", fill: "#1B367C", stroke: "#1B367C", strokeWidth: "0.25" }),
                React.createElement("path", { d: "M10.5 19.5682L11.4231 20.25L12.4615 19L13.5 17.75", stroke: "white", strokeWidth: "0.428572", strokeLinecap: "round" }),
                React.createElement("path", { d: "M18.6575 4.71222C18.5727 4.72088 18.4874 4.70184 18.4147 4.65802C18.342 4.61421 18.286 4.54806 18.2554 4.46982C18.2248 4.39157 18.2212 4.30559 18.2452 4.22515C18.2693 4.14471 18.3196 4.0743 18.3884 4.0248L20.4259 2.11722C20.5005 2.04748 20.6003 2.00975 20.7032 2.01233C20.8061 2.01491 20.9037 2.05758 20.9746 2.13096C21.0455 2.20435 21.0839 2.30242 21.0813 2.40362C21.0786 2.50482 21.0353 2.60085 20.9606 2.67059L18.9231 4.57817C18.8902 4.6168 18.8497 4.64856 18.8041 4.67159C18.7585 4.69461 18.7086 4.70842 18.6575 4.71222Z", fill: "white" }),
                React.createElement("path", { d: "M22.3525 9.56203C22.2585 9.56242 22.1675 9.52923 22.0965 9.46862C22.0255 9.40802 21.9793 9.32412 21.9665 9.23253C21.9537 9.14094 21.9751 9.04788 22.0268 8.97067C22.0786 8.89346 22.157 8.83734 22.2476 8.81274L24.9562 8.07033C25.0053 8.05679 25.0566 8.0529 25.1072 8.05887C25.1579 8.06484 25.2068 8.08056 25.2513 8.10514C25.2958 8.12972 25.3349 8.16266 25.3664 8.2021C25.3979 8.24154 25.4212 8.28669 25.435 8.33499C25.4487 8.38329 25.4527 8.43378 25.4466 8.48358C25.4405 8.53338 25.4246 8.58152 25.3996 8.62524C25.3746 8.66897 25.3411 8.70742 25.301 8.73842C25.2609 8.76941 25.215 8.79233 25.1659 8.80587L22.4573 9.54828C22.4232 9.55751 22.3879 9.56213 22.3525 9.56203Z", fill: "white" }),
                React.createElement("path", { d: "M25.0744 16.0545C25.0442 16.058 25.0137 16.058 24.9835 16.0545L22.247 15.4152C22.149 15.3901 22.0648 15.3282 22.0126 15.2428C21.9603 15.1575 21.9441 15.0554 21.9674 14.9584C21.9907 14.8614 22.0516 14.7773 22.1372 14.7239C22.2227 14.6705 22.3261 14.6522 22.4252 14.6728L25.1617 15.3121C25.2466 15.3396 25.3191 15.395 25.3672 15.469C25.4153 15.543 25.436 15.6311 25.4257 15.7183C25.4154 15.8056 25.3749 15.8867 25.3109 15.9479C25.2469 16.0092 25.1633 16.0468 25.0744 16.0545Z", fill: "white" }),
                React.createElement("path", { d: "M5.85218 4.10415C5.75184 4.1042 5.65539 4.06601 5.58307 3.9976L3.54556 2.09002C3.50861 2.05549 3.47894 2.01414 3.45825 1.96834C3.43755 1.92253 3.42623 1.87317 3.42493 1.82306C3.42363 1.77295 3.43238 1.72308 3.45068 1.6763C3.46898 1.62951 3.49647 1.58673 3.53158 1.5504C3.56669 1.51406 3.60873 1.48489 3.65531 1.46453C3.70188 1.44417 3.75208 1.43304 3.80303 1.43176C3.85398 1.43049 3.90469 1.43909 3.95226 1.45709C3.99983 1.47509 4.04333 1.50212 4.08027 1.53665L6.11779 3.44423C6.17356 3.49699 6.21201 3.56495 6.2282 3.63936C6.2444 3.71378 6.23759 3.79126 6.20866 3.86185C6.17972 3.93243 6.12999 3.99289 6.06584 4.03546C6.00169 4.07803 5.92606 4.10075 5.84868 4.10072L5.85218 4.10415Z", fill: "white" }),
                React.createElement("path", { d: "M2.15842 8.96723C2.12363 8.97185 2.08837 8.97185 2.05358 8.96723L-0.65495 8.22482C-0.704058 8.21128 -0.749974 8.18835 -0.790075 8.15736C-0.830175 8.12637 -0.863676 8.08791 -0.888665 8.04419C-0.939132 7.95588 -0.951863 7.85147 -0.924056 7.75393C-0.896249 7.6564 -0.830182 7.57372 -0.74039 7.52408C-0.650598 7.47445 -0.544436 7.46193 -0.445258 7.48928L2.26327 8.23169C2.35389 8.25628 2.43235 8.3124 2.48406 8.38962C2.53577 8.46683 2.5572 8.55988 2.54439 8.65147C2.53157 8.74307 2.48537 8.82696 2.41438 8.88757C2.34339 8.94818 2.25243 8.98137 2.15842 8.98097V8.96723Z", fill: "white" }),
                React.createElement("path", { d: "M-0.563983 15.443C-0.666869 15.4549 -0.770327 15.426 -0.851599 15.3629C-0.93287 15.2997 -0.985297 15.2074 -0.997347 15.1062C-1.0094 15.005 -0.980082 14.9032 -0.915851 14.8233C-0.85162 14.7434 -0.757735 14.6918 -0.654849 14.68L2.08164 14.0407C2.13167 14.0278 2.18381 14.025 2.23498 14.0322C2.28616 14.0395 2.33535 14.0567 2.37965 14.083C2.42395 14.1092 2.46248 14.1439 2.49296 14.1849C2.52344 14.226 2.54526 14.2727 2.55714 14.3222C2.56902 14.3717 2.57073 14.423 2.56215 14.4731C2.55357 14.5233 2.53488 14.5712 2.50718 14.6142C2.47949 14.6571 2.44334 14.6942 2.40087 14.7232C2.3584 14.7522 2.31046 14.7726 2.25987 14.7831L-0.476611 15.4224C-0.504825 15.4326 -0.534145 15.4395 -0.563983 15.443Z", fill: "white" })),
            React.createElement("defs", null,
                React.createElement("clipPath", { id: "clip0_3485_40364" },
                    React.createElement("rect", { width: "24", height: "24", fill: "white" })))));
    }
    return (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_3485_40348)" },
            React.createElement("path", { d: "M10.5561 10.1627C10.3462 10.1035 9.5809 9.80403 9.16946 9.40358C9.15713 9.39663 9.14476 9.38618 9.13657 9.37574C9.13657 9.37574 9.13243 9.37226 9.12833 9.36877C8.77447 9.18421 7.92277 8.65494 7.41669 7.6103C7.25623 7.59636 7.1122 7.57198 6.98877 7.53369L6.73779 11.4615C7.61005 11.0402 8.78269 10.7059 10.2557 10.4726L10.7618 10.3925L11.0704 10.974V10.2567C11.0663 10.2532 11.058 10.2532 11.0539 10.2497C10.9675 10.2358 10.8852 10.2184 10.8071 10.1975C10.7206 10.194 10.6342 10.1836 10.5561 10.1627Z", fill: fill }),
            React.createElement("path", { d: "M15.555 7.54824C15.2423 8.28645 14.477 9.69321 12.9629 10.1668V10.9746L13.2715 10.3931L13.7776 10.4732C15.2464 10.7065 16.4232 11.0373 17.2955 11.4621L17.0363 7.52734C16.5425 7.66662 15.8924 7.64921 15.555 7.54824Z", fill: fill }),
            React.createElement("path", { d: "M6.98626 6.94725C7.14673 7.08086 7.54725 7.10726 7.85678 7.10041C8.36264 8.48635 9.56281 8.98154 9.56281 8.98154C9.77156 9.26466 10.4927 9.59718 10.7409 9.66394C10.9012 9.70704 11.1269 9.66876 11.2156 9.63247C11.3814 9.56473 11.5043 9.44131 11.5587 9.29368C11.6101 9.15333 11.593 9.00331 11.5129 8.87262C11.43 8.73954 11.2899 8.64517 11.124 8.6016C10.8838 8.54111 10.209 8.49029 9.82875 8.57257C8.87736 8.18438 8.5338 7.26509 8.39084 6.96744H8.37557C8.45307 6.8706 8.46033 6.74633 8.45395 6.64885C8.44159 6.46928 8.4148 6.06763 8.38976 5.68923C11.4147 5.2949 13.094 2.89824 13.094 2.89824C13.6285 4.46126 15.3458 5.15449 15.6681 5.27246C15.6386 5.7209 15.5936 6.39916 15.5764 6.64885C15.5676 6.78313 15.5808 6.96963 15.7747 7.06288C15.8276 7.09645 16.7752 7.17105 17.0441 6.94725C17.2468 6.77567 17.3614 6.57427 17.379 6.31688C17.379 6.31688 17.4408 5.41047 17.4363 5.29112C17.4279 4.89572 17.2251 4.6267 16.8465 4.48273L16.8051 3.83822C16.6641 1.68599 14.5661 0 12.0142 0C9.46665 0 7.36429 1.68599 7.22768 3.83822L7.18664 4.4814C6.80605 4.62505 6.60244 4.89467 6.59397 5.29112C6.58958 5.41047 6.65129 6.31688 6.65129 6.31688C6.66891 6.57427 6.7835 6.77567 6.98626 6.94725Z", fill: fill }),
            React.createElement("path", { d: "M20.3156 23.5428H18.0187C17.9389 23.2364 17.6172 23.0068 17.2312 23.0068H6.76878C6.38278 23.0068 6.06106 23.2364 5.98124 23.5428H3.68444C3.30643 23.5428 3 23.8021 3 24.122C3 24.4419 3.30643 24.7013 3.68444 24.7013H20.3156C20.6936 24.7013 21 24.4419 21 24.122C21 23.8021 20.6936 23.5428 20.3156 23.5428Z", fill: fill }),
            React.createElement("path", { d: "M6.70175 15.0923C6.0577 15.0923 5.5569 15.5675 5.63138 16.1097L6.40837 21.8017C6.40837 22.2808 6.86676 22.6678 7.4306 22.6678H16.5688C17.1326 22.6678 17.591 22.2808 17.591 21.8017L18.368 16.1097C18.4424 15.5675 17.9416 15.0923 17.2976 15.0923H6.70175Z", fill: fill }),
            React.createElement("path", { d: "M4.73099 21.6982H4.73101C5.18418 21.6982 5.53617 21.3639 5.48423 20.9829L4.83075 16.1884C4.76818 15.7374 4.93774 15.2829 5.29506 14.9429C5.65238 14.6047 6.16514 14.4099 6.70211 14.4099H17.2985C17.8354 14.4099 18.3502 14.6047 18.7075 14.9429C19.0648 15.2829 19.2324 15.7374 19.1718 16.1884L18.5166 20.9827C18.4645 21.3638 18.8165 21.6982 19.2698 21.6982H19.2716C19.6676 21.6982 19.9973 21.4405 20.0282 21.1064C20.2682 18.5176 20.1731 15.7749 19.62 14.6593C19.2203 13.8495 18.6349 13.1439 17.8859 12.5887C17.2153 11.9815 16.0714 11.5816 14.9709 11.3216C14.225 11.1453 13.4394 11.4613 13.1207 12.0587L12.5827 13.0671C12.4696 13.2772 12.2355 13.3831 12.0013 13.3831C11.7671 13.3831 11.5329 13.2772 11.4199 13.0671L10.8819 12.0587C10.5632 11.4613 9.77763 11.1453 9.03172 11.3216C7.93122 11.5816 6.78728 11.9814 6.11669 12.5887C5.36774 13.1439 4.7823 13.8495 4.38259 14.6593C3.82946 15.7749 3.73435 18.5176 3.97433 21.1064C4.00531 21.4405 4.33498 21.6982 4.73099 21.6982Z", fill: fill })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_3485_40348" },
                React.createElement("rect", { width: "24", height: "24", fill: "white" })))));
};
export default Scribe;
