// @
import BackIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import { Drawer, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WorkspaceDivider } from '../components';
import useWorkspaceUIContext from '../hooks/useWorkspaceUIContext';
import AllocatedLicenses from './AllocatedLicenses';
import AssignLicense from './AssignLicense';
import SidebarMemberProfile from './SidebarMemberProfile';
const useStyles = makeStyles(() => ({
    container: {
        padding: 40,
    },
    heading: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    headingLeft: {
        alignItems: 'center',
        display: 'flex',
    },
    closeIcon: {
        cursor: 'pointer',
    },
}));
const WorkspaceSidebar = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { sidebarView, setSidebarView } = useWorkspaceUIContext();
    const close = () => setSidebarView(undefined);
    const getHeading = () => {
        if (sidebarView === 'AllocateLicenses')
            return t('workspace.members.proLicenses');
        if (sidebarView === 'MemberProfile')
            return t('workspace.members.generalInfo');
        if (sidebarView === 'AssignLicense')
            return t('workspace.members.back');
    };
    const goToAllocatedLicenses = () => setSidebarView('AllocateLicenses');
    const goToAssignLicense = () => setSidebarView('AssignLicense');
    return (React.createElement(Drawer, { anchor: "right", onClose: close, open: !!sidebarView, PaperProps: {
            style: { width: '40%' },
        } },
        React.createElement("div", { className: classes.container },
            React.createElement("div", { className: classes.heading },
                React.createElement("div", { className: classes.headingLeft },
                    sidebarView === 'AssignLicense' && (React.createElement(BackIcon, { className: classes.closeIcon, onClick: goToAllocatedLicenses })),
                    React.createElement(Typography, { variant: "h6" }, getHeading())),
                React.createElement(CloseIcon, { className: classes.closeIcon, onClick: close })),
            React.createElement(WorkspaceDivider, null),
            sidebarView === 'MemberProfile' && React.createElement(SidebarMemberProfile, null),
            sidebarView === 'AllocateLicenses' && React.createElement(AllocatedLicenses, { handleGoToAssign: goToAssignLicense }),
            sidebarView === 'AssignLicense' && React.createElement(AssignLicense, null))));
};
export default WorkspaceSidebar;
