import KeyboardOutlinedIcon from '@mui/icons-material/KeyboardOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SquareIcon from '@mui/icons-material/Square';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useV1Socket } from '../../hooks/useV1Socket';
import { selectFirebaseUser } from '../../selectors/auth';
import { selectStatus } from '../../selectors/legacy-conversation';
import { selectAvaId } from '../../selectors/userProfile';
import { updateKeyboardInputShown } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { getDefaultRoomId, sendResetRoomIdMessage, sendRoomStateUpdateMessage } from '../../utils/ws-v1';
import AudioRecorder from '../AudioRecorder';
import { ApplessControlBarButton } from './ApplessControlBarButton';
export function ApplessControlBar({ open }) {
    const status = useAppSelector(selectStatus);
    const [ws] = useV1Socket();
    const avaId = useAppSelector(selectAvaId);
    const firebaseUser = useAppSelector(selectFirebaseUser);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    return (React.createElement(Box, { sx: {
            flexShrink: 0,
            width: '100%',
            backgroundColor: theme.palette.grey[100],
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingY: open ? 2 : 0,
            height: open ? 120 : 0,
            overflow: 'hidden',
            transition: 'transform 0.3s ease-in-out',
            transform: open ? 'translateY(0%)' : 'translateY(100%)',
        } },
        React.createElement(Box, { sx: {
                height: '88px',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                minWidth: '200px',
                padding: 2,
                borderRadius: '56px',
                boxShadow: '2px 2px 10px 0px rgba(0,0,0,0.1)',
                boxSizing: 'border-box',
            } },
            React.createElement(ApplessControlBarButton, null,
                React.createElement(Box, { sx: { marginTop: '10px', marginLeft: '1px' } },
                    React.createElement(AudioRecorder, { hideTextBelow: true }))),
            React.createElement(ApplessControlBarButton, { onClick: () => {
                    dispatch(updateKeyboardInputShown(true));
                } },
                React.createElement(KeyboardOutlinedIcon, null)),
            React.createElement(ApplessControlBarButton, null,
                React.createElement(SettingsOutlinedIcon, null)),
            React.createElement(ApplessControlBarButton, { onClick: () => {
                    // TODO: This is copied from ConversationPage. Abstract out.
                    if (status.host && status.host.avaId === avaId) {
                        if (ws)
                            sendRoomStateUpdateMessage(ws, { value: 'ended' });
                    }
                    if (ws)
                        sendResetRoomIdMessage(ws, getDefaultRoomId(firebaseUser.uid));
                }, border: `1px solid ${theme.palette.error.main}` },
                React.createElement(SquareIcon, { sx: {
                        color: theme.palette.error.main,
                    } })))));
}
