import platform from 'platform';
import { useCallback, useEffect } from 'react';
import { selectInsertSpeakerNameMode } from '../../selectors/ui';
import { setInsertSpeakerNameMode } from '../../store/slices/uiState';
import { useAppDispatch, useAppSelector } from '../../store/store';
import { singletonTrackPlayer } from '../../utils/audio';
import keycodes from '../../utils/keycodes';
import { last } from '../../utils/lodash';
const isMac = platform.os.family === 'OS X';
export default function ScribeKeyPressHandler(props) {
    const { dispatchSpeakerChangeMutation, insertSpeakerName, mergeTranscript, scribeCreateTranscript, scribeDeselectTranscriptWord, scribeSelectTranscriptWord, selectedTranscriptIndex, selectedWordIndex, splitTranscript, status, transcriptsCurrent, } = props;
    const allProps = [
        dispatchSpeakerChangeMutation,
        insertSpeakerName,
        mergeTranscript,
        scribeCreateTranscript,
        scribeDeselectTranscriptWord,
        scribeSelectTranscriptWord,
        selectedTranscriptIndex,
        selectedWordIndex,
        splitTranscript,
        status,
        transcriptsCurrent,
    ];
    const dispatch = useAppDispatch();
    const insertSpeakerNameMode = useAppSelector(selectInsertSpeakerNameMode);
    const handleKeydown = useCallback((ev) => {
        const key = keycodes(ev);
        if (key.ESC) {
            ev.preventDefault();
            ev.stopPropagation();
            // deselect, dont commit whatever was changed
            scribeDeselectTranscriptWord({ skipCommit: true });
            if (insertSpeakerNameMode)
                dispatch(setInsertSpeakerNameMode(false));
        }
        else if (key.TAB) {
            if (insertSpeakerNameMode)
                dispatch(setInsertSpeakerNameMode(false));
            if (selectedWordIndex === undefined) {
                return;
            }
            ev.preventDefault();
            ev.stopPropagation();
            scribeSelectTranscriptWord({
                index: selectedWordIndex,
                indexTranscript: selectedTranscriptIndex,
                direction: key.SHIFT ? -1 : 1,
                skipCreateNextBloc: true,
            }, { src: `${key.SHIFT ? 'SHIFT_' : ''}TAB_KEYPRESS` });
        }
        else if ((!isMac && key.CTRL && key.SHIFT && key.RETURN) || (isMac && key.META && key.SHIFT && key.RETURN)) {
            const lastIdTranscript = last(transcriptsCurrent) ? last(transcriptsCurrent) : 0;
            scribeCreateTranscript(lastIdTranscript);
            if (insertSpeakerNameMode)
                dispatch(setInsertSpeakerNameMode(false));
        }
        else if ((!isMac && key.CTRL && ev.key === 'x') || (isMac && key.META && ev.key === 'x')) {
            ev.preventDefault();
            ev.stopPropagation();
            dispatch(setInsertSpeakerNameMode(true));
        }
        else if ((!isMac && key.CTRL && !key.SHIFT && ev.code.includes('Digit')) ||
            (isMac && key.META && !key.SHIFT && ev.code.includes('Digit'))) {
            ev.preventDefault();
            ev.stopPropagation();
            let index = parseInt(ev.code.split('Digit')[1], 10);
            if (key.ALT) {
                index += 9;
            }
            if (insertSpeakerNameMode) {
                insertSpeakerName(index - 1);
                dispatch(setInsertSpeakerNameMode(false));
            }
            else {
                dispatchSpeakerChangeMutation(index - 1);
            }
        }
        else if ((!isMac && key.CTRL && key.RETURN && !key.SHIFT) || (isMac && key.META && key.RETURN && !key.SHIFT)) {
            scribeCreateTranscript(selectedTranscriptIndex);
        }
        else if ((!isMac && key.CTRL && ev.key === 's') || (isMac && key.META && ev.key === 's')) {
            ev.preventDefault();
            ev.stopPropagation();
            splitTranscript({
                selectedWordIndex,
                selectedTranscriptIndex,
            });
        }
        else if ((!isMac && key.CTRL && key.BACKSPACE) || (isMac && key.META && key.BACKSPACE)) {
            ev.preventDefault();
            ev.stopPropagation();
            ev.preventDefault();
            mergeTranscript({
                selectedTranscriptIndex,
            });
        }
        else if ((!isMac && key.CTRL && ev.key === 'j') || (isMac && key.META && ev.key === 'j')) {
            ev.preventDefault();
            ev.stopPropagation();
            singletonTrackPlayer.incrementDelay();
        }
        else if ((!isMac && key.CTRL && ev.key === 'k') || (isMac && key.META && ev.key === 'k')) {
            ev.preventDefault();
            ev.stopPropagation();
            singletonTrackPlayer.decrementDelay();
        }
        else if ((!isMac && key.CTRL && ev.key === 'u') || (isMac && key.META && ev.key === 'u')) {
            ev.preventDefault();
            ev.stopPropagation();
            singletonTrackPlayer.incrementTinyDelay();
        }
        else if ((!isMac && key.CTRL && ev.key === 'i') || (isMac && key.META && ev.key === 'i')) {
            ev.preventDefault();
            ev.stopPropagation();
            singletonTrackPlayer.decrementTinyDelay();
        }
        else if ((!isMac && key.CTRL && ev.key === 'p') || (isMac && key.META && ev.key === 'p')) {
            ev.preventDefault();
            ev.stopPropagation();
            singletonTrackPlayer.playPause();
        }
        else if ((!isMac && key.CTRL && ev.key === 'g') || (isMac && key.META && ev.key === 'g')) {
            ev.preventDefault();
            ev.stopPropagation();
            singletonTrackPlayer.decreaseGain();
        }
        else if ((!isMac && key.CTRL && ev.key === 'h') || (isMac && key.META && ev.key === 'h')) {
            ev.preventDefault();
            ev.stopPropagation();
            singletonTrackPlayer.increaseGain();
        }
    }, allProps);
    function unsubscribeFromKeydownEvents() {
        document.removeEventListener('keydown', handleKeydown, false);
    }
    function subscribeToKeydownEvents() {
        document.addEventListener('keydown', handleKeydown, false);
    }
    useEffect(() => {
        subscribeToKeydownEvents();
        return unsubscribeFromKeydownEvents;
    }, allProps);
    return null;
}
