import * as Sentry from '@sentry/browser';
import { withSnackbar } from 'notistack';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { getSnack } from '../../utils/snackbar';
const withMediaDevices = (Component) => (props) => {
    if (window.__TAURI__) {
        // The only thing this function does is error out if a part of the API
        // relating to recording audio is not available. In Tauri sometimes
        // (looking at you Linux and Safari) it is not available, but we are not
        // using it in the first place.
        return React.createElement(Component, Object.assign({}, props));
    }
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!window.navigator || !window.navigator.mediaDevices) {
        const { t } = props;
        getSnack(props)(t('snackbar.request.invalidMediaDevice'), {
            variant: 'error',
        });
        Sentry.captureException('Browser incompatibility, `navigator.mediaDevices` missing.');
        return React.createElement("div", null);
    }
    return React.createElement(Component, Object.assign({}, props));
};
export default (Component) => withSnackbar(withTranslation()(withMediaDevices(Component)));
