import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import React, { useState } from 'react';
import shortcutList from '../../../assets/shortcuts';
import Transition from '../../components/Transition';
const Shortcut = withStyles(() => ({
    borderBottom: '3px',
    borderColor: 'grey !important',
}))(Chip);
const ScribeShortcutModal = (props) => {
    const { open, close } = props;
    const [tab, setTab] = useState(0);
    const handleChangeTab = (event, tab) => {
        setTab(tab);
    };
    return (React.createElement(Dialog, { TransitionComponent: Transition, open: open, onClose: close, "aria-labelledby": "form-dialog-title" },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Shortcut List"),
        React.createElement(DialogContent, null,
            React.createElement(Tabs, { value: tab, onChange: handleChangeTab, indicatorColor: "primary", textColor: "secondary", centered: true },
                React.createElement(Tab, { label: "Windows" }),
                React.createElement(Tab, { label: "Mac" })),
            shortcutList &&
                shortcutList.map((categorie) => (React.createElement("div", { key: categorie.title },
                    React.createElement(Typography, { variant: "subtitle1", margin: "normal", gutterBottom: true, key: categorie.title }, categorie.title),
                    React.createElement(List, null, categorie.content.map((shortcutL) => (React.createElement("div", { key: shortcutL.title + Math.random() },
                        React.createElement(ListItem, null,
                            tab === 0 &&
                                shortcutL.shortcut.windows.map((keycode, i, arr) => (React.createElement("span", { key: keycode.toString() },
                                    React.createElement(Shortcut, { label: keycode }),
                                    i !== arr.length - 1 ? ' + ' : ' '))),
                            tab === 1 &&
                                shortcutL.shortcut.mac.map((keycode, i, arr) => (React.createElement("span", { key: keycode.toString() },
                                    React.createElement(Shortcut, { label: keycode }),
                                    i !== arr.length - 1 ? ' + ' : ' '))),
                            React.createElement(ListItemText, { align: "right", primary: shortcutL.title })))))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { variant: "text", onClick: close }, "Close"))));
};
export default ScribeShortcutModal;
