import React from 'react';
import { selectElectronCaptionMode } from '../../selectors/ui';
import { selectAvaId, selectUserName } from '../../selectors/userProfile';
import { useAppSelector } from '../../store/store';
import FinalTranscript from './ScribeFinalTranscript';
const FinalTranscriptList = React.memo((props) => {
    const { features, fontSize, lang, speakers, theme, transcripts, transcriptsFinal } = props;
    const electronCaptionMode = useAppSelector(selectElectronCaptionMode);
    const avaId = useAppSelector(selectAvaId);
    const userName = useAppSelector(selectUserName);
    return (React.createElement(React.Fragment, null, transcriptsFinal.map((tid) => (React.createElement(FinalTranscript, { avaId: avaId, userName: userName, features: features, fontSize: fontSize, key: transcripts[tid].id, lang: lang, speaker: speakers[transcripts[tid].author], theme: theme, transcript: transcripts[tid], electronCaptionMode: electronCaptionMode })))));
}, (prevProps, nextProps) => {
    const { transcriptsFinal, fontSize, theme, lang, speakers } = prevProps;
    if (fontSize !== nextProps.fontSize ||
        theme !== nextProps.theme ||
        lang !== nextProps.lang ||
        speakers !== nextProps.speakers) {
        return false;
    }
    return transcriptsFinal.length === nextProps.transcriptsFinal.length;
});
export default FinalTranscriptList;
