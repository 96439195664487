import { useLocation } from 'react-router-dom';
export const useShouldShowNewSidebarAndHeader = ({ electronCaptionMode, firebaseUser, isInConversation, }) => {
    const location = useLocation();
    return (firebaseUser &&
        !(firebaseUser === null || firebaseUser === void 0 ? void 0 : firebaseUser.isAnonymous) &&
        !isInConversation &&
        !electronCaptionMode &&
        !location.pathname.includes('/login') &&
        !location.pathname.includes('/account/start') &&
        !location.pathname.includes('/account/organization/create'));
};
